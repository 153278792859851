import { ApiKeyModel } from './ApiKey.model';
import { ClsTercerosDireccionesModel } from './ClsTercerosDirecciones.model';

export class ClsTercerosModel extends ApiKeyModel {
  public TER_ID: number;

  public TER_ID_KEY: string;

  public TER_TIPODOCUMENTO_ID: number;

  public TER_IDENTIFICACION: number;

  public TER_NOMBRES: string;

  public TER_APELLIDOS: string;

  public TER_GENERO: string;

  public TER_GENERO_DESCRIP: string;

  public TER_NACIMIENTO: string;

  public TER_PAIS_ID: number;

  public TER_DEPARTAMENTO_ID: number;

  public TER_CIUDAD_ID: number;

  public TER_NATURALEZA_ID: number;

  public TER_REGIMEN_ID: number;

  public TER_RESPONSABILIDADFISCAL_ID: number;

  public TER_TELEFONO: string;

  public TER_CORREO: string;

  public TER_CLAVE: string;

  public TER_CLAVE_KEY: string;

  public TER_EMPRESA_ID: number;

  public TER_ROL: string;

  public TER_CLIENTE_PAGA: boolean;

  public TER_CODIGO_PAIS: string;

  public TER_CODIGO_MONEDA: string;

  public Direcciones: ClsTercerosDireccionesModel[];

  public PIN_DIRECCION: string;

  public PIN_NEGOCIO: string;
  public TER_ACCESO: boolean = false;;
  public TER_PASSWORD: string;
  public TER_PASSWORD_CONFIRMA: string;
}
