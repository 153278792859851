import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SpotifyService {

    constructor(private http: HttpClient) {

    }

    //POSTMAN GENERAR TOKEN
    //https://accounts.spotify.com/api/token
    //grant_type --> client_credentials
    //client_id --> 73d63edcf94d43a4861651726636141b
    //client_secret --> 3de00c004575465188af3efdfa144f8a

    getQuery(query: string) {
        const headers = new HttpHeaders({
            //'Authorization': 'Bearer BQD3o_gRcO06MFiWb_HpnIPufgW-aA3tqu1uI1hzArCRVz8q155f_ziy2CyyagfG-QGh5vLOeoJ1XND9nps'
            'Authorization': 'Bearer BQD4M8ndgCM5FSAa6Q1j42fPCBKC__dMrriPS6ootmwVSLwgjJPT9IutjIN8IaRCbPSkMF2tjnqiD7HZG0k'
        });

        const url = `https://api.spotify.com/v1/${query}`;

        return this.http.get(url, { headers });
    }


    getNewReleases() {
        return this.getQuery("browse/new-releases?limit=20")
            .pipe(map((data: any) => {
                return data.albums.items;
            }));
    }


    getArtistas(termino: string) {
        return this.getQuery(`search?q=${termino}&type=artist&market=US&limit=20`)
            .pipe(map((data: any) => {
                return data.artists.items;
            }));
    }

    getArtista(id: string) {
        return this.getQuery(`artists/${id}`)
            .pipe(map((data: any) => {
                return data;
            }));
    }

    getTopTracks(id: string) {
        return this.getQuery(`artists/${id}/top-tracks?country=ES`)
            .pipe(map((data: any) => {
                return data.tracks;
            }));
    }

    //
}
