import { Injectable, Output, EventEmitter } from '@angular/core';
import { FileModel } from '../models/file.model';


@Injectable({
  providedIn: 'root'
})
export class FileUploadService{
    @Output() _escuchaEvento_Archivo: EventEmitter<FileModel> = new EventEmitter();
  
    constructor() {
    }

    generarEventoArchivo(Valido:Boolean, Archivo: any) {
        this._escuchaEvento_Archivo.emit(Archivo);
    }  
}
