export const
  APIENDPOINT = {
    //Loggin
    verificarLogin: 'api/Acceso/VerificarAcceso',
    verificarInicio: 'api/Acceso/VerificarInicio',

    //Configurar Negocio
    GuardarConfigNegocio: 'api/ConfigNegocio/GuardarConfigNegocio',
    ConsultarConfigNegocio: 'api/ConfigNegocio/ConsultarConfigNegocio',
    EliminarTerceroDomicilio: 'api/ConfigNegocio/EliminarTerceroDomicilio',
    GuardarTerceroDomicilio: 'api/ConfigNegocio/GuardarTerceroDomicilio',
    ListarTerceroDomicilio: 'api/Pedido/ListarDomiciliario',
    ListarTercero: 'api/Pedido/ListarTercero',

    //CargarArchivos
    CargarArchivo: 'api/FileUpload/Upload',

    //Generales
    ListarParametros: 'api/Parametros/ListarParametros',

    //opciones
    ConsultarOpciones: 'api/Opciones/ConsultarOpcionesUsuario',

    //imagenesPromociones
    GuardarImagenPromocion: 'api/ImagenPromocion/GuardarImagenPromocion',
    EliminarImagenPromocion: 'api/ImagenPromocion/EliminarImagenPromocion',
    ListarImagenPromocion: 'api/ImagenPromocion/ListarImagenPromocion',

    //TipoPedido
    GuardarTipoPedidoEmpresa: 'api/TipoPedido/GuardarTipoPedidoEmpresa',
    ListarTipoPedidoEmpresa: 'api/TipoPedido/ListarTipoPedidoEmpresa',
    GuardarDistanciaNegocio: 'api/TipoPedido/GuardarDistanciaNegocio',
    ListarDistanciaNegocio: 'api/TipoPedido/ListarDistanciaNegocio',
    ListarMedioPago: 'api/TipoPedido/ListarMedioPago',
    ListarTiempoEsperaEmpresa: 'api/TipoPedido/ListarTiempoEsperaEmpresa',

    //Pedidos
    ConsultarPedidosNegocio: 'api/Pedido/ConsultarPedidosNegocio',
    CambiarEstadoPedido: 'api/Pedido/CambiarEstadoPedido',
    ImprimirPedido: 'api/Pedido/ImprimirPedido',
    AceptarPedido: 'api/Pedido/AceptarPedido',
    ListarDomiciliario: 'api/Pedido/ListarDomiciliario',
    GuardarDomiciliario: 'api/Pedido/GuardarPedidoDomiciliario',
    ConsultarPedidosKanban: 'api/Pedido/ConsultarPedidosKanban',
    ConsultarPedidosKanbanHistorial: 'api/Pedido/ConsultarPedidosKanbanHistorial',
    ImprimirPedidoFactura: 'api/Pedido/ConsultarFacturaImprimir',
    CancelarPedidoId: 'api/Pedido/CancelarPedidoId',

    //Negocio
    NegocioPedidoOnlineListar: 'api/Negocio/NegocioPedidoOnlineListar',

    //Tercero
    TerceroPedidoOnlineLogin: 'api/Terceros/TerceroPedidoOnlineLogin',
    RecuperarClaveOnline: 'api/Terceros/RecuperarClaveOnline',

    //Personas
    setPersonas: 'api/Persona/guardarPersona',
    getPersonas: 'api/Persona/listarPersonas',
    getPersonasNombre: 'api/Persona/filtrarPersonasNombreCompleto',
    getPersonaID: 'api/Persona/listarPersonaID',
    setEliminarPersonaID: 'api/Persona/eliminarPersona',

    //pagos
    asignarPagoWompi: 'api/VerificaPagos/VerificaWompi',
    listarPedidoKey: 'api/CarritoCompra/ListarPedidoKey',
    verificarPagoPedidoKey: 'api/CarritoCompra/VerificarPagoPedidoKey',

    //Notificacion
    ConsultarHub: 'api/Notificacion/ConsultarUrlHub',

    //ConfigEmpresa
    ConsultarMapaKey: 'api/ConfigEmpresa/ConsultarMapaKey',
    GuardarMapaKey: 'api/ConfigEmpresa/GuardarMapaKey',
    ConsultarSMTPEmpresa: 'api/ConfigEmpresa/ConsultarSMTP',
    GuardarSMTPEMpresa: 'api/ConfigEmpresa/GuardarSMTP',
    ConsultarReferenciasEmpresa: 'api/ConfigEmpresa/ConsultarReferenciasEmpresa',
    ConsultarReferenciasEmpresaDescripcion: 'api/ConfigEmpresa/ConsultarReferenciasEmpresaDescripcion',
    ConsultarNegociosEmpresa: 'api/ConfigEmpresa/ConsultarNegociosEmpresa',


    //Memu
    GuardarMenuApp: 'api/MenuVenta/GuardarMenuEmpresa',
    GuardarMenuDetalleApp: 'api/MenuVenta/GuardarMenuDetalle',
    GuardarMenuDetalleArticulo: 'api/MenuVenta/GuardarMenuDetalleArticulo',
    GuardarMenuDetalleTopping: 'api/MenuVenta/GuardarMenuDetalleTopping',
    GuardarMenuDetalleToppingArticulo: 'api/MenuVenta/GuardarMenuDetalleToppingArticulo',

    EliminarMenuApp: 'api/MenuVenta/EliminarMenuEmpresa',
    ConsultarMenuApp: 'api/MenuVenta/ListarMenuApp',


    //
    getTipoUsuarios: 'api/Usuario/listarTipoUsuario',
    uploadFile: 'api/upload/upload',
    getVerificarLogin: 'api/ChatLogin/getVerificarLogin',
    postVerificarSesion: 'api/ChatLogin/VerificarSesion',


    //PEDIDOS CALL CENTER
    CallCenterTipoPedidoListar: 'api/PedidoCallCenter/ListarTipoPedido',
    CallCenterTerceroBuscar: 'api/PedidoCallCenter/TerceroBuscar',
    CallCenterTerceroBuscarPorTipo: 'api/PedidoCallCenter/TerceroBuscarPorTipo',
    CallCenterTerceroGuardar: 'api/PedidoCallCenter/TerceroGuardar',
    CallCenterTerceroGuardarOnline: 'api/PedidoCallCenter/TerceroGuardarOnline',
    CallCenterDireccionGuardar: 'api/PedidoCallCenter/DireccionGuardar',
    CallCenterDireccionEliminar: 'api/PedidoCallCenter/DireccionEliminar',
    CallCenterNegocioListar: 'api/PedidoCallCenter/NegocioListar',
    CallCenterNegocioPorTipoPedidoListar: 'api/PedidoCallCenter/NegocioPorTipoPedidoListar',
    CallCenterNegocioIdPorTipoPedidoListar: 'api/PedidoCallCenter/NegocioIdPorTipoPedidoListar',
    CallCenterMenuVentaListar: 'api/PedidoCallCenter/MenuVentaListar',
    CallCenterMenuDetalleListar: 'api/PedidoCallCenter/MenuDetalleListar',
    CallCenterMenuDetalleArticuloListar: 'api/PedidoCallCenter/MenuDetalleArticuloListar',
    CallCenterToppingsListar: 'api/PedidoCallCenter/ToppingsListar',
    CallCenterPedidoGuardar: 'api/PedidoCallCenter/PedidoGuardar',

    ConsultarDireccionApi: 'api/PedidoCallCenter/DireccionListar',
    ConsultarPlaceIdApi: 'api/PedidoCallCenter/PlaceIdListar',

    ZonaDomicilioGuardar: 'api/ZonaDomicilio/ZonaDomicilioGuardar',
    ZonaDomicilioEliminar: 'api/ZonaDomicilio/ZonaDomicilioEliminar',
    ZonaDomicilioListar: 'api/ZonaDomicilio/ZonaDomicilioListar',
  }
