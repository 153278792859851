import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MasterModel } from '../models/master.model';
import { BaseService } from './_Base.service';
import { environment } from '../../environments/environment';
import { APIENDPOINT } from '../config/configuration';
import { ClsUsuarioAccesoModel } from '../models/Web/ClsAccesoUsuario.model';
import { ClsOpcionesModel } from '../models/Web/ClsOpciones.model';

@Injectable({
  providedIn: 'root'
})
export class OpcionesService extends BaseService<any, MasterModel> {
    usuId: number =0;
    private apiURL: string;
  ip: string = "";
  nombre: string = "prueba";
  fecha: string = "";
  nombreusuario: string = "";
  opcionesUsu: ClsOpcionesModel[] = [];

    constructor(protected _http: HttpClient) {
        super(_http, environment.apiGatewayURL)
      this.apiURL = environment.apiGatewayURL;

      let datos: any = localStorage.getItem('sesion');
      if (datos != null && datos!= '') {
        let objUsu = new ClsUsuarioAccesoModel();
        objUsu = JSON.parse(datos);
        this.usuId = objUsu.USU_ID;
      }
    }

  async asignarOpciones(id: number) {
    let resp: any = await this.ConsultarOpcionesUsuario('');
    if (resp != null) {
      this.opcionesUsu = resp;
    }
  }

  async ConsultarOpcionesUsuario(_token: string) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }

    if (this.usuId != 0) {
      return new Promise(resolve => {
        this.get(APIENDPOINT.ConsultarOpciones + "?UsuarioId=" + this.usuId, true, token)
          .subscribe(resp => {
            //console.log("opciones", resp);
            if (resp.IsSuccess) {
              resolve(resp.Data);

            } else {
              resolve(null);
              console.log('Error al consultar opciones usuario');
            }
          }, error => {
            resolve(null);
            console.log('Error al consultar opciones usuario');
            return null;
          });
      });
    }
  }

}
