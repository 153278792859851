import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MasterModel } from '../models/master.model';
import { BaseService } from './_Base.service';
import { environment } from '../../environments/environment';
import { APIENDPOINT } from '../config/configuration';
import { ClsTercerosModel } from '../models/Aplicacion/ClsTerceros.model';
import { ClsTercerosDireccionesModel } from '../models/Aplicacion/ClsTercerosDirecciones.model';
import { ClsReferenciaModel } from '../models/Aplicacion/ClsReferencia.model';
import { ClsNegocioModel } from '../models/Aplicacion/ClsNegocio.model';
import { ClsCarritoCompraModel } from '../models/Aplicacion/ClsCarritoCompra.model';
import { ClsTipoPedidoModel } from '../models/Aplicacion/ClsTipoPedidoEmpresa.model';
import { ClsPedidoModel } from '../models/Aplicacion/ClsPedido.model';
import { LocalizacionService } from '../services/localizacion.service';
import { ClsPedidoUbicacionModel } from '../models/Aplicacion/ClsPedidoUbicacion.model';


@Injectable({
  providedIn: 'root'
})
export class PedidoCallCenterService extends BaseService<any, MasterModel> {
  public Carrito_Compra: ClsCarritoCompraModel = new ClsCarritoCompraModel();

  public Negocio_Pedido: ClsNegocioModel = new ClsNegocioModel();
  public Tipo_Pedido: ClsTipoPedidoModel = new ClsTipoPedidoModel();
  public Tercero_Pedido: ClsTercerosModel = new ClsTercerosModel();
  public Direccion_Pedido: ClsTercerosDireccionesModel = new ClsTercerosDireccionesModel();
  public Informacion_Pedido: ClsPedidoModel = new ClsPedidoModel();

  private apiURL: string;

  constructor(protected _http: HttpClient,
    private localizacion: LocalizacionService
  ) {
    super(_http, environment.apiGatewayURL)
    this.apiURL = environment.apiGatewayURL;

    this.AgregarCarrito_Ubicacion();
  }

  async CallCenterTipoPedidoListar(EmpresaId: number) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }

    if (EmpresaId != 0) {
      return new Promise(resolve => {
        this.get(APIENDPOINT.CallCenterTipoPedidoListar + "?EmpresaID=" + EmpresaId, true, token)
          .subscribe(resp => {
            resolve(resp);
          }, error => {
            console.log('Error en Call Center --> Tipo Pedido Listar: ', error);
            resolve(null);
          });
      });
    }
  }

  async CallCenterTerceroBuscar(EmpresaId: number, Descripcion: string) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }

    if (EmpresaId != 0) {
      return new Promise(resolve => {
        this.get(APIENDPOINT.CallCenterTerceroBuscar + "?" + "EmpresaID=" + EmpresaId + "&" + "Descripcion=" + Descripcion, true, token)
          .subscribe(resp => {
            resolve(resp);
          }, error => {
            console.log('Error en Call Center --> Tercero Buscar: ', error);
            resolve(null);
          });
      });
    }
  }

  async CallCenterTerceroBuscarPorTipo(EmpresaId: number, Descripcion: string, TipoBusqueda: string) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }

    if (EmpresaId != 0) {
      return new Promise(resolve => {
        this.get(APIENDPOINT.CallCenterTerceroBuscarPorTipo + "?" + "EmpresaID=" + EmpresaId + "&" + "Descripcion=" + Descripcion + "&" + "TipoBusqueda=" + TipoBusqueda, true, token)
          .subscribe(resp => {
            resolve(resp);
          }, error => {
            console.log('Error en Call Center --> Tercero Buscar: ', error);
            resolve(null);
          });
      });
    }
  }

  async CallCenterTerceroGuardar(Tercero: ClsTercerosModel) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }

    return new Promise(resolve => {
      this.post(APIENDPOINT.CallCenterTerceroGuardar, Tercero, true, token)
        .subscribe(resp => {
          resolve(resp);
        }, error => {
          console.log('Error en Call Center --> Tercero Guardar: ', error);
          resolve(null);
        });
    });
  }

  async CallCenterTerceroGuardarOnline(Tercero: ClsTercerosModel) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }

    return new Promise(resolve => {
      this.post(APIENDPOINT.CallCenterTerceroGuardarOnline, Tercero, true, token)
        .subscribe(resp => {
          resolve(resp);
        }, error => {
          console.log('Error en Call Center --> Tercero Guardar: ', error);
          resolve(null);
        });
    });
  }

  async CallCenterDireccionGuardar(Direccion: ClsTercerosDireccionesModel) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }

    return new Promise(resolve => {
      this.post(APIENDPOINT.CallCenterDireccionGuardar, Direccion, true, token)
        .subscribe(resp => {
          resolve(resp);
        }, error => {
          console.log('Error en Call Center --> Direccion Guardar: ', error);
          resolve(null);
        });
    });
  }

  async CallCenterDireccionEliminar(direccionId: number) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }

    return new Promise(resolve => {
      this.post(APIENDPOINT.CallCenterDireccionEliminar, direccionId, true, token)
        .subscribe(resp => {
          resolve(resp);
        }, error => {
          console.log('Error en Call Center --> Direccion Eliminar: ', error);
          resolve(null);
        });
    });
  }

  async CallCenterNegocioListar(EmpresaID: number, Latitud: number, Longitud: number) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }

    return new Promise(resolve => {
      this.get(APIENDPOINT.CallCenterNegocioListar + "?EmpresaID=" + EmpresaID + "&" + "Latitud=" + Latitud + "&" + "Longitud=" + Longitud, true, token)
        .subscribe(resp => {
          resolve(resp);
        }, error => {
          console.log('Error en Call Center --> Tipo Pedido Listar: ', error);
          resolve(null);
        });
    });
  }

  async CallCenterNegocioPorTipoPedidoListar(EmpresaID: number, Latitud: number, Longitud: number, TipoPedidoID: number) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }

    return new Promise(resolve => {
      this.get(APIENDPOINT.CallCenterNegocioPorTipoPedidoListar + "?EmpresaID=" + EmpresaID + "&" + "Latitud=" + Latitud + "&" + "Longitud=" + Longitud + "&" + "TipoPedidoID=" + TipoPedidoID, true, token)
        .subscribe(resp => {
          resolve(resp);
        }, error => {
          console.log('Error en Call Center --> Tipo Pedido Listar: ', error);
          resolve(null);
        });
    });
  }

  async CallCenterNegocioIdPorTipoPedidoListar(EmpresaID: number, NegocioID: number, Latitud: number, Longitud: number, TipoPedidoID: number) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }

    return new Promise(resolve => {
      this.get(APIENDPOINT.CallCenterNegocioIdPorTipoPedidoListar + "?EmpresaID=" + EmpresaID + "&" + "NegocioID=" + NegocioID + "&" + "Latitud=" + Latitud + "&" + "Longitud=" + Longitud + "&" + "TipoPedidoID=" + TipoPedidoID, true, token)
        .subscribe(resp => {
          resolve(resp);
        }, error => {
          console.log('Error en Call Center --> Tipo Pedido Listar: ', error);
          resolve(null);
        });
    });
  }

  async CallCenterMenuVentaListar(EmpresaID: number, NegocioID: number, UltimoID: number, TopConsulta: number) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }

    return new Promise(resolve => {
      this.get(APIENDPOINT.CallCenterMenuVentaListar + "?EmpresaID=" + EmpresaID + "&" + "NegocioID=" + NegocioID + "&" + "UltimoID=" + UltimoID + "&" + "TopConsulta=" + TopConsulta, true, token)
        .subscribe(resp => {
          resolve(resp);
        }, error => {
          console.log('Error en Call Center --> Menú Venta Listar: ', error);
          resolve(null);
        });
    });
  }

  async CallCenterMenuDetalleListar(EmpresaID: number, NegocioID: number, MenuID: number, UltimoID: number, TopConsulta: number) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }

    return new Promise(resolve => {
      this.get(APIENDPOINT.CallCenterMenuDetalleListar + "?EmpresaID=" + EmpresaID + "&" + "NegocioID=" + NegocioID + "&" + "MenuID=" + MenuID + "&" + "UltimoID=" + UltimoID + "&" + "TopConsulta=" + TopConsulta, true, token)
        .subscribe(resp => {
          resolve(resp);
        }, error => {
          console.log('Error en Call Center --> Menú Venta Detalle Listar: ', error);
          resolve(null);
        });
    });
  }

  async CallCenterMenuDetalleArticuloListar(EmpresaID: number, NegocioID: number, MenuDetalleID: number, UltimoID: number, TopConsulta: number) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }

    return new Promise(resolve => {
      this.get(APIENDPOINT.CallCenterMenuDetalleArticuloListar + "?EmpresaID=" + EmpresaID + "&" + "NegocioID=" + NegocioID + "&" + "MenuDetalleID=" + MenuDetalleID + "&" + "UltimoID=" + UltimoID + "&" + "TopConsulta=" + TopConsulta, true, token)
        .subscribe(resp => {
          resolve(resp);
        }, error => {
          console.log('Error en Call Center --> Menú Venta Detalle Listar: ', error);
          resolve(null);
        });
    });
  }

  async CallCenterToppingsListar(EmpresaID: number, NegocioID: number, MenuDetalleID: number, UltimoID: number, TopConsulta: number) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }

    return new Promise(resolve => {
      this.get(APIENDPOINT.CallCenterToppingsListar + "?EmpresaID=" + EmpresaID + "&" + "NegocioID=" + NegocioID + "&" + "MenuDetalleID=" + MenuDetalleID + "&" + "UltimoID=" + UltimoID + "&" + "TopConsulta=" + TopConsulta, true, token)
        .subscribe(resp => {
          resolve(resp);
        }, error => {
          console.log('Error en Call Center --> Menú Venta Detalle Listar: ', error);
          resolve(null);
        });
    });
  }

  async CallCenterPedidoGuardar(NegocioID: number, UsuarioID: number, observacion: string, Tipo_Solicitud: string) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }

    this.Carrito_Compra.Informacion_Pedido.Pedido_Observacion = observacion;
    this.Carrito_Compra.Informacion_Pedido.Tipo_Solicitud = Tipo_Solicitud;
    return new Promise(resolve => {
      this.post(APIENDPOINT.CallCenterPedidoGuardar + "?" + "NegocioID=" + NegocioID + "&" + "UsuarioID=" + UsuarioID, this.Carrito_Compra, true, token)
        .subscribe(resp => {
          resolve(resp);
        }, error => {
          console.log('Error en Call Center --> Pedido Guardar: ', error);
          resolve(null);
        });
    });
  }


  //#region Carrito de Compra
  async ValidarCombo(Referencia: ClsReferenciaModel) {
    return new Promise(resolve => {
      let RefValida = true;

      if (Referencia != null) {
        if (Referencia.REF_ES_COMBO == true) {
          Referencia.Referencia_Combo_Valor.Referencia_Combo.forEach((combo => {
            if (combo.COMBO_MENU_VALIDO == false) {
              RefValida = false;
              resolve(RefValida);
              return;
            }

            combo.Combo_Detalle.forEach((detalle => {
              if (detalle.COMBO_TIENE_SUB_COMBO == true) {
                detalle.Info_SubCombo_Valor.Info_SubCombo.forEach((subCombo => {
                  if (subCombo.COMBO_MENU_VALIDO == false) {
                    RefValida = false;
                    resolve(RefValida);
                    return;
                  }
                }));
              }
            }));
          }));
        }
      }

      resolve(RefValida);
    });
  }

  async AgregarReferenciaCarrito(Referencia: ClsReferenciaModel) {
    //let Cargar = await this.uiService.iniciarLoadingNEW('');
    try {
      //await this.AgregarCarrito_Negocio(this.Negocio_Pedido);

      //await this.AgregarCarrito_TipoPedido();

      await this.Carrito_Compra.Referencias.push(Referencia);

      await this.TotalizarItemsCarrito();

      //await this.oGeneral.GuardarStorage("carritoPedido_" + "NegocioID_" + Negocio.NEGO_ID + "_" + environment.API_KEY, this.Carrito_Compra);
    } catch (e) {

    } finally {
      //await this.uiService.cerrarLoadingNEW(Cargar);
    }
  }

  async AgregarCarrito_Negocio(Negocio: ClsNegocioModel) {
    try {
      this.Negocio_Pedido = Negocio;
      this.Carrito_Compra.Negocio = this.Negocio_Pedido;

      this.Carrito_Compra.Negocio.MedioPago.forEach((medio => {
        if (medio.Seleccionado == true) {
          this.AgregarCarrito_MedioPago(medio);
          return;
        }
      }));
    } catch (e) {

    }
  }

  async AgregarCarrito_TipoPedido(TipoPedido: any) {
    try {
      //this.Carrito_Compra.Tipo_Pedido = await this.oGeneral.LeerStorage("tipoPedido_" + environment.API_KEY);
      this.Tipo_Pedido = TipoPedido;
      this.Carrito_Compra.Tipo_Pedido = this.Tipo_Pedido;
    } catch (e) {

    }
  }

  async AgregarCarrito_TerceroPedido(TerceroPedido: any) {
    try {
      //let tmpTercero = await this.oGeneral.LeerStorage("login_" + environment.API_KEY);

      //let TerceroPedido = new ClsTercerosModel();
      //TerceroPedido.TER_ID = tmpTercero.TER_ID;
      //TerceroPedido.TER_TIPODOCUMENTO_ID = tmpTercero.TER_TIPODOCUMENTO_ID;
      //TerceroPedido.TER_IDENTIFICACION = tmpTercero.TER_IDENTIFICACION;
      //TerceroPedido.TER_NOMBRES = tmpTercero.TER_NOMBRES;
      //TerceroPedido.TER_APELLIDOS = tmpTercero.TER_APELLIDOS;
      //TerceroPedido.TER_GENERO = tmpTercero.TER_GENERO;
      //TerceroPedido.TER_NACIMIENTO = tmpTercero.TER_NACIMIENTO;
      //TerceroPedido.TER_PAIS_ID = tmpTercero.TER_PAIS_ID;
      //TerceroPedido.TER_TELEFONO = tmpTercero.TER_TELEFONO;
      //TerceroPedido.TER_CORREO = tmpTercero.TER_CORREO;
      //TerceroPedido.TER_CLAVE = tmpTercero.TER_CLAVE;

      //this.Carrito_Compra.Tercero_Pedido = TerceroPedido;
      this.Tercero_Pedido = TerceroPedido;
      this.Carrito_Compra.Tercero_Pedido = this.Tercero_Pedido;
    } catch (e) {

    }
  }

  async AgregarCarrito_TerceroPaga(TerceroPaga) {
    try {
      //let tmpTercero = await this.oGeneral.LeerStorage("login_" + environment.API_KEY);

      //let TerceroPaga = new ClsTercerosModel();
      //TerceroPaga.TER_ID = tmpTercero.TER_ID;
      //TerceroPaga.TER_TIPODOCUMENTO_ID = tmpTercero.TER_TIPODOCUMENTO_ID;
      //TerceroPaga.TER_IDENTIFICACION = tmpTercero.TER_IDENTIFICACION;
      //TerceroPaga.TER_NOMBRES = tmpTercero.TER_NOMBRES;
      //TerceroPaga.TER_APELLIDOS = tmpTercero.TER_APELLIDOS;
      //TerceroPaga.TER_GENERO = tmpTercero.TER_GENERO;
      //TerceroPaga.TER_NACIMIENTO = tmpTercero.TER_NACIMIENTO;
      //TerceroPaga.TER_PAIS_ID = tmpTercero.TER_PAIS_ID;
      //TerceroPaga.TER_TELEFONO = tmpTercero.TER_TELEFONO;
      //TerceroPaga.TER_CORREO = tmpTercero.TER_CORREO;
      //TerceroPaga.TER_CLAVE = tmpTercero.TER_CLAVE;

      //this.Carrito_Compra.Tercero_Paga = TerceroPaga;
      this.Tercero_Pedido = TerceroPaga;
      this.Carrito_Compra.Tercero_Paga = this.Tercero_Pedido;
    } catch (e) {

    }
  }

  async AgregarCarrito_Direccion(DireccionPedido: any) {
    try {
      //let DireccionPedido = await this.oGeneral.LeerStorage("direccionPedido_" + environment.API_KEY);

      //this.Carrito_Compra.Direccion_Entrega = DireccionPedido;
      this.Direccion_Pedido = DireccionPedido;
      this.Carrito_Compra.Direccion_Entrega = this.Direccion_Pedido;
    } catch (e) {

    }
  }

  async AgregarCarrito_InformacionPedido() {
    try {
      //let InformacionPedido = await this.oGeneral.LeerStorage("informacionPedido_" + "NegocioID_" + this.Carrito_Compra.Negocio.NEGO_ID + "_" + environment.API_KEY);

      //console.log("InformacionPedido", InformacionPedido);
      if (this.Informacion_Pedido != null) {
        this.Carrito_Compra.Informacion_Pedido = this.Informacion_Pedido;
      }
    } catch (e) {

    }
  }

  async AgregarCarrito_Ubicacion() {
    try {
      let geoLocalizacion: any = await this.localizacion.getGeoLocalizacion();

      //let latIni = geoLocalizacion.Data.lat;
      //let lngIni = geoLocalizacion.Data.lng;

      //let latFin = geoLocalizacion.Data.lat;
      //let lngFin = geoLocalizacion.Data.lng;

      let latIni = geoLocalizacion.Latitud;
      let lngIni = geoLocalizacion.Longitud;

      let latFin = geoLocalizacion.Latitud;
      let lngFin = geoLocalizacion.Longitud;

      let PedidoUbicacion: ClsPedidoUbicacionModel = new ClsPedidoUbicacionModel();
      PedidoUbicacion.Latitud_Ini = latIni;
      PedidoUbicacion.Longitud_Ini = lngIni;
      PedidoUbicacion.Latitud_Fin = latFin;
      PedidoUbicacion.Longitud_Fin = lngFin;

      this.Carrito_Compra.Pedido_Ubicacion = PedidoUbicacion;
    } catch (e) {

    }
  }

  async AgregarCarrito_MedioPago(MedioPago: any) {
    try {
      this.Carrito_Compra.MedioPago_Pedido = MedioPago;
    } catch (e) {

    }
  }

  async RestarReferencia(Negocio: ClsNegocioModel, Referencia: ClsReferenciaModel) {
    //let Cargar = await this.uiService.iniciarLoadingNEW('');
    try {
      let CantIni = Referencia.REF_CANTIDAD_TOTAL;

      let CantFinal = CantIni - 1;
      if (CantFinal <= 0) {
        CantFinal = CantIni;
      }

      Referencia.REF_CANTIDAD_TOTAL = CantFinal;

      await this.ToppingCantidad(Referencia);

      await this.TotalizarItemsCarrito();

      //console.log("restar Referencia", Referencia);

      //await this.oGeneral.GuardarStorage("carritoPedido_" + "NegocioID_" + Negocio.NEGO_ID + "_" + environment.API_KEY, this.Carrito_Compra);
    } catch (e) {

    } finally {
      //await this.uiService.cerrarLoadingNEW(Cargar);
    }
  }

  async SumarReferencia(Negocio: ClsNegocioModel, Referencia: ClsReferenciaModel) {
    //let Cargar = await this.uiService.iniciarLoadingNEW('');
    try {
      let CantIni = Referencia.REF_CANTIDAD_TOTAL;

      let CantFinal = CantIni + 1;

      Referencia.REF_CANTIDAD_TOTAL = CantFinal;

      await this.ToppingCantidad(Referencia);

      await this.TotalizarItemsCarrito();

      //console.log("sumar Referencia", Referencia);

      //await this.oGeneral.GuardarStorage("carritoPedido_" + "NegocioID_" + Negocio.NEGO_ID + "_" + environment.API_KEY, this.Carrito_Compra);
    } catch (e) {

    } finally {
      //await this.uiService.cerrarLoadingNEW(Cargar);
    }
  }

  async ToppingCantidad(Referencia: ClsReferenciaModel) {
    for (var t = 0; t < Referencia.Referencia_Topping_Valor.Menu_Toppings.length; t++) {
      let dataTopping = Referencia.Referencia_Topping_Valor.Menu_Toppings[t];
      for (var i = 0; i < dataTopping.Topping_Articulos.length; i++) {
        let dataDetalle = dataTopping.Topping_Articulos[i];
        if (dataTopping.Topping_Articulos[i].TOPART_ID == dataDetalle.TOPART_ID) {
          dataTopping.Topping_Articulos[i].TOPART_CANTIDAD_TOTAL = dataTopping.Topping_Articulos[i].Referencia.Referencia_Valor.REF_CANTIDAD * Referencia.REF_CANTIDAD_TOTAL;
        }
      }
    }

    await this.TotalizarToppings(Referencia);
  }

  async TotalizarToppings(Referencia: ClsReferenciaModel) {
    let tmpTopping = Referencia.Referencia_Topping_Valor;
    tmpTopping.REFTOP_VALOR_TOTAL = 0;
    tmpTopping.REFTOP_VALOR_SUBTOTAL = 0;
    tmpTopping.REFTOP_VALOR_IMPUESTO = 0;

    for (var t = 0; t < tmpTopping.Menu_Toppings.length; t++) {
      let tmpMenuTopping = tmpTopping.Menu_Toppings[t];

      for (var i = 0; i < tmpMenuTopping.Topping_Articulos.length; i++) {
        console.log("REF_UNIDAD_VENTA_TOTAL", tmpMenuTopping.Topping_Articulos[i].Referencia.Referencia_Valor.REF_UNIDAD_VENTA_TOTAL);
        console.log("REF_UNIDAD_VENTA_SUBTOTAL", tmpMenuTopping.Topping_Articulos[i].Referencia.Referencia_Valor.REF_UNIDAD_VENTA_SUBTOTAL);
        console.log("REF_UNIDAD_VENTA_IMPUESTO", tmpMenuTopping.Topping_Articulos[i].Referencia.Referencia_Valor.REF_UNIDAD_VENTA_IMPUESTO);

        tmpTopping.REFTOP_VALOR_TOTAL = tmpTopping.REFTOP_VALOR_TOTAL + (tmpMenuTopping.Topping_Articulos[i].Referencia.Referencia_Valor.REF_UNIDAD_VENTA_TOTAL * tmpMenuTopping.Topping_Articulos[i].TOPART_CANTIDAD_TOTAL);
        tmpTopping.REFTOP_VALOR_SUBTOTAL = tmpTopping.REFTOP_VALOR_SUBTOTAL + (tmpMenuTopping.Topping_Articulos[i].Referencia.Referencia_Valor.REF_UNIDAD_VENTA_SUBTOTAL * tmpMenuTopping.Topping_Articulos[i].TOPART_CANTIDAD_TOTAL);
        tmpTopping.REFTOP_VALOR_IMPUESTO = tmpTopping.REFTOP_VALOR_IMPUESTO + (tmpMenuTopping.Topping_Articulos[i].Referencia.Referencia_Valor.REF_UNIDAD_VENTA_IMPUESTO * tmpMenuTopping.Topping_Articulos[i].TOPART_CANTIDAD_TOTAL);

        tmpMenuTopping.Topping_Articulos[i].Referencia.Referencia_Valor.REF_VALOR_TOTAL = (tmpMenuTopping.Topping_Articulos[i].Referencia.Referencia_Valor.REF_UNIDAD_VENTA_TOTAL * tmpMenuTopping.Topping_Articulos[i].TOPART_CANTIDAD_TOTAL);
        tmpMenuTopping.Topping_Articulos[i].Referencia.Referencia_Valor.REF_VALOR_SUBTOTAL = (tmpMenuTopping.Topping_Articulos[i].Referencia.Referencia_Valor.REF_UNIDAD_VENTA_SUBTOTAL * tmpMenuTopping.Topping_Articulos[i].TOPART_CANTIDAD_TOTAL);
        tmpMenuTopping.Topping_Articulos[i].Referencia.Referencia_Valor.REF_VALOR_IMPUESTO = (tmpMenuTopping.Topping_Articulos[i].Referencia.Referencia_Valor.REF_UNIDAD_VENTA_IMPUESTO * tmpMenuTopping.Topping_Articulos[i].TOPART_CANTIDAD_TOTAL);

        console.log("tmpTopping REF_UNIDAD_VENTA_TOTAL", tmpTopping.REFTOP_VALOR_TOTAL);
        console.log("tmpTopping REF_UNIDAD_VENTA_SUBTOTAL", tmpTopping.REFTOP_VALOR_SUBTOTAL);
        console.log("tmpTopping REF_UNIDAD_VENTA_IMPUESTO", tmpTopping.REFTOP_VALOR_IMPUESTO);

        //let tmpReferenciaTopping: ClsMenuToppingArticuloModel = Referencia.Referencia_Topping_Valor.Menu_Toppings[t].Topping_Articulos[i];
        //tmpMenuTopping.Topping_Articulos.push(tmpReferenciaTopping);
      }
      //if (tmpMenuTopping.Topping_Articulos.length > 0) {
      //    tmpTopping.Menu_Toppings.push(tmpMenuTopping);
      //}
    }

    //Referencia.Referencia_Topping_Valor = tmpTopping;
    //console.log("Referencia Total Toppings", Referencia);
  }

  async EliminarReferencia(Negocio: ClsNegocioModel, Referencia: ClsReferenciaModel, posItem: number) {
    //let Cargar = await this.uiService.iniciarLoadingNEW('');
    try {
      this.Carrito_Compra.Referencias.splice(posItem, 1);

      await this.TotalizarItemsCarrito();

      //await this.oGeneral.GuardarStorage("carritoPedido_" + "NegocioID_" + Negocio.NEGO_ID + "_" + environment.API_KEY, this.Carrito_Compra);
    } catch (e) {

    } finally {
      //await this.uiService.cerrarLoadingNEW(Cargar);
    }
  }

  //async VaciarCarritoCompra(Negocio: ClsNegocioModel) {
  //    let NegocioID = this.Carrito_Compra.Negocio.NEGO_ID;

  //    this.Carrito_Compra = new ClsCarritoCompraModel();

  //    //let InformacionPedido = await this.oGeneral.LeerStorage("informacionPedido_" + "NegocioID_" + NegocioID + "_" + environment.API_KEY);
  //    if (this.Informacion_Pedido != null) {
  //        //await this.oGeneral.GuardarStorage("informacionPedido_" + "NegocioID_" + NegocioID + "_" + environment.API_KEY, this.Carrito_Compra.Informacion_Pedido);
  //    }

  //    //let DireccionPedido = await this.oGeneral.LeerStorage("direccionPedido_" + environment.API_KEY);
  //    if (this.Direccion_Pedido != null) {
  //        //await this.oGeneral.GuardarStorage("direccionPedido_" + environment.API_KEY, this.Carrito_Compra.Direccion_Entrega);
  //    }

  //    //this.Carrito_Compra.Informacion_Pedido = new ClsPedidoModel();

  //    await this.TotalizarItemsCarrito();

  //    //await this.oGeneral.GuardarStorage("carritoPedido_" + "NegocioID_" + Negocio.NEGO_ID + "_" + environment.API_KEY, this.Carrito_Compra);
  //}

  async VaciarCarritoCompra() {
    var TotalItems = this.Carrito_Compra.Referencias.length - 1;
    for (var i = TotalItems; i >= 0; i--) {
      await this.EliminarReferencia(this.Carrito_Compra.Negocio, this.Carrito_Compra.Referencias[i], i);
    }

  }

  async TotalizarItemsCarrito() {
    try {
      if (this.Carrito_Compra != null && this.Carrito_Compra != undefined) {
        this.Carrito_Compra.Carrito_Total.Valor_Total = 0;
        this.Carrito_Compra.Carrito_Total.Valor_SubTotal = 0;
        this.Carrito_Compra.Carrito_Total.Valor_Impuesto = 0;

        this.Carrito_Compra.Carrito_Total.Cantidad_Items = 0;

        this.Carrito_Compra.Carrito_Total.Valor_Propina = 0;

        this.Carrito_Compra.Carrito_Total.Valor_Envio = 0;
        if (this.Carrito_Compra.Negocio.Tiempo_Entrega != null) {
          this.Carrito_Compra.Carrito_Total.Valor_Envio = this.Carrito_Compra.Negocio.Tiempo_Entrega.VALOR_DOMICILIO;
        }

        for (var r = 0; r < this.Carrito_Compra.Referencias.length; r++) {
          let Referencia: ClsReferenciaModel = this.Carrito_Compra.Referencias[r];

          let Uni_Valor_Total = Referencia.Referencia_Valor.REF_UNIDAD_VENTA_TOTAL * Referencia.REF_CANTIDAD_TOTAL;
          let Uni_Valor_SubTotal = Referencia.Referencia_Valor.REF_UNIDAD_VENTA_SUBTOTAL * Referencia.REF_CANTIDAD_TOTAL;
          let Uni_Valor_Impuesto = Referencia.Referencia_Valor.REF_UNIDAD_VENTA_IMPUESTO * Referencia.REF_CANTIDAD_TOTAL;

          let Topping_Valor_Total = Referencia.Referencia_Topping_Valor.REFTOP_VALOR_TOTAL;
          let Topping_Valor_SubTotal = Referencia.Referencia_Topping_Valor.REFTOP_VALOR_SUBTOTAL;
          let Topping_Valor_Impuesto = Referencia.Referencia_Topping_Valor.REFTOP_VALOR_IMPUESTO;

          Referencia.Referencia_Valor.REF_VALOR_TOTAL = Uni_Valor_Total;
          Referencia.Referencia_Valor.REF_VALOR_SUBTOTAL = Uni_Valor_SubTotal;
          Referencia.Referencia_Valor.REF_VALOR_IMPUESTO = Uni_Valor_Impuesto;

          this.Carrito_Compra.Carrito_Total.Valor_Total = this.Carrito_Compra.Carrito_Total.Valor_Total + Uni_Valor_Total;
          this.Carrito_Compra.Carrito_Total.Valor_SubTotal = this.Carrito_Compra.Carrito_Total.Valor_SubTotal + Uni_Valor_SubTotal;
          this.Carrito_Compra.Carrito_Total.Valor_Impuesto = this.Carrito_Compra.Carrito_Total.Valor_Impuesto + Uni_Valor_Impuesto;

          this.Carrito_Compra.Carrito_Total.Cantidad_Items = this.Carrito_Compra.Carrito_Total.Cantidad_Items + Referencia.REF_CANTIDAD_TOTAL;
        }

        this.Carrito_Compra.Carrito_Total.Valor_Total = this.Carrito_Compra.Carrito_Total.Valor_Total + this.Carrito_Compra.Carrito_Total.Valor_Propina + this.Carrito_Compra.Carrito_Total.Valor_Envio;
      }
    } catch (e) {
      console.log(e.message);
    }
  }
  //#endregion
}
