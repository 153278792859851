export class ClsMenuVentaModel {
    public MENU_ID: number;
        
    public MENU_NOMBRE: string;
        
    public MENU_DESCRIPCION: string;
        
    public MENU_COLOR_INI: string;
        
    public MENU_COLOR_FIN: string;
        
    public MENU_COLOR_FUENTE: string;
        
    public MENU_ANCHO_COLUMNAS: number;

    public MENU_ANCHO_ESPACIOS: number;

    public MENU_TAMANO_FUENTE: string

    public MENU_TAMANO_FUENTE_DESCRIP: string;
        
    public MENU_APLICA_DIA: boolean;
        
    public MENU_FECHA_INI: string;
        
    public MENU_FECHA_FIN: string;
        
    public MENU_VISUALIZA: string;
        
    public MENU_AVATAR: string;
        
    public MENU_ORDEN: number;

    public MENU_NUMERO_DIA: number;
}