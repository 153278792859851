export class ClsMedioPagoTipoPedidoModel {
  public MTP_ID: number;

  public MTP_NEGOCIO_ID: number;

  public MTP_MEDIOPAGO_NEGOCIO_ID: number;

  public MTP_TIPOPEDIDO_ID: number;

  public MTP_ESTADO: string
}
