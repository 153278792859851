import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CalificarService {
    @Output() _escuchaEvento_Calificar: EventEmitter<any> = new EventEmitter();

    constructor() {

    }

    generarEvento_Calificar(Valido:Boolean, Dato: any) {
        this._escuchaEvento_Calificar.emit(Dato);
    }
}
