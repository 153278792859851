import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pipes',
  templateUrl: './pipes.component.html',
  styleUrls: ['./pipes.component.css']
})
export class PipesComponent implements OnInit {
    nombre = "Diego";
    arreglo = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    PI = Math.PI;
    porcentaje = 0.234;
    salario = 1234.5;
    dataJson = {
        nombre: "Logan",
        clave: "Wolverine",
        edad: 500,
        direccion: {
            calle: "Primera",
            casa: "19"
        }
    };
    valorPromesa = new Promise((resolve, reject) => {
        setTimeout(() => resolve('Llego la data!'), 3500);
    });
    fecha = new Date();
    nombre2 = "Diego ArMaNdo sanchez vARGas";
    mVideo = "YmAh2I-qjOo";
    convertirPass: boolean = true;

  constructor() { }

  ngOnInit() {
  }

    validaConvertirPass() {

    }

}
