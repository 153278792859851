import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SpotifyService } from '../../services/spotify.service';

@Component({
  selector: 'app-artista',
  templateUrl: './artista.component.html',
  styleUrls: ['./artista.component.css']
})
export class ArtistaComponent implements OnInit {
    artistaId: string;

    artista: any;
    topTracks: any[] = [];

    loadingArtista: boolean = false;

    constructor(private _activatedRoute: ActivatedRoute, private spotify: SpotifyService) {
        this.loadingArtista = true;
        this._activatedRoute.params
            .subscribe(param => {
                console.log(param);
                //this.artistaId = params['id'];
                this.artistaId = param.id;
                this.getArtista(param.id);
                this.getTopTracks(param.id);
            });
    }

  ngOnInit() {
  }

    getArtista(id:string) {
        this.spotify.getArtista(id).subscribe((data: any) => {
            console.log(data);
            this.artista = data;
            //this.artistas = data;
            //this.loading = false;
            this.loadingArtista = false;
        });
    }

    getTopTracks(id: string) {
        this.spotify.getTopTracks(id).subscribe((data: any) => {
            console.log(data);
            this.topTracks = data;
            //this.artista = data;
            //this.loading = false;
        });
    }
    

}
