import { Component, OnInit } from '@angular/core';
import { ClsAccesoNegocioModel } from '../../models/Web/ClsAccesoNegocio.model';
import { ParametrosService } from '../../services/Parametros.service';
import { ClsParametrosModel } from '../../models/Web/ClsParametros.model';
import { FormGroup, FormControl } from '@angular/forms';
import { ClsDistanciaNegocioModel } from '../../models/Web/ClsDistanciaNegocio.model';
import { TipoPedidoEmpresaService } from '../../services/TipoPedidoEmpresa.service';
import { GeneralesService } from '../../services/_Generales.service';
declare var window: any;

@Component({
  selector: 'app-admin-distancias',
  templateUrl: './admin-distancias.component.html',
  styleUrls: ['./admin-distancias.component.css']
})
export class AdminDistanciasComponent implements OnInit {
  objNego: ClsAccesoNegocioModel[];
  listDistancias: ClsParametrosModel[];
  myGroupDistancia: FormGroup;
  mensajeRespuesta: string;
  constructor(private _paramService: ParametrosService, private tipoPedidoService: TipoPedidoEmpresaService, private generales: GeneralesService) {

  }

  async ngOnInit()
  {     
    this.myGroupDistancia = new FormGroup({
      'negocioId': new FormControl()
    });
    
    await this.cargarDistancias(); 
    for (var i = 0; i < this.listDistancias.length; i++) {
      this.myGroupDistancia.addControl(this.listDistancias[i].PARAM_ID + "", new FormControl());
    }
   
    let datos: any = localStorage.getItem('sesion');
    if (datos != null && datos != '') {
      this.objNego = [];
      var info = JSON.parse(datos);
      this.objNego = info.LISTADO_NEGOCIO;

      let actual: any = JSON.parse(localStorage.getItem('negocio'));
      this.myGroupDistancia.get('negocioId').setValue(actual[0].NEGO_ID);
    }    
        
    this.ListarAdminDistancias(); 
   
  }

    async cargarDistancias() {
        let resp: any = await this._paramService.ListarParametrosGeneral(53, 1);
        if (resp != null) {
            if (resp.IsSuccess) {
                this.listDistancias = resp.Data;
            }
        }
    }

  
  async GuardarAdminDistancias() {
      this.generales.OpenDialogAjax();

    let listaDistanciaxNegocio: ClsDistanciaNegocioModel[] = [];
    for (const field in this.myGroupDistancia.controls) { // 'field' is a string
      if (field != 'negocioId') {
        const control = this.myGroupDistancia.get(field); // 'control' is a FormControl
        let distanciaxnego: ClsDistanciaNegocioModel = new ClsDistanciaNegocioModel();
        distanciaxnego.ND_NEGO_ID = parseInt(this.myGroupDistancia.get('negocioId').value);
        distanciaxnego.ND_PARAM_ID = parseInt(field);
        if (control.value == true) {
          distanciaxnego.ND_ESTADO = "A";
        } else {
          distanciaxnego.ND_ESTADO = "N";
        }
        listaDistanciaxNegocio.push(distanciaxnego);
      }
    }

    let resp: any = await this.tipoPedidoService.GuardarDistanciaNegocio(listaDistanciaxNegocio);
    if (resp != null) {
      if (resp.IsSuccess) {
        this.mensajeRespuesta = resp.Message;
        window.$('#myModal').modal('show');
      }
      else {
        console.log(resp.Message);
      }
    }

      this.generales.CancelDialogAjax();
  }

  async ListarAdminDistancias() {
    
    let resp: any = await this.tipoPedidoService.ConsultarDistanciasNegocio('', parseInt(this.myGroupDistancia.get('negocioId').value));
    let listaDistanciaxNegocio: ClsDistanciaNegocioModel[] = [];
    listaDistanciaxNegocio = resp;
    let nego = parseInt(this.myGroupDistancia.get('negocioId').value);
    this.myGroupDistancia.reset();
    this.myGroupDistancia.get('negocioId').setValue(nego);
    if (resp != null) {
      for (var i = 0; i < listaDistanciaxNegocio.length; i++) {
        const control = this.myGroupDistancia.get(listaDistanciaxNegocio[i].ND_PARAM_ID + "");
        if (listaDistanciaxNegocio[i].ND_ESTADO == "A") {
          control.setValue(true);
        }
        else {
          control.setValue(false);
        }
      }
    } 
  }

}
