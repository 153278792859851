import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { promise } from 'protractor';

import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { APIENDPOINT } from '../../config/configuration';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
const apiUrl = 'http://localhost:3000/api/v1/products';

@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.css']
})
export class DataComponent implements OnInit {
    forma: FormGroup;
    usuario = {
        nombrecompleto: {
            nombre: "Diego",
            apellido: "Sanchez"
        },
        correo: "diegoasan2005@hotmail.com",
        pasatiempos: ["Caminar", "Dormir", "Comer"]
    };

    constructor(private http: HttpClient) {
        this.forma = new FormGroup({
            nombrecompleto: new FormGroup({
                nombre: new FormControl('',
                    [Validators.required, Validators.minLength(3)]),
                apellido: new FormControl('',
                    [Validators.required, this.misValidaciones])
            }),

            correo: new FormControl('',
                [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]),

            pasatiempos: new FormArray([
                new FormControl("Caminar", [Validators.required]),
                new FormControl("Dormir", [Validators.required]),
                new FormControl("Comer", [Validators.required])
            ]),

            username: new FormControl('',
                [Validators.required], [this.existeUsuario]),

            password1: new FormControl('',
                [Validators.required]),
            password2: new FormControl('')
        });

        //SETEAR EL USUARIO EN LOS CONTROLES DEL FORMULARIO
        //this.forma.setValue(this.usuario);

        this.forma.controls['password2'].setValidators([Validators.required, this.noIgual.bind(this.forma)]);

        //se ejecuta cuando cambia el estado del CONTROL
        this.forma.controls['username'].statusChanges
            .subscribe(data => {
            console.log(data);
            });

        //se ejecuta cuando cambia valores del CONTROL
        this.forma.controls['username'].valueChanges
            .subscribe(data => {
                console.log(data);
            });

        //se ejecuta cuando cambia valores del FORMULARIO
        this.forma.valueChanges
            .subscribe(data => {
            console.log(data);
            });
    }




    //*******************************************OBSERVABLE PARA PETICIONES API*******************************************//
    data: any[] = [];

    metodoLlamadoObservable() {
        this.getProducts()
            .subscribe((res: any) => {
                this.data = res;
                console.log(this.data);
            }, err => {
                console.log(err);
            });
    }


    getProducts(): Observable<any[]> {
        return this.http.get<any[]>(apiUrl)
            .pipe(
                tap(product => console.log('fetched products')),
                catchError(this.handleError('getProducts', []))
            );
    }

    getProduct(id: number): Observable<any> {
        const url = `${apiUrl}/${id}`;
        return this.http.get<any>(url).pipe(
            tap(_ => console.log(`fetched product id=${id}`)),
            catchError(this.handleError<any>(`getProduct id=${id}`))
        );
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
    //*******************************************OBSERVABLE PARA PETICIONES API*******************************************//



    //*******************************************UPLOAD FILE API*******************************************//
    public progress: number;
    public message: string;

    public uploadFile = (files) => {
        this.progress = 0;
        if (files.length === 0) {
            return;
        }

        let fileToUpload = <File>files[0];
        const formData = new FormData();
        formData.append('file', fileToUpload, fileToUpload.name);

        this.http.post(APIENDPOINT.uploadFile, formData, { reportProgress: true, observe: 'events' })
            .subscribe(event => {
                if (event.type === HttpEventType.UploadProgress)
                    this.progress = Math.round(100 * event.loaded / event.total);
                else if (event.type === HttpEventType.Response) {
                    this.message = 'Upload success.';
                }
            });
    }
    //*******************************************UPLOAD FILE API*******************************************//



  ngOnInit() {
  }

    guardarCambios() {
        console.log(this.forma);

        //this.forma.setValue(this.usuario);

        //this.forma.reset({
        //    nombrecompleto: {
        //        nombre: "",
        //        apellido: ""
        //    },
        //    correo: ""
        //});
    }

    agregarPasatiempo() {
        (<FormArray>this.forma.controls['pasatiempos']).push(
            new FormControl('', [Validators.required]),
        );
    }

    misValidaciones(control: FormControl): { [s: string]:boolean} {
        if (control.value === "herrera") {
            return {
                nodiego: true
            }
        }

        return null;
    }

    noIgual(control: FormControl): any {
        let forma: any = this;
        if (control.value !== forma.controls['password1'].value) {
            return {
                noiguales: true
            }
        }

        return null;
    }

    existeUsuario(control: FormControl): Promise<any>|Observable<any> {
        let mPromesa = new Promise((resolve, reject) => {
            setTimeout(() => {
                if (control.value === "strider") {
                    resolve({existe: true})
                } else {
                    resolve(null);
                }
            }, 1000);
        });

        return mPromesa;
    }
}



