import { ClsMenuToppingReferenciaModel } from "./ClsMenuToppingReferencia.model";

export class ClsMenuToppingModel {
  public TOP_ID:number;
  public TOP_MENU_DETALLE_ID:number;
  public TOP_NOMBRE:string;
  public TOP_DESCRIPCION: string;
  public TOP_APLICA_VARIOS: string;
  public TOP_OBLIGATORIO: string;
  public TOP_CANT_MINIMA: number;
  public TOP_CANT_MAXIMA: number;
  public TOP_URL_IMAGEN: string;
  public TOP_ORDEN: number;
  public TOP_ESTADO: string;
  public TOP_USU_ID: number;
  public TOP_IP_EQUIPO: string;
  public T_ARTICULOS: ClsMenuToppingReferenciaModel[]
  public EMPRESA_ID: number;
}
