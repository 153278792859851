export class ClsMenuToppingReferenciaModel {
  public TA_ID:number;
  public TA_MENU_TOPPING_ID: number;
  public TA_ARTICULO_ID: number;
  public TA_NOMBRE:string;
  public TA_DESCRIPCION: string;
  public TA_LIMITE_MAXIMO: number;
  public TA_URL_IMAGEN: string;
  public TA_ORDEN: number;
  public TA_ESTADO: string;
  public TA_USU_ID: number;
  public TA_IP_EQUIPO: string;
  public TA_COMBO: string;
  public TA_PERSONALIZAR_PERMITIDO: string;
  public TA_PERSONALIZAR_DESCRIPCION: string;
  public TA_SUGERIDO: string;
  public EMPRESA_ID: number;
  public REF_CODIGO: string;
  public REF_DESCRIPCION: string;
}
