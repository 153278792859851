import { Injectable, EventEmitter, Output } from '@angular/core';
//import { Geolocation } from '@ionic-native/geolocation/ngx';
import { debug } from 'util';

@Injectable({
    providedIn: 'root'
})
export class LocalizacionService {
    //@Output() eventoLocalizacionIni: any = new EventEmitter();
    // @Output() eventoLocalizacionWatch: any = new EventEmitter();

    latitudIni: number = 0;
    longitudIni: number = 0;

    latitudWatch: number = 0;
    longitudWatch: number = 0;

    tieneUbicacion: boolean = false;

    constructor(

    ) {
        this.IniciarGeoLocalizacion();
        console.log("constructor iniciarGeoLocalizacion");
    }

    async IniciarGeoLocalizacion() {
        navigator.geolocation.getCurrentPosition(resp => {
            console.log("getCurrentPosition");
            //this.eventoLocalizacionIni.emit(resp.coords);
            // resp.coords.latitude
            // resp.coords.longitude
            this.latitudIni = resp.coords.latitude;
            this.longitudIni = resp.coords.longitude;
            this.tieneUbicacion = true;
            console.log(this.latitudIni + " " + this.longitudIni + " " + this.tieneUbicacion);
        });

        //this.geolocation.getCurrentPosition().then((resp) => {
        //    this.eventoLocalizacionIni.emit(resp.coords);
        //    // resp.coords.latitude
        //    // resp.coords.longitude
        //    this.latitudIni = resp.coords.latitude;
        //    this.longitudIni = resp.coords.longitude;
        //    this.tieneUbicacion = true;
        //}).catch((error) => {
        //    this.eventoLocalizacionIni.emit(null);
        //    this.tieneUbicacion = false;
        //    console.log('Error getting location', error);
        //});

        //let watch = this.geolocation.watchPosition();
        //watch.subscribe((data) => {
        //    this.eventoLocalizacionWatch.emit(data.coords);
        //    // data can be a set of coordinates, or an error (if an error occurred).
        //    // data.coords.latitude
        //    // data.coords.longitude
        //    this.latitudWatch = data.coords.latitude;
        //    this.longitudWatch = data.coords.longitude;

        //    this.tieneUbicacion = true;
        //});
    }

    public getGeoLocalizacion() {
        return new Promise((resolve, reject) => {
            var geoSuccess = function (position) {
                //hideNudgeBanner();
                //// We have the location, don't display banner
                //clearTimeout(nudgeTimeoutId);

                // Do magic with location
              var startPos = position;
              //document.getElementById('startLat').innerHTML = startPos.coords.latitude;
              //document.getElementById('startLon').innerHTML = startPos.coords.longitude;
            };
            var geoError = function (error) {
                console.log("getGeoLocalizacion error", error);

                switch (error.code) {
                    case error.TIMEOUT:
                        // The user didn't accept the callout
                        //showNudgeBanner();

                        break;
                };
            }
            navigator.geolocation.getCurrentPosition(geoSuccess, geoError);

            navigator.geolocation.getCurrentPosition(resp => {
                console.log("getCurrentPosition");

                //this.eventoLocalizacionIni.emit(resp.coords);
                this.latitudIni = resp.coords.latitude;
                this.longitudIni = resp.coords.longitude;
                this.tieneUbicacion = true;

                let Respuesta =
                {
                    IsSuccess: true,
                    Message: "Posición generada correctamente.",
                    Latitud: this.latitudIni,
                    Longitud: this.longitudIni
                };

                resolve(Respuesta);
            });


            //this.geolocation.getCurrentPosition().then((resp) => {
            //    this.eventoLocalizacionIni.emit(resp.coords);
            //    this.latitudIni = resp.coords.latitude;
            //    this.longitudIni = resp.coords.longitude;
            //    this.tieneUbicacion = true;

            //    let Respuesta =
            //    {
            //        IsSuccess: true,
            //        Message: "Posición generada correctamente.",
            //        Latitud: this.latitudIni,
            //        Longitud: this.longitudIni
            //    };

            //    resolve(Respuesta);
            //}).catch((error) => {
            //    this.eventoLocalizacionIni.emit(null);
            //    this.tieneUbicacion = false;

            //    let Respuesta =
            //    {
            //        IsSuccess: false,
            //        Message: "Error generando posición.",
            //        Latitud: this.latitudIni,
            //        Longitud: this.longitudIni
            //    };

            //    resolve(Respuesta);
            //});
        });
  }

    //async GetLocalizacion() {
    //    return new Promise(resolve => {

    //        this.geolocation.getCurrentPosition().then((resp) => {
    //            //this.eventoLocalizacionIni.emit(resp.coords);
    //            // resp.coords.latitude
    //            // resp.coords.longitude
    //            //this.latitudIni = resp.coords.latitude;
    //            //this.longitudIni = resp.coords.longitude;
    //            //this.tieneUbicacion = true;

    //            resolve(resp);
    //        }).catch((error) => {
    //            //this.eventoLocalizacionIni.emit(null);
    //            //this.tieneUbicacion = false;
    //            //console.log('Error getting location', error);

    //            resolve(null);
    //        });

    //        //this.get(APIENDPOINT.listarNegocios + "?" + "EmpresaID=" + EmpresaID + "&" + "Latitud=" + Latitud + "&" + "Longitud=" + Longitud, true, _token)
    //        //    .subscribe(resp => {
    //        //        if (resp.IsSuccess) {
    //        //            resolve(resp.Data);
    //        //        } else {
    //        //            this.uiService.ShowAlert(resp.Message, environment.tituloAPP);
    //        //            resolve(null);
    //        //        }
    //        //    }, error => {
    //        //        this.uiService.ShowAlert(MensajeError, environment.tituloAPP);
    //        //        resolve(null);
    //        //    });
    //    });
    //}
}
