import { Component, OnInit, ViewChild, EventEmitter, Output, Input, Renderer2, Inject, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ClsNegocioModel } from '../../models/Aplicacion/ClsNegocio.model';
import { GeneralesService } from '../../services/_Generales.service';
import { ClsMenuVentaModel } from '../../models/Aplicacion/ClsMenuVenta.model';
import { PedidoCallCenterService } from '../../services/PedidoCallCenter.service';
import { ClsMenuDetalleModel } from '../../models/Web/Menu/ClsMenuDetalle.model';
declare var window: any;

@Component({
    selector: 'app-menuVentaDetalle',
    templateUrl: './menuVentaDetalle.component.html',
    styleUrls: ['./menuVentaDetalle.component.css']
})
export class MenuVentaDetalleComponent implements OnInit {
    @Input() Negocio: ClsNegocioModel;
    @Input() MenuVenta: ClsMenuVentaModel;
    @Input() MenuDetalle: ClsMenuDetalleModel;

    @Output() eventoMenuVentaDetalle: any = new EventEmitter();
    
    constructor(
        private renderer2: Renderer2,
        @Inject(DOCUMENT) private document: Document,
        private generales: GeneralesService
    ) {
        
    }

    async  ngOnInit() {
        
    }

    async SeleccionaMenuDetalle(data: any) {
        console.log("SeleccionaMenuVentaDetalle", data);
        this.eventoMenuVentaDetalle.emit(data);
    }
}
