import { Component, OnInit, ViewChild, EventEmitter, Output, Input, Renderer2, Inject, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ClsNegocioModel } from '../../models/Aplicacion/ClsNegocio.model';
import { GeneralesService } from '../../services/_Generales.service';
import { ClsMenuVentaModel } from '../../models/Aplicacion/ClsMenuVenta.model';
import { PedidoCallCenterService } from '../../services/PedidoCallCenter.service';
import { ClsMenuDetalleModel } from '../../models/Aplicacion/ClsMenuDetalle.model';
import { ClsMenuDetalleArticuloModel } from '../../models/Aplicacion/ClsMenuDetalleArticulo.model';
declare var window: any;

@Component({
    selector: 'app-menuDetalleArticulo',
    templateUrl: './menuDetalleArticulo.component.html',
    styleUrls: ['./menuDetalleArticulo.component.css']
})
export class MenuDetalleArticuloComponent implements OnInit {
    @Input() Negocio: ClsNegocioModel;
    @Input() MenuVenta: ClsMenuVentaModel;
    @Input() MenuDetalle: ClsMenuDetalleModel;

    ListadoMenuDetalleArticulo: ClsMenuDetalleArticuloModel[] = [];

    @Output() eventoMenuDetalleArticulo: any = new EventEmitter();

    CargandoDatos: boolean = true;
    UltimoID: number = 0;
    TopConsulta: number = 50;
    TieneDatos: boolean = true;
    
    constructor(
        private renderer2: Renderer2,
        @Inject(DOCUMENT) private document: Document,
        private pedidoCallCenterService: PedidoCallCenterService,
        private generales: GeneralesService
    ) {
        
    }

    async  ngOnInit() {
        await this.CargarMenuDetalleArticulo(this.MenuDetalle.DETALLE_ID);
    }

    async CargarMenuDetalleArticulo(MenuDetalleId: number) {
        try {
            //this.generales.OpenDialogAjax();

            this.CargandoDatos = true;

            const infoMenu: any = await this.pedidoCallCenterService.CallCenterMenuDetalleArticuloListar(this.Negocio.NEGO_EMPRESA_ID, this.Negocio.NEGO_ID, MenuDetalleId, this.UltimoID, this.TopConsulta);
            if (infoMenu != null) {
                if (infoMenu.IsSuccess) {
                    if (infoMenu.Data.length > 0) {
                        this.TieneDatos = true;
                    } else {
                        this.TieneDatos = false;
                    }

                    this.ListadoMenuDetalleArticulo = infoMenu.Data;

                    for (var i = 0; i < infoMenu.Data.length; i++) {
                        this.UltimoID = infoMenu.Data[i].DETART_ID;

                        console.log("infoMenuDetalleArticulo_" + [i], infoMenu.Data[i]);
                        console.log("UltimoID", this.UltimoID);
                    }
                }

                //await this.oGeneral.GuardarStorage("menuVenta_" + "NegocioID_" + this.datosNEGOCIO.NEGO_ID + "_" + environment.API_KEY, this.MenuVenta);
            } else {
                this.generales.MostrarMensajeERROR("No es posible listar la información del menú en este momento.");
            }

            this.CargandoDatos = false;

        } catch (e) {
            this.CargandoDatos = false;
        } finally {
            //this.generales.CancelDialogAjax();
        }
    }

    async SeleccionaMenuDetalleArticulo(data: any) {
        console.log("SeleccionaMenuDetalle", data);
        this.eventoMenuDetalleArticulo.emit(data);
    }
}
