import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.css']
})
export class TemplateComponent implements OnInit {
    usuario: Object = {
        nombre: "Diego",
        apellido: "Sanchez",
        correo: "",
        pais: "",
        sexo: "Hombre",
        acepta: false
    };

    paises = [
        {
            codigo: "CRI",
            nombre: "Costa Rica"
        },
        {
            codigo: "ESP",
            nombre: "España"
        }
    ];

    sexos: string[] = ["Hombre", "Mujer"];

  constructor() { }

  ngOnInit() {
  }

    guardar(mForma: NgForm) {
        console.log("Guardar info del formulario.");
    }
}
