export class ClsMedioPagoNegocioModel {
    public MedioPago_Negocio_ID: number;

    public Tipo_Pago: string;

    public Descripcion_Pago: string;

    public Observacion_Pago: string;

    public Api_Key: string;

    public Ambiente_Prueba: boolean;

    public Pago_Externo: boolean;

    public Descripcion_Factura: string;

    public Lenguaje: string;

    public Ceros_Adicionales: number;

    public Aplica_Cliente_NO_Paga: boolean;

    public Aplica_Cliente_SI_Paga: boolean;

    public Solicitar_Cliente_Paga: boolean;

    public Url_Pago: string;

    public Url_Response: string;

    public Url_Imagen: string;

    public Pago_Online: boolean;

    public Seleccionado: boolean;
}
