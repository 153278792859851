import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MasterModel } from '../models/master.model';
import { BaseService } from './_Base.service';
import { environment } from '../../environments/environment';
import { APIENDPOINT } from '../config/configuration';
import { ClsTercerosModel } from '../models/Aplicacion/ClsTerceros.model';
import { ClsTercerosDireccionesModel } from '../models/Aplicacion/ClsTercerosDirecciones.model';
import { ClsReferenciaModel } from '../models/Aplicacion/ClsReferencia.model';
import { ClsNegocioModel } from '../models/Aplicacion/ClsNegocio.model';
import { ClsCarritoCompraModel } from '../models/Aplicacion/ClsCarritoCompra.model';
import { ClsTipoPedidoModel } from '../models/Aplicacion/ClsTipoPedidoEmpresa.model';
import { ClsPedidoModel } from '../models/Aplicacion/ClsPedido.model';
import { LocalizacionService } from '../services/localizacion.service';
import { ClsPedidoUbicacionModel } from '../models/Aplicacion/ClsPedidoUbicacion.model';
import { ClsZonaDomicilioModel } from '../models/Aplicacion/ClsZonaDomicilio.model';


@Injectable({
  providedIn: 'root'
})
export class ZonaDomicilioService extends BaseService<any, MasterModel> {
  private apiURL: string;

  constructor(protected _http: HttpClient,
    private localizacion: LocalizacionService
  ) {
    super(_http, environment.apiGatewayURL)
    this.apiURL = environment.apiGatewayURL;
  }

  async ZonaDomicilioGuardar(ZonaDomicilio: ClsZonaDomicilioModel) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }

    return new Promise(resolve => {
      this.post(APIENDPOINT.ZonaDomicilioGuardar, ZonaDomicilio, true, token)
        .subscribe(resp => {
          resolve(resp);
        }, error => {
          console.log('Error: ', error);
          resolve(null);
        });
    });
  }

  async ZonaDomicilioEliminar(ZonaDomicilio: ClsZonaDomicilioModel) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }

    return new Promise(resolve => {
      this.post(APIENDPOINT.ZonaDomicilioEliminar, ZonaDomicilio, true, token)
        .subscribe(resp => {
          resolve(resp);
        }, error => {
          console.log('Error: ', error);
          resolve(null);
        });
    });
  }

  async ZonaDomicilioListar(NegocioID: number) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }

    return new Promise(resolve => {
      this.get(APIENDPOINT.ZonaDomicilioListar + "?NegocioID=" + NegocioID, true, token)
        .subscribe(resp => {
          resolve(resp);
        }, error => {
          console.log('Error: ', error);
          resolve(null);
        });
    });
  }
}
