import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ng-style',
  templateUrl: './ng-style.component.html',
  styleUrls: ['./ng-style.component.css']
})
export class NgStyleComponent implements OnInit {
    tamano: number = 20;
    alerta: string = "alert-danger";
    propiedades: Object = {
        danger: true
    };
    loading: boolean = false;

    alertSwitch: string = "warning";

  constructor() { }

  ngOnInit() {
  }

    Ejecutar() {
        this.loading = true;

        setTimeout(() => this.loading = false, 3000);
    }
}
