import { Component, OnInit } from '@angular/core';
import { FileUpload2Service } from '../../services/FileUpload2.service';
import { MatDialog } from '@angular/material/dialog';
import { MenuComponent } from '../Menu/menu/menu.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfigEmpresaService } from '../../services/ConfigEmpresa.service';
import { ClsAccesoNegocioModel } from '../../models/Web/ClsAccesoNegocio.model';
import { ClsMenuModel } from '../../models/Web/Menu/ClsMenu.model';
import { ClsUsuarioAccesoModel } from '../../models/Web/ClsAccesoUsuario.model';
import { ClsMenuDiaModel } from '../../models/Web/Menu/ClsMenuDia.model';
import { ClsMenuNegocioAppModel } from '../../models/Web/Menu/ClsMenuNegocioApp.model';
import { MenuService } from '../../services/Menu.service';
import { ClsMenuDetalleModel } from '../../models/Web/Menu/ClsMenuDetalle.model';
import { ClsMenuDetReferenciaModel } from '../../models/Web/Menu/ClsMenuDetReferencia.model';
import { ClsMenuToppingModel } from '../../models/Web/Menu/ClsMenuTopping.model';
import { ClsMenuToppingReferenciaModel } from '../../models/Web/Menu/ClsMenuToppingReferencia.model';
import { ClsReferenciaModel } from '../../models/Web/ClsReferencia.model';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

declare var window: any;
@Component({
  selector: 'app-app-menu',
  templateUrl: './app-menu.component.html',
  styleUrls: ['./app-menu.component.css']
})
export class AppMenuComponent implements OnInit {
  negocios: ClsAccesoNegocioModel[] = []
  empresaId: number;
  ip: string
  usuId: number;
  mensajeRespuesta: string = "";
  imagenUrl = "../../assets/img/NoImg.png";
  formaNegocios: FormGroup;
  formaMenu: FormGroup;
  formaMenuDetalle: FormGroup;
  formaMenuDetalleArticulo: FormGroup;
  formaTopping: FormGroup;
  formaToppingArticulo: FormGroup;
  listamenu: ClsMenuModel[] = [];
  listaDetalle: ClsMenuDetalleModel[] = [];
  listaReferencias: ClsMenuDetReferenciaModel[] = [];
  listaToppings: ClsMenuToppingModel[] = [];
  listaTopRef: ClsMenuToppingReferenciaModel[] = [];

  menu: ClsMenuModel = new ClsMenuModel();
  menuSeleccionado: number = 0;
  menuDetalle: ClsMenuDetalleModel = new ClsMenuDetalleModel();
  menuDatalleSeleccionado: number = 0;
  menuDetalleArticulo: ClsMenuDetReferenciaModel = new ClsMenuDetReferenciaModel();
  menuTopping: ClsMenuToppingModel = new ClsMenuToppingModel();
  menuToppingSeleccionado: number = 0;
  menuToppingArticulo: ClsMenuToppingReferenciaModel = new ClsMenuToppingReferenciaModel();

  aplicadia = false;

  tipoEliminar: string = "";
  idEliminar: number = 0;
  indexEliminar: number = 0;

  DA_REF_CODIGO: string = "";
  DA_REF_DESCRIPCION: string = "";
  TA_REF_DESCRIPCION: string = "";
  TA_REF_CODIGO: string = "";
  

  constructor(private uploadService: FileUpload2Service, private empresaService: ConfigEmpresaService,
    private menuService: MenuService, public dialog: MatDialog) {
    let datosn: any = localStorage.getItem('negocio');
    if (datosn != null && datosn != '') {
      let objNego: any = JSON.parse(datosn);
      this.empresaId = objNego[0].NEGO_EMPRESA;
    }

    let datos1: any = localStorage.getItem('sesion');
    if (datos1 != null && datos1 != '') {
      let objUsu = new ClsUsuarioAccesoModel();
      objUsu = JSON.parse(datos1);
      this.usuId = objUsu.USU_ID;
    }

    this.ip = localStorage.getItem('ip');
  }

  

  async ngOnInit() {
    this.formaNegocios = new FormGroup({});
    this.formaMenu = new FormGroup({
      "MENU_ID": new FormControl('0', Validators.required),
      "MENU_NOMBRE": new FormControl('', Validators.required),
      "MENU_DESCRIPCION": new FormControl(' '),
      "MENU_COLOR_INI": new FormControl(''),
      "MENU_COLOR_FIN": new FormControl(''),
      "MENU_COLOR_FUENTE": new FormControl(''),
      "MENU_ANCHO_COLUMNAS": new FormControl('2', Validators.required),
      "MENU_ANCHO_ESPACIOS": new FormControl('0', Validators.required),
      "MENU_TAMANO_FUENTE": new FormControl('0.6rem', Validators.required),
      "MENU_TAMANO_FUENTE_DESCRIP": new FormControl('0.6rem', Validators.required),
      "MENU_APLICA_DIA": new FormControl('N', Validators.required),
      "MENU_FECHA_INI": new FormControl(),
      "MENU_FECHA_FIN": new FormControl(),
      "MENU_VISUALIZA": new FormControl('NOMBRE', Validators.required),
      "MENU_URL_IMAGEN": new FormControl(),
      "MENU_ORDEN": new FormControl('0', Validators.required),
      "DIA_LUNES": new FormControl(),
      "DIA_MARTES": new FormControl(),
      "DIA_MIERCOLES": new FormControl(),
      "DIA_JUEVES": new FormControl(),
      "DIA_VIERNES": new FormControl(),
      "DIA_SABADO": new FormControl(),
      "DIA_DOMINGO": new FormControl()
    });

    this.formaMenuDetalle = new FormGroup({
      "DET_ID": new FormControl(0),      
      "DET_NOMBRE": new FormControl('', Validators.required),
      "DET_DESCRIPCION": new FormControl(' '),
      "DET_APLICA_VARIOS": new FormControl('UNO', Validators.required),
      "DET_TIENE_TOPPINGS": new FormControl('N', Validators.required),
      "DET_ORDEN": new FormControl('0', Validators.required),
      "DET_VALOR_INFO_TEXTO": new FormControl(''),
      "DET_VALOR_INFO": new FormControl(0)      
    });
    
    this.formaMenuDetalleArticulo = new FormGroup({      
      "DA_ID": new FormControl(0),
      "DA_ARTICULO_ID": new FormControl('', Validators.required),
      "DA_NOMBRE": new FormControl('', Validators.required),
      "DA_DESCRIPCION": new FormControl(" "),
      "DA_ORDEN": new FormControl('0', Validators.required)
      
    });

    this.formaTopping = new FormGroup({
      "TOP_ID": new FormControl(0),
      "TOP_NOMBRE": new FormControl('', Validators.required),
      "TOP_DESCRIPCION": new FormControl(' '),
      "TOP_APLICA_VARIOS": new FormControl('UNO', Validators.required),
      "TOP_OBLIGATORIO": new FormControl('N', Validators.required),
      "TOP_CANT_MINIMA": new FormControl('0', Validators.required),
      "TOP_CANT_MAXIMA": new FormControl('0', Validators.required),
      "TOP_ORDEN": new FormControl('0', Validators.required)
    });

    this.formaToppingArticulo = new FormGroup({
      "TA_ID": new FormControl(0),
      "TA_ARTICULO_ID": new FormControl('', Validators.required),
      "TA_NOMBRE": new FormControl('', Validators.required),
      "TA_DESCRIPCION": new FormControl(" "),
      "TA_LIMITE_MAXIMO": new FormControl(0, Validators.required),
      "TA_ORDEN": new FormControl('0', Validators.required),
      "TA_COMBO": new FormControl("N"),
      "TA_PERSONALIZAR_PERMITIDO": new FormControl("N"),
      "TA_PERSONALIZAR_DESCRIPCION": new FormControl(""),
      "TA_SUGERIDO": new FormControl("N"),
    });

    await this.ListarNegociosEmpresa();
    await this.ListarMenuEmpresa();    
  }
    

  async ListarNegociosEmpresa() {
    let resp: any = await this.empresaService.ConsultarNegociosEmpresa(this.empresaId);
      console.log(resp);
      if (resp != null) {
          if (resp.IsSuccess) {
              this.negocios = resp.Data;
              for (var i = 0; i < this.negocios.length; i++) {
                  this.formaNegocios.addControl(this.negocios[i].NEGO_ID + "", new FormControl());
              }
              console.log("negocios", this.negocios);
          } else {
              console.log("Error al listar los negocios", resp.Message);
          }
      }
    if (resp != null) {
      
    }
  }

    async ListarMenuEmpresa() {
        let resp: any = await this.menuService.ConsultarMenuApp(this.empresaId);
        console.log("consulta", resp);
        if (resp != null) {
            if (resp.IsSuccess) {
                this.listamenu = resp.Data;
                console.log("lista", this.listamenu);
            } else {
                console.log("Error al listar el menú", resp.Message);
            }
        }
    }



  modalMenu() {
    window.$('#myModalMenu').modal('show');
  }

  modalDetalle() {
   window.$('#myModalDetalle').modal('show');
  }

  modalReferencia() {
   window.$('#myModalReferencia').modal('show');
  }

  modalTopping() {
  window.$('#myModalTopping').modal('show');
  }

  modalItemTopping(id, index) {
    window.$('#myModalItemTopping').modal('show');
    this.SeleccionMenuTopping(id, index);
  }

  seleccionar(){
    let listaId: string[] = []
    for (const field in this.formaNegocios.controls) { // 'field' is a string
      const control = this.formaNegocios.get(field); // 'control' is a FormControl      
      if (control.value == true) {
        listaId.push(field);
      }
    }
    console.log(listaId);    
  }

  async GuardarMenu() {
    this.menu.MENU_ID = this.formaMenu.controls['MENU_ID'].value;
    this.menu.MENU_NOMBRE = this.formaMenu.controls['MENU_NOMBRE'].value;
    this.menu.MENU_DESCRIPCION = this.formaMenu.controls['MENU_DESCRIPCION'].value;
    this.menu.MENU_COLOR_INI = this.formaMenu.controls['MENU_COLOR_INI'].value;
    this.menu.MENU_COLOR_FIN = this.formaMenu.controls['MENU_COLOR_FIN'].value;
    this.menu.MENU_COLOR_FUENTE = this.formaMenu.controls['MENU_COLOR_FUENTE'].value;
    this.menu.MENU_TAMANO_FUENTE = this.formaMenu.controls['MENU_TAMANO_FUENTE'].value;
    this.menu.MENU_TAMANO_FUENTE_DESCRIP = this.formaMenu.controls['MENU_TAMANO_FUENTE_DESCRIP'].value;
    this.menu.MENU_ANCHO_COLUMNAS = this.formaMenu.controls['MENU_ANCHO_COLUMNAS'].value;
    this.menu.MENU_ANCHO_ESPACIOS = this.formaMenu.controls['MENU_ANCHO_ESPACIOS'].value;
    this.menu.MENU_VISUALIZA = this.formaMenu.controls['MENU_VISUALIZA'].value;
    this.menu.MENU_ORDEN = this.formaMenu.controls['MENU_ORDEN'].value;
    this.menu.MENU_URL_IMAGEN = ""
    if (this.uploadService.urlfile != undefined) {
      this.menu.MENU_URL_IMAGEN = this.uploadService.urlfile;
    }    
    this.menu.MENU_APLICA_DIA = this.formaMenu.controls['MENU_APLICA_DIA'].value;
    this.menu.MENU_FECHA_INI = this.formaMenu.controls['MENU_FECHA_INI'].value;
    this.menu.MENU_FECHA_FIN = this.formaMenu.controls['MENU_FECHA_FIN'].value;
    this.menu.MENU_ESTADO = 'A'
    this.menu.MENU_USU_ID = this.usuId;
    this.menu.MENU_IP_EQUIPO = this.ip;
    this.menu.MENU_EMPRESA = this.empresaId;
  

    let listaNego: ClsMenuNegocioAppModel[] = []
    for (const field in this.formaNegocios.controls) { // 'field' is a string
      const control = this.formaNegocios.get(field); // 'control' is a FormControl
      let negocio: ClsMenuNegocioAppModel = new ClsMenuNegocioAppModel();
      negocio.MN_MENU_ID = this.formaMenu.controls['MENU_ID'].value;
      negocio.MN_MOSTRAR_IMAGEN = "N";
      negocio.MN_IP_EQUIPO = this.ip;      
      negocio.MN_NEGOCIO_ID = parseInt(field);      
      if (control.value == true) {
        negocio.MN_ESTADO = "A";
      } else {
        negocio.MN_ESTADO = "N";
      }
      listaNego.push(negocio);
    }
    this.menu.NEGOCIOS = listaNego;

    let listaDias: ClsMenuDiaModel[] = [];
    let dia1: ClsMenuDiaModel = new ClsMenuDiaModel;
    dia1.MD_DIA_NOMBRE = 'DOMINGO';
    dia1.MD_DIA_NUMERO = 1;
    dia1.MD_MENU_ID = this.formaMenu.controls['MENU_ID'].value;
    dia1.MD_IP_EQUIPO = this.ip;
    dia1.MD_USU_ID = this.usuId;
    if (this.formaMenu.controls['DIA_DOMINGO'].value == true) {
      dia1.MD_ESTADO = "A";
    } else {
      dia1.MD_ESTADO = "N";
    } 
    listaDias.push(dia1);

    let dia2: ClsMenuDiaModel = new ClsMenuDiaModel;
    dia2.MD_DIA_NOMBRE = 'LUNES';
    dia2.MD_DIA_NUMERO = 2;
    dia2.MD_MENU_ID = this.formaMenu.controls['MENU_ID'].value;
    dia2.MD_IP_EQUIPO = this.ip;
    dia2.MD_USU_ID = this.usuId;
    if (this.formaMenu.controls['DIA_LUNES'].value == true) {
      dia2.MD_ESTADO = "A";
    } else {
      dia2.MD_ESTADO = "N";
    } 
    listaDias.push(dia2);

    let dia3: ClsMenuDiaModel = new ClsMenuDiaModel;
    dia3.MD_DIA_NOMBRE = 'MARTES';
    dia3.MD_DIA_NUMERO = 3;
    dia3.MD_MENU_ID = this.formaMenu.controls['MENU_ID'].value;
    dia3.MD_IP_EQUIPO = this.ip;
    dia3.MD_USU_ID = this.usuId;
    if (this.formaMenu.controls['DIA_MARTES'].value == true) {
      dia3.MD_ESTADO = "A";
    } else {
      dia3.MD_ESTADO = "N";
    } 
    listaDias.push(dia3);

    let dia4: ClsMenuDiaModel = new ClsMenuDiaModel;
    dia4.MD_DIA_NOMBRE = 'MIERCOLES';
    dia4.MD_DIA_NUMERO = 4
    dia4.MD_MENU_ID = this.formaMenu.controls['MENU_ID'].value;
    dia4.MD_IP_EQUIPO = this.ip;
    dia4.MD_USU_ID = this.usuId;
    if (this.formaMenu.controls['DIA_MIERCOLES'].value == true) {
      dia4.MD_ESTADO = "A";
    } else {
      dia4.MD_ESTADO = "N";
    } 
    listaDias.push(dia4);

    let dia5: ClsMenuDiaModel = new ClsMenuDiaModel;
    dia5.MD_DIA_NOMBRE = 'JUEVES';
    dia5.MD_DIA_NUMERO = 5;
    dia5.MD_MENU_ID = this.formaMenu.controls['MENU_ID'].value;
    dia5.MD_IP_EQUIPO = this.ip;
    dia5.MD_USU_ID = this.usuId;
    if (this.formaMenu.controls['DIA_JUEVES'].value == true) {
      dia5.MD_ESTADO = "A";
    } else {
      dia5.MD_ESTADO = "N";
    } 
    listaDias.push(dia5);

    let dia6: ClsMenuDiaModel = new ClsMenuDiaModel;
    dia6.MD_DIA_NOMBRE = 'VIERNES';
    dia6.MD_DIA_NUMERO = 6;
    dia6.MD_MENU_ID = this.formaMenu.controls['MENU_ID'].value;
    dia6.MD_IP_EQUIPO = this.ip;
    dia6.MD_USU_ID = this.usuId;
    if (this.formaMenu.controls['DIA_VIERNES'].value == true) {
      dia6.MD_ESTADO = "A";
    } else {
      dia6.MD_ESTADO = "N";
    } 
    listaDias.push(dia6);

    let dia7: ClsMenuDiaModel = new ClsMenuDiaModel;
    dia7.MD_DIA_NOMBRE = 'SABADO';
    dia7.MD_DIA_NUMERO = 7;
    dia7.MD_MENU_ID = this.formaMenu.controls['MENU_ID'].value;
    dia7.MD_IP_EQUIPO = this.ip;
    dia7.MD_USU_ID = this.usuId;
    if (this.formaMenu.controls['DIA_SABADO'].value == true) {
      dia7.MD_ESTADO = "A";
    } else {
      dia7.MD_ESTADO = "N";
    }   
    listaDias.push(dia7);

    this.menu.DIAS = listaDias;

    console.log("menu", this.menu);
    let result: any = await this.menuService.GuardarMenuApp(this.menu);
    console.log(result);
    if (result.IsSuccess) {
      this.LimpiarTodo();
      await this.ListarMenuEmpresa();
      this.menuSeleccionado = 0;
      this.menuDatalleSeleccionado = 0;
      this.menuToppingSeleccionado = 0;
    }
    this.mensajeRespuesta = result.Message;
    window.$('#myModal').modal('show');
  }

  SeleccionMenu(menuId: number, index) {    
    this.menuSeleccionado = menuId;
    console.log("indexm", index);
    this.listaDetalle = this.listamenu[index].DETALLE;
    this.menuDatalleSeleccionado = 0;
    this.menuToppingSeleccionado = 0;
  }

  async GuardarMenuDetalle() {
    this.menuDetalle.DET_ID = this.formaMenuDetalle.controls['DET_ID'].value;
    this.menuDetalle.DET_MENU_ID = this.menuSeleccionado;
    this.menuDetalle.DET_NOMBRE = this.formaMenuDetalle.controls['DET_NOMBRE'].value;
    this.menuDetalle.DET_DESCRIPCION = this.formaMenuDetalle.controls['DET_DESCRIPCION'].value;
    this.menuDetalle.DET_APLICA_VARIOS = this.formaMenuDetalle.controls['DET_APLICA_VARIOS'].value;
    this.menuDetalle.DET_TIENE_TOPPINGS = this.formaMenuDetalle.controls['DET_TIENE_TOPPINGS'].value;
    this.menuDetalle.DET_ORDEN = this.formaMenuDetalle.controls['DET_ORDEN'].value;
    this.menuDetalle.DET_VALOR_INFO = this.formaMenuDetalle.controls['DET_VALOR_INFO'].value;
    this.menuDetalle.DET_VALOR_INFO_TEXTO = this.formaMenuDetalle.controls['DET_VALOR_INFO_TEXTO'].value;
    this.menuDetalle.DET_USU_ID = this.usuId;
    this.menuDetalle.DET_IP_EQUIPO = this.ip;
    this.menuDetalle.DET_ESTADO = 'A';
    this.menuDetalle.EMPRESA_ID = this.empresaId;
    this.menuDetalle.DET_URL_IMAGEN = ""
    if (this.uploadService.urlfile != undefined) {
      this.menuDetalle.DET_URL_IMAGEN = this.uploadService.urlfile;
    }
    console.log("detalle", this.menuDetalle);
    let result: any = await this.menuService.GuardarMenuDetalleApp(this.menuDetalle);
    console.log(result);
    if (result.IsSuccess) {
      this.LimpiarTodo();
      await this.ListarMenuEmpresa();
      if (this.menuSeleccionado != 0) {
        for (var i = 0; i < this.listamenu.length; i++) {
          if (this.listamenu[i].MENU_ID == this.menuSeleccionado) {
            this.listaDetalle = this.listamenu[i].DETALLE;
            break;
          }
        }
      }
      this.menuDatalleSeleccionado = 0;
      this.menuToppingSeleccionado = 0;
    }
    this.mensajeRespuesta = result.Message;
    window.$('#myModal').modal('show');
  }

  SeleccionMenuDetalle(DetalleId: number, index) {
    this.menuDatalleSeleccionado = DetalleId;
    console.log("detalleid", this.menuDatalleSeleccionado)
    this.listaReferencias = this.listaDetalle[index].ARTICULOS;
    this.listaToppings = this.listaDetalle[index].TOPPINGS;
  }

  async GuardarMenuDetalleArticulo() {
    this.menuDetalleArticulo.DA_ID = this.formaMenuDetalleArticulo.controls['DA_ID'].value;
    this.menuDetalleArticulo.DA_MENU_DETALLE_ID = this.menuDatalleSeleccionado;
    this.menuDetalleArticulo.DA_ARTICULO_ID = this.formaMenuDetalleArticulo.controls['DA_ARTICULO_ID'].value;
    this.menuDetalleArticulo.DA_NOMBRE = this.formaMenuDetalleArticulo.controls['DA_NOMBRE'].value;
    this.menuDetalleArticulo.DA_DESCRIPCION = this.formaMenuDetalleArticulo.controls['DA_DESCRIPCION'].value;
    this.menuDetalleArticulo.DA_ESTADO = 'A';
    this.menuDetalleArticulo.DA_ORDEN = this.formaMenuDetalleArticulo.controls['DA_ORDEN'].value;
    this.menuDetalleArticulo.DA_IP_EQUIPO = this.ip;
    this.menuDetalleArticulo.EMPRESA_ID = this.empresaId;
    this.menuDetalleArticulo.DA_URL_IMAGEN = ""
    if (this.uploadService.urlfile != undefined) {
      this.menuDetalleArticulo.DA_URL_IMAGEN = this.uploadService.urlfile;
    }
    this.menuDetalleArticulo.DA_USU_ID= this.usuId;

    console.log("referencias", this.menuDetalleArticulo);
    let result: any = await this.menuService.GuardarMenuDetalleArticulo(this.menuDetalleArticulo);
    console.log(result);
    if (result.IsSuccess) {
      this.LimpiarTodo();
      await this.ListarMenuEmpresa();
      if (this.menuSeleccionado != 0) {
        for (var i = 0; i < this.listamenu.length; i++) {
          if (this.listamenu[i].MENU_ID == this.menuSeleccionado) {
            this.listaDetalle = this.listamenu[i].DETALLE;
            if (this.menuDatalleSeleccionado != 0) {
              for (var j = 0; j < this.listaDetalle.length; j++) {
                if (this.listaDetalle[j].DET_ID == this.menuDatalleSeleccionado) {
                  this.listaReferencias = this.listaDetalle[j].ARTICULOS;
                  this.listaToppings = this.listaDetalle[j].TOPPINGS;
                  break;
                }
              }
            }
            break;
          }
        }
      }      
      this.menuToppingSeleccionado = 0;
    }
    this.mensajeRespuesta = result.Message;
    window.$('#myModal').modal('show');
  }

  async GuardarTopping() {
    this.menuTopping.TOP_ID = this.formaTopping.controls['TOP_ID'].value;
    this.menuTopping.TOP_MENU_DETALLE_ID = this.menuDatalleSeleccionado;
    this.menuTopping.TOP_NOMBRE = this.formaTopping.controls['TOP_NOMBRE'].value;
    this.menuTopping.TOP_DESCRIPCION = this.formaTopping.controls['TOP_DESCRIPCION'].value;
    this.menuTopping.TOP_APLICA_VARIOS = this.formaTopping.controls['TOP_APLICA_VARIOS'].value;
    this.menuTopping.TOP_OBLIGATORIO = this.formaTopping.controls['TOP_OBLIGATORIO'].value;
    this.menuTopping.TOP_CANT_MINIMA = this.formaTopping.controls['TOP_CANT_MINIMA'].value;
    this.menuTopping.TOP_CANT_MAXIMA = this.formaTopping.controls['TOP_CANT_MAXIMA'].value;
    this.menuTopping.TOP_ORDEN = this.formaTopping.controls['TOP_ORDEN'].value;
    this.menuTopping.TOP_USU_ID = this.usuId;
    this.menuTopping.TOP_IP_EQUIPO = this.ip;
    this.menuTopping.TOP_ESTADO = 'A';
    this.menuTopping.EMPRESA_ID = this.empresaId;
    this.menuTopping.TOP_URL_IMAGEN = ""
    if (this.uploadService.urlfile != undefined) {
      this.menuTopping.TOP_URL_IMAGEN = this.uploadService.urlfile;
    }
    console.log("TOP", this.menuTopping);
    let result: any = await this.menuService.GuardarMenuDetalleTopping(this.menuTopping);
    console.log(result);
    if (result.IsSuccess) {
      this.LimpiarTodo();
      await this.ListarMenuEmpresa();
      if (this.menuSeleccionado != 0) {
        for (var i = 0; i < this.listamenu.length; i++) {
          if (this.listamenu[i].MENU_ID == this.menuSeleccionado) {
            this.listaDetalle = this.listamenu[i].DETALLE;
            if (this.menuDatalleSeleccionado != 0) {
              for (var j = 0; j < this.listaDetalle.length; j++) {
                if (this.listaDetalle[j].DET_ID == this.menuDatalleSeleccionado) {
                  this.listaReferencias = this.listaDetalle[j].ARTICULOS;
                  this.listaToppings = this.listaDetalle[j].TOPPINGS;
                  break;
                }
              }
            }
            break;
          }
        }
      }
      this.menuToppingSeleccionado = 0;
    }
    this.mensajeRespuesta = result.Message;
    window.$('#myModal').modal('show');
  }

  SeleccionMenuTopping(TopId: number, index) {
    this.menuToppingSeleccionado = TopId;
    console.log("TOPID", this.menuToppingSeleccionado);
    this.listaTopRef = this.listaToppings[index].T_ARTICULOS;
  }

  async GuardarToppingArticulo() {
    this.menuToppingArticulo.TA_ID = this.formaToppingArticulo.controls['TA_ID'].value;
    this.menuToppingArticulo.TA_MENU_TOPPING_ID = this.menuToppingSeleccionado;
    this.menuToppingArticulo.TA_ARTICULO_ID = this.formaToppingArticulo.controls['TA_ARTICULO_ID'].value;
    this.menuToppingArticulo.TA_NOMBRE = this.formaToppingArticulo.controls['TA_NOMBRE'].value;
    this.menuToppingArticulo.TA_DESCRIPCION = this.formaToppingArticulo.controls['TA_DESCRIPCION'].value;
    this.menuToppingArticulo.TA_LIMITE_MAXIMO = this.formaToppingArticulo.controls['TA_LIMITE_MAXIMO'].value;
    this.menuToppingArticulo.TA_ESTADO = 'A';
    this.menuToppingArticulo.TA_COMBO = this.formaToppingArticulo.controls['TA_COMBO'].value;
    this.menuToppingArticulo.TA_PERSONALIZAR_PERMITIDO = this.formaToppingArticulo.controls['TA_PERSONALIZAR_PERMITIDO'].value;
    this.menuToppingArticulo.TA_PERSONALIZAR_DESCRIPCION = this.formaToppingArticulo.controls['TA_PERSONALIZAR_DESCRIPCION'].value;
    this.menuToppingArticulo.TA_SUGERIDO = this.formaToppingArticulo.controls['TA_SUGERIDO'].value;
    this.menuToppingArticulo.TA_ORDEN = this.formaToppingArticulo.controls['TA_ORDEN'].value;
    this.menuToppingArticulo.TA_IP_EQUIPO = this.ip;
    this.menuToppingArticulo.EMPRESA_ID = this.empresaId;
    this.menuToppingArticulo.TA_URL_IMAGEN = ""

    if (this.uploadService.urlfile != undefined) {
      this.menuToppingArticulo.TA_URL_IMAGEN = this.uploadService.urlfile;
    }
    this.menuToppingArticulo.TA_USU_ID = this.usuId;

    console.log("top ref", this.menuToppingArticulo);
    let result: any = await this.menuService.GuardarMenuDetalleToppingArticulo(this.menuToppingArticulo);
    console.log(result);
    if (result.IsSuccess) {
      this.LimpiarTodo();
      await this.ListarMenuEmpresa();
      if (this.menuSeleccionado != 0) {
        for (var i = 0; i < this.listamenu.length; i++) {
          if (this.listamenu[i].MENU_ID == this.menuSeleccionado) {
            this.listaDetalle = this.listamenu[i].DETALLE;
            if (this.menuDatalleSeleccionado != 0) {
              for (var j = 0; j < this.listaDetalle.length; j++) {
                if (this.listaDetalle[j].DET_ID == this.menuDatalleSeleccionado) {
                  this.listaReferencias = this.listaDetalle[j].ARTICULOS;
                  this.listaToppings = this.listaDetalle[j].TOPPINGS;
                  if (this.menuToppingSeleccionado != 0) {
                    for (var k = 0; k < this.listaToppings.length; k++) {
                      if (this.listaToppings[k].TOP_ID == this.menuToppingSeleccionado) {
                        this.listaTopRef = this.listaToppings[k].T_ARTICULOS;
                        break;
                      }
                    }
                  }
                  break;
                }
              }
            }
            break;
          }
        }
      }
      
    }
    this.mensajeRespuesta = result.Message;
    window.$('#myModal').modal('show');
  }

  async LimpiarTodo() {
    
    this.uploadService.urlfile = "";
    this.uploadService.message = "";
    this.uploadService.progress = 0;
       
    window.$('#myModalMenu').modal('hide');
    window.$('#myModalDetalle').modal('hide');
    window.$('#myModalReferencia').modal('hide');
    window.$('#myModalTopping').modal('hide');
   // window.$('#myModalItemTopping').modal('hide');
    this.imagenUrl = "../../assets/img/NoImg.png";

    this.formaMenu.controls['MENU_ID'].setValue(0);
    this.formaMenu.controls['MENU_NOMBRE'].setValue("");
    this.formaMenu.controls['MENU_DESCRIPCION'].setValue(" ");

    this.formaMenuDetalle.controls['DET_ID'].setValue(0);
    this.formaMenuDetalle.controls['DET_NOMBRE'].setValue("");
    this.formaMenuDetalle.controls['DET_DESCRIPCION'].setValue(" ");

    this.formaMenuDetalleArticulo.controls['DA_ID'].setValue(0);
    this.formaMenuDetalleArticulo.controls['DA_ARTICULO_ID'].setValue(0);
    this.formaMenuDetalleArticulo.controls['DA_NOMBRE'].setValue("");
    this.formaMenuDetalleArticulo.controls['DA_DESCRIPCION'].setValue(" ");
    this.DA_REF_CODIGO = "";
    this.DA_REF_DESCRIPCION = "";

    this.formaTopping.controls['TOP_ID'].setValue(0);
    this.formaTopping.controls['TOP_NOMBRE'].setValue("");
    this.formaTopping.controls['TOP_DESCRIPCION'].setValue(" ");

    this.formaToppingArticulo.controls['TA_ID'].setValue(0);
    this.formaToppingArticulo.controls['TA_ARTICULO_ID'].setValue(0);
    this.formaToppingArticulo.controls['TA_NOMBRE'].setValue("");
    this.formaToppingArticulo.controls['TA_DESCRIPCION'].setValue(" ");
    this.formaMenuDetalleArticulo.controls['TA_PERSONALIZAR_DESCRIPCION'].setValue(" ");
    this.TA_REF_DESCRIPCION = ""; 
    this.TA_REF_CODIGO = "";
    
  }
     

  EditarMenu(Menu, Index) {    
    this.menu = this.listamenu[Index];

      this.formaMenu.controls['MENU_ID'].setValue(this.menu.MENU_ID);
      this.formaMenu.controls['MENU_NOMBRE'].setValue(this.menu.MENU_NOMBRE);
      this.formaMenu.controls['MENU_DESCRIPCION'].setValue(this.menu.MENU_DESCRIPCION);
      this.formaMenu.controls['MENU_COLOR_INI'].setValue(this.menu.MENU_COLOR_INI);
      this.formaMenu.controls['MENU_COLOR_FIN'].setValue(this.menu.MENU_COLOR_FIN);
      this.formaMenu.controls['MENU_COLOR_FUENTE'].setValue(this.menu.MENU_COLOR_FUENTE);
      this.formaMenu.controls['MENU_TAMANO_FUENTE'].setValue(this.menu.MENU_TAMANO_FUENTE);
      this.formaMenu.controls['MENU_TAMANO_FUENTE_DESCRIP'].setValue(this.menu.MENU_TAMANO_FUENTE_DESCRIP);
      this.formaMenu.controls['MENU_ANCHO_COLUMNAS'].setValue(this.menu.MENU_ANCHO_COLUMNAS);
      this.formaMenu.controls['MENU_ANCHO_ESPACIOS'].setValue(this.menu.MENU_ANCHO_ESPACIOS);
      this.formaMenu.controls['MENU_VISUALIZA'].setValue(this.menu.MENU_VISUALIZA);
      this.formaMenu.controls['MENU_ORDEN'].setValue(this.menu.MENU_ORDEN);
      this.formaMenu.controls['MENU_APLICA_DIA'].setValue(this.menu.MENU_APLICA_DIA);
      this.formaMenu.controls['MENU_FECHA_INI'].setValue(this.menu.MENU_FECHA_INI);
    this.formaMenu.controls['MENU_FECHA_FIN'].setValue(this.menu.MENU_FECHA_FIN);

    this.validarAplicaDia();

    for (var i = 0; i < this.menu.NEGOCIOS.length; i++) {
      if (this.formaNegocios.controls[this.menu.NEGOCIOS[i].MN_NEGOCIO_ID] != null) {
        if (this.menu.NEGOCIOS[i].MN_ESTADO == "A") {
          this.formaNegocios.controls[this.menu.NEGOCIOS[i].MN_NEGOCIO_ID].setValue(true);
        } else {
          this.formaNegocios.controls[this.menu.NEGOCIOS[i].MN_NEGOCIO_ID].setValue(false);
        }
      }
    }
    this.formaMenu.controls['DIA_LUNES'].setValue(false);
    this.formaMenu.controls['DIA_MARTES'].setValue(false);
    this.formaMenu.controls['DIA_MIERCOLES'].setValue(false);
    this.formaMenu.controls['DIA_JUEVES'].setValue(false);
    this.formaMenu.controls['DIA_VIERNES'].setValue(false);
    this.formaMenu.controls['DIA_SABADO'].setValue(false);
    this.formaMenu.controls['DIA_DOMINGO'].setValue(false);
    for (var i = 0; i < this.menu.DIAS.length; i++) {
      if (this.menu.DIAS[i].MD_DIA_NUMERO == 1 && this.menu.DIAS[i].MD_ESTADO == "A") {
        this.formaMenu.controls['DIA_DOMINGO'].setValue(true);
      }
      else if (this.menu.DIAS[i].MD_DIA_NUMERO == 2 && this.menu.DIAS[i].MD_ESTADO == "A") {
        this.formaMenu.controls['DIA_LUNES'].setValue(true);
      }
      else if(this.menu.DIAS[i].MD_DIA_NUMERO == 3 && this.menu.DIAS[i].MD_ESTADO == "A") {
        this.formaMenu.controls['DIA_MARTES'].setValue(true);
      }
      else if (this.menu.DIAS[i].MD_DIA_NUMERO == 4 && this.menu.DIAS[i].MD_ESTADO == "A") {
        this.formaMenu.controls['DIA_MIERCOLES'].setValue(true);
      }
      else if (this.menu.DIAS[i].MD_DIA_NUMERO == 5 && this.menu.DIAS[i].MD_ESTADO == "A") {
        this.formaMenu.controls['DIA_JUEVES'].setValue(true);
      }
      else if (this.menu.DIAS[i].MD_DIA_NUMERO == 6 && this.menu.DIAS[i].MD_ESTADO == "A") {
        this.formaMenu.controls['DIA_VIERNES'].setValue(true);
      }
      else if (this.menu.DIAS[i].MD_DIA_NUMERO == 7 && this.menu.DIAS[i].MD_ESTADO == "A") {
        this.formaMenu.controls['DIA_SABADO'].setValue(true);
      }
    }

    if (this.menu.MENU_URL_IMAGEN != "") {
      this.imagenUrl = this.menuService.urlBase + this.menu.MENU_URL_IMAGEN;
    } else {
      this.imagenUrl = "../../assets/img/NoImg.png";
    }

    this.modalMenu();
  }


  EditarDetalle(Detalle, Index) {    
    this.menuDetalle = this.listaDetalle[Index];
    this.formaMenuDetalle.controls['DET_ID'].setValue(this.menuDetalle.DET_ID);
    this.formaMenuDetalle.controls['DET_NOMBRE'].setValue(this.menuDetalle.DET_NOMBRE);
    this.formaMenuDetalle.controls['DET_DESCRIPCION'].setValue(this.menuDetalle.DET_DESCRIPCION );
    this.formaMenuDetalle.controls['DET_APLICA_VARIOS'].setValue(this.menuDetalle.DET_APLICA_VARIOS);
    this.formaMenuDetalle.controls['DET_TIENE_TOPPINGS'].setValue(this.menuDetalle.DET_TIENE_TOPPINGS);
    this.formaMenuDetalle.controls['DET_ORDEN'].setValue(this.menuDetalle.DET_ORDEN);
    this.formaMenuDetalle.controls['DET_VALOR_INFO'].setValue(this.menuDetalle.DET_VALOR_INFO);
    this.formaMenuDetalle.controls['DET_VALOR_INFO_TEXTO'].setValue(this.menuDetalle.DET_VALOR_INFO_TEXTO);

    if (this.menuDetalle.DET_URL_IMAGEN != "") {
      this.imagenUrl = this.menuService.urlBase + this.menuDetalle.DET_URL_IMAGEN;
    } else {
      this.imagenUrl = "../../assets/img/NoImg.png";
    }
    this.modalDetalle();
  }

  EditarReferencia(Referencia, Index) {
    this.menuDetalleArticulo = this.listaReferencias[Index];
    this.formaMenuDetalleArticulo.controls['DA_ID'].setValue(this.menuDetalleArticulo.DA_ID);
    this.formaMenuDetalleArticulo.controls['DA_ARTICULO_ID'].setValue(this.menuDetalleArticulo.DA_ARTICULO_ID);
    this.formaMenuDetalleArticulo.controls['DA_NOMBRE'].setValue(this.menuDetalleArticulo.DA_NOMBRE);
    this.formaMenuDetalleArticulo.controls['DA_DESCRIPCION'].setValue(this.menuDetalleArticulo.DA_DESCRIPCION);   
    this.formaMenuDetalleArticulo.controls['DA_ORDEN'].setValue(this.menuDetalleArticulo.DA_ORDEN);
    this.DA_REF_CODIGO = this.menuDetalleArticulo.REF_CODIGO;
    this.DA_REF_DESCRIPCION= this.menuDetalleArticulo.REF_DESCRIPCION;

    if (this.menuDetalleArticulo.DA_URL_IMAGEN != "") {
      this.imagenUrl = this.menuService.urlBase + this.menuDetalleArticulo.DA_URL_IMAGEN;
    } else {
      this.imagenUrl = "../../assets/img/NoImg.png";
    }

    this.modalReferencia();
  }

  EditarTopping(Topping, Index) {
    this.menuTopping = this.listaToppings[Index];
    this.formaTopping.controls['TOP_ID'].setValue(this.menuTopping.TOP_ID);   
    this.formaTopping.controls['TOP_NOMBRE'].setValue(this.menuTopping.TOP_NOMBRE);
    this.formaTopping.controls['TOP_DESCRIPCION'].setValue(this.menuTopping.TOP_DESCRIPCION);
    this.formaTopping.controls['TOP_APLICA_VARIOS'].setValue(this.menuTopping.TOP_APLICA_VARIOS);
    this.formaTopping.controls['TOP_OBLIGATORIO'].setValue(this.menuTopping.TOP_OBLIGATORIO);
    this.formaTopping.controls['TOP_CANT_MINIMA'].setValue(this.menuTopping.TOP_CANT_MINIMA );
    this.formaTopping.controls['TOP_CANT_MAXIMA'].setValue(this.menuTopping.TOP_CANT_MAXIMA);
    this.formaTopping.controls['TOP_ORDEN'].setValue(this.menuTopping.TOP_ORDEN);
    this.modalTopping();

    if (this.menuTopping.TOP_URL_IMAGEN != "") {
      this.imagenUrl = this.menuService.urlBase + this.menuTopping.TOP_URL_IMAGEN;
    } else {
      this.imagenUrl = "../../assets/img/NoImg.png";
    }
  }

  EditarToppingRef(TopRef, Index) {
    this.menuToppingArticulo = this.listaTopRef[Index];
    this.formaToppingArticulo.controls['TA_ID'].setValue(this.menuToppingArticulo.TA_ID);
    this.formaToppingArticulo.controls['TA_ARTICULO_ID'].setValue(this.menuToppingArticulo.TA_ARTICULO_ID);
    this.formaToppingArticulo.controls['TA_NOMBRE'].setValue(this.menuToppingArticulo.TA_NOMBRE);
    this.formaToppingArticulo.controls['TA_DESCRIPCION'].setValue(this.menuToppingArticulo.TA_DESCRIPCION);
    this.formaToppingArticulo.controls['TA_LIMITE_MAXIMO'].setValue(this.menuToppingArticulo.TA_LIMITE_MAXIMO);
    this.formaToppingArticulo.controls['TA_ORDEN'].setValue(this.menuToppingArticulo.TA_ORDEN);
    this.formaToppingArticulo.controls['TA_COMBO'].setValue(this.menuToppingArticulo.TA_COMBO);
    this.formaToppingArticulo.controls['TA_PERSONALIZAR_PERMITIDO'].setValue(this.menuToppingArticulo.TA_PERSONALIZAR_PERMITIDO);
    this.formaToppingArticulo.controls['TA_PERSONALIZAR_DESCRIPCION'].setValue(this.menuToppingArticulo.TA_PERSONALIZAR_DESCRIPCION);
    this.formaToppingArticulo.controls['TA_SUGERIDO'].setValue(this.menuToppingArticulo.TA_SUGERIDO);
    this.TA_REF_CODIGO = this.menuToppingArticulo.REF_CODIGO;
    this.TA_REF_DESCRIPCION = this.menuToppingArticulo.REF_DESCRIPCION;

    if (this.menuToppingArticulo.TA_URL_IMAGEN != "") {
      this.imagenUrl = this.menuService.urlBase + this.menuToppingArticulo.TA_URL_IMAGEN;
    } else {
      this.imagenUrl = "../../assets/img/NoImg.png";
    }
  }

  async ConfirmarEliminar(id, index, tipo) {
    this.tipoEliminar = tipo;
    this.idEliminar = id;
    this.indexEliminar = index;
    window.$('#myModalDelete').modal('show');
  }

  async Eliminar() {
    if (this.tipoEliminar == "menu") {
      this.EliminarMenu(this.idEliminar, this.indexEliminar);
    } else if (this.tipoEliminar == "detalle") {
      this.EliminarMenuDetalle(this.idEliminar, this.indexEliminar);
    } else if (this.tipoEliminar == "articulo") {
      this.EliminarMenuDetalleArticulo(this.idEliminar, this.indexEliminar);
    } else if (this.tipoEliminar == "topping") {
      this.EliminarTopping(this.idEliminar, this.indexEliminar);
    } else if (this.tipoEliminar == "item topping") {
      this.EliminarToppingArticulo(this.idEliminar, this.indexEliminar);
    }
  }

  async EliminarMenu(menuId, i) {
    this.menu = new ClsMenuModel();
    this.menu.MENU_ID = menuId;
    this.menu.MENU_ESTADO = "N";
    this.menu.MENU_USU_ID = this.usuId;
    this.menu.MENU_IP_EQUIPO = this.ip;

    let result: any = await this.menuService.GuardarMenuApp(this.menu);
    
    this.mensajeRespuesta = result.Message;
    window.$('#myModal').modal('show');
    if (result.IsSuccess) {
      this.listamenu.splice(i,1);
      this.menuSeleccionado = 0;
      this.menuDatalleSeleccionado = 0;
      this.menuToppingSeleccionado = 0;
    }
  }

  async EliminarMenuDetalle(detId, i) {
    this.menuDetalle.DET_ID = detId;    
    this.menuDetalle.DET_USU_ID = this.usuId;
    this.menuDetalle.DET_IP_EQUIPO = this.ip;
    this.menuDetalle.DET_ESTADO = 'N';
   
    let result: any = await this.menuService.GuardarMenuDetalleApp(this.menuDetalle);
    
    if (result.IsSuccess) {
      this.menuDatalleSeleccionado = 0;
      this.menuToppingSeleccionado = 0;
      this.listaDetalle.splice(i,1);
    }
    this.mensajeRespuesta = result.Message;
    window.$('#myModal').modal('show');
  }

  async EliminarMenuDetalleArticulo(refId, i) {
    this.menuDetalleArticulo.DA_ID = refId;
    this.menuDetalleArticulo.DA_ESTADO = "N";
    this.menuDetalleArticulo.DA_IP_EQUIPO = this.ip;    
    this.menuDetalleArticulo.DA_USU_ID = this.usuId;
    let result: any = await this.menuService.GuardarMenuDetalleArticulo(this.menuDetalleArticulo);

    if (result.IsSuccess) {     
      this.listaReferencias.splice(i, 1);
    }
    this.mensajeRespuesta = result.Message;
    window.$('#myModal').modal('show');
  }
  
  async EliminarTopping(TopId, i) {
    this.menuTopping.TOP_ID = TopId;  
    this.menuTopping.TOP_USU_ID = this.usuId;
    this.menuTopping.TOP_IP_EQUIPO = this.ip;
    this.menuTopping.TOP_ESTADO = 'N';
   
    let result: any = await this.menuService.GuardarMenuDetalleTopping(this.menuTopping);
    
    if (result.IsSuccess) {
      this.listaToppings.splice(i, 1);
    }
    this.mensajeRespuesta = result.Message;
    window.$('#myModal').modal('show');
  }
  
  async EliminarToppingArticulo(TaId,i) {
    this.menuToppingArticulo.TA_ID = TaId;    
    this.menuToppingArticulo.TA_ESTADO = 'N';
    this.menuToppingArticulo.TA_IP_EQUIPO = this.ip;
    this.menuToppingArticulo.TA_USU_ID = this.usuId;
    
    let result: any = await this.menuService.GuardarMenuDetalleToppingArticulo(this.menuToppingArticulo);
    if (result.IsSuccess) {
      this.listaTopRef.splice(i, 1);
    }
    this.mensajeRespuesta = result.Message;
    window.$('#myModal').modal('show');
  }

  async escucharReferencia(dataRef: any) {    
    console.log("llego Referencia", dataRef);
    this.formaMenuDetalleArticulo.controls["DA_ARTICULO_ID"].setValue(dataRef.REF_ID);    
    this.formaMenuDetalleArticulo.controls["DA_NOMBRE"].setValue(this.titleCaseWord(dataRef.REF_DESCRIPCION));
    
    
  }

  async escucharReferenciaTopping(dataRef: any) {
    console.log("llego Referencia", dataRef);
    this.formaToppingArticulo.controls["TA_ARTICULO_ID"].setValue(dataRef.REF_ID);
    this.formaToppingArticulo.controls["TA_NOMBRE"].setValue(this.titleCaseWord(dataRef.REF_DESCRIPCION));  
    
  }

  titleCaseWord(Word: string):string {
    if (!Word) return Word;
    return Word[0].toUpperCase() + Word.substr(1).toLowerCase();
  }

  validarAplicaDia() {
    if (this.formaMenu.controls['MENU_APLICA_DIA'].value == "S") {
      this.aplicadia = true;
    } else {
      this.aplicadia = false;
    }
  }
}
