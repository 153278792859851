import { Component, OnInit, ViewChild, EventEmitter, Output, Input, Renderer2, Inject, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ClsNegocioModel } from '../../models/Aplicacion/ClsNegocio.model';
import { GeneralesService } from '../../services/_Generales.service';
import { ClsMenuVentaModel } from '../../models/Aplicacion/ClsMenuVenta.model';
import { ClsMenuToppingModel } from '../../models/Aplicacion/ClsMenuTopping.model';
import { ClsMenuDetalleModel } from '../../models/Aplicacion/ClsMenuDetalle.model';
import { ClsMenuDetalleArticuloModel } from '../../models/Aplicacion/ClsMenuDetalleArticulo.model';
import { PedidoCallCenterService } from '../../services/PedidoCallCenter.service';
import { ClsReferenciaToppingValorModel } from '../../models/Aplicacion/ClsReferenciaToppingValor.model';
import { ClsMenuToppingArticuloModel } from '../../models/Aplicacion/ClsMenuToppingArticulo.model';
import { ClsReferenciaModel } from '../../models/Aplicacion/ClsReferencia.model';



declare var window: any;

@Component({
    selector: 'app-infoMenuDetallePersonalizarTopping',
    templateUrl: './infoMenuDetallePersonalizarTopping.component.html',
    styleUrls: ['./infoMenuDetallePersonalizarTopping.component.css']
})
export class InfoMenuDetallePersonalizarToppingComponent implements OnInit {
    @Input() Negocio: ClsNegocioModel;
    @Input() MenuVenta: ClsMenuVentaModel;
    //@Input() MenuDetalle: ClsMenuDetalleModel;
    //@Input() MenuDetalleArticulo: any;
    @Input() Topping: ClsMenuToppingArticuloModel;
    @Output() eventoPersonalizarTopping: any = new EventEmitter();


    Referencia: ClsReferenciaModel;
    Referencia_SubCombo: ClsReferenciaModel;

    //Toppings: ClsMenuToppingModel[] = [];

    //TieneToppings: boolean = true;
    //ToppingsValidos: boolean = true;
    PersonalizarValido: boolean = true;

    //CargandoDatos: boolean = true;
    //UltimoID: number = 0;
    //TopConsulta: number = 50;
    //TieneDatos: boolean = true;

    constructor(
        private renderer2: Renderer2,
        @Inject(DOCUMENT) private document: Document,
        private pedidoCallCenterService: PedidoCallCenterService,
        private generales: GeneralesService
    ) {

    }

    async  ngOnInit() {
        //console.log("ngOnInit Negocio", this.Negocio);
        this.Referencia = this.Topping.Referencia;

        //if (this.MenuDetalle.DETALLE_TOPPINGS) {
        //    await this.CargarToppings();
        //} else {
        //    this.CargandoDatos = false;
        //}

        await this.VerificaPersonalizarTODO();
    }

    async AceptaPersonalizar(data: any) {
        console.log(data);

        await this.eventoPersonalizarTopping.emit(data);
    }

    async VerificaPersonalizarToggle(Control: any, Combo: any, Detalle: any) {
        for (var i = 0; i < Combo.Combo_Detalle.length; i++) {
            if (Combo.Combo_Detalle[i].COMBO_ID == Detalle.COMBO_ID) {
                Combo.Combo_Detalle[i].COMBO_SELECCIONADO = true;
            } else {
                Combo.Combo_Detalle[i].COMBO_SELECCIONADO = false;
            }
        }

        console.log("Control", Control);
        console.log("Combo", Combo);
        console.log("Detalle", Detalle);

        console.log("Detalle.COMBO_SELECCIONADO", Detalle.COMBO_SELECCIONADO);

        this.VerificarMenuValidoToggle(Combo, Detalle);
    }

    async VerificarMenuValidoChecked(Combo: any) {
        Combo.COMBO_MENU_VALIDO = true;

        let CtaSeleccionados = 0;
        Combo.Combo_Detalle.forEach((detalle => {
            if (detalle.COMBO_SELECCIONADO == true && detalle.COMBO_MODIFICABLE == true) {
                CtaSeleccionados++;
            }
        }));

        if (CtaSeleccionados < Combo.COMBO_CANTIDAD_MINIMA) {
            Combo.COMBO_MENU_VALIDO = false;
            this.PersonalizarValido = false;
        }

        if (CtaSeleccionados > Combo.COMBO_CANTIDAD_MAXIMA) {
            Combo.COMBO_MENU_VALIDO = false;
            this.PersonalizarValido = false;
        }

        await this.TotalizarValorSubCOMBO();
        await this.TotalizarValorCOMBO();
        await this.VerificarTodoPersonalizarValido();
        //await this.ValidarToppings();
    }

    async VerificarMenuValidoToggle(Combo: any, Detalle: any) {
        Combo.COMBO_MENU_VALIDO = true;

        let CtaSeleccionados = 0;

        if (Detalle.COMBO_SELECCIONADO == true) {
            Combo.Combo_Detalle.forEach((detalle => {
                if (detalle.COMBO_ID != Detalle.COMBO_ID && detalle.COMBO_MODIFICABLE == true) {
                    detalle.COMBO_SELECCIONADO = false;
                }
            }));
        }

        Combo.Combo_Detalle.forEach((detalle => {
            if (detalle.COMBO_SELECCIONADO == true && detalle.COMBO_MODIFICABLE == true) {
                CtaSeleccionados++;
            }
        }));

        if (CtaSeleccionados < Combo.COMBO_CANTIDAD_MINIMA) {
            Combo.COMBO_MENU_VALIDO = false;
            this.PersonalizarValido = false;
        }

        if (CtaSeleccionados > Combo.COMBO_CANTIDAD_MAXIMA) {
            Combo.COMBO_MENU_VALIDO = false;
            this.PersonalizarValido = false;
        }

        console.log("Detalle", Detalle);

        await this.TotalizarValorSubCOMBO();
        await this.TotalizarValorCOMBO();
        await this.VerificarTodoPersonalizarValido();
        //await this.ValidarToppings();
    }

    async PresionaComentario() {
        //console.log("datosREFERENCIA", this.datosREFERENCIA);
    }

    //async CargarToppings() {
    //    try {
    //        this.CargandoDatos = true;

    //        const infoTopping: any = await this.pedidoCallCenterService.CallCenterToppingsListar(this.Negocio.NEGO_EMPRESA_ID, this.Negocio.NEGO_ID, this.MenuDetalle.DETALLE_ID, this.UltimoID, this.TopConsulta);
    //        console.log("Toppings", infoTopping);
    //        if (infoTopping != null) {
    //            if (infoTopping.IsSuccess) {
    //                if (infoTopping.Data.length > 0) {
    //                    this.TieneDatos = true;
    //                } else {
    //                    this.TieneDatos = false;
    //                }

    //                infoTopping.Data.forEach((topping => {
    //                    this.Toppings.push(topping);
    //                    this.UltimoID = topping.TOP_ID;

    //                    console.log("infoTopping", topping);
    //                    console.log("UltimoID", this.UltimoID);
    //                }));
    //            }

    //            //    //await this.oGeneral.GuardarStorage("menuDetalle_" + "NegocioID_" + this.infoNEGOCIO.NEGO_ID + "_" + environment.API_KEY, this.Toppings);
    //            await this.ValidarToppings();
    //        } else {
    //            this.generales.MostrarMensajeERROR("No es posible listar la información de toppings en este momento.");
    //        }

    //        this.CargandoDatos = false;
    //    } catch (e) {
    //        this.CargandoDatos = false;
    //        //this.uiService.ShowAlert(e.message, environment.tituloAPP);
    //    }
    //    finally {

    //    }
    //}

    //async ValidarToppings() {
    //    this.ToppingsValidos = true;
    //    let CantDetalles = 0;
    //    if (this.Toppings.length > 0) {
    //        this.Toppings.forEach((topping => {
    //            let RefValida: any = true;
    //            topping.Topping_Articulos.forEach((async articulo => {
    //                CantDetalles = CantDetalles + articulo.TOPART_CANTIDAD_TOTAL;

    //                RefValida = await this.pedidoCallCenterService.ValidarCombo(articulo.Referencia);
    //                if (RefValida == false) {
    //                    topping.TOP_VALIDO = false;
    //                    this.ToppingsValidos = false;
    //                    return;
    //                }
    //            }));

    //            if (topping.TOP_OBLIGATORIO == true && CantDetalles >= topping.TOP_CANT_MINIMA && CantDetalles <= topping.TOP_CANT_MAXIMA) {
    //                topping.TOP_VALIDO = true;
    //            } else {
    //                if (topping.TOP_OBLIGATORIO == false && CantDetalles >= topping.TOP_CANT_MINIMA && CantDetalles <= topping.TOP_CANT_MAXIMA) {
    //                    topping.TOP_VALIDO = true;
    //                } else {
    //                    topping.TOP_VALIDO = false;
    //                    this.ToppingsValidos = false;
    //                }
    //            }
    //        }));
    //    }

    //    await this.TotalizarToppings();
    //}

    //async TotalizarToppings() {
    //    let tmpTopping: ClsReferenciaToppingValorModel = new ClsReferenciaToppingValorModel();
    //    tmpTopping.REFTOP_VALOR_TOTAL = 0;
    //    tmpTopping.REFTOP_VALOR_SUBTOTAL = 0;
    //    tmpTopping.REFTOP_VALOR_IMPUESTO = 0;
    //    tmpTopping.Menu_Toppings = [];

    //    this.Toppings.forEach((topping => {
    //        if (topping.TOP_VALIDO == true) {
    //            let tmpMenuTopping: ClsMenuToppingModel = JSON.parse(JSON.stringify(topping));
    //            tmpMenuTopping.Topping_Articulos = [];

    //            topping.Topping_Articulos.forEach((articulo => {
    //                if (articulo.TOPART_SELECCIONADO == true) {
    //                    console.log("REF_UNIDAD_VENTA_TOTAL", articulo.Referencia.Referencia_Valor.REF_UNIDAD_VENTA_TOTAL);
    //                    console.log("REF_UNIDAD_VENTA_SUBTOTAL", articulo.Referencia.Referencia_Valor.REF_UNIDAD_VENTA_SUBTOTAL);
    //                    console.log("REF_UNIDAD_VENTA_IMPUESTO", articulo.Referencia.Referencia_Valor.REF_UNIDAD_VENTA_IMPUESTO);

    //                    tmpTopping.REFTOP_VALOR_TOTAL = tmpTopping.REFTOP_VALOR_TOTAL + (articulo.Referencia.Referencia_Valor.REF_UNIDAD_VENTA_TOTAL * articulo.Referencia.Referencia_Valor.REF_CANTIDAD);
    //                    tmpTopping.REFTOP_VALOR_SUBTOTAL = tmpTopping.REFTOP_VALOR_SUBTOTAL + (articulo.Referencia.Referencia_Valor.REF_UNIDAD_VENTA_SUBTOTAL * articulo.Referencia.Referencia_Valor.REF_CANTIDAD);
    //                    tmpTopping.REFTOP_VALOR_IMPUESTO = tmpTopping.REFTOP_VALOR_IMPUESTO + (articulo.Referencia.Referencia_Valor.REF_UNIDAD_VENTA_IMPUESTO * articulo.Referencia.Referencia_Valor.REF_CANTIDAD);

    //                    console.log("tmpTopping REF_UNIDAD_VENTA_TOTAL", tmpTopping.REFTOP_VALOR_TOTAL);
    //                    console.log("tmpTopping REF_UNIDAD_VENTA_SUBTOTAL", tmpTopping.REFTOP_VALOR_SUBTOTAL);
    //                    console.log("tmpTopping REF_UNIDAD_VENTA_IMPUESTO", tmpTopping.REFTOP_VALOR_IMPUESTO);

    //                    let tmpReferenciaTopping: ClsMenuToppingArticuloModel = articulo;
    //                    tmpMenuTopping.Topping_Articulos.push(tmpReferenciaTopping);
    //                }
    //            }));

    //            if (tmpMenuTopping.Topping_Articulos.length > 0) {
    //                tmpTopping.Menu_Toppings.push(tmpMenuTopping);
    //            }
    //        }
    //    }));

    //    this.Referencia.Referencia_Topping_Valor = tmpTopping;
    //    console.log("Referencia Total Toppings", this.Referencia);
    //}

    async VerificaPersonalizarTODO() {
        this.Referencia.Referencia_Combo_Valor.Referencia_Combo.forEach((combo => {
            if (combo.COMBO_CANTIDAD_MAXIMA > 1 && combo.COMBO_MUESTRA_MENU == true) {
                this.VerificarMenuValidoChecked(combo);
            }

            if (combo.COMBO_CANTIDAD_MAXIMA <= 1 && combo.COMBO_MUESTRA_MENU == true) {
                combo.Combo_Detalle.forEach((detalle => {
                    this.VerificarMenuValidoToggle(combo, detalle);
                }));
            }
        }));
    }

    async VerificarTodoPersonalizarValido() {
        this.PersonalizarValido = true;

        this.Referencia.Referencia_Combo_Valor.Referencia_Combo.forEach((combo => {
            if (combo.COMBO_MENU_VALIDO == false) {
                this.PersonalizarValido = false;
            }
        }));
    }

    async ValidarRadio(data: any, dataTopping: any, dataDetalle: any) {
        console.log("Change Radio", data);
        dataTopping.Topping_Articulos.forEach((topping => {
            if (topping.TOPART_ID == dataDetalle.TOPART_ID) {
                topping.TOPART_SELECCIONADO = true;
                topping.TOPART_CANTIDAD_TOTAL = 1;
                dataTopping.TOP_VALIDO = true;
            } else {
                topping.TOPART_SELECCIONADO = false;
                topping.TOPART_CANTIDAD_TOTAL = 0;
            }
        }));

        //await this.ValidarToppings();
    }

    async ValidarRestar(data: any, dataTopping: any, dataDetalle: any) {
        console.log("dataDetalle Restar", dataDetalle);

        dataTopping.Topping_Articulos.forEach((topping => {
            if (topping.TOPART_ID == dataDetalle.TOPART_ID) {
                if (topping.TOPART_CANTIDAD_TOTAL > 0) {
                    topping.TOPART_CANTIDAD_TOTAL = topping.TOPART_CANTIDAD_TOTAL - 1;
                    topping.Referencia.Referencia_Valor.REF_CANTIDAD = topping.TOPART_CANTIDAD_TOTAL;
                }
            }

            if (topping.TOPART_CANTIDAD_TOTAL > 0) {
                topping.TOPART_SELECCIONADO = true;
            } else {
                topping.TOPART_SELECCIONADO = false;
            }
        }));

        //await this.ValidarToppings();
    }

    async ValidarSumar(data: any, dataTopping: any, dataDetalle: any) {
        console.log("dataDetalle Sumar", dataDetalle);
        let CantDetalles = 0;

        //CONTAR CANTIDAD DE DETALLE
        dataTopping.Topping_Articulos.forEach((topping => {
            CantDetalles = CantDetalles + topping.TOPART_CANTIDAD_TOTAL;
        }));

        dataTopping.Topping_Articulos.forEach((topping => {
            if (CantDetalles < dataTopping.TOP_CANT_MAXIMA) {
                if (topping.TOPART_ID == dataDetalle.TOPART_ID) {
                    if (topping.TOPART_CANTIDAD_TOTAL < topping.TOPART_LIMITE_MAXIMO) {
                        topping.TOPART_CANTIDAD_TOTAL = topping.TOPART_CANTIDAD_TOTAL + 1;
                        topping.Referencia.Referencia_Valor.REF_CANTIDAD = topping.TOPART_CANTIDAD_TOTAL;
                    }
                }
            }

            if (topping.TOPART_CANTIDAD_TOTAL > 0) {
                topping.TOPART_SELECCIONADO = true;
            } else {
                topping.TOPART_SELECCIONADO = false;
            }
        }));

        //await this.ValidarToppings();
    }

    async TotalizarValorSubCOMBO() {
        this.Referencia.Referencia_Combo_Valor.Referencia_Combo.forEach((combo => {
            combo.Combo_Detalle.forEach((detalle => {
                if (detalle.COMBO_TIENE_SUB_COMBO == true && detalle.COMBO_SELECCIONADO == true) {
                    let Combo_Valor_Total = 0;
                    let Combo_Valor_SubTotal = 0;
                    let Combo_Valor_Impuesto = 0;

                    detalle.Info_SubCombo_Valor.Info_SubCombo.forEach((combo => {
                        combo.Combo_Detalle.forEach((detalle => {
                            if (detalle.COMBO_SELECCIONADO == true) {
                                Combo_Valor_Total = Combo_Valor_Total + detalle.COMBO_VALOR_TOTAL;
                                Combo_Valor_SubTotal = Combo_Valor_SubTotal + detalle.COMBO_VALOR_SUBTOTAL;
                                Combo_Valor_Impuesto = Combo_Valor_Impuesto + detalle.COMBO_VALOR_IMPUESTO;
                            }
                        }));
                    }));

                    detalle.Info_SubCombo_Valor.SUBCOMBO_VALOR_TOTAL = Combo_Valor_Total;
                    detalle.Info_SubCombo_Valor.SUBCOMBO_VALOR_SUBTOTAL = Combo_Valor_SubTotal;
                    detalle.Info_SubCombo_Valor.SUBCOMBO_VALOR_IMPUESTO = Combo_Valor_Impuesto;
                }
            }));
        }));
    }

    async TotalizarValorCOMBO() {
        let Combo_Valor_Total = 0;
        let Combo_Valor_SubTotal = 0;
        let Combo_Valor_Impuesto = 0;

        this.Referencia.Referencia_Combo_Valor.Referencia_Combo.forEach((combo => {
            combo.Combo_Detalle.forEach((detalle => {
                if (detalle.COMBO_SELECCIONADO == true) {
                    Combo_Valor_Total = Combo_Valor_Total + detalle.COMBO_VALOR_TOTAL + detalle.Info_SubCombo_Valor.SUBCOMBO_VALOR_TOTAL;
                    Combo_Valor_SubTotal = Combo_Valor_SubTotal + detalle.COMBO_VALOR_SUBTOTAL + detalle.Info_SubCombo_Valor.SUBCOMBO_VALOR_SUBTOTAL;
                    Combo_Valor_Impuesto = Combo_Valor_Impuesto + detalle.COMBO_VALOR_IMPUESTO + detalle.Info_SubCombo_Valor.SUBCOMBO_VALOR_IMPUESTO;
                }
            }));
        }));

        this.Referencia.Referencia_Combo_Valor.REFCOMBO_VALOR_TOTAL = Combo_Valor_Total;
        this.Referencia.Referencia_Combo_Valor.REFCOMBO_VALOR_SUBTOTAL = Combo_Valor_SubTotal;
        this.Referencia.Referencia_Combo_Valor.REFCOMBO_VALOR_IMPUESTO = Combo_Valor_Impuesto;

        this.Referencia.Referencia_Valor.REF_UNIDAD_VENTA_TOTAL = this.Referencia.Referencia_Valor.REF_UNIDAD_ORIGINAL_TOTAL + this.Referencia.Referencia_Combo_Valor.REFCOMBO_VALOR_TOTAL + this.Referencia.Referencia_Topping_Valor.REFTOP_VALOR_TOTAL;
        this.Referencia.Referencia_Valor.REF_UNIDAD_VENTA_SUBTOTAL = this.Referencia.Referencia_Valor.REF_UNIDAD_ORIGINAL_SUBTOTAL + this.Referencia.Referencia_Combo_Valor.REFCOMBO_VALOR_SUBTOTAL + this.Referencia.Referencia_Topping_Valor.REFTOP_VALOR_SUBTOTAL;
        this.Referencia.Referencia_Valor.REF_UNIDAD_VENTA_IMPUESTO = this.Referencia.Referencia_Valor.REF_UNIDAD_ORIGINAL_IMPUESTO + this.Referencia.Referencia_Combo_Valor.REFCOMBO_VALOR_IMPUESTO + this.Referencia.Referencia_Topping_Valor.REFTOP_VALOR_IMPUESTO;

        this.Referencia.Referencia_Valor.REF_VALOR_TOTAL = this.Referencia.Referencia_Valor.REF_UNIDAD_VENTA_TOTAL * this.Referencia.REF_CANTIDAD_TOTAL;
        this.Referencia.Referencia_Valor.REF_VALOR_SUBTOTAL = this.Referencia.Referencia_Valor.REF_UNIDAD_VENTA_SUBTOTAL * this.Referencia.REF_CANTIDAD_TOTAL;
        this.Referencia.Referencia_Valor.REF_VALOR_IMPUESTO = this.Referencia.Referencia_Valor.REF_UNIDAD_VENTA_IMPUESTO * this.Referencia.REF_CANTIDAD_TOTAL;
    }

    async SeleccionaPersonalizarSubCombo(Detalle: any) {
        //let Cargar = await this.uiService.iniciarLoadingNEW('');
        try {
            console.log("SeleccionaPersonalizarSubCombo", Detalle);
            this.Referencia_SubCombo = null;

            setTimeout(async () => {
                this.Referencia_SubCombo = Detalle;

                await this.AbrirModal_PersonalizarSubCombo(Detalle);
            }, 100);

            //let modal: any = await this.IniciaModal_PersonalizarSubCombo(Detalle);

            //console.log("SeleccionaPersonalizarSubCombo", Detalle);

            //await this.uiService.cerrarLoadingNEW(Cargar);

            //const { data } = await modal.onDidDismiss();
            //if (data["ModalProcess"]) {
            //    if (data["model"] != null) {
            //        Detalle = data["model"];

            //        await this.TotalizarValorSubCOMBO();
            //        await this.TotalizarValorCOMBO();
            //        await this.VerificarTodoPersonalizarValido();
            //        await this.ValidarToppings();
            //    }
            //}
        } catch (e) {
            //await this.uiService.cerrarLoadingNEW(Cargar);
        }
    }

    async AbrirModal_PersonalizarSubCombo(data: any) {
        window.$('#modalMenuDetallePersonalizarToppingSUB').modal('show');
    }

    async CerrarModal_PersonalizarSubCombo(data: any) {
        window.$('#modalMenuDetallePersonalizarToppingSUB').modal('hide');
    }


    async SeleccionaPersonalizarTopping(Topping: any) {
        //let Cargar = await this.uiService.iniciarLoadingNEW('');
        try {
            ///*************PENDIENTE PERSONALIZAR TOPPINGS Y SUB DEL MISMO

            //let modal: any = await this.IniciaModal_PersonalizarTopping(Topping);

            //console.log("SeleccionaPersonalizarTopping", Topping);

            //await this.uiService.cerrarLoadingNEW(Cargar);

            //const { data } = await modal.onDidDismiss();
            //if (data["ModalProcess"]) {
            //    if (data["model"] != null) {
            //        Topping = data["model"];

            //        await this.TotalizarValorSubCOMBO();
            //        await this.TotalizarValorCOMBO();
            //        await this.VerificarTodoPersonalizarValido();
            //        await this.ValidarToppings();
            //    }
            //}
        } catch (e) {
            //await this.uiService.cerrarLoadingNEW(Cargar);
        }
    }

    async AceptaPersonalizarTopping(data: any) {
        await this.generales.OpenDialogAjax();

        await this.eventoPersonalizarTopping.emit(data);

        await window.$('#modalMenuDetallePersonalizarTopping').modal('hide');

        await this.generales.CancelDialogAjax();
    }

    async AceptaPersonalizarToppingSubCombo(data: any) {
        //await this.generales.OpenDialogAjax();

        //await this.eventoPersonalizarToppingSUB.emit(data);

        //await window.$('#modalMenuDetallePersonalizarToppingSUB').modal('hide');

        //await this.generales.CancelDialogAjax();
    }
}
