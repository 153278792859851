import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ParametrosService } from '../../services/Parametros.service';
import { ClsParametrosModel } from '../../models/Web/ClsParametros.model';
import { ClsConfigNegocioModel } from '../../models/Web/ClsConfigNegocio.model';
import { ConfigNegocioService } from '../../services/ConfigNegocio.service';
import { ClsHorarioModel } from '../../models/Web/ClsHorario.model';
import { ClsAccesoNegocioModel } from '../../models/Web/ClsAccesoNegocio.model';
import { TipoPedidoEmpresaService } from '../../services/TipoPedidoEmpresa.service';
import { ClsDistanciaNegocioModel } from '../../models/Web/ClsDistanciaNegocio.model';
import { FileUpload2Service } from '../../services/FileUpload2.service';
import { ClsMedioPagoModel } from '../../models/Web/ClsMedioPago.model';
import { ClsTiempoEsperaEmpresaModel } from '../../models/Web/ClsTiempoEsperaEmpresa.model';
import { ClsTipoPedidoEmpresaConfigModel } from '../../models/Web/ClsTipoPedidoEmpresa.model';
import { ClsTiempoEsperaNegocioModel } from '../../models/Web/ClsTiempoEsperaNegocio.model';
import { ClsTiempoEntregaNegocioModel } from '../../models/Web/ClsTiempoEntregaNegocio.model';
import { ClsMedioPagoNegocioModel } from '../../models/Web/ClsMedioPagoNegocio.model';
import { ClsMedioPagoTipoPedidoModel } from '../../models/Web/ClsMedioPagoTipoPedido.model';
import { MatTableDataSource } from '@angular/material/table';
import { ClsTercerosDomicilioModel } from '../../models/Web/ClsTercerosDomicilio.model';
import { GeneralesService } from '../../services/_Generales.service';

declare var window: any;

@Component({
  selector: 'app-config-negocio',
  templateUrl: './config-negocio.component.html',
  styleUrls: ['./config-negocio.component.css']
})
export class ConfigNegocioComponent implements OnInit {

  mensajeRespuesta: string = "";
  //ciudades: ClsParametrosModel[];
  lat: number = 4.804771;
  lng: number = -75.7838021;
  mostrarMap: boolean = false;
  formaConfig: FormGroup;
  objConfigNegocio: ClsConfigNegocioModel;
  negoId: number;
  empresa: number;
  ip: string;
  listaDistanciaxNegocio: ClsDistanciaNegocioModel[] = [];
  loading: boolean;
  imagensrc: string;
  listaMediosPago: ClsMedioPagoModel[] = [];
  listaTiempoEspera: ClsTiempoEsperaEmpresaModel[] = [];
  listaTipoPedido: ClsTipoPedidoEmpresaConfigModel[] = [];
  KeyMap: string = "";
  Georeferencia: string = "";
  DirMap: string = "";
  displayedColumns: string[] = ['TER_IDENTIFICACION', 'TER_NOMBRE','ELIMINAR'];
  dataSource: MatTableDataSource<ClsTercerosDomicilioModel>;
  terDomicilios: ClsTercerosDomicilioModel[] = [];

 

  constructor(private _paramService: ParametrosService, private _ConfigNegocioService: ConfigNegocioService, private uploadService: FileUpload2Service, private tipoPedidoService: TipoPedidoEmpresaService, private generales: GeneralesService) {
    let datos: any = localStorage.getItem('negocio');
    if (datos != null && datos != '') {
      let objNego = new ClsAccesoNegocioModel();
      objNego = JSON.parse(datos);
      this.negoId = objNego[0].NEGO_ID;
      this.empresa = objNego[0].NEGO_EMPRESA;
    }
    this.ip = localStorage.getItem('ip');
    
    
  }



  async ngOnInit() {
    
    //this.comboCiudad();
    this.formaConfig = new FormGroup({
      //General
      'TxtValorMin': new FormControl('', [Validators.required, Validators.min(0)]),
      'TxtAbierto': new FormControl('S', Validators.required),
      'TxtLogo': new FormControl(''),
      'TxtDireccion': new FormControl(''),
      'TxtIndicaciones': new FormControl(''),
      //'TxtCiudad': new FormControl(''),
      'HdnLatitud': new FormControl(this.lat),
      'HdnLongitud': new FormControl(this.lng),
      'HdnGeoreferencia': new FormControl("0"),
      'HdnCNID': new FormControl('0'),

      //Entrega
      'TE_TIPO': new FormControl('M'),

      //'Mapa'
      'TxtKeyMapa': new FormControl(),

      //'Negocio Key'
      'TxtNegocioKey': new FormControl(),
      
      //Horario
      'TxtDiaNL': new FormControl('2'),
      'TxtDiaL': new FormControl('LUNES'),
      'TxtHoraIL': new FormControl('00:00'),
      'TxtHoraFL': new FormControl('00:00'),
      'TxtHoraI2L': new FormControl('00:00'),
      'TxtHoraF2L': new FormControl('00:00'),
      'TxtCerrarL': new FormControl('0'),

      'TxtDiaNM': new FormControl('3'),
      'TxtDiaM': new FormControl('MARTES'),
      'TxtHoraIM': new FormControl('00:00'),
      'TxtHoraFM': new FormControl('00:00'),
      'TxtHoraI2M': new FormControl('00:00'),
      'TxtHoraF2M': new FormControl('00:00'),
      'TxtCerrarM': new FormControl('0'),

      'TxtDiaNX': new FormControl('4'),
      'TxtDiaX': new FormControl('MIÉRCOLES'),
      'TxtHoraIX': new FormControl('00:00'),
      'TxtHoraFX': new FormControl('00:00'),
      'TxtHoraI2X': new FormControl('00:00'),
      'TxtHoraF2X': new FormControl('00:00'),
      'TxtCerrarX': new FormControl('0'),

      'TxtDiaNJ': new FormControl('5'),
      'TxtDiaJ': new FormControl('JUEVES'),
      'TxtHoraIJ': new FormControl('00:00'),
      'TxtHoraFJ': new FormControl('00:00'),
      'TxtHoraI2J': new FormControl('00:00'),
      'TxtHoraF2J': new FormControl('00:00'),
      'TxtCerrarJ': new FormControl('0'),

      'TxtDiaNV': new FormControl('6'),
      'TxtDiaV': new FormControl('VIERNES'),
      'TxtHoraIV': new FormControl('00:00'),
      'TxtHoraFV': new FormControl('00:00'),
      'TxtHoraI2V': new FormControl('00:00'),
      'TxtHoraF2V': new FormControl('00:00'),
      'TxtCerrarV': new FormControl('0'),

      'TxtDiaNS': new FormControl('7'),
      'TxtDiaS': new FormControl('SÁBADO'),
      'TxtHoraIS': new FormControl('00:00'),
      'TxtHoraFS': new FormControl('00:00'),
      'TxtHoraI2S': new FormControl('00:00'),
      'TxtHoraF2S': new FormControl('00:00'),
      'TxtCerrarS': new FormControl('0'),

      'TxtDiaND': new FormControl('1'),
      'TxtDiaD': new FormControl('DOMINGO'),
      'TxtHoraID': new FormControl('00:00'),
      'TxtHoraFD': new FormControl('00:00'),
      'TxtHoraI2D': new FormControl('00:00'),
      'TxtHoraF2D': new FormControl('00:00'),
      'TxtCerrarD': new FormControl('0'),
    });

    await this.ConsultarTipoPedido();    

    this.ListarAdminDistancias();

    this.ConsultarTiempoEsperaEmpresa();

    await this.ConsultarMedioPago();

    await this.ConsultarConfigNegocio();

    await this.ListarTerceroDomicilio();
    this.dataSource = new MatTableDataSource<ClsTercerosDomicilioModel>(this.terDomicilios);
  }

  validarMostrar() {
    if (!this.mostrarMap) { this.mostrarMap = true; }
  }

  //async comboCiudad() {
  //  let resp: any = await this._paramService.ListarParametros('');
  //  if (resp != null) {
  //    this.ciudades = resp;
  //    this.ConsultarConfigNegocio();
  //  } else {
  //    console.log(resp);
  //  }
  //}


  async ConsultarConfigNegocio() {
    let resp: any = await this._ConfigNegocioService.ConsultarConfigNegocio('');
    if (resp != null) {
      this.objConfigNegocio = resp;
      if (this.objConfigNegocio.CN_ID != 0) {
        if (this.objConfigNegocio.MK_KEY_MAPS != '' && this.objConfigNegocio.MK_KEY_MAPS != null) { 
          this.KeyMap = this.objConfigNegocio.MK_KEY_MAPS;
          this.formaConfig.controls['TxtKeyMapa'].setValue(this.objConfigNegocio.MK_KEY_MAPS);
        }

        if (this.objConfigNegocio.Negocio_KEY != null && this.objConfigNegocio.Negocio_KEY != "") {
          this.formaConfig.controls['TxtNegocioKey'].setValue(this.objConfigNegocio.Negocio_KEY);
        }

        this.formaConfig.controls['TxtValorMin'].setValue(this.objConfigNegocio.CN_VALOR_PEDIDO_MIN);
        this.formaConfig.controls['TxtAbierto'].setValue(this.objConfigNegocio.CN_ABIERTO);
        //this.formaConfig.controls['TxtLogo'].setValue(this.objConfigNegocio.CN_LOGO);
        this.imagensrc = this.objConfigNegocio.CN_LOGO;
        this.formaConfig.controls['TxtDireccion'].setValue(this.objConfigNegocio.CN_DIRECCION);
        this.formaConfig.controls['TxtIndicaciones'].setValue(this.objConfigNegocio.CN_INDICACIONES);
        //this.formaConfig.controls['TxtCiudad'].setValue(this.objConfigNegocio.CN_CIUDAD_ID);
        this.formaConfig.controls['HdnLatitud'].setValue(this.objConfigNegocio.CN_LATITUD);
        this.formaConfig.controls['HdnLongitud'].setValue(this.objConfigNegocio.CN_LONGITUD);
        this.formaConfig.controls['HdnGeoreferencia'].setValue(this.objConfigNegocio.CN_GEOREFERENCIA);
        this.Georeferencia = this.objConfigNegocio.CN_GEOREFERENCIA;
        this.formaConfig.controls['HdnCNID'].setValue(this.objConfigNegocio.CN_ID);
        if (this.objConfigNegocio.LISTADO_HORARIO != undefined) {
          for (var i = 0; i < this.objConfigNegocio.LISTADO_HORARIO.length; i++) {
            if (this.objConfigNegocio.LISTADO_HORARIO[i].NH_DIA_NUMERO == 2) {
              this.formaConfig.controls['TxtHoraIL'].setValue(this.objConfigNegocio.LISTADO_HORARIO[i].NH_HORA_INI);
              this.formaConfig.controls['TxtHoraFL'].setValue(this.objConfigNegocio.LISTADO_HORARIO[i].NH_HORA_FIN);
              this.formaConfig.controls['TxtHoraI2L'].setValue(this.objConfigNegocio.LISTADO_HORARIO[i].NH_HORA_INI_2);
              this.formaConfig.controls['TxtHoraF2L'].setValue(this.objConfigNegocio.LISTADO_HORARIO[i].NH_HORA_FIN_2);
              this.formaConfig.controls['TxtCerrarL'].setValue(this.objConfigNegocio.LISTADO_HORARIO[i].NH_TIEMPO_CERRAR);
            }
            if (this.objConfigNegocio.LISTADO_HORARIO[i].NH_DIA_NUMERO == 3) {
              this.formaConfig.controls['TxtHoraIM'].setValue(this.objConfigNegocio.LISTADO_HORARIO[i].NH_HORA_INI);
              this.formaConfig.controls['TxtHoraFM'].setValue(this.objConfigNegocio.LISTADO_HORARIO[i].NH_HORA_FIN);
              this.formaConfig.controls['TxtHoraI2M'].setValue(this.objConfigNegocio.LISTADO_HORARIO[i].NH_HORA_INI_2);
              this.formaConfig.controls['TxtHoraF2M'].setValue(this.objConfigNegocio.LISTADO_HORARIO[i].NH_HORA_FIN_2);
              this.formaConfig.controls['TxtCerrarM'].setValue(this.objConfigNegocio.LISTADO_HORARIO[i].NH_TIEMPO_CERRAR);
            }
            if (this.objConfigNegocio.LISTADO_HORARIO[i].NH_DIA_NUMERO == 4) {
              this.formaConfig.controls['TxtHoraIX'].setValue(this.objConfigNegocio.LISTADO_HORARIO[i].NH_HORA_INI);
              this.formaConfig.controls['TxtHoraFX'].setValue(this.objConfigNegocio.LISTADO_HORARIO[i].NH_HORA_FIN);
              this.formaConfig.controls['TxtHoraI2X'].setValue(this.objConfigNegocio.LISTADO_HORARIO[i].NH_HORA_INI_2);
              this.formaConfig.controls['TxtHoraF2X'].setValue(this.objConfigNegocio.LISTADO_HORARIO[i].NH_HORA_FIN_2);
              this.formaConfig.controls['TxtCerrarX'].setValue(this.objConfigNegocio.LISTADO_HORARIO[i].NH_TIEMPO_CERRAR);
            }
            if (this.objConfigNegocio.LISTADO_HORARIO[i].NH_DIA_NUMERO == 5) {
              this.formaConfig.controls['TxtHoraIJ'].setValue(this.objConfigNegocio.LISTADO_HORARIO[i].NH_HORA_INI);
              this.formaConfig.controls['TxtHoraFJ'].setValue(this.objConfigNegocio.LISTADO_HORARIO[i].NH_HORA_FIN);
              this.formaConfig.controls['TxtHoraI2J'].setValue(this.objConfigNegocio.LISTADO_HORARIO[i].NH_HORA_INI_2);
              this.formaConfig.controls['TxtHoraF2J'].setValue(this.objConfigNegocio.LISTADO_HORARIO[i].NH_HORA_FIN_2);
              this.formaConfig.controls['TxtCerrarJ'].setValue(this.objConfigNegocio.LISTADO_HORARIO[i].NH_TIEMPO_CERRAR);
            }
            if (this.objConfigNegocio.LISTADO_HORARIO[i].NH_DIA_NUMERO == 6) {
              this.formaConfig.controls['TxtHoraIV'].setValue(this.objConfigNegocio.LISTADO_HORARIO[i].NH_HORA_INI);
              this.formaConfig.controls['TxtHoraFV'].setValue(this.objConfigNegocio.LISTADO_HORARIO[i].NH_HORA_FIN);
              this.formaConfig.controls['TxtHoraI2V'].setValue(this.objConfigNegocio.LISTADO_HORARIO[i].NH_HORA_INI_2);
              this.formaConfig.controls['TxtHoraF2V'].setValue(this.objConfigNegocio.LISTADO_HORARIO[i].NH_HORA_FIN_2);
              this.formaConfig.controls['TxtCerrarV'].setValue(this.objConfigNegocio.LISTADO_HORARIO[i].NH_TIEMPO_CERRAR);
            }
            if (this.objConfigNegocio.LISTADO_HORARIO[i].NH_DIA_NUMERO == 7) {
              this.formaConfig.controls['TxtHoraIS'].setValue(this.objConfigNegocio.LISTADO_HORARIO[i].NH_HORA_INI);
              this.formaConfig.controls['TxtHoraFS'].setValue(this.objConfigNegocio.LISTADO_HORARIO[i].NH_HORA_FIN);
              this.formaConfig.controls['TxtHoraI2S'].setValue(this.objConfigNegocio.LISTADO_HORARIO[i].NH_HORA_INI_2);
              this.formaConfig.controls['TxtHoraF2S'].setValue(this.objConfigNegocio.LISTADO_HORARIO[i].NH_HORA_FIN_2);
              this.formaConfig.controls['TxtCerrarS'].setValue(this.objConfigNegocio.LISTADO_HORARIO[i].NH_TIEMPO_CERRAR);
            }
            if (this.objConfigNegocio.LISTADO_HORARIO[i].NH_DIA_NUMERO == 1) {
              this.formaConfig.controls['TxtHoraID'].setValue(this.objConfigNegocio.LISTADO_HORARIO[i].NH_HORA_INI);
              this.formaConfig.controls['TxtHoraFD'].setValue(this.objConfigNegocio.LISTADO_HORARIO[i].NH_HORA_FIN);
              this.formaConfig.controls['TxtHoraI2D'].setValue(this.objConfigNegocio.LISTADO_HORARIO[i].NH_HORA_INI_2);
              this.formaConfig.controls['TxtHoraF2D'].setValue(this.objConfigNegocio.LISTADO_HORARIO[i].NH_HORA_FIN_2);
              this.formaConfig.controls['TxtCerrarD'].setValue(this.objConfigNegocio.LISTADO_HORARIO[i].NH_TIEMPO_CERRAR);
            }
          }
        }
        if (this.objConfigNegocio.LISTADO_TIEMPO_ESPERA != undefined) {
          for (var i = 0; i < this.objConfigNegocio.LISTADO_TIEMPO_ESPERA.length; i++) {
            if (this.objConfigNegocio.LISTADO_TIEMPO_ESPERA[i].TE_ESTADO == 'A') {
              //console.log(this.objConfigNegocio.LISTADO_TIEMPO_ESPERA[i].TE_PARAM_ID);
              this.formaConfig.get("TE_" + this.objConfigNegocio.LISTADO_TIEMPO_ESPERA[i].TE_PARAM_ID).setValue(true);
            }
          }
        }
        if (this.objConfigNegocio.LISTADO_TIEMPO_ENTREGA != undefined) {
          for (var i = 0; i < this.objConfigNegocio.LISTADO_TIEMPO_ENTREGA.length; i++) {
            if (this.objConfigNegocio.LISTADO_TIEMPO_ENTREGA[i].TE_MINUTOS != null) {
            
              this.formaConfig.get("TE_MIN1_" + this.objConfigNegocio.LISTADO_TIEMPO_ENTREGA[i].TE_PARAM_ID).setValue(this.objConfigNegocio.LISTADO_TIEMPO_ENTREGA[i].TE_MINUTOS);
              this.formaConfig.get("TE_MIN2_" + this.objConfigNegocio.LISTADO_TIEMPO_ENTREGA[i].TE_PARAM_ID).setValue(this.objConfigNegocio.LISTADO_TIEMPO_ENTREGA[i].TE_MINUTOS2);
            }
            if (this.objConfigNegocio.LISTADO_TIEMPO_ENTREGA[i].VD_VALOR != null) {
              this.formaConfig.get("VD_" + this.objConfigNegocio.LISTADO_TIEMPO_ENTREGA[i].TE_PARAM_ID).setValue(this.objConfigNegocio.LISTADO_TIEMPO_ENTREGA[i].VD_VALOR);
            } 
            if (this.objConfigNegocio.LISTADO_TIEMPO_ENTREGA[i].TE_ESTADO != null) {
              if (this.objConfigNegocio.LISTADO_TIEMPO_ENTREGA[i].TE_ESTADO == 'A') {
                this.formaConfig.get("DN_" + this.objConfigNegocio.LISTADO_TIEMPO_ENTREGA[i].TE_PARAM_ID).setValue(true);
              }
            }
          }
        }
        if (this.objConfigNegocio.LISTADO_MEDIO_PAGO != undefined) {
          for (var i = 0; i < this.objConfigNegocio.LISTADO_MEDIO_PAGO.length; i++) {
            if (this.objConfigNegocio.LISTADO_MEDIO_PAGO[i].MPN_ESTADO == 'A') {
              this.formaConfig.get("MP_" + this.objConfigNegocio.LISTADO_MEDIO_PAGO[i].MPN_MEDIOPAGO_ID).setValue(true);
              this.formaConfig.get("KEY_" + this.objConfigNegocio.LISTADO_MEDIO_PAGO[i].MPN_MEDIOPAGO_ID).setValue(this.objConfigNegocio.LISTADO_MEDIO_PAGO[i].MPN_KEY);
              this.formaConfig.get("AMBIENTE_" + this.objConfigNegocio.LISTADO_MEDIO_PAGO[i].MPN_MEDIOPAGO_ID).setValue(this.objConfigNegocio.LISTADO_MEDIO_PAGO[i].MPN_AMBIENTE_PRUEBA);
              this.formaConfig.get("DESCRIP_" + this.objConfigNegocio.LISTADO_MEDIO_PAGO[i].MPN_MEDIOPAGO_ID).setValue(this.objConfigNegocio.LISTADO_MEDIO_PAGO[i].MPN_DESCRIPCION_FACTURA);

              if (this.objConfigNegocio.LISTADO_MEDIO_PAGO[i].LISTADO_TIPO_PEDIDO != undefined) {
                for (var j = 0; j < this.objConfigNegocio.LISTADO_MEDIO_PAGO[i].LISTADO_TIPO_PEDIDO.length; j++) {
                  if (this.objConfigNegocio.LISTADO_MEDIO_PAGO[i].LISTADO_TIPO_PEDIDO[j].MTP_ESTADO == 'A') {
                   
                    if (this.formaConfig.get("MP_TP_" + this.objConfigNegocio.LISTADO_MEDIO_PAGO[i].MPN_MEDIOPAGO_ID + "_" + this.objConfigNegocio.LISTADO_MEDIO_PAGO[i].LISTADO_TIPO_PEDIDO[j].MTP_TIPOPEDIDO_ID) != undefined) {
                      this.formaConfig.get("MP_TP_" + this.objConfigNegocio.LISTADO_MEDIO_PAGO[i].MPN_MEDIOPAGO_ID + "_" + this.objConfigNegocio.LISTADO_MEDIO_PAGO[i].LISTADO_TIPO_PEDIDO[j].MTP_TIPOPEDIDO_ID).setValue(true);
                    }
                    
                  }
                }
              }
            }
          }
        }
      }
    } else {
      console.log(resp);
    }
  }

  async GuardarConfigNegocio() {
    //this.loading = true;
      this.generales.OpenDialogAjax();

    this.objConfigNegocio.CN_VALOR_PEDIDO_MIN = this.formaConfig.controls['TxtValorMin'].value;
    this.objConfigNegocio.CN_ABIERTO = this.formaConfig.controls['TxtAbierto'].value;
    if (this.uploadService.urlfile == null) {
      this.objConfigNegocio.CN_LOGO = "";
    } else {
      this.objConfigNegocio.CN_LOGO = this.uploadService.urlfile;
    }
    //this.objConfigNegocio.CN_LOGO = this.formaConfig.controls['TxtLogo'].value;
    this.objConfigNegocio.CN_DIRECCION = this.formaConfig.controls['TxtDireccion'].value;
    this.objConfigNegocio.CN_INDICACIONES = this.formaConfig.controls['TxtIndicaciones'].value;
    //this.objConfigNegocio.CN_CIUDAD_ID = this.formaConfig.controls['TxtCiudad'].value;
    this.objConfigNegocio.CN_LATITUD = this.formaConfig.controls['HdnLatitud'].value;
    this.objConfigNegocio.CN_LONGITUD = this.formaConfig.controls['HdnLongitud'].value;
    this.objConfigNegocio.CN_GEOREFERENCIA = this.formaConfig.controls['HdnGeoreferencia'].value;
    this.objConfigNegocio.CN_ID = this.formaConfig.controls['HdnCNID'].value;
    this.objConfigNegocio.CN_IP_EQUIPO = this.ip;

    this.objConfigNegocio.MK_KEY_MAPS = this.formaConfig.controls['TxtKeyMapa'].value;
    this.objConfigNegocio.Negocio_KEY = this.formaConfig.controls['TxtNegocioKey'].value;

    this.formaConfig.controls["TxtLogo"].setValue("");
    this.uploadService.urlfile = "";
    this.uploadService.message = "";
    this.uploadService.progress = 0;

    let listahorario: ClsHorarioModel[] = [];
    let horarioL: ClsHorarioModel = new ClsHorarioModel;
    horarioL.NH_NEGOCIO_ID = this.negoId;
    horarioL.NH_DIA_NUMERO = this.formaConfig.controls['TxtDiaNL'].value;
    horarioL.NH_DIA_NOMBRE = this.formaConfig.controls['TxtDiaL'].value;
    horarioL.NH_HORA_INI = this.formaConfig.controls['TxtHoraIL'].value;
    horarioL.NH_HORA_FIN = this.formaConfig.controls['TxtHoraFL'].value;
    horarioL.NH_HORA_INI_2 = this.formaConfig.controls['TxtHoraI2L'].value;
    horarioL.NH_HORA_FIN_2 = this.formaConfig.controls['TxtHoraF2L'].value;
    horarioL.NH_TIEMPO_CERRAR = this.formaConfig.controls['TxtCerrarL'].value;
    horarioL.NH_IP_EQUIPO = this.ip;
    listahorario.push(horarioL);

    let horarioM: ClsHorarioModel = new ClsHorarioModel;
    horarioM.NH_NEGOCIO_ID = this.negoId;
    horarioM.NH_DIA_NUMERO = this.formaConfig.controls['TxtDiaNM'].value;
    horarioM.NH_DIA_NOMBRE = this.formaConfig.controls['TxtDiaM'].value;
    horarioM.NH_HORA_INI = this.formaConfig.controls['TxtHoraIM'].value;
    horarioM.NH_HORA_FIN = this.formaConfig.controls['TxtHoraFM'].value;
    horarioM.NH_HORA_INI_2 = this.formaConfig.controls['TxtHoraI2M'].value;
    horarioM.NH_HORA_FIN_2 = this.formaConfig.controls['TxtHoraF2M'].value;
    horarioM.NH_TIEMPO_CERRAR = this.formaConfig.controls['TxtCerrarM'].value;
    horarioM.NH_IP_EQUIPO = this.ip;
    listahorario.push(horarioM);

    let horarioX: ClsHorarioModel = new ClsHorarioModel;
    horarioX.NH_NEGOCIO_ID = this.negoId;
    horarioX.NH_DIA_NUMERO = this.formaConfig.controls['TxtDiaNX'].value;
    horarioX.NH_DIA_NOMBRE = this.formaConfig.controls['TxtDiaX'].value;
    horarioX.NH_HORA_INI = this.formaConfig.controls['TxtHoraIX'].value;
    horarioX.NH_HORA_FIN = this.formaConfig.controls['TxtHoraFX'].value;
    horarioX.NH_HORA_INI_2 = this.formaConfig.controls['TxtHoraI2X'].value;
    horarioX.NH_HORA_FIN_2 = this.formaConfig.controls['TxtHoraF2X'].value;
    horarioX.NH_TIEMPO_CERRAR = this.formaConfig.controls['TxtCerrarX'].value;
    horarioX.NH_IP_EQUIPO = this.ip;
    listahorario.push(horarioX);

    let horarioJ: ClsHorarioModel = new ClsHorarioModel;
    horarioJ.NH_NEGOCIO_ID = this.negoId;
    horarioJ.NH_DIA_NUMERO = this.formaConfig.controls['TxtDiaNJ'].value;
    horarioJ.NH_DIA_NOMBRE = this.formaConfig.controls['TxtDiaJ'].value;
    horarioJ.NH_HORA_INI = this.formaConfig.controls['TxtHoraIJ'].value;
    horarioJ.NH_HORA_FIN = this.formaConfig.controls['TxtHoraFJ'].value;
    horarioJ.NH_HORA_INI_2 = this.formaConfig.controls['TxtHoraI2J'].value;
    horarioJ.NH_HORA_FIN_2 = this.formaConfig.controls['TxtHoraF2J'].value;
    horarioJ.NH_TIEMPO_CERRAR = this.formaConfig.controls['TxtCerrarJ'].value;
    horarioJ.NH_IP_EQUIPO = this.ip;
    listahorario.push(horarioJ);

    let horarioV: ClsHorarioModel = new ClsHorarioModel;
    horarioV.NH_NEGOCIO_ID = this.negoId;
    horarioV.NH_DIA_NUMERO = this.formaConfig.controls['TxtDiaNV'].value;
    horarioV.NH_DIA_NOMBRE = this.formaConfig.controls['TxtDiaV'].value;
    horarioV.NH_HORA_INI = this.formaConfig.controls['TxtHoraIV'].value;
    horarioV.NH_HORA_FIN = this.formaConfig.controls['TxtHoraFV'].value;
    horarioV.NH_HORA_INI_2 = this.formaConfig.controls['TxtHoraI2V'].value;
    horarioV.NH_HORA_FIN_2 = this.formaConfig.controls['TxtHoraF2V'].value;
    horarioV.NH_TIEMPO_CERRAR = this.formaConfig.controls['TxtCerrarV'].value;
    horarioV.NH_IP_EQUIPO = this.ip;
    listahorario.push(horarioV);

    let horarioS: ClsHorarioModel = new ClsHorarioModel;
    horarioS.NH_NEGOCIO_ID = this.negoId;
    horarioS.NH_DIA_NUMERO = this.formaConfig.controls['TxtDiaNS'].value;
    horarioS.NH_DIA_NOMBRE = this.formaConfig.controls['TxtDiaS'].value;
    horarioS.NH_HORA_INI = this.formaConfig.controls['TxtHoraIS'].value;
    horarioS.NH_HORA_FIN = this.formaConfig.controls['TxtHoraFS'].value;
    horarioS.NH_HORA_INI_2 = this.formaConfig.controls['TxtHoraI2S'].value;
    horarioS.NH_HORA_FIN_2 = this.formaConfig.controls['TxtHoraF2S'].value;
    horarioS.NH_TIEMPO_CERRAR = this.formaConfig.controls['TxtCerrarS'].value;
    horarioS.NH_IP_EQUIPO = this.ip;
    listahorario.push(horarioS);

    let horarioD: ClsHorarioModel = new ClsHorarioModel;
    horarioD.NH_NEGOCIO_ID = this.negoId;
    horarioD.NH_DIA_NUMERO = this.formaConfig.controls['TxtDiaND'].value;
    horarioD.NH_DIA_NOMBRE = this.formaConfig.controls['TxtDiaD'].value;
    horarioD.NH_HORA_INI = this.formaConfig.controls['TxtHoraID'].value;
    horarioD.NH_HORA_FIN = this.formaConfig.controls['TxtHoraFD'].value;
    horarioD.NH_HORA_INI_2 = this.formaConfig.controls['TxtHoraI2D'].value;
    horarioD.NH_HORA_FIN_2 = this.formaConfig.controls['TxtHoraF2D'].value;
    horarioD.NH_TIEMPO_CERRAR = this.formaConfig.controls['TxtCerrarD'].value;
    horarioD.NH_IP_EQUIPO = this.ip;
    listahorario.push(horarioD);
    this.objConfigNegocio.LISTADO_HORARIO = listahorario;


    let lTiempoEspera: ClsTiempoEsperaNegocioModel[] = [];
    for (var i = 0; i < this.listaTiempoEspera.length; i++) {
      let objTiempoEspera: ClsTiempoEsperaNegocioModel = new ClsTiempoEsperaNegocioModel();
      objTiempoEspera.TE_PARAM_ID = this.listaTiempoEspera[i].TE_PARAM_ID;
      objTiempoEspera.TE_NEGO_ID = this.negoId;
      objTiempoEspera.TE_TIPO = this.formaConfig.get("TE_TIPO").value;      
      if (this.formaConfig.get("TE_" + this.listaTiempoEspera[i].TE_PARAM_ID).value == true) { 
        objTiempoEspera.TE_ESTADO = "A";
      } else {
        objTiempoEspera.TE_ESTADO  = "N";
      }
      lTiempoEspera.push(objTiempoEspera);
    }
    this.objConfigNegocio.LISTADO_TIEMPO_ESPERA = lTiempoEspera;
    
    let lTiempoEntrega: ClsTiempoEntregaNegocioModel[] = [];
    for (var i = 0; i < this.listaDistanciaxNegocio.length; i++) {
      let objTiempoEntrega: ClsTiempoEntregaNegocioModel = new ClsTiempoEntregaNegocioModel();
      objTiempoEntrega.TE_PARAM_ID = this.listaDistanciaxNegocio[i].ND_PARAM_ID;
      objTiempoEntrega.TE_NEGO_ID = this.negoId;
      let estado = "N";
      
      if (this.formaConfig.get("DN_" + this.listaDistanciaxNegocio[i].ND_PARAM_ID).value == true) {
        estado = "A"
      }
      let min1 = this.formaConfig.get("TE_MIN1_" + this.listaDistanciaxNegocio[i].ND_PARAM_ID).value;
      let min2 = this.formaConfig.get("TE_MIN2_" + this.listaDistanciaxNegocio[i].ND_PARAM_ID).value;
      let valor = this.formaConfig.get("VD_" + this.listaDistanciaxNegocio[i].ND_PARAM_ID).value;
      if (min1 == null) {
        min1 = 0;
      }
      if (min2 == null) {
        min2 = 0;
      }
      if (valor == null) {
        valor = 0;
      }
      objTiempoEntrega.TE_MINUTOS = min1;
      objTiempoEntrega.TE_MINUTOS2 = min2;
      objTiempoEntrega.VD_VALOR = valor;
      objTiempoEntrega.TE_ESTADO = estado;
      lTiempoEntrega.push(objTiempoEntrega);
    }
    this.objConfigNegocio.LISTADO_TIEMPO_ENTREGA = lTiempoEntrega;

    let lMedioPago: ClsMedioPagoNegocioModel[] = [];
    for (var i = 0; i < this.listaMediosPago.length; i++) {
      let objMedioPago: ClsMedioPagoNegocioModel = new ClsMedioPagoNegocioModel();
      objMedioPago.MPN_MEDIOPAGO_ID = this.listaMediosPago[i].MP_ID;
      objMedioPago.MPN_NEGOCIO_ID = this.negoId;
      let ambiente = this.formaConfig.get("AMBIENTE_" + this.listaMediosPago[i].MP_ID).value;
      let descrip = this.formaConfig.get("DESCRIP_" + this.listaMediosPago[i].MP_ID).value;
      let key = this.formaConfig.get("KEY_" + this.listaMediosPago[i].MP_ID).value;
      if (ambiente == null) {
        ambiente = "";
      }
      if (descrip == null) {
        descrip = "";
      }
      if (key == null) {
        key = "";
      }
      objMedioPago.MPN_AMBIENTE_PRUEBA = ambiente;
      objMedioPago.MPN_DESCRIPCION_FACTURA = descrip;      
      objMedioPago.MPN_KEY = key;
      //objMedioPago.MPN_ID = this.formaConfig.get("MPN_" + this.listaMediosPago[i].MP_ID).value;
      if (this.formaConfig.get("MP_" + this.listaMediosPago[i].MP_ID).value == true) {
        objMedioPago.MPN_ESTADO = "A";
      } else {
        objMedioPago.MPN_ESTADO = "N";
      }      

      let lMedioPagoTipoPedido: ClsMedioPagoTipoPedidoModel[] = [];
      for (var j = 0; j < this.listaTipoPedido.length; j++) {
        let objMedioPagoTipoPedido: ClsMedioPagoTipoPedidoModel = new ClsMedioPagoTipoPedidoModel();
        objMedioPagoTipoPedido.MTP_NEGOCIO_ID = this.negoId;
        objMedioPagoTipoPedido.MTP_TIPOPEDIDO_ID = parseInt(this.listaTipoPedido[j].TP_ID+"");
        objMedioPagoTipoPedido.MTP_ID = this.listaMediosPago[i].MP_ID;
        if (this.formaConfig.get("MP_TP_" + this.listaMediosPago[i].MP_ID + "_" + this.listaTipoPedido[j].TP_ID).value == true) {
          objMedioPagoTipoPedido.MTP_ESTADO = "A";
        } else {
          objMedioPagoTipoPedido.MTP_ESTADO = "N";
        }
        lMedioPagoTipoPedido.push(objMedioPagoTipoPedido);
      }
      objMedioPago.LISTADO_TIPO_PEDIDO = lMedioPagoTipoPedido;

      lMedioPago.push(objMedioPago);
    }
    this.objConfigNegocio.LISTADO_MEDIO_PAGO = lMedioPago;

    
    //console.log("guardar",this.objConfigNegocio);
    let resp: any = await this._ConfigNegocioService.GuardarConfigNegocio(this.objConfigNegocio);
    if (resp != null) {
      if (resp.IsSuccess) {
        this.mensajeRespuesta = resp.Message;
        if (resp.Data != null) {
          this.imagensrc = resp.Data;
        }
        window.$('#myModal').modal('show');
      }
      else {
        console.log(resp.Message);
      }
    }

      this.generales.CancelDialogAjax();
  }

  async ListarAdminDistancias() {
    let resp: any = await this.tipoPedidoService.ConsultarDistanciasNegocio('', this.negoId);
   
    for (var i = 0; i < resp.length; i++) {
      if (resp[i].ND_ESTADO == 'A') {
       
        let objDNego: ClsDistanciaNegocioModel = new ClsDistanciaNegocioModel();
        objDNego = resp[i];
        this.formaConfig.addControl("DN_" + resp[i].ND_PARAM_ID, new FormControl());
        this.formaConfig.addControl("TE_MIN1_" + resp[i].ND_PARAM_ID, new FormControl());
        this.formaConfig.addControl("TE_MIN2_" + resp[i].ND_PARAM_ID, new FormControl());
        this.formaConfig.addControl("VD_" + resp[i].ND_PARAM_ID, new FormControl());
        this.listaDistanciaxNegocio.push(objDNego);
      }
    }
  }

  async ConsultarTiempoEsperaEmpresa() {
    let resp: any = await this.tipoPedidoService.ConsultarTiempoEsperaEmpresa('', this.empresa);
    for (var i = 0; i < resp.length; i++) {
      this.formaConfig.addControl("TE_" + resp[i].TE_PARAM_ID , new FormControl());
    }
    //console.log("lista",resp);
    this.listaTiempoEspera = resp;    
  }

  async ConsultarMedioPago() {
    let resp: any = await this.tipoPedidoService.ConsultarMedioPago('');    
    for (var i = 0; i < resp.length; i++) {
      this.formaConfig.addControl("MP_" + resp[i].MP_ID, new FormControl());
      this.formaConfig.addControl("KEY_" + resp[i].MP_ID, new FormControl());
      this.formaConfig.addControl("DESCRIP_" + resp[i].MP_ID, new FormControl());
      this.formaConfig.addControl("AMBIENTE_" + resp[i].MP_ID, new FormControl());
      //this.formaConfig.addControl("MPN_" + resp[i].MP_ID, new FormControl());
      for (var j = 0; j < this.listaTipoPedido.length; j++) {
        this.formaConfig.addControl("MP_TP_" + resp[i].MP_ID + "_" + this.listaTipoPedido[j].TP_ID, new FormControl());
      }
    }    
    this.listaMediosPago = resp;
  }

  
  async ConsultarTipoPedido() {
    let resp: any = await this.tipoPedidoService.ConsultarTipoPedidosEmpresa('', this.empresa);    

    for (var i = 0; i < resp.length; i++) {
      if (resp[i].TP_ESTADO == 'A') {
        let objTipoPedido: ClsTipoPedidoEmpresaConfigModel = new ClsTipoPedidoEmpresaConfigModel();
        objTipoPedido = resp[i];        
        this.listaTipoPedido.push(objTipoPedido);
      }
    }    
  }
  
  GetDireccion(event: any) {

    this.objConfigNegocio.CN_DIRECCION = event.formatted_address;
    this.objConfigNegocio.CN_GEOREFERENCIA = event.formatted_address;
    this.objConfigNegocio.CN_LATITUD = event.geometry.location.lat();
    this.objConfigNegocio.CN_LONGITUD = event.geometry.location.lng();
    this.formaConfig.controls['HdnLatitud'].setValue(event.geometry.location.lat());
    this.formaConfig.controls['HdnLongitud'].setValue(event.geometry.location.lng());
    this.formaConfig.controls['HdnGeoreferencia'].setValue(event.formatted_address);
    this.formaConfig.controls['TxtDireccion'].setValue(event.formatted_address);    
  }

  async EliminarTerceroDomicilio(idTercero) {
    //console.log(idTercero);
    let resp: any = await this._ConfigNegocioService.EliminarTerceroDomicilio(idTercero);

  }

  async GuardarTerceroDomicilio() {
    if (this.idTer.value != 0) {
      let ter: ClsTercerosDomicilioModel = new ClsTercerosDomicilioModel();
      ter.TER_ID = this.idTer.value;
      let resp: any = await this._ConfigNegocioService.GuardarTerceroDomicilio(ter);
    }
  }

  async ListarTerceroDomicilio() {
    let resp: any = await this._ConfigNegocioService.ListarTerceroDomicilio();    
    this.terDomicilios = resp;
    //console.log("listas", this.terDomicilios);
  }

  async onKey(event: any) {
    if (event.key == "Enter") {
      //console.log(event.target.value);
      let resp: any = await this._ConfigNegocioService.ConsultarTercero('I', event.target.value);     
      if (resp != null) {
        this.idTer.setValue(resp.TER_ID);
        this.idTerCC.setValue(resp.TER_IDENTIFICACION);
        this.idTerNM.setValue(resp.TER_NOMBRE);
      }
    }    
  }

  idTer: FormControl = new FormControl(0);
  idTerCC: FormControl = new FormControl();
  idTerNM: FormControl = new FormControl();

}

