import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClsVerificaInicioModel } from '../../models/Web/ClsVerificaInicio.model';
import { VerificaInicioService } from '../../services/VerificarInicio.service';
import { AuthService } from '../../services/_Auth.service';
import { ClsAccesoNegocioModel } from '../../models/Web/ClsAccesoNegocio.model';

@Component({
  selector: 'app-verificar-inicio',
  templateUrl: './verificar-inicio.component.html',
  styleUrls: ['./verificar-inicio.component.css']
})
export class VerificarInicioComponent implements OnInit {

  objVerificarModel: ClsVerificaInicioModel  ;

  constructor(private _activatedRoute: ActivatedRoute, private _verificaInicioService: VerificaInicioService, private _router: Router, private _authService: AuthService) {
    this._activatedRoute.params.subscribe(data => {
      this.objVerificarModel = new ClsVerificaInicioModel();
      this.objVerificarModel.UsuarioID = data.UsuarioID;
      this.objVerificarModel.EmpresaID = data.EmpresaID;
      this.objVerificarModel.NegocioID = data.NegocioID;
      this.objVerificarModel.UsuLogin = data.UsuLogin;
      this.objVerificarModel.UsuPass = data.UsuPass;
      this.objVerificarModel.Opcion = data.Opcion;
      this.objVerificarModel.OpcionId = data.OpcionId;
      console.log(this.objVerificarModel);
      this.verificar();
    });
  }

  async verificar() {
    console.log('llamando metodo verificar login');
    let resp: any = await this._verificaInicioService.VerificarLogin(this.objVerificarModel);
    if (resp != null) {
      console.log("verificar iniciio", resp);
      if (resp.IsSuccess) {        
        let datos = JSON.stringify(resp.Data[0]);
        localStorage.setItem('sesion', datos);

        let lNegocio: ClsAccesoNegocioModel[] = [];
        for (var i = 0; i < resp.Data.LISTADO_NEGOCIO.length; i++) {
          if (resp.Data.LISTADO_NEGOCIO[i].NEGO_ID == this.objVerificarModel.NegocioID) {
            lNegocio.push(resp.Data.LISTADO_NEGOCIO[i]);
          }
        }

        this._authService.asingarVariables(resp.Data.LISTADO_OPCIONES, lNegocio[0].NEGO_ULTCIERRE,
          lNegocio[0].NEGO_NOMBRE, resp.Data.USU_NOMBRE);

        
        
        await localStorage.setItem('sesion', JSON.stringify(resp.Data));
        await localStorage.setItem('negocio', JSON.stringify(lNegocio));
        await localStorage.setItem('opciones', JSON.stringify(resp.Data.LISTADO_OPCIONES));
        let ip: any = await this._authService.getIP();
        await localStorage.setItem('ip',ip.ip );
        await localStorage.setItem('token', resp.Data.TOKEN);

        this._authService.loggedIn = true;
        console.log("["+this.objVerificarModel.Opcion+"]");
        this._router.navigate([''+this.objVerificarModel.Opcion+'']);
      } else {
        this._authService.loggedIn = false;
        this._router.navigate(['home']);
      }
    } else {
      
    }  
  }

  ngOnInit() {
  }

}
