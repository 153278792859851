import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//import { CarritoCompraService } from '../../services/carritoCompra/carritoCompra.service';
import { ClsCarritoCompraModel } from '../../models/Aplicacion/ClsCarritoCompra.model';
import { CarritoCompraService } from '../../services/carritoCompra.service';
import { GeneralesService } from '../../services/_Generales.service';

@Component({
    selector: 'app-pagos',
    templateUrl: './pagos.component.html',
    styleUrls: ['./pagos.component.css']
})
export class PagosComponent implements OnInit {
    loading: boolean;

  window: any = window;
  infoCarritoPAGO: any
  key: any

  constructor(private _activatedRoute: ActivatedRoute, private carritoCompra: CarritoCompraService,
    private oGeneral: GeneralesService) {
        this.loading = true;
    this.oGeneral.mostrarMenu = false;
        this._activatedRoute.params
            .subscribe(param => {
                console.log("Parametros", param);;
                //this.artistaId = params['id'];
                //this.artistaId = param.id;
                //this.getArtista(param.id);
                //this.getTopTracks(param.id);
                this.key = param.Key
                this.Llamar_PedidoKey(param.Key);
            });
    }

    ngOnInit() {
        
    }

    async Llamar_PedidoKey(key: any) {
        try {
            let _token = "";

            const infoPedido: any = await this.carritoCompra.ListarPedidoKey(_token, "Error verificando los datos el pedido, intente nuevamente.", key);
            if (infoPedido != null) {
                this.infoCarritoPAGO = infoPedido;
                console.log("infoPedido", infoPedido);

                switch (infoPedido.MedioPago_Pedido.Tipo_Pago) {

                    case "DAVIVIENDA":
                        this.epayco(infoPedido);
                        break;

                    case "BANCOLOMBIA":
                        this.wompi(infoPedido);
                    break;

                    case "MERCADOPAGO":
                      this.mercadopago(infoPedido);
                      break;
                }
            }
        } catch (e) {
            //this.uiService.ShowAlert(e.message, "Error");
        }
        finally {
            //await this.uiService.cerrarLoadingNEW(Cargar);
            this.loading = false;
        }
    }

    async epayco(infoPedido: any) {
        console.log("epayco", infoPedido);

        let handler = this.window.ePayco.checkout.configure({
            key: infoPedido.MedioPago_Pedido.Api_Key,
            test: infoPedido.MedioPago_Pedido.Ambiente_Prueba
        });

        var data = {
            //Parametros compra (obligatorio) 
            invoice: infoPedido.Informacion_Pedido.Pedido_ID,
            currency: infoPedido.Tercero_Paga.TER_CODIGO_MONEDA,
            name: infoPedido.MedioPago_Pedido.Descripcion_Factura,
            description: infoPedido.MedioPago_Pedido.Descripcion_Factura,
            tax_base: infoPedido.Carrito_Total.Valor_SubTotal,
            tax: infoPedido.Carrito_Total.Valor_Impuesto,
            amount: infoPedido.Carrito_Total.Valor_Total,
            country: infoPedido.Tercero_Paga.TER_CODIGO_PAIS,
            lang: infoPedido.MedioPago_Pedido.Lenguaje,
            external: infoPedido.MedioPago_Pedido.Pago_Externo,

            //Onpage="false" - Standard="true"
            //Atributos opcionales
            //method: '',
            extra1: 'ePayco',
            extra2: '',
          extra3: '',
          confirmation: infoPedido.MedioPago_Pedido.Url_Response,//"http://ventas.globussistemas.com/api/FileUpload/prueba_epayco_json",
           response: infoPedido.MedioPago_Pedido.Url_Response
        }
      
        
        handler.onCloseModal = this.onCloseEpaycoModal
        handler.open(data)
    }

    onCloseEpaycoModal() {
      //this.Llamar_PedidoKey(this.key);
    }

    async wompi(infoPedido: any) {
        console.log("wompi", infoPedido);

        var cerosCents = "";
        for (var i = 0; i < infoPedido.MedioPago_Pedido.Ceros_Adicionales; i++) {
            cerosCents = cerosCents + "0";
        }

        console.log("wompi cerosCents", cerosCents);

        var valorCents = infoPedido.Carrito_Total.Valor_Total.toString().split('.')[0];
        valorCents = valorCents.toString() + cerosCents.toString();

        console.log("wompi valorCents", valorCents);

        var currency = infoPedido.Tercero_Paga.TER_CODIGO_MONEDA.toString();
        console.log("wompi currency", currency);

        var amountInCents = valorCents.toString();
        console.log("wompi amountInCents", amountInCents);

        //var reference = infoPedido.Informacion_Pedido.Pedido_ID.toString();
        //console.log("wompi reference", reference);

        var publicKey = infoPedido.MedioPago_Pedido.Api_Key.toString();
        console.log("wompi publicKey", publicKey);

        var checkout = new this.window.WidgetCheckout({
            currency: currency,
            amountInCents: valorCents,
            reference: infoPedido.Informacion_Pedido.Pedido_ID.toString(),
            publicKey: publicKey
            //redirectUrl: 'http://ventas.globussistemas.com/api/FileUpload/prueba_wompi' // Opcional
          , redirectUrl: 'http://appwebdata.globussistemas.com/api/VerificaPagos/VerificaWompiPrueba' // Opcional
        });

        checkout.open((result)=> {
            var transaction = result.transaction
            console.log('Transaction ID: ', transaction.id)
            console.log('Transaction object: ', transaction)
          this.ValidaP();
            //12700-1588950952-22138

            //ro hacerle un redirect si no que retorno al clase response solamente por si requiero validar si es exitosa o no
            //var url = "http://ventas.globussistemas.com/api/FileUpload/prueba_wompi";
            //VerificarPagoWompi(url, transaction.id);
            //alert("Termina wompi, llamar metodo API");
        });
    }

    async AsignaWompi(key: any) {
        //let Cargar = await this.uiService.iniciarLoadingNEW('Un momento por favor...');
        try {
            let _token = "";

            const infoWompi: any = await this.carritoCompra.AsignarPagoWompi(_token, "Error verificando los datos el pedido, intente nuevamente.", key);
            if (infoWompi != null) {

                console.log("infoPedido", infoWompi);

            }
        } catch (e) {
            //this.uiService.ShowAlert(e.message, "Error");
        }
        finally {
            //await this.uiService.cerrarLoadingNEW(Cargar);
        }
    }

  public ValidaP(): void {    
    this.Llamar_PedidoKey(this.key);
  }

  public mercadopago(infoPedido: any) {    
    let mercadoPago = JSON.parse(infoPedido.Informacion_Pedido.Pedido_MercadoPago);
    console.log(mercadoPago);
    if (mercadoPago != null && mercadoPago.InitPoint != null) {
      window.open(mercadoPago.InitPoint, "_self");
    } 
  }
}
