import { RouterModule, Routes } from '@angular/router';

//RUTAS HIJAS DE USUARIO
import { USUARIO_ROUTES } from './components/usuario/usuario.routes';

//GUARD
import { AuthGuardService } from './services/_AuthGuard.service';

//COMPONENTES
import { HomeComponent } from './components/home/home.component';
//import { AboutComponent } from './components/about/about.component';
import { HeroesComponent } from './components/heroes/heroes.component';
import { HeroeComponent } from './components/heroe/heroe.component';
import { BuscadorComponent } from './components/buscador/buscador.component';
import { PipesComponent } from './components/pipes/pipes.component';
import { SearchComponent } from './components/search/search.component';
import { ArtistaComponent } from './components/artista/artista.component';
import { NgStyleComponent } from './components/ng-style/ng-style.component';
import { UsuarioComponent } from './components/usuario/usuario.component';
import { ProtegidaComponent } from './components/protegida/protegida.component';
import { TemplateComponent } from './components/template/template.component';
import { DataComponent } from './components/data/data.component';
//import { ChatComponent } from './components/chat/chat.component';
import { PagosComponent } from './components/pagos/pagos.component';
import { ConfigNegocioComponent } from './components/config-negocio/config-negocio.component';
import { PpalComponent } from './components/ppal/ppal.component';
import { VerificarInicioComponent } from './components/verificar-inicio/verificar-inicio.component';
import { ImagenPromocionComponent } from './components/imagen-promocion/imagen-promocion.component';
import { TipoPedidoComponent } from './components/tipo-pedido/tipo-pedido.component';
import { AdminDistanciasComponent } from './components/admin-distancias/admin-distancias.component';
import { AdminPedidosComponent } from './components/admin-pedidos/admin-pedidos.component';
import { MapaCrearDireccionComponent } from './components/MapaCrearDireccion/MapaCrearDireccion.component';
import { MapaKeyComponent } from './components/mapa-key/mapa-key.component';
import { SMTPComponent } from './components/smtp/smtp.component';
import { ReferenciasComponent } from './components/referencias/referencias.component';
import { NegociosComponent } from './components/negocios/negocios.component';
import { AppMenuComponent } from './components/app-menu/app-menu.component';
import { AdminPedidosCallcenterComponent } from './components/admin-pedidos-callcenter/admin-pedidos-callcenter.component';
import { AdminPedidosOnlineComponent } from './components/admin-pedidos-online/admin-pedidos-online.component';
import { ZonaDomicilioComponent } from './components/zona-domicilio/zona-domicilio.component';

const APP_ROUTES: Routes = [

  { path: 'home', component: HomeComponent },
  { path: 'VerificarInicio/:UsuarioID/:EmpresaID/:NegocioID/:UsuLogin/:UsuPass/:Opcion/:OpcionId', component: VerificarInicioComponent },
  { path: 'ppal', component: PpalComponent, canActivate: [AuthGuardService] },
  { path: 'config-negocio', component: ConfigNegocioComponent, canActivate: [AuthGuardService] },
  { path: 'imagen-promocion', component: ImagenPromocionComponent, canActivate: [AuthGuardService] },
  { path: 'tipo-pedido', component: TipoPedidoComponent, canActivate: [AuthGuardService] },
  { path: 'admin-distancias', component: AdminDistanciasComponent, canActivate: [AuthGuardService] },

  { path: 'admin-pedidos', component: AdminPedidosComponent, canActivate: [AuthGuardService] },
  { path: 'admin-pedidos-callcenter', component: AdminPedidosCallcenterComponent, canActivate: [AuthGuardService] },
  { path: 'admin-pedidos-online/:Key', component: AdminPedidosOnlineComponent },//NO UTILIZAR GUARD PARA ESTA OPCIÓN, YA QUE ES PARA CLIENTES
  { path: 'zona-domicilio', component: ZonaDomicilioComponent, canActivate: [AuthGuardService] },

  { path: 'mapa-key', component: MapaKeyComponent, canActivate: [AuthGuardService] },
  { path: 'smtp', component: SMTPComponent, canActivate: [AuthGuardService] },
  { path: 'MapaCrearDireccion', component: MapaCrearDireccionComponent },
  { path: 'referencias', component: ReferenciasComponent, },
  { path: 'negocios', component: NegociosComponent, },
  { path: 'app-menu', component: AppMenuComponent, },
  { path: 'pagoPedido/:Key', component: PagosComponent },

  //{ path: 'about', component: AboutComponent, canActivate: [AuthGuardService] },
  { path: 'heroes', component: HeroesComponent, canActivate: [AuthGuardService] },
  { path: 'heroe/:id', component: HeroeComponent, canActivate: [AuthGuardService] },
  { path: 'buscar/:termino', component: BuscadorComponent, canActivate: [AuthGuardService] },
  { path: 'pipes', component: PipesComponent, canActivate: [AuthGuardService] },
  { path: 'search', component: SearchComponent, canActivate: [AuthGuardService] },
  { path: 'artista/:id', component: ArtistaComponent, canActivate: [AuthGuardService] },
  { path: 'estilos', component: NgStyleComponent, canActivate: [AuthGuardService] },
  {
    path: 'usuario/:id', component: UsuarioComponent, canActivate: [AuthGuardService],
    children: USUARIO_ROUTES
  },
  { path: 'protegida', component: ProtegidaComponent, canActivate: [AuthGuardService] },
  { path: 'template', component: TemplateComponent, canActivate: [AuthGuardService] },
  { path: 'data', component: DataComponent, canActivate: [AuthGuardService] },

  //*******************Inicio Rutas CHAT*******************//
  //{ path: 'chat/:widget_id', component: ChatComponent },//No validamos autorización por ahora, se debe implementar un guard de LoginChat
  //{ path: 'chat', component: ChatComponent },//No validamos autorización por ahora, se debe implementar un guard de LoginChat
  //*******************Final Rutas CHAT*******************//

  { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: '**', pathMatch: 'full', redirectTo: 'home' }
];

export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES, { useHash: true });
