import { Component, OnInit, Input } from '@angular/core';
import { HttpClient, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { APIENDPOINT } from '../../config/configuration';
import { FileModel } from '../../models/file.model';
import { FileUploadService } from '../../services/_FileUpload.service';

@Component({
    selector: 'app-FileUpload',
    templateUrl: './FileUpload.component.html',
    styleUrls: ['./FileUpload.component.css']
})
export class FileUploadComponent implements OnInit {
    constructor(private _http: HttpClient, private _fileUploadService: FileUploadService) { }

    ngOnInit() {

    }

    //*******************************************UPLOAD FILE API*******************************************//
    public progress: number;
    public message: string;
    public cargandoArchivo: boolean;

    public uploadFile = (files) => {
        this.progress = 0;
        if (files.length === 0) {
            return;
        }

        let fileToUpload = <File>files[0];
        const formData = new FormData();
        formData.append('file', fileToUpload, fileToUpload.name);

        this._http.post(APIENDPOINT.uploadFile, formData, { reportProgress: true, observe: 'events' })
            .subscribe(event => {
                if (event.type === HttpEventType.UploadProgress) {
                    this.cargandoArchivo = true;
                    this.progress = Math.round(100 * event.loaded / event.total);
                }
                else if (event.type === HttpEventType.Response) {
                    this.message = 'Carga completa.';

                    setTimeout(() => {
                        this.cargandoArchivo = false;
                        this.progress = 0;
                    }, 500);
                }
                this._fileUploadService.generarEventoArchivo(true, event);
            }, (error: HttpErrorResponse) => {
                console.log('HTTPERROR INTERCEPTOR');
                console.log(error);
                    if (error.status >= 400) {
                        this.cargandoArchivo = false;
                        this.progress = 0;
                        this.message = 'Error en archivo.';
                        console.log('Error controlado en carga');

                        this._fileUploadService.generarEventoArchivo(false, error);
                }
            });
    }
    //*******************************************UPLOAD FILE API*******************************************//

}
