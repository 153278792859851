import { Component, OnInit, ViewChild, EventEmitter, Output, Input, Renderer2, Inject, ElementRef } from '@angular/core';
declare var window: any;

@Component({
  selector: 'app-direccion-buscar-manual',
  templateUrl: './direccion-buscar-manual.component.html',
  styleUrls: ['./direccion-buscar-manual.component.css']
})
export class DireccionBuscarManualComponent implements OnInit {
  EmpresaId: number;
  IndicacionesDescrip: string = "";
  DireccionReal: string = "";

  direccion = {
    IsSuccess: false,
    place_id: "",
    name: "",
    formatted_address: "",
    adr_address: "",
    formatted_phone_number: "",
    international_phone_number: "",
    url: "",
    website: "",
    rating: 0,
    address_components: null,
    lat: 0,
    lng: 0,
    indicaciones: ""
  };

  @Output() eventoDireccion: any = new EventEmitter();
  constructor() {
    let datosn: any = localStorage.getItem('negocio');
    if (datosn != null && datosn != '') {
      let objNego: any = JSON.parse(datosn);
      this.EmpresaId = objNego[0].NEGO_EMPRESA;
    }
  }

  ngOnInit() {
  }

  async AbrirBuscadorManual() { 
    this.DireccionReal = "";
    this.IndicacionesDescrip = "";

    window.jQuery('#modalBuscarDireccionManual').modal('show');
  }

  async CerrarBuscador() {
    window.jQuery('#modalBuscarDireccionManual').modal('hide');
  }


  async AceptarDireccion() {
    console.log("CerrarBuscadorDireccion");

    this.direccion.indicaciones = this.IndicacionesDescrip;
    this.direccion.formatted_address = this.DireccionReal;
    this.CerrarBuscador();
    this.direccion.IsSuccess = true

    this.eventoDireccion.emit(this.direccion);
  }
}
