import { ClsMenuDetalleModel } from "./ClsMenuDetalle.model";
import { ClsMenuDiaModel } from "./ClsMenuDia.model";
import { ClsMenuNegocioAppModel } from "./ClsMenuNegocioApp.model";

export class ClsMenuModel {
  public MENU_ID:number;
  public MENU_NOMBRE:string;
  public MENU_DESCRIPCION: string;
  public MENU_COLOR_INI: string;
  public MENU_COLOR_FIN: string;
  public MENU_COLOR_FUENTE: string;
  public MENU_ANCHO_COLUMNAS: number;
  public MENU_ANCHO_ESPACIOS: number;
  public MENU_TAMANO_FUENTE: string;
  public MENU_TAMANO_FUENTE_DESCRIP: string;
  public MENU_APLICA_DIA: string;
  public MENU_FECHA_INI: string;
  public MENU_FECHA_FIN: string;
  public MENU_VISUALIZA: string;
  public MENU_URL_IMAGEN: string;
  public MENU_ORDEN: number;
  public MENU_ESTADO: string;
  public MENU_USU_ID: number;
  public MENU_IP_EQUIPO: string;
  public MENU_EMPRESA: number;
  public DIAS: ClsMenuDiaModel[];
  public NEGOCIOS: ClsMenuNegocioAppModel[];
  public DETALLE: ClsMenuDetalleModel[];
}
