import { Component, OnInit } from '@angular/core';
import {FormGroup, FormControl, Validators } from '@angular/forms';
import { UsuarioService } from '../../services/usuario.service';
import { Router } from '@angular/router';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    usuario: FormGroup;
    error: boolean = false;

    constructor(private usuarioService: UsuarioService, private router: Router) {
        this.usuario = new FormGroup({
            'Nombre_Usuario': new FormControl("",Validators.required ),
            'Contra_Usuario': new FormControl("", Validators.required),
        });
    }

  ngOnInit() {
  }

    IniciarSesion() {
        if (this.usuario.valid) {
            this.usuarioService.getLogin(this.usuario.get('Nombre_Usuario').value, this.usuario.get('Contra_Usuario').value)
                .subscribe(data => {
                    if (data["idusuario"] == 0) {
                        //error
                        this.error = true;
                        //this.router.navigate(["/permiso-error-pagina"]);
                    } else {
                        //OK
                        this.error = false;
                        this.router.navigate(["/"]);//pagina principal  
                    }
                });
        }
    }
}
