export class ClsCarritoCompraTotalModel {
    public Cantidad_Items: number = 0;

    public Valor_Total: number = 0;

    public Valor_SubTotal: number = 0;

    public Valor_Impuesto: number = 0;

    public Valor_Envio: number = 0;

    public Valor_Propina: number = 0;
}