import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MasterModel } from '../models/master.model';
import { BaseService } from './_Base.service';
import { environment } from '../../environments/environment';
import { APIENDPOINT } from '../config/configuration';
import { ClsAccesoNegocioModel } from '../models/Web/ClsAccesoNegocio.model';

@Injectable({
  providedIn: 'root'
})
export class ParametrosService extends BaseService<string, MasterModel> {

  private apiURL: string;
  private negoId: number = 0;

  constructor(protected _http: HttpClient) {
    super(_http, environment.apiGatewayURL)
    this.apiURL = environment.apiGatewayURL;

    let datos: any = localStorage.getItem('negocio');
    if (datos != null && datos != '') {
      let objNego = new ClsAccesoNegocioModel();
      objNego = JSON.parse(datos);
      this.negoId = objNego[0].NEGO_ID;
    }
  }


  async ListarParametros(_token: string) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }

    if (this.negoId != 0) {
      return new Promise(resolve => {
        this.get(APIENDPOINT.ListarParametros + "?ClaseId=1&EmpresaId=" + this.negoId, true, token)
          .subscribe(resp => {
            if (resp.IsSuccess) {
              resolve(resp.Data);
            } else {
              resolve(null);
              console.log('Error al listar las ciudades');
            }
          }, error => {
            resolve(null);
            console.log('Error al listar las ciudades');
            return null;
          });
      });
    }
  }

  async ListarParametrosGeneral(clase: number, empresa: number) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }

    return new Promise(resolve => {
      this.get(APIENDPOINT.ListarParametros + "?ClaseId=" + clase + "&EmpresaId=" + empresa, true, token)
        .subscribe(resp => {
          resolve(resp);
        }, error => {
          resolve(null);
          console.log('Error al listar parametros generales.');
          return null;
        });
    });
  }
}
