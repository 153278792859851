import { Component, OnInit } from '@angular/core';

import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { APIENDPOINT } from '../../config/configuration';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {

    constructor(private http: HttpClient) {

    }

  ngOnInit() {
  }


    //*******************************************UPLOAD FILE API*******************************************//
    public progress: number;
    public message: string;

    public uploadFile = (files) => {
        this.progress = 0;
        if (files.length === 0) {
            return;
        }

        let fileToUpload = <File>files[0];
        const formData = new FormData();
        formData.append('file', fileToUpload, fileToUpload.name);

        this.http.post(APIENDPOINT.uploadFile, formData, { reportProgress: true, observe: 'events' })
            .subscribe(event => {
                if (event.type === HttpEventType.UploadProgress)
                    this.progress = Math.round(100 * event.loaded / event.total);
                else if (event.type === HttpEventType.Response) {
                    this.message = 'Carga completa.';
                }
            });
    }
    //*******************************************UPLOAD FILE API*******************************************//

}
