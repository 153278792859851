import { Component, OnInit, ViewChild, EventEmitter, Output, Input, Renderer2, Inject, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { PedidoCallCenterService } from '../../services/PedidoCallCenter.service';
import { ClsTercerosModel } from '../../models/Aplicacion/ClsTerceros.model';
import { GeneralesService } from '../../services/_Generales.service';
import { LocalizacionService } from '../../services/localizacion.service';
import { ConfigEmpresaService } from '../../services/ConfigEmpresa.service';
import { escapeLeadingUnderscores } from 'typescript';
declare var window: any;
declare var google;

@Component({
  selector: 'app-direccionBuscar',
  templateUrl: './direccionBuscar.component.html',
  styleUrls: ['./direccionBuscar.component.css']
})
export class DireccionBuscarComponent implements OnInit {
  //********************************************************************************//
  EmpresaId: number;
  @Input() IconoPlusMostrar: boolean = true;
  @Input() BotonTexto: string = "";
  @Input() TituloTexto: string = "";
  @Input() SubtituloTexto: string = "";
  @Input() IndicacionesMostrar: boolean = true;
  @Input() IndicacionesTexto: string = "";

  DireccionValida: boolean = false;
  DireccionDescrip: string = "";
  IndicacionesDescrip: string = "";
  DireccionReal: string = "";

  direccion = {
    IsSuccess: false,
    place_id: "",
    name: "",
    formatted_address: "",
    adr_address: "",
    formatted_phone_number: "",
    international_phone_number: "",
    url: "",
    website: "",
    rating: 0,
    address_components: null,
    lat: 0,
    lng: 0,
    indicaciones: ""
  };


  MapaKey: string = "";
  TieneMapaKey: boolean = true;
  mapInitialised: boolean = false;
  CargandoMapa: boolean = true;
  PinDireccion: string = "";
  GoogleAutocomplete: any;
  GoogleGeocoder: any;
  MapaZoom: number = 16;

  BuscandoDireccion: boolean = false;
  ListadoDirecciones = [];

  lat_Negocio: number = 0;
  lng_Negocio: number = 0;

  @ViewChild('map', { static: true }) mapElement: ElementRef;
  public map: any;

  @Output() eventoDireccion: any = new EventEmitter();

  public markers: any[] = [];

  constructor(
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private localizacion: LocalizacionService,
    private EmpresaService: ConfigEmpresaService,
    private generales: GeneralesService
  ) {
    let datosn: any = localStorage.getItem('negocio');
    if (datosn != null && datosn != '') {
      let objNego: any = JSON.parse(datosn);
      this.EmpresaId = objNego[0].NEGO_EMPRESA;
    }
  }

  async ngOnInit() {
    await this.ListarMapaKey();
  }

  async AbrirBuscador() {
    this.DireccionValida = false;
    this.DireccionDescrip = "";
    this.DireccionReal = "";
    this.IndicacionesDescrip = "";
    this.deleteMarkerAll();

    window.$('#modalBuscarDireccion').modal('show');
  }

  async CerrarBuscador() {
    window.$('#modalBuscarDireccion').modal('hide');
  }

  async ListarMapaKey() {
    try {
      let resp: any = await this.EmpresaService.ConsultarMapaKey(this.EmpresaId);
      if (resp != null) {
        if (resp.IsSuccess) {
          this.MapaKey = resp.Data.MK_KEY_MAPS;
          console.log("MapaKey", resp);
          if (this.MapaKey == "") {
            this.TieneMapaKey = false;
          } else {
            this.TieneMapaKey = true;
            this.loadGoogleMaps();
          }
        } else {
          this.TieneMapaKey = false;
        }
      } else {
        this.TieneMapaKey = false;
      }
    } catch (e) {
      this.TieneMapaKey = false;
    }
    finally {
    }
  }

  async loadGoogleMaps() {

    this.addConnectivityListeners();

    if (typeof google == "undefined" || typeof google.maps == "undefined") {
      console.log("online, loading map");
      window['mapInit'] = () => {
        this.initMap();
        //this.initBuscar();
        this.enableMap();
        this.initGeocoder();
      }
      let script = this.renderer2.createElement('script');
      script.type = "text/javascript";
      script.id = "googleMaps";

      if (this.MapaKey != "") {
        script.src = 'https://maps.google.com/maps/api/js?key=' + this.MapaKey + '&libraries=places&callback=mapInit';
      } else {
        script.src = 'https://maps.google.com/maps/api/js?&libraries=places&callback=mapInit';
      }

      this.renderer2.appendChild(this.document.body, script);
    }
    else {
      console.log("showing map");
      this.initMap();
      //this.initBuscar();
      this.enableMap();
      this.initGeocoder();
    }
  }

  async initMap() {
    console.log("initMap");

    this.mapInitialised = true;

    let geoLocalizacion: any = await this.localizacion.getGeoLocalizacion();
    if (geoLocalizacion.IsSuccess) {
      this.CargandoMapa = false;
      console.log("getCurrentPosition");
      let latLng = new google.maps.LatLng(geoLocalizacion.Latitud, geoLocalizacion.Longitud);

      this.lat_Negocio = geoLocalizacion.Latitud;
      this.lng_Negocio = geoLocalizacion.Longitud;

      let mapOptions = {
        center: latLng,
        zoom: this.MapaZoom,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      }

      this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);

      google.maps.event.addListenerOnce(this.map, 'idle', () => {
        console.log("idle");
      });
    }
  }

  async initBuscar() {
    this.GoogleAutocomplete = new google.maps.places.Autocomplete(document.getElementById('Direccion'));

    google.maps.event.addListener(this.GoogleAutocomplete, 'place_changed', () => {
      this.DireccionValida = false;

      const place = this.GoogleAutocomplete.getPlace();
      console.log("Place", place);
      this.invokeEvent(place);

      if (!place.geometry || !place.geometry.location) {
        return;
      }

      // If the place has a geometry, then present it on a map.
      if (place.geometry.viewport) {
        this.map.fitBounds(place.geometry.viewport);
      } else {
        this.map.setCenter(place.geometry.location);
      }

      if (place.geometry.location != null && place.geometry.location != undefined) {
        this.DireccionValida = true;
        this.addMarkerDireccion(place.geometry.location.lat(), place.geometry.location.lng());

        this.AsignarDireccion(place);
      }
    });
  }

  invokeEvent(place: any) {

  }

  DireccionKeyUp(event: any) {
    this.DireccionValida = false;
    this.deleteMarkerAll();
  }

  disableMap() {
    console.log("disable map");
  }

  enableMap() {
    console.log("enable map");
  }

  addConnectivityListeners() {
    let onOnline = () => {
      setTimeout(() => {
        if (typeof google == "undefined" || typeof google.maps == "undefined") {
          this.loadGoogleMaps();
        } else {
          if (!this.mapInitialised) {
            this.initMap();
            //this.initBuscar();
            this.initGeocoder();
          }
          this.enableMap();
        }
      }, 2000);
    };

    let onOffline = () => {
      this.disableMap();
    };
    document.addEventListener('online', onOnline, false);
    document.addEventListener('offline', onOffline, false);
  }

  public addMarkerDireccion(lat: number, lng: number): void {
    let latLng = new google.maps.LatLng(lat, lng);

    this.map.setCenter(latLng);

    if (this.PinDireccion != "") {
      var image = this.PinDireccion;
      let marker = new google.maps.Marker({
        map: this.map,
        animation: google.maps.Animation.DROP,
        position: latLng,
        icon: image,
        draggable: true,
        title: ""
      });
      this.markers.push(marker);

      google.maps.event.addListener(marker, 'dragend', (event) => {
        console.log("dragend direccion", event);
        console.log(event.latLng.lat());
        console.log(event.latLng.lng());
        this.BuscarDireccionDragend(event.latLng.lat(), event.latLng.lng());
      });

    } else {
      let marker = new google.maps.Marker({
        map: this.map,
        animation: google.maps.Animation.DROP,
        position: latLng,
        draggable: true,
        title: ""
      });
      this.markers.push(marker);

      google.maps.event.addListener(marker, 'dragend', (event) => {
        console.log("dragend direccion", event);
        console.log(event.latLng.lat());
        console.log(event.latLng.lng());
        this.BuscarDireccionDragend(event.latLng.lat(), event.latLng.lng());
      });
    }
  }

  setMapOnAll(map) {
    //hace ciclo sobre los marcadores que hemos guardado en la variable markers
    for (var i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(map);
    }
  }

  deleteMarkerAll() {
    this.setMapOnAll(null);
    this.markers = [];
  }

  async AceptarDireccion() {
    console.log("CerrarBuscadorDireccion");

    this.direccion.indicaciones = this.IndicacionesDescrip;
    this.direccion.formatted_address = this.DireccionReal;
    this.CerrarBuscador();
    this.direccion.IsSuccess = true

    this.eventoDireccion.emit(this.direccion);
  }

  //*************************INI GEOCODER DIRECCION*************************//
  initGeocoder() {
    this.GoogleGeocoder = new google.maps.Geocoder();
  }

  async BuscarDireccion() {
    await this.deleteMarkerAll();

    let infoDireccion: any = document.getElementById('Direccion');
    if (infoDireccion.value != "") {
      var Direccion: any = await this.getAddress(infoDireccion.value);
      if (Direccion.IsSuccess) {
        let lat = Direccion.Data.geometry.location.lat();
        let lng = Direccion.Data.geometry.location.lng();
        let Dir = Direccion.Data.formatted_address;

        await this.addMarkerDireccion(lat, lng);
      }

      console.log("Buscar Direccion", Direccion);
    }
  }

  async BuscarCoordenadas(latitud: number, longitud: number) {
    if (latitud != 0 && longitud != 0) {
      var Direccion: any = await this.getAddressFromLatLng(latitud, longitud);
      if (Direccion.IsSuccess) {
        let lat = Direccion.Data.geometry.location.lat();
        let lng = Direccion.Data.geometry.location.lng();
        let Dir = Direccion.Data.formatted_address;

        let infoDireccion: any = document.getElementById('Direccion');
        infoDireccion.value = Dir;
        //await this.AsignarDireccionTercero(lat, lng, Dir, Dir, "");
        await this.addMarkerDireccion(lat, lng);
      }

      console.log("Buscar Coordenadas", Direccion);
    }
  }

  async BuscarDireccionDragend(latitud: number, longitud: number) {
    if (latitud != 0 && longitud != 0) {
      var Direccion: any = await this.getAddressFromLatLng(latitud, longitud);
      if (Direccion.IsSuccess) {
        let lat = Direccion.Data.geometry.location.lat();
        let lng = Direccion.Data.geometry.location.lng();
        let Dir = Direccion.Data.formatted_address;
        console.log("Direccion data", Direccion.Data);
        let infoDireccion: any = document.getElementById('Direccion');
        infoDireccion.value = Dir;
      }

      console.log("Buscar Coordenadas Dragend", Direccion);
    }
  }

  getAddress(address: string) {
    return new Promise((resolve, reject) => {
      this.GoogleGeocoder.geocode({ 'address': address }, (result, status) => {
        if (status == 'OK') {
          let Respuesta =
          {
            IsSuccess: true,
            Message: "Direccion consultada correctamente.",
            Data: result[0]
          };

          this.AsignarDireccion(result[0]);

          resolve(Respuesta);
        } else {
          let Respuesta =
          {
            IsSuccess: false,
            Message: "GOOGLE",
            Data: status
          };

          resolve(Respuesta);
        }
      }, error => {
        let Respuesta =
        {
          IsSuccess: false,
          Message: "ERROR",
          Data: error.error
        };

        resolve(Respuesta);
      });
    });
  }

  public getAddressFromLatLng(latitud: number, longitud: number) {
    return new Promise((resolve, reject) => {
      var latlng = { lat: latitud, lng: longitud };
      this.GoogleGeocoder.geocode({ 'location': latlng }, (result, status) => {
        if (status == 'OK') {
          let Respuesta =
          {
            IsSuccess: true,
            Message: "Direccion consultada correctamente.",
            Data: result[0]
          };

          this.AsignarDireccion(result[0]);

          resolve(Respuesta);
        } else {
          let Respuesta =
          {
            IsSuccess: false,
            Message: "GOOGLE",
            Data: status
          };

          resolve(Respuesta);
        }
      }, error => {
        let Respuesta =
        {
          IsSuccess: false,
          Message: "ERROR",
          Data: error.error
        };

        resolve(Respuesta);
      });
    });
  }

  async AsignarDireccion(place: any) {
    try {
      this.direccion.IsSuccess = true;
      let place_id = "";
      if (place.place_id != undefined == place.place_id != null) {
        place_id = place.place_id;
      }

      let name = "";
      if (place.name != undefined && place.name != null) {
        name = place.name;
      }

      let formatted_address = "";
      if (place.formatted_address != undefined && place.formatted_address != null) {
        formatted_address = place.formatted_address;
      }

      let adr_address = "";
      if (place.adr_address != undefined && place.adr_address != null) {
        adr_address = place.adr_address;
      }

      let formatted_phone_number = "";
      if (place.formatted_phone_number != undefined && place.formatted_phone_number != null) {
        formatted_phone_number = place.formatted_phone_number;
      }

      let international_phone_number = "";
      if (place.international_phone_number != undefined && place.international_phone_number != null) {
        international_phone_number = place.international_phone_number;
      }

      let url = "";
      if (place.url != undefined && place.url != null) {
        url = place.url;
      }

      let website = "";
      if (place.website != undefined && place.website != null) {
        website = place.website;
      }

      let rating = 0;
      if (place.rating != undefined && place.rating != null) {
        rating = place.rating;
      }

      let address_components = null;
      if (place.address_components != undefined && place.address_components != null) {
        address_components = place.address_components;
      }

      let lat = 0;
      let lng = 0;
      if (place.geometry != undefined && place.geometry != null) {
        lat = place.geometry.location.lat;
        lng = place.geometry.location.lng;
      }

      this.direccion.place_id = place_id;
      this.direccion.name = name;
      this.direccion.formatted_address = formatted_address;
      this.direccion.adr_address = adr_address;
      this.direccion.formatted_phone_number = formatted_phone_number;
      this.direccion.international_phone_number = international_phone_number;
      this.direccion.url = url;
      this.direccion.website = website;
      this.direccion.rating = rating;
      this.direccion.address_components = address_components;
      this.direccion.lat = lat;
      this.direccion.lng = lng;
      this.direccion.indicaciones = this.IndicacionesDescrip;
    } catch (e) {
      this.direccion.IsSuccess = true;
      this.direccion.place_id = "";
      this.direccion.name = "";
      this.direccion.formatted_address = "";
      this.direccion.adr_address = "";
      this.direccion.formatted_phone_number = "";
      this.direccion.international_phone_number = "";
      this.direccion.url = "";
      this.direccion.website = "";
      this.direccion.rating = 0;
      this.direccion.address_components = null;
      this.direccion.lat = 0;
      this.direccion.lng = 0;
      this.direccion.indicaciones = "";
    }
  }

  async DireccionApiKeyUp(data: any) {
    try {
      this.BuscandoDireccion = true;

      //Cl 1a #12 - 18
      //let re = /\#/gi;
      //this.DireccionDescrip = this.DireccionDescrip.replace(re, "N");
      if (this.DireccionDescrip != "") {
        let resp: any = await this.EmpresaService.ConsultarDireccionApi(this.DireccionDescrip, this.MapaKey);
        if (resp != null) {
          if (resp.IsSuccess) {
            this.ListadoDirecciones = resp.Data.predictions;
          } else {
            this.ListadoDirecciones = [];
          }
        } else {
          this.ListadoDirecciones = [];
        }
      } else {
        this.ListadoDirecciones = [];
      }

      this.BuscandoDireccion = false;
      console.log("DireccionApiKeyUp response", "");
    } catch (e) {
      console.log("DireccionApiKeyUp error", e);
    }
    finally {
    }
  }

  async SeleccionaPlaceIdApi(data: any) {
    try {
      await this.generales.OpenDialogAjax();

      this.DireccionDescrip = data.description;
      if (this.DireccionReal == "" || this.DireccionReal == null) {
        this.DireccionReal = data.description;
      }

      let resp: any = await this.EmpresaService.ConsultarPlaceIdApi(data.place_id, data.description, this.MapaKey);
      if (resp != null) {
        if (resp.IsSuccess) {
          this.DireccionValida = true;
          await this.deleteMarkerAll();
          let lat = resp.Data.result.geometry.location.lat;
          let lng = resp.Data.result.geometry.location.lng;

          if (resp.Data.result.geometry.location.lat == 0) {
            resp.Data.result.geometry.location.lat = this.lat_Negocio;
          }
          if (resp.Data.result.geometry.location.lng == 0) {
            resp.Data.result.geometry.location.lng = this.lng_Negocio;
          }

          if (lat != 0 && lng != 0) {
            await this.addMarkerDireccion(lat, lng);
          }
          this.AsignarDireccion(resp.Data.result);
        } else {
          console.log("SeleccionaPlaceIdApi response", resp.IsSuccess);
        }
      } else {
        console.log("SeleccionaPlaceIdApi response", null);
      }
    } catch (e) {
      console.log("SeleccionaPlaceIdApi error", e);
    }
    finally {
      this.ListadoDirecciones = null;
      await this.generales.CancelDialogAjax();
    }
  }
}
