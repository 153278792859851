export class ClsHorarioModel {  
  public NH_NEGOCIO_ID: number;
  public NH_DIA_NUMERO: number;
  public NH_DIA_NOMBRE: string;
  public NH_HORA_INI: string;
  public NH_HORA_FIN: string;
  public NH_HORA_INI_2: string;
  public NH_HORA_FIN_2: string;
  public NH_TIEMPO_CERRAR: number;
  public NH_IP_EQUIPO: string;
}
