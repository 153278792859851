import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MasterModel } from '../models/master.model';
import { BaseService } from './_Base.service';
import { environment } from '../../environments/environment';
import { APIENDPOINT } from '../config/configuration';
import { ClsTerceroLoginModel } from '../models/Aplicacion/ClsTerceroLogin.model';
import { ClsTercerosRecuperarPassword } from '../models/Aplicacion/ClsTercerosRecuperarPassword.model';

@Injectable({
  providedIn: 'root'
})
export class TerceroService extends BaseService<any, MasterModel> {

  private apiURL: string;

  constructor(protected _http: HttpClient) {
    super(_http, environment.apiGatewayURL)
    this.apiURL = environment.apiGatewayURL;
  }

  async TerceroPedidoOnlineLogin(Login: ClsTerceroLoginModel) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }

    return new Promise(resolve => {
      this.post(APIENDPOINT.TerceroPedidoOnlineLogin, Login, true, token)
        .subscribe(resp => {
          resolve(resp);
        }, error => {
          console.log(error);
          resolve(null);
        });
    });
  }

  async RecuperarClaveOnline(Tercero: ClsTercerosRecuperarPassword) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }

    return new Promise(resolve => {
      this.post(APIENDPOINT.RecuperarClaveOnline, Tercero, true, token)
        .subscribe(resp => {
          resolve(resp);
        }, error => {
          console.log(error);
          resolve(null);
        });
    });
  }

}
