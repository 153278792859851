import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HeroesService, Heroe } from '../../services/heroes.service';

@Component({
  selector: 'app-buscador',
  templateUrl: './buscador.component.html',
  styleUrls: ['./buscador.component.css']
})
export class BuscadorComponent implements OnInit {
    heroes: Heroe[] = [];
    termino: string;

    //constructor(private _activatedRoute: ActivatedRoute, private _heroesService: HeroesService, private _heroe: Heroe) {
    constructor(private _activatedRoute: ActivatedRoute, private _heroesService: HeroesService) {
        this._activatedRoute.params.subscribe(data => {
            console.log(data["termino"]);
            this.termino = data["termino"];
            this.heroes = this._heroesService.buscarHeroe(data["termino"]);
        });
    }

  ngOnInit() {
  }

    //verHeroeEvento(idx) {
    //    this._router.navigate(['/heroe', idx]);
    //}

}
