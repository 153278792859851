import { Component, OnInit } from '@angular/core';
import { FileUpload2Service } from '../../services/FileUpload2.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ClsImagenPromocionModel } from '../../models/Web/ClsImagenPromocion.model';
import { ClsAccesoNegocioModel } from '../../models/Web/ClsAccesoNegocio.model';
import { ClsUsuarioAccesoModel } from '../../models/Web/ClsAccesoUsuario.model';
import { ImagenPromoService } from '../../services/ImagenPromo.service';
import { GeneralesService } from '../../services/_Generales.service';
declare var window: any;

@Component({
  selector: 'app-imagen-promocion',
  templateUrl: './imagen-promocion.component.html',
  styleUrls: ['./imagen-promocion.component.css']
})
export class ImagenPromocionComponent implements OnInit {
  mensajeRespuesta: string = "";
  formaCargar: FormGroup;
  objImagen: ClsImagenPromocionModel;
  listaImagen: ClsImagenPromocionModel[];
  objNego: ClsAccesoNegocioModel;
  objUsu: ClsUsuarioAccesoModel;

  constructor(private uploadService: FileUpload2Service, private imagenPromo: ImagenPromoService, private generales: GeneralesService) {
    let fecha: string = new Date().getFullYear() + "-" + (new Date().getMonth() ? '0' : '')+(new Date().getMonth() + 1) + "-" + new Date().getDate();
   
    this.formaCargar = new FormGroup({
      'TxtImages': new FormControl('', [Validators.required]),
      'TxtFechaIni': new FormControl(fecha, [Validators.required]),      
      'TxtFechaFin': new FormControl(fecha, [Validators.required]),
      'TxtHoraIni': new FormControl('00:00', [Validators.required]),
      'TxtHoraFin': new FormControl('12:59', [Validators.required]),
      'TxtHipervinculo': new FormControl('')
    });

  }

  ngOnInit() {

    let datosn: any = localStorage.getItem('negocio');
    this.objNego = new ClsAccesoNegocioModel();
    this.objUsu = new ClsUsuarioAccesoModel();
    if (datosn != null && datosn != '') {
      this.objNego = JSON.parse(datosn);
    }

    let datoss: any = localStorage.getItem('sesion');
    if (datoss != null && datoss != '') {
      this.objUsu = JSON.parse(datoss);
    }
    this.ListarImagenPromocion();
  }

  async GuardarImagenPromocion() {    
      this.generales.OpenDialogAjax();

    let datosi: any = localStorage.getItem('ip');

    this.objImagen = new ClsImagenPromocionModel();
    this.objImagen.IMG_ID = 0;
    this.objImagen.IMG_EMPRESA_ID = this.objNego[0].NEGO_EMPRESA;
    this.objImagen.IMG_FECHA_INI = this.formaCargar.controls['TxtFechaIni'].value;
    this.objImagen.IMG_FECHA_FIN = this.formaCargar.controls['TxtFechaFin'].value;;;    
    this.objImagen.IMG_HORA_INI = this.formaCargar.controls['TxtHoraIni'].value;;;
    this.objImagen.IMG_HORA_FIN = this.formaCargar.controls['TxtHoraFin'].value;;;
    this.objImagen.IMG_URL_IMAGEN = this.uploadService.urlfile;
    this.objImagen.IMG_HIPERVINCULO = this.formaCargar.controls['TxtHipervinculo'].value;
    this.objImagen.IMG_USUARIO_ID = this.objUsu.USU_ID;
    this.objImagen.IMG_IP_EQUIPO = datosi;

    let resp: any = await this.imagenPromo.GuardarImagenPromocion(this.objImagen);
    this.mensajeRespuesta = resp.Message;
    window.$('#myModal').modal('show');
    
    this.formaCargar.controls["TxtImages"].setValue("");
    this.uploadService.urlfile = "";
    this.uploadService.message = "";
    this.uploadService.progress = 0;
    await this.ListarImagenPromocion();

      this.generales.CancelDialogAjax();
  }

  async ListarImagenPromocion() {
    let resp: any = await this.imagenPromo.ConsultarImagenPromocion('', this.objNego[0].NEGO_EMPRESA);
      this.listaImagen= resp;
  }

  async EliminarImagen(id) {
    let resp: any = await this.imagenPromo.EliminarImagenPromocion(id);
    this.ListarImagenPromocion();
  }
}
