import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MasterModel } from '../models/master.model';
import { BaseService } from './_Base.service';
import { environment } from '../../environments/environment';
import { APIENDPOINT } from '../config/configuration';
import { ClsAccesoModel } from '../models/Web/ClsAcceso.model';
import { ClsAccesoNegocioModel } from '../models/Web/ClsAccesoNegocio.model';

@Injectable({
    providedIn: 'root'
})
export class HubService extends BaseService<any, MasterModel> {

    private apiURL: string;
    EmpresaId: number;
    Local: string;

    constructor(protected _http: HttpClient) {
        super(_http, environment.apiGatewayURL)
        this.apiURL = environment.apiGatewayURL;

        let datos: any = localStorage.getItem('negocio');
        if (datos != null && datos != '') {
            let objNego = new ClsAccesoNegocioModel();
            objNego = JSON.parse(datos);
            this.EmpresaId = objNego[0].NEGO_EMPRESA;
            this.Local = objNego[0].NEGO_SISTEMA_LOCAL;
        }

    }

    async ConsultarHub() {
        let token = await localStorage.getItem('token');
        if (token == null || token == undefined) {
            token = "";
        }

        if (this.EmpresaId != 0) {
            return new Promise(resolve => {
                this.get(APIENDPOINT.ConsultarHub + "?EmpresaId=" + this.EmpresaId, true, token)
                    .subscribe(resp => {
                        resolve(resp);
                    }, error => {
                        console.log('Error información HUB: ' + error);
                        resolve(null);
                    });
            });
        }
    }

}
