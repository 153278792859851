export class ClsTiempoEntregaNegocioModel {  
  public TE_NEGO_ID: number;

  public TE_PARAM_ID: number;

  public TE_MINUTOS: number;

  public TE_MINUTOS2: number;

  public VD_VALOR: number;

  public TE_ESTADO: string;

}
