import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MasterModel } from '../models/master.model';
import { BaseService } from './_Base.service';
import { environment } from '../../environments/environment';
import { APIENDPOINT } from '../config/configuration';
import { ClsMenuModel } from '../models/Web/Menu/ClsMenu.model';
import { ClsMenuDetReferenciaModel } from '../models/Web/Menu/ClsMenuDetReferencia.model';
import { ClsMenuDetalleModel } from '../models/Web/Menu/ClsMenuDetalle.model';
import { ClsMenuToppingModel } from '../models/Web/Menu/ClsMenuTopping.model';
import { ClsMenuToppingReferenciaModel } from '../models/Web/Menu/ClsMenuToppingReferencia.model';

@Injectable({
  providedIn: 'root'
})
export class MenuService extends BaseService<any, MasterModel> {

  private apiURL: string;
  urlBase: string;

    constructor(protected _http: HttpClient) {
        super(_http, environment.apiGatewayURL)
      this.apiURL = environment.apiGatewayURL;
      this.urlBase = environment.BaseURL;
    }


  async GuardarMenuApp(iDatos: ClsMenuModel) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }

    return new Promise(resolve => {
      this.post(APIENDPOINT.GuardarMenuApp, iDatos, true, token)
        .subscribe(resp => {
          resolve(resp);
        }, error => {
          resolve(error.error);
          console.log('Error', error.error);
        });
    });
  }

  async GuardarMenuDetalleApp(iDatos: ClsMenuDetalleModel) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }

    return new Promise(resolve => {
      this.post(APIENDPOINT.GuardarMenuDetalleApp, iDatos, true, token)
        .subscribe(resp => {
          resolve(resp);
        }, error => {
          resolve(error.error);
          console.log('Error', error.error);
        });
    });
  }

  async GuardarMenuDetalleArticulo(iDatos: ClsMenuDetReferenciaModel) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }

    return new Promise(resolve => {
      this.post(APIENDPOINT.GuardarMenuDetalleArticulo, iDatos, true, token)
        .subscribe(resp => {
          resolve(resp);
        }, error => {
          resolve(error.error);
          console.log('Error', error.error);
        });
    });
  }

  async GuardarMenuDetalleTopping(iDatos: ClsMenuToppingModel) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }

    return new Promise(resolve => {
      this.post(APIENDPOINT.GuardarMenuDetalleTopping, iDatos, true, token)
        .subscribe(resp => {
          resolve(resp);
        }, error => {
          resolve(error.error);
          console.log('Error', error.error);
        });
    });
  }

  async GuardarMenuDetalleToppingArticulo(iDatos: ClsMenuToppingReferenciaModel) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }

    return new Promise(resolve => {
      this.post(APIENDPOINT.GuardarMenuDetalleToppingArticulo, iDatos, true, token)
        .subscribe(resp => {
          resolve(resp);
        }, error => {
          resolve(error.error);
          console.log('Error', error.error);
        });
    });
  }

  async EliminarMenuApp(iDatos: number) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }

      return new Promise(resolve => {
        this.post(APIENDPOINT.EliminarMenuApp, iDatos, true, token)
        .subscribe(resp => {
          resolve(resp);
        }, error => {
          resolve(error.error);
          console.log('Error', error.error);
        });
    });
  }


  async ConsultarMenuApp(empresaId: number) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }
    if (empresaId != 0) {
      return new Promise(resolve => {
        this.get(APIENDPOINT.ConsultarMenuApp + "?EmpresaID=" + empresaId, true, token)
          .subscribe(resp => {
              resolve(resp);
          }, error => {
                  console.log('Error al consultar el menú de venta', error);
            resolve(null);
          });
      });
    }
  }
}
