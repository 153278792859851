import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { LocalizacionService } from '../../services/localizacion.service';

declare var google;

@Component({
    selector: 'app-MapaCrearDireccion',
    templateUrl: './MapaCrearDireccion.component.html',
    styleUrls: ['./MapaCrearDireccion.component.scss'],
})
export class MapaCrearDireccionComponent implements OnInit {
  @Input() apiKey: string = "";
  @Input() GeoNego: string = "";
    @ViewChild('map', { static: true }) mapElement: ElementRef;    
  CargandoMapa: boolean = true;
  PinDireccion: string = "";
  GoogleAutocomplete: any;
  GoogleGeocoder: any;

    public map: any;
    public markers: any[] = [];
    mapInitialised: boolean = false;
  TieneMapaKey: boolean = true;
  MapaZoom: number = 16;

  negocioDireccion: any;
  @Output() eventoDireccion: any = new EventEmitter();

    validation_messages = {
        Direccion: [
            { type: 'required', message: 'El dato es requerido.' }
        ]
    };

  constructor(private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private localizacion: LocalizacionService) {

    }
      
    async ngOnInit() {
      console.log("llega ngOnInit");
      console.log(this.apiKey); 
      
      await this.ListarMapaKey();
    }

    async ListarMapaKey() {        
        try {
            if (this.apiKey == "") {
              const infoListar: any = null;
                if (infoListar != null) {
                    this.apiKey = infoListar;

                    console.log("MapaKey", infoListar);
                    this.loadGoogleMaps();
                } else {
                    this.TieneMapaKey = false;
                }
            } else {
                this.loadGoogleMaps();
            }
        } catch (e) {
            this.TieneMapaKey = false;            
        }
        finally {          
        }
    }

  async loadGoogleMaps() {

    this.addConnectivityListeners();
      debugger;
        if (typeof google == "undefined" || typeof google.maps == "undefined") {
            console.log("online, loading map");
            //Load the SDK
          window['mapInit'] = () => {
                this.initMap();
                //this.initBuscar();
              this.enableMap();
            this.initGeocoder();
          }
          let script = this._renderer2.createElement('script');
          script.type = "text/javascript";
          script.id = "googleMaps";

            if (this.apiKey != "") {
              script.src = 'https://maps.google.com/maps/api/js?key=' + this.apiKey + '&libraries=places&callback=mapInit';
            } else {
              script.src = 'https://maps.google.com/maps/api/js?&libraries=places&callback=mapInit';
            }
          this._renderer2.appendChild(this._document.body, script);
        }
        else {
            console.log("showing map");
            this.initMap();
            //this.initBuscar();
          this.enableMap();
          this.initGeocoder();
        }
    }

  async initMap() {
    console.log("initMap");

    this.mapInitialised = true;

    let geoLocalizacion: any = await this.localizacion.getGeoLocalizacion();
    if (geoLocalizacion.IsSuccess) {
      this.CargandoMapa = false;
      console.log("getCurrentPosition");
      let latLng = new google.maps.LatLng(geoLocalizacion.Latitud, geoLocalizacion.Longitud);

      let mapOptions = {
        center: latLng,
        zoom: this.MapaZoom,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      }

      this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);


     
      if (this.GeoNego != "" && this.GeoNego != "0" && this.GeoNego != null) {
        let infoDireccion: any = document.getElementById('Direccion');
        infoDireccion.value = this.GeoNego;
        this.BuscarDireccion();
      } else {
        this.BuscarCoordenadas(geoLocalizacion.Latitud, geoLocalizacion.Longitud);
      }

      google.maps.event.addListenerOnce(this.map, 'idle', () => {
        console.log("idle");
      });
    } else {
      //this.uiService.ShowAlert(geoLocalizacion.Message, environment.tituloAPP);
    }

        //console.log("initMap");
        //this.mapInitialised = true;         
        //   navigator.geolocation.getCurrentPosition(position => {
        //    console.log("getCurrentPosition");             
        //    let latLng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);

        //    let mapOptions = {
        //        center: latLng,
        //        zoom: 16,
        //        mapTypeId: google.maps.MapTypeId.ROADMAP
        //    }

        //    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);

        //     this.BuscarCoordenadas(position.coords.latitude, position.coords.longitude);


        //    google.maps.event.addListenerOnce(this.map, 'idle', () => {
        //        console.log("idle");
        //    });
        //});
    }

    initBuscar() {
        this.GoogleAutocomplete = new google.maps.places.Autocomplete(document.getElementById('Direccion'));
        google.maps.event.addListener(this.GoogleAutocomplete, 'place_changed', () => {
            const place = this.GoogleAutocomplete.getPlace();
            this.invokeEvent(place);
        });
    }

  invokeEvent(place: any) {
       // this.deleteMarkerAll();
        //this.addMarkerDireccion(place.geometry.location.lat(), place.geometry.location.lng());
        this.negocioDireccion = place;    
    }

    DireccionKeyUp(event: any) {
        this.deleteMarkerAll();       
    }     

    disableMap() {
        console.log("disable map");
    }

    enableMap() {
        console.log("enable map");
    }

    addConnectivityListeners() {
        let onOnline = () => {
            setTimeout(() => {
                if (typeof google == "undefined" || typeof google.maps == "undefined") {
                    this.loadGoogleMaps();
                } else {
                    if (!this.mapInitialised) {
                        this.initMap();
                        //this.initBuscar();
                      this.initGeocoder();
                    }
                    this.enableMap();
                }
            }, 2000);
        };

        let onOffline = () => {
            this.disableMap();
        };
        document.addEventListener('online', onOnline, false);
        document.addEventListener('offline', onOffline, false);
    }

    public addMarkerDireccion(lat: number, lng: number): void {
      let latLng = new google.maps.LatLng(lat, lng);

      this.map.setCenter(latLng);

        if (this.PinDireccion != "") {
            var image = this.PinDireccion;
            let marker = new google.maps.Marker({
                map: this.map,
                animation: google.maps.Animation.DROP,
                position: latLng,
                icon: image,
                draggable: true,
                title: ""
            });
          this.markers.push(marker);

          google.maps.event.addListener(marker, 'dragend', (event) => {
            console.log("dragend direccion", event);
            console.log(event.latLng.lat());
            console.log(event.latLng.lng());
            this.BuscarDireccionDragend(event.latLng.lat(), event.latLng.lng());
          });

        } else {
            let marker = new google.maps.Marker({
                map: this.map,
                animation: google.maps.Animation.DROP,
                position: latLng,
                draggable: true,
                title: ""
            });
          this.markers.push(marker);

          google.maps.event.addListener(marker, 'dragend', (event) => {
            console.log("dragend direccion", event);
            console.log(event.latLng.lat());
            console.log(event.latLng.lng());
            this.BuscarDireccionDragend(event.latLng.lat(), event.latLng.lng());
          });
        }        
    }

    setMapOnAll(map) {
        //hace ciclo sobre los marcadores que hemos guardado en la variable markers
        for (var i = 0; i < this.markers.length; i++) {
            this.markers[i].setMap(map);
        }
    }

    deleteMarkerAll() {
        this.setMapOnAll(null);
        this.markers = [];
    }

  async AceptarDireccion() {
    this.eventoDireccion.emit(this.negocioDireccion);
  }

  //*************************INI GEOCODER DIRECCION*************************//
  initGeocoder() {
    this.GoogleGeocoder = new google.maps.Geocoder();
  }

  async BuscarDireccion() {
    await this.deleteMarkerAll();

    let infoDireccion: any = document.getElementById('Direccion');
    if (infoDireccion.value != "") {
      var Direccion: any = await this.getAddress(infoDireccion.value);
      if (Direccion.IsSuccess) {
        let lat = Direccion.Data.geometry.location.lat();
        let lng = Direccion.Data.geometry.location.lng();
        let Dir = Direccion.Data.formatted_address;

        this.negocioDireccion = Direccion.Data;
        this.AceptarDireccion(); 
        //await this.AsignarDireccionTercero(lat, lng, Dir, Dir, "");

        await this.addMarkerDireccion(lat, lng);
      } else {
        //this.uiService.ShowAlert(Direccion.Message, environment.tituloAPP);
      }
      console.log("Buscar Direccion", Direccion);
    }
  }

  async BuscarCoordenadas(latitud: number, longitud: number) {
    //await this.deleteMarkerAll();

    if (latitud != 0 && longitud != 0) {
      var Direccion: any = await this.getAddressFromLatLng(latitud, longitud);
      if (Direccion.IsSuccess) {
        let lat = Direccion.Data.geometry.location.lat();
        let lng = Direccion.Data.geometry.location.lng();
        let Dir = Direccion.Data.formatted_address;

        this.negocioDireccion = Direccion.Data;
        let infoDireccion: any = document.getElementById('Direccion');
        infoDireccion.value = Dir;
        
        //await this.AsignarDireccionTercero(lat, lng, Dir, Dir, "");

        await this.addMarkerDireccion(lat, lng);
      } else {
        //this.uiService.ShowAlert(Direccion.Message, environment.tituloAPP);
      }
      console.log("Buscar Coordenadas", Direccion);
    }
  }

  async BuscarDireccionDragend(latitud: number, longitud: number) {
    if (latitud != 0 && longitud != 0) {
      var Direccion: any = await this.getAddressFromLatLng(latitud, longitud);
      if (Direccion.IsSuccess) {
        let lat = Direccion.Data.geometry.location.lat();
        let lng = Direccion.Data.geometry.location.lng();
        let Dir = Direccion.Data.formatted_address;
        console.log("Direccion data",Direccion.Data);
        this.negocioDireccion = Direccion.Data;
        let infoDireccion: any = document.getElementById('Direccion');
        infoDireccion.value = Dir;
        this.AceptarDireccion();
        //await this.AsignarDireccionTercero(lat, lng, Dir, Dir, "");

        //await this.addMarkerDireccion(lat, lng);
      } else {
        //this.uiService.ShowAlert(Direccion.Message, environment.tituloAPP);
      }
      console.log("Buscar Coordenadas Dragend", Direccion);
    }
  }

  getAddress(address: string) {
    return new Promise((resolve, reject) => {
      this.GoogleGeocoder.geocode({ 'address': address }, (result, status) => {
        if (status == 'OK') {
          let Respuesta =
          {
            IsSuccess: true,
            Message: "Direccion consultada correctamente.",
            Data: result[0]
          };

          resolve(Respuesta);
        } else {
          let Respuesta =
          {
            IsSuccess: false,
            Message: "GOOGLE",
            Data: status
          };

          resolve(Respuesta);
        }
      }, error => {
        let Respuesta =
        {
          IsSuccess: false,
          Message: "ERROR",
          Data: error.error
        };

        resolve(Respuesta);
      });
    });
  }

  public getAddressFromLatLng(latitud: number, longitud: number) {
    return new Promise((resolve, reject) => {
      var latlng = { lat: latitud, lng: longitud };
      this.GoogleGeocoder.geocode({ 'location': latlng }, (result, status) => {
        if (status == 'OK') {
          let Respuesta =
          {
            IsSuccess: true,
            Message: "Direccion consultada correctamente.",
            Data: result[0]
          };

          resolve(Respuesta);
        } else {
          let Respuesta =
          {
            IsSuccess: false,
            Message: "GOOGLE",
            Data: status
          };

          resolve(Respuesta);
        }
      }, error => {
        let Respuesta =
        {
          IsSuccess: false,
          Message: "ERROR",
          Data: error.error
        };

        resolve(Respuesta);
      });
    });
  }
    //*************************FIN GEOCODER DIRECCION*************************//

}
