export class ClsUsuarioAccesoModel {
  
	public USU_ID: number
  public USU_EMPRESA: number
	public USU_NOMBRE:string
  public USU_LOGIN: string
  public USU_CLAVE: string
  public USU_EMAIL: string
  public USU_ESTADO: string
  public USU_TIPO: string
  public USU_FECHACAMBIACLAVE: Date

}
