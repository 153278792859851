import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MasterModel } from '../models/master.model';
import { BaseService } from './_Base.service';
import { environment } from '../../environments/environment';
import { APIENDPOINT } from '../config/configuration';
import { map } from "rxjs/operators";
import { ClsConfigNegocioModel } from '../models/Web/ClsConfigNegocio.model';
import { ClsAccesoNegocioModel } from '../models/Web/ClsAccesoNegocio.model';
import { ClsTercerosDomicilioModel } from '../models/Web/ClsTercerosDomicilio.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigNegocioService extends BaseService<any, MasterModel> {

  private apiURL: string;
  negoId: number = 0;
  EmpresaId: number = 0;


  constructor(protected _http: HttpClient) {
    super(_http, environment.apiGatewayURL)
    this.apiURL = environment.apiGatewayURL;

    let datos: any = localStorage.getItem('negocio');
    if (datos != null && datos != '') {
      let objNego = new ClsAccesoNegocioModel();
      objNego = JSON.parse(datos);
      this.negoId = objNego[0].NEGO_ID;
      this.EmpresaId = objNego[0].NEGO_EMPRESA;
    }
  }


  async GuardarConfigNegocio(iDatos: ClsConfigNegocioModel) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }
    iDatos.CN_NEGO_ID = this.negoId;
    return new Promise(resolve => {
      this.post(APIENDPOINT.GuardarConfigNegocio, iDatos, true, token)
        .subscribe(resp => {
          resolve(resp);
        }, error => {
          resolve(error.error);
          console.log('Error', error.error);
        });
    });
  }

  async ConsultarConfigNegocio(_token: string) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }

    console.log(this.negoId)
    if (this.negoId != 0) {
      return new Promise(resolve => {
        this.get(APIENDPOINT.ConsultarConfigNegocio + "?NegoID=" + this.negoId, true, token)
          .subscribe(resp => {
            if (resp.IsSuccess) {
              resolve(resp.Data);

            } else {
              resolve(null);
              console.log('Error al consultar configNegocio');
            }
          }, error => {
            resolve(null);
            console.log('Error al consultar configNegocio');
            return null;
          });
      });
    }
  }

  ListarMapaKey(_token: string, mesanje: string, API_KEY: string) {

  }


  async GuardarTerceroDomicilio(iDatos: ClsTercerosDomicilioModel) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }
    return new Promise(resolve => {
      this.post(APIENDPOINT.GuardarTerceroDomicilio, iDatos, true, token)
        .subscribe(resp => {
          resolve(resp);
        }, error => {
          resolve(error.error);
          console.log('Error', error.error);
        });
    });
  }

  async EliminarTerceroDomicilio(iDatos: number) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }
    return new Promise(resolve => {
      this.post(APIENDPOINT.EliminarTerceroDomicilio, iDatos, true, token)
        .subscribe(resp => {
          resolve(resp);
        }, error => {
          resolve(error.error);
          console.log('Error', error.error);
        });
    });
  }


  async ListarTerceroDomicilio() {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }
    return new Promise(resolve => {
      this.get(APIENDPOINT.ListarTerceroDomicilio + "?NegoId=" + this.negoId + "&EmpresaId=" + this.EmpresaId, true, token)
        .subscribe(resp => {
          if (resp.IsSuccess) {
            resolve(resp.Data);
          } else {
            resolve(null);
            console.log('Error al consultar Tercero Domicilio');
          }
        }, error => {
          resolve(null);
          console.log('Error al consultar Tercero Domicilio');
          return null;
        });
    });
  }

  async ConsultarTercero(tipo: string, dato: string) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }
    return new Promise(resolve => {
      this.get(APIENDPOINT.ListarTercero + "?Tipo=" + tipo + "&Dato=" + dato + "&EmpresaId=" + this.EmpresaId, true, token)
        .subscribe(resp => {
          if (resp.IsSuccess) {
            resolve(resp.Data);
          } else {
            resolve(null);
            console.log('Error al consultar Tercero Domicilio');
          }
        }, error => {
          resolve(null);
          console.log('Error al consultar Tercero Domicilio');
          return null;
        });
    });
  }

}
