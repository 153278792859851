import { Component, OnInit } from '@angular/core';
import { ParametrosService } from '../../services/Parametros.service';
import { ClsParametrosModel } from '../../models/Web/ClsParametros.model';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { ClsAccesoNegocioModel } from '../../models/Web/ClsAccesoNegocio.model';
import { ClsTipoPedidoEmpresaConfigModel } from '../../models/Web/ClsTipoPedidoEmpresa.model';
import { TipoPedidoEmpresaService } from '../../services/TipoPedidoEmpresa.service';
import { GeneralesService } from '../../services/_Generales.service';
declare var window: any;


@Component({
  selector: 'app-tipo-pedido',
  templateUrl: './tipo-pedido.component.html',
  styleUrls: ['./tipo-pedido.component.css']
})
export class TipoPedidoComponent implements OnInit {
  listTipoP: ClsParametrosModel[];
  myGroup: FormGroup;
  objNego: ClsAccesoNegocioModel;
  empresaId: number;
  mensajeRespuesta: string = "";
  constructor(private _paramService: ParametrosService, private tipoPedidoService: TipoPedidoEmpresaService, private generales: GeneralesService) {

  }
  
  async ngOnInit() {
    this.myGroup = new FormGroup({ 
    });
    await this.cargarTipoPedidos();
    for (var i = 0; i < this.listTipoP.length; i++) {
      this.myGroup.addControl(this.listTipoP[i].PARAM_ID + "", new FormControl());
    }
    
    let datosn: any = localStorage.getItem('negocio');
    this.objNego = new ClsAccesoNegocioModel();
    if (datosn != null && datosn != '') {
      this.objNego = JSON.parse(datosn);
    }
    this.empresaId = this.objNego[0].NEGO_EMPRESA;
    await this.ListarTipoPedidosEmpresa();
  }

   
  async cargarTipoPedidos() {
      let resp: any = await this._paramService.ListarParametrosGeneral(52, 1);
      if (resp != null) {
          if (resp.IsSuccess) {
              console.log(resp);
              this.listTipoP = resp.Data;
          }
    } else {
      console.log(resp);
    }
  }

  async GuardarTipoPedidosEmpresa() {
      this.generales.OpenDialogAjax();

    let listaTipoxEmpresa: ClsTipoPedidoEmpresaConfigModel[] = [];    
    for (const field in this.myGroup.controls) { // 'field' is a string
      const control = this.myGroup.get(field); // 'control' is a FormControl
      let tipoxEmpresa: ClsTipoPedidoEmpresaConfigModel = new ClsTipoPedidoEmpresaConfigModel();
      tipoxEmpresa.TP_EMPRESA_ID = this.empresaId;
      tipoxEmpresa.TP_PARAMETRO_ID = field
      if (control.value == true) {
        tipoxEmpresa.TP_ESTADO = "A";
      } else {
        tipoxEmpresa.TP_ESTADO = "N";
      }      
      listaTipoxEmpresa.push(tipoxEmpresa);      
    }
    let resp: any = await this.tipoPedidoService.GuardarTipoPedidosEmpresa(listaTipoxEmpresa);
    this.mensajeRespuesta = resp.Message;
    window.$('#myModal').modal('show');

      this.generales.CancelDialogAjax();
  }

  async ListarTipoPedidosEmpresa() {
    let resp: any = await this.tipoPedidoService.ConsultarTipoPedidosEmpresa('', this.empresaId);
    let listaTipoxEmpresa: ClsTipoPedidoEmpresaConfigModel[] = [];
    listaTipoxEmpresa = resp;
    if (resp != null) {
      for (var i = 0; i < listaTipoxEmpresa.length; i++) {
        const control = this.myGroup.get(listaTipoxEmpresa[i].TP_PARAMETRO_ID);
        if (listaTipoxEmpresa[i].TP_ESTADO == "A") {
          control.setValue(true);
        }
        else {
          control.setValue(false);
        }
      }
    }
  }
}
