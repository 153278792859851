import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HeroesService, Heroe } from '../../services/heroes.service';

@Component({
  selector: 'app-heroe',
  templateUrl: './heroe.component.html',
  styleUrls: ['./heroe.component.css']
})
export class HeroeComponent implements OnInit {
    heroe: any = {};

    constructor(private _activatedRoute: ActivatedRoute, private _heroesService: HeroesService) {
        this._activatedRoute.params.subscribe(data => {
            console.log(data["id"]);
            this.heroe = _heroesService.getHeroe(data["id"]);
        });
    }

    ngOnInit() {
        
  }
}
