import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tarjetas',
  templateUrl: './tarjetas.component.html',
  styleUrls: ['./tarjetas.component.css']
})
export class TarjetasComponent implements OnInit {
    @Input() items: any[] = [];

    constructor(private _router: Router) { }

  ngOnInit() {
  }

    verArtista(item: any) {
        console.log(item);

        let artistaId: number;

        if (item.type === "artist") {
            artistaId = item.id;
        } else {
            if (item.type === "album") {
                artistaId = item.artists[0].id;
            }
        }

        this._router.navigate(['/artista', artistaId]);
    }
}
