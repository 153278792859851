import { Component, OnInit } from '@angular/core';
import { EmoticonesService } from '../../services/_Emoticones.service';

@Component({
  selector: 'app-emoticones',
  templateUrl: './emoticones.component.html',
  styleUrls: ['./emoticones.component.css']
})
export class EmoticonesComponent implements OnInit {

    constructor(private _emoticonesService: EmoticonesService) { }

  ngOnInit() {
  }

    validaGenerarEmoticon(objEmoticon) {
        this._emoticonesService.generarEvento_MostrarEmoticones(true, objEmoticon);
    }
}
