import { Component, OnInit } from '@angular/core';
import { SignalRService } from '../../services/SignalR/signalR.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/_Auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl, FormGroup } from '@angular/forms';
import { ClsAccesoNegocioModel } from '../../models/Web/ClsAccesoNegocio.model';
import { ClsZonaDomicilioModel } from '../../models/Aplicacion/ClsZonaDomicilio.model';
import { GeneralesService } from '../../services/_Generales.service';
import { ParametrosService } from '../../services/Parametros.service';
import { ZonaDomicilioService } from '../../services/ZonaDomicilio.service';
import { CONNREFUSED } from 'dns';
import { debug } from 'console';
import { retry } from 'rxjs/operators';

@Component({
  selector: 'app-zona-domicilio',
  templateUrl: './zona-domicilio.component.html',
  styleUrls: ['./zona-domicilio.component.css']
})
export class ZonaDomicilioComponent implements OnInit {
  Listado_Negocios: ClsAccesoNegocioModel[];
  Listado_Parametros: any;
  Listado_Zonas_Negocio: ClsZonaDomicilioModel[];
  frmNegocio: FormGroup;

  EmpresaID: number = 0;
  NegocioID: number = 0;
  UsuarioID: number = 0;

  constructor(
    public auth: AuthService,
    private signalR: SignalRService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private generales: GeneralesService,
    private parametros: ParametrosService,
    private ZonaDomicilioService: ZonaDomicilioService,
  ) {

  }

  async ngOnInit() {
    this.frmNegocio = new FormGroup({
      'negocioId': new FormControl(),
      'zonaId': new FormControl(),
      'valorDomicilio': new FormControl()
    });

    this.frmNegocio.get('valorDomicilio').setValue("0");

    await this.AsignarNegocios();
    await this.ListarParametrosZona();
    await this.ListarZonasNegocio();
  }

  async EliminarZonaDomicilio(zona: any) {
    console.log("Eliminar", zona);
    try {
      this.generales.OpenDialogAjax();

      let resp: any = await this.ZonaDomicilioService.ZonaDomicilioEliminar(zona);
      if (resp != null) {
        if (resp.IsSuccess) {
          this.generales.MostrarMensajeOK(resp.Message);
          this.frmNegocio.get('valorDomicilio').setValue("0");

          await this.ListarZonasNegocio();
        } else {
          this.generales.MostrarMensajeERROR(resp.Message);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.generales.CancelDialogAjax();
    }
  }

  async AsignarNegocios() {
    let datos: any = localStorage.getItem('sesion');
    if (datos != null && datos != '') {
      this.Listado_Negocios = [];
      var info = JSON.parse(datos);
      this.Listado_Negocios = info.LISTADO_NEGOCIO;

      let actual: any = JSON.parse(localStorage.getItem('negocio'));
      this.frmNegocio.get('negocioId').setValue(actual[0].NEGO_ID);

      this.EmpresaID = actual[0].NEGO_EMPRESA;
      this.NegocioID = actual[0].NEGO_ID;
      this.UsuarioID = info.USU_ID;
    }
  }

  async InicializarFormulario() {
    this.frmNegocio.get('negocioId').setValue(this.NegocioID);
    this.frmNegocio.get('valorDomicilio').setValue("0");
  }


  async ListarParametrosZona() {
    try {
      this.generales.OpenDialogAjax();

      let resp: any = await this.parametros.ListarParametrosGeneral(62, this.EmpresaID);
      if (resp != null) {
        if (resp.IsSuccess) {
          let defaultID = "";
          this.Listado_Parametros = resp.Data;
          if (this.Listado_Parametros.length > 0) {
            for (var i = 0; i < this.Listado_Parametros.length; i++) {
              if (this.Listado_Parametros[i].PARAM_DEFAULT) {
                defaultID = this.Listado_Parametros[i].PARAM_ID;
              }
            }

            if (defaultID == "") {
              defaultID = this.Listado_Parametros[0].PARAM_ID;
            }
          }

          this.frmNegocio.get('zonaId').setValue(defaultID);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.generales.CancelDialogAjax();
    }
  }

  async GuardarZonaNegocio() {
    try {
      this.generales.OpenDialogAjax();

      let ZonaID = this.frmNegocio.get('zonaId').value;
      if (ZonaID == null || ZonaID == "" || ZonaID == "0") {
        this.generales.MostrarMensajeERROR("Por favor verifique la zona.");
        return;
      }

      let Valor = this.frmNegocio.get('valorDomicilio').value;
      //if (Valor == null || Valor == "" || Valor == "0") {
      //  this.generales.MostrarMensajeERROR("Por favor verifique el valor.");
      //  return;
      //}

      let NegoId = this.frmNegocio.get('negocioId').value;

      let ZonaDomicilio: ClsZonaDomicilioModel = new ClsZonaDomicilioModel();
      ZonaDomicilio.ZD_ID = 0;
      ZonaDomicilio.ZD_NEGO_ID = NegoId;
      ZonaDomicilio.ZD_PARAM_ID = ZonaID;
      ZonaDomicilio.ZD_VALOR = Valor;
      ZonaDomicilio.ZD_ESTADO = "A";
      ZonaDomicilio.ZD_USU_ID = this.UsuarioID;

      let resp: any = await this.ZonaDomicilioService.ZonaDomicilioGuardar(ZonaDomicilio);
      if (resp != null) {
        if (resp.IsSuccess) {
          this.generales.MostrarMensajeOK(resp.Message);
          this.frmNegocio.get('valorDomicilio').setValue("0");

          await this.ListarZonasNegocio();
        } else {
          this.generales.MostrarMensajeERROR(resp.Message);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.generales.CancelDialogAjax();
    }
  }

  async ListarZonasNegocio() {
    try {
      let NegoId = this.frmNegocio.get('negocioId').value;
      let resp: any = await this.ZonaDomicilioService.ZonaDomicilioListar(NegoId);
      if (resp != null) {
        if (resp.IsSuccess) {
          this.Listado_Zonas_Negocio = resp.Data;
        } else {
          this.generales.MostrarMensajeERROR(resp.Message);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
}


