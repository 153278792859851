import { DatePipe } from '@angular/common';
import { DecimalPipe } from '@angular/common';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
declare var window: any;

@Injectable({
    providedIn: 'root'
})
export class GeneralesService {
    mostrarMenu: boolean = null;

    constructor(
        private datePipe: DatePipe,
        private decimalPipe: DecimalPipe,
        @Inject(DOCUMENT) private document: Document,
    ) {

    }
    formatoFecha(fecha: any) {
        return this.datePipe.transform(fecha, 'yyyy-MM-dd');
    }

    formatoDecimal(val: any, cantDecimal: number) {
        return this.decimalPipe.transform(val, '1.' + cantDecimal + '-' + cantDecimal);
    }

    formatoBotonDegradado(Ini: string, Fin: string) {
        return "linear-gradient(100deg, " + Ini + " 0, " + Fin + " 100%)";
    }

    formatoTextoDegradado(Ini: string, Fin: string) {
        let style =
            "background-color: #FFFFFF;" +
            "background-image: linear-gradient(45deg, " + Ini + ", " + Fin + ");" +
            "background-size: 100%;" +
            "background-repeat: repeat;" +
            "-webkit-background-clip: text;" +
            "-webkit-text-fill-color: transparent;" +
            "-moz-background-clip: text;" +
            "-moz-text-fill-color: transparent;";

        return style;
    }

    async PasarFoco(NombreControl: string, RequiereEnter: boolean, data: any) {
        console.log("PasarFoco", data);
        if (RequiereEnter) {
            if (data.keyCode == 13) {
                this.document.getElementById(NombreControl).focus();
            }
        } else {
            this.document.getElementById(NombreControl).focus();
        }
    }

    OpenDialogAjax() {
        window.$('#LoadingModal').modal('show');
    }

    CancelDialogAjax() {
        window.$('#LoadingModal').modal('hide');
    }


    async MostrarMensajeOK(mensaje: string) {
        window.$('#lblMensajeOK').text(mensaje);
        window.$('#modalMensajeOK').modal('show');
    }

    async MostrarMensajeERROR(mensaje: string) {
        window.$('#lblMensajeERROR').text(mensaje);
        window.$('#modalMensajeERROR').modal('show');
    }
}
