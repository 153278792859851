import { Component, OnInit } from '@angular/core';
import { CalificarService } from '../../services/_Calificar.service';

@Component({
    selector: 'app-Calificar',
    templateUrl: './Calificar.component.html',
    styleUrls: ['./Calificar.component.css']
})
export class CalificarComponent implements OnInit {

    constructor(private _calificarService: CalificarService) { }

  ngOnInit() {
  }

    validaGenerarCalificacion(objCalificacion) {
        this._calificarService.generarEvento_Calificar(true, objCalificacion);
    }

}
