import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from, of, Observable, BehaviorSubject, combineLatest, throwError } from 'rxjs';
import { tap, catchError, concatMap, shareReplay } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';
import { ClsAccesoModel } from '../models/Web/ClsAcceso.model';
import { AccesoService } from './acceso.service';
import { HomeComponent } from "../components/home/home.component"
import { NavbarComponent } from '../components/shared/navbar/navbar.component';
import { OpcionesService } from './opciones.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  mostrar_Barra_Menu: boolean = true;
  chat_loggedIn: boolean = null;//Bool para controlar si el chat de los visitantes ya inicio sesion
  ingresar: boolean = null;
  loggedIn: boolean = null;//Bool para controlar si los agetes ya iniciaron sesion en el sistema
  //ipAddress: string
  respuesta: string = "";
  constructor(private _accesoService: AccesoService, private _router: Router, private http: HttpClient, private opcService: OpcionesService) {
    if (this._router.url != '' && this._router.url != 'home') {
      if (localStorage.getItem('sesion') != null && localStorage.getItem('sesion') != '' && localStorage.getItem('sesion') != undefined) {
        this.loggedIn = true;
      }
    }
  }

  async Ingresar(objacceso: ClsAccesoModel) {
    //let ip: any = await this.getIP();
    //this.ipAddress=(ip.ip)
    let resp: any = await this._accesoService.VerificarLogin(objacceso);
    if (resp != null) {

      if (resp.IsSuccess) {
        this.opcService.opcionesUsu = resp.Data.LISTADO_OPCIONES;
        this.opcService.ip = resp.Data.IP;
        this.opcService.nombreusuario = resp.Data.USU_NOMBRE;
        this.ingresar = true;

        await this.AsignarInformacionSesionStorage(resp.Data);

        //this._router.navigate(['ppal']);
      } else {
        this.loggedIn = false;
        this.ingresar = false;
        this.respuesta = resp.Message;
      }
    } else {
      this.loggedIn = false;
      this.ingresar = false;
      this.respuesta = "No es posible crear una conexión, intente en unos minutos.";
    }
  }

  async AsignarInformacionSesionStorage(data) {
    await localStorage.setItem('sesion', JSON.stringify(data));
    await localStorage.setItem('negocio', JSON.stringify(data.LISTADO_NEGOCIO));
    await localStorage.setItem('opciones', JSON.stringify(data.LISTADO_OPCIONES));
    await localStorage.setItem('ip', data.IP);
    await localStorage.setItem('token', data.TOKEN);
  }

  async asignarNegocio(fecha, nombre) {
    this.opcService.fecha = fecha;
    this.opcService.nombre = nombre;
  }

  async asingarVariables(opciones, fecha, nombre, usuario) {

    this.opcService.opcionesUsu = opciones;
    this.opcService.fecha = fecha;
    this.opcService.nombre = nombre;
    this.opcService.nombreusuario = usuario;
    //let ip: any = await this.getIP();
    //this.ipAddress = (ip.ip)
  }

  Salir() {
    this.loggedIn = false;
    localStorage.setItem('sesion', "");
    localStorage.setItem('negocio', "");
    localStorage.setItem('opciones', "");
    localStorage.setItem('token', "");
    localStorage.setItem('tercero', "");
    this.ingresar = false;
  }

  public getIPAddress() {
    return this.http.get("http://api.ipify.org/?format=json");
  }

  getIP() {
    return new Promise(resolve => {
      this.http.get("http://api.ipify.org/?format=json")
        .subscribe(resp => {
          resolve(resp);
        }, error => {
          resolve(error.error);
          //console.log('Error', error.error);
        });
    });
  }
}
