export class ClsMenuDetReferenciaModel {
  public DA_ID: number;
  public DA_MENU_DETALLE_ID: number;
  public DA_ARTICULO_ID: number;
  public DA_NOMBRE:string;
  public DA_DESCRIPCION: string;
  public DA_URL_IMAGEN: string;
  public DA_ORDEN: number;
  public DA_ESTADO: string;
  public DA_USU_ID: number;
  public DA_IP_EQUIPO: string;
  public EMPRESA_ID: number;
  public REF_CODIGO: string;
  public REF_DESCRIPCION: string;
}
