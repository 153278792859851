export class ClsTercerosDireccionesModel {
    public DT_ID: number;

    public DT_TERCERO_ID: number;

    public DT_GEO_LATITUD: number;

    public DT_GEO_LONGITUD: number;

    public DT_GEO_DIRECCION: string;

    public DT_DIRECCION: string;

    public DT_INDICACIONES: string;

    public DT_FAVORITA: boolean;

    public DT_REGISTRADA: boolean;

    public DT_IP_EQUIPO: string = "";

    public DT_SELECCIONADA: boolean = false;
}
