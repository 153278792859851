export class ClsPedidoEstadoModel {
    public NegoId: number
    public PedidoId: number
    public UsuarioId: number
    public IPEquipo: string
    public EstadoActual: string
    public MedioPago: string
    public MedioPagoOnline: boolean
    public PARAM_ID_PAGO: number
    public Pedido: any
}
