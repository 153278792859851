import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { MasterModel } from '../models/master.model';
import { BaseService } from './_Base.service';
import { environment } from '../../environments/environment';
import { APIENDPOINT } from '../config/configuration';
import { ClsConfigNegocioModel } from '../models/Web/ClsConfigNegocio.model';
import { ConfigNegocioComponent } from '../components/config-negocio/config-negocio.component';

@Injectable({
  providedIn: 'root'
})
export class FileUpload2Service extends BaseService<FormData, MasterModel> {

  private apiURL: string;
  public progress: number;
  public message: string;
  public urlfile: string;

    constructor(protected _http: HttpClient) {
        super(_http, environment.apiGatewayURL)
        this.apiURL = environment.apiGatewayURL;
    }
   

  public uploadFile = (files, accion) => {
    this.progress = 0;
    if (files.length === 0) {
      return;
    }

    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);    
    this.cargararchivom(formData,accion);
  }

  async cargararchivom(archivo, accion) {
    let resp: any = await this.CargarArchivoImagen(archivo);
    this.urlfile = resp;
    if (accion == 'ImgPromo') {     
      
    }
  }

  CargarArchivoImagen(formData: FormData) {
    return new Promise(resolve => {
      this._http.post(this.apiURL + APIENDPOINT.CargarArchivo, formData, { reportProgress: true, observe: 'events' })
        .subscribe(event => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progress = Math.round(100 * event.loaded / event.total);
          }
          else if (event.type === HttpEventType.Response) {
            let url: any = event.body;
            resolve(url.dbPath);
            this.message = 'Exitoso';
          }
        });
    });
  }

}
