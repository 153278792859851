import { ClsMenuDetReferenciaModel } from "./ClsMenuDetReferencia.model";
import { ClsMenuToppingModel } from "./ClsMenuTopping.model";

export class ClsMenuDetalleModel {
  public DET_ID: number;
  public DET_MENU_ID: number;
  public DET_NOMBRE:string;
  public DET_DESCRIPCION: string;
  public DET_APLICA_VARIOS: string;
  public DET_TIENE_TOPPINGS: string;
  public DET_URL_IMAGEN: string;
  public DET_ORDEN: number;
  public DET_ESTADO: string;
  public DET_USU_ID: number;
  public DET_IP_EQUIPO: string;
  public DET_VALOR_INFO_TEXTO: string;
  public DET_VALOR_INFO: number;
  public ARTICULOS: ClsMenuDetReferenciaModel[];
  public TOPPINGS: ClsMenuToppingModel[];  
  public EMPRESA_ID: number;
}

