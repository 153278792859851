import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ClsReferenciaModel } from '../../models/Web/ClsReferencia.model';
import { ConfigEmpresaService } from '../../services/ConfigEmpresa.service';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';


@Component({
  selector: 'app-referencias',
  templateUrl: './referencias.component.html',
  styleUrls: ['./referencias.component.css']
})
export class ReferenciasComponent implements OnInit {
  
  referencias: ClsReferenciaModel[] = []
  @Output() eventoReferencias: any = new EventEmitter();
  empresaId: number;
  negoId: number= 317;
  refSelect: ClsReferenciaModel = new ClsReferenciaModel();
  txtRefCodigo = new FormControl();
  txtRefId= new FormControl();
  txtRefDescripcion = new FormControl();
  filteredStreets: Observable<ClsReferenciaModel[]>;
  datoVacio: boolean = true;
  descripcion: string = "";



  constructor(private empresaService: ConfigEmpresaService) {
    let datosn: any = localStorage.getItem('negocio');
    if (datosn != null && datosn != '') {
      let objNego: any = JSON.parse(datosn);
      this.empresaId = objNego[0].NEGO_EMPRESA;
    }
  }
  

  async  ngOnInit() {
   
  }

  async EscribeTexto(data: any) {
    console.log("referencia", data.srcElement.value);
    if (data.srcElement != null) {
      if (data.srcElement.value != "") {
        this.datoVacio = false;
        if (data.srcElement.value.length >= 3) {
          
          let resp: any = await this.empresaService.ConsultarReferenciasEmpresaDescripcion(this.empresaId, this.negoId, data.srcElement.value, 20);
         
          this.referencias = resp;
        } else {
          this.referencias = [];
        }
      } else {
        this.datoVacio = true;
      }
        
    }
  }

  async seleccionaReferencia(data:any) {
    console.log(data);
    this.referencias = [];
    this.descripcion = "";//data.REF_DESCRIPCION;
    
    await this.eventoReferencias.emit(data);
  }

  getPosts(ref: any) {
    this.refSelect = ref;
    this.seleccionar(ref);
  }

  displayFn(user: ClsReferenciaModel): string {
    if (user != null) {
      this.refSelect = user;
      //this.eventoReferencias.emit(user);
    }    
    return user && user.REF_CODIGO ? user.REF_CODIGO : '';
  }

 
  private _filter(value: string): ClsReferenciaModel[] {
    const filterValue = this._normalizeValue(value);
    return this.referencias.filter(street => this._normalizeValue(street.REF_DESCRIPCION).includes(filterValue));

  }

  private _normalizeValue(value: string): string {
    if (typeof value === 'string') {
      return value.toLowerCase().replace(/\s/g, '');
    } 
  }

  async seleccionar(todo: any) {
  //  this.eventoReferencias.emit(todo);
  }

  async listarReferencias() {
    let resp: any = await this.empresaService.ConsultarReferenciasEmpresa(this.empresaId);
    console.log(resp);
    if (resp != null) {
      this.referencias = resp;
    }
  }

}
