import { Pipe, PipeTransform, Type } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: "noimage"
})
export class NoimagePipe implements PipeTransform {

    constructor(private _domSanitizer: DomSanitizer) {

    }

    transform(imagenes: any[]): string {
        if (!imagenes) {
            return 'assets/img/noimage.png';
        }

        if (imagenes.length > 0) {
            return imagenes[0].url;
        } else {
            return 'assets/img/noimage.png';
        }
    }

}
