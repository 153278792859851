import { Component, OnInit, ViewChild, EventEmitter, Output, Input, Renderer2, Inject, ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ClsReferenciaModel } from '../../models/Web/ClsReferencia.model';
import { ConfigEmpresaService } from '../../services/ConfigEmpresa.service';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { PedidoCallCenterService } from '../../services/PedidoCallCenter.service';
import { ClsTercerosModel } from '../../models/Aplicacion/ClsTerceros.model';
import { GeneralesService } from '../../services/_Generales.service';
import { ParametrosService } from '../../services/Parametros.service';
import { ClsParametrosModel } from '../../models/Web/ClsParametros.model';
import { ActivatedRoute } from '@angular/router';
import { NegocioService } from '../../services/Negocio.service';
declare var window: any;

@Component({
  selector: 'app-terceroCrear',
  templateUrl: './terceroCrear.component.html',
  styleUrls: ['./terceroCrear.component.css']
})
export class TerceroCrearComponent implements OnInit {
  //********************************************************************************//
  @Output() eventoTercero: any = new EventEmitter();
  key: string = "";
  EmpresaId: number = 0;
  @Input() IconoPlusMostrar: boolean = true;
  @Input() BotonTexto: string = "";
  @Input() TituloTexto: string = "";
  @Input() SubtituloTexto: string = "";
  @Input() Crear_Acceso: boolean = false;

  Listado_Documentos: ClsParametrosModel[] = [];
  Listado_Pais: ClsParametrosModel[] = [];
  Listado_Departamento: ClsParametrosModel[] = [];
  Listado_Ciudad: ClsParametrosModel[] = [];
  Listado_Naturaleza: ClsParametrosModel[] = [];
  Listado_Regimen: ClsParametrosModel[] = [];
  Listado_ResponsabilidadFiscal: ClsParametrosModel[] = [];

  Listado_Genero = [
    { id: "M", Descripcion: 'MASCULINO' },
    { id: "F", Descripcion: 'FEMENINO' }
  ];

  fomulario_Registrarse: FormGroup;

  validation_messages = {
    TipoDocumento: [
      { type: 'required', message: 'Seleccione el tipo de documento.' }
    ],
    NumeroDocumento: [
      { type: 'required', message: 'El dato es requerido.' },
      { type: 'pattern', message: 'El dato no es valido.' }
    ],
    Nombres: [
      { type: 'required', message: 'El dato es requerido.' },
      { type: 'pattern', message: 'El dato no es valido.' }
    ],
    Apellidos: [
      { type: 'required', message: 'El dato es requerido.' },
      { type: 'pattern', message: 'El dato no es valido.' }
    ],
    Genero: [
      { type: 'required', message: 'El dato es requerido.' },
      { type: 'pattern', message: 'El dato no es valido.' }
    ],
    FechaNacimiento: [
      { type: 'required', message: 'El dato es requerido.' },
      { type: 'pattern', message: 'El dato no es valido.' }
    ],
    Pais: [
      { type: 'required', message: 'El dato es requerido.' },
      { type: 'pattern', message: 'El dato no es valido.' }
    ],
    Departamento: [
      { type: 'required', message: 'El dato es requerido.' },
      { type: 'pattern', message: 'El dato no es valido.' }
    ],
    Ciudad: [
      { type: 'required', message: 'El dato es requerido.' },
      { type: 'pattern', message: 'El dato no es valido.' }
    ],
    Naturaleza: [
      { type: 'required', message: 'El dato es requerido.' },
      { type: 'pattern', message: 'El dato no es valido.' }
    ],
    Regimen: [
      { type: 'required', message: 'El dato es requerido.' },
      { type: 'pattern', message: 'El dato no es valido.' }
    ],
    ResponsabilidadFiscal: [
      { type: 'required', message: 'El dato es requerido.' },
      { type: 'pattern', message: 'El dato no es valido.' }
    ],
    Telefono: [
      { type: 'required', message: 'El dato es requerido.' },
      { type: 'pattern', message: 'El dato no es valido, solo numeros.' },
      { type: 'maxlength', message: 'Sobrepasa los caracteres permitidos.' }
    ],
    Correo: [
      { type: 'required', message: 'El dato es requerido.' },
      { type: 'pattern', message: 'El dato no es valido.' },
      { type: 'email', message: 'El formato no es valido.' }
    ],
    Password: [
      { type: 'required', message: 'Los datos son requeridos.' },
      { type: 'minlength', message: 'Por lo menos 6 caracteres.' },
      { type: 'pattern', message: 'Debe tener letra mayuscula.' },
      { type: 'pattern', message: 'Debe tener letra minuscula.' },
      { type: 'pattern', message: 'Debe tener numeros.' },
      { type: 'pattern', message: 'Debe tener caracter especial.' },
      { type: 'maxlength', message: 'Sobrepasa los caracteres permitidos.' }
    ],
    Password_Confirma: [
      { type: 'required', message: 'Los datos son requeridos.' },
      { type: 'minlength', message: 'Por lo menos 6 caracteres.' },
      { type: 'pattern', message: 'Debe tener letra mayuscula.' },
      { type: 'pattern', message: 'Debe tener letra minuscula.' },
      { type: 'pattern', message: 'Debe tener numeros.' },
      { type: 'pattern', message: 'Debe tener caracter especial.' },
      { type: 'maxlength', message: 'Sobrepasa los caracteres permitidos.' }
    ],
  };

  //********************************************************************************//

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private parametrosService: ParametrosService,
    private generales: GeneralesService,
    private pedidoCallCenterService: PedidoCallCenterService,
    public negocioService: NegocioService,
    private activatedRoute: ActivatedRoute,
  ) {
    
  }

  async ngOnInit() {
    this.InicializarFormulario();

    let datosn: any = localStorage.getItem('negocio');
    if (datosn != null && datosn != '') {
      let objNego: any = JSON.parse(datosn);
      this.EmpresaId = objNego[0].NEGO_EMPRESA;
    }

    if (this.EmpresaId == null || this.EmpresaId == 0) {
      this.activatedRoute.params
        .subscribe(param => {
          console.log("Parametros", param);;
          this.key = param.Key
        });
    }

    if (this.EmpresaId == null || this.EmpresaId == 0) {
      await this.ListarInformacionNegocio();
    }
    await this.CargarParametros();
  }

  async ngAfterViewInit() {

  }

  async ListarInformacionNegocio() {
    await this.generales.OpenDialogAjax();
    try {
      var response: any = await this.negocioService.NegocioPedidoOnlineListar(this.key);
      if (response.IsSuccess) {
        if (response.Data != null) {
          this.EmpresaId = response.Data.NEGO_EMPRESA;
        }
      } else {
        console.log(response.Message);
      }
    } catch (e) {
      console.log(e);
    } finally {
      await this.generales.CancelDialogAjax();
    }
  }

  async InicializarFormulario() {
    this.fomulario_Registrarse = new FormGroup({
      ID: new FormControl("0"),
      TipoDocumento: new FormControl(""),
      NumeroDocumento: new FormControl(""),
      Nombres: new FormControl(""),
      Apellidos: new FormControl(""),
      Genero: new FormControl(""),
      FechaNacimiento: new FormControl(""),
      Pais: new FormControl(""),
      Departamento: new FormControl(""),
      Ciudad: new FormControl(""),
      Naturaleza: new FormControl(""),
      Regimen: new FormControl(""),
      ResponsabilidadFiscal: new FormControl(""),
      Telefono: new FormControl(""),
      Correo: new FormControl(""),
      Password: new FormControl(""),
      Password_Confirma: new FormControl("")
    });

    this.fomulario_Registrarse.controls['TipoDocumento'].setValidators([Validators.required]);
    this.fomulario_Registrarse.controls['NumeroDocumento'].setValidators([Validators.required, Validators.minLength(7)]);
    this.fomulario_Registrarse.controls['Nombres'].setValidators([Validators.required, Validators.minLength(3)]);
    this.fomulario_Registrarse.controls['Apellidos'].setValidators([Validators.required, Validators.minLength(3)]);
    this.fomulario_Registrarse.controls['Genero'].setValidators([Validators.required]);
    this.fomulario_Registrarse.controls['FechaNacimiento'].setValidators([Validators.required]);
    this.fomulario_Registrarse.controls['Pais'].setValidators([Validators.required]);
    this.fomulario_Registrarse.controls['Departamento'].setValidators([Validators.required]);
    this.fomulario_Registrarse.controls['Ciudad'].setValidators([Validators.required]);
    this.fomulario_Registrarse.controls['Naturaleza'].setValidators([Validators.required]);
    this.fomulario_Registrarse.controls['Regimen'].setValidators([Validators.required]);
    this.fomulario_Registrarse.controls['ResponsabilidadFiscal'].setValidators([Validators.required]);
    this.fomulario_Registrarse.controls['Telefono'].setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(14), Validators.pattern('^[0-9.+-]+$')]);

    if (this.Crear_Acceso) {
      this.fomulario_Registrarse.controls['Correo'].setValidators([Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]);
      this.fomulario_Registrarse.controls['Password'].setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(64)]);
      this.fomulario_Registrarse.controls['Password_Confirma'].setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(64)]);
      //this.fomulario_Registrarse.controls['Password'].setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(64), Validators.pattern('^(?=.*[A-Z].*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z].*[a-z].*[a-z])$')]);
      //this.fomulario_Registrarse.controls['Password'].setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(64), Validators.pattern('^(?=.*[A-Z].*[A-Z])$')]);
      //this.fomulario_Registrarse.controls['Password'].setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(64), Validators.pattern('^(?=.*[!@#$&*])$')]);
      //this.fomulario_Registrarse.controls['Password'].setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(64), Validators.pattern('^(?=.*[0-9])$')]);
      //this.fomulario_Registrarse.controls['Password'].setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(64), Validators.pattern('^(?=.*[a-z].*[a-z].*[a-z])$')]);
      //this.fomulario_Registrarse.controls['Password_Confirma'].setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(64), Validators.pattern('^(?=.*[A-Z].*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z].*[a-z].*[a-z])$')]);
    } else {
      this.fomulario_Registrarse.controls['Correo'].setValidators([Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]);
    }

    this.fomulario_Registrarse.get("Genero").setValue("M");
    this.fomulario_Registrarse.get("FechaNacimiento").setValue(this.generales.formatoFecha(new Date()));
  }

  async CargarParametros() {
    await this.generales.OpenDialogAjax();

    const paramTipoDoc: any = await this.parametrosService.ListarParametrosGeneral(4, 0);
    if (paramTipoDoc != null) {
      if (paramTipoDoc.IsSuccess) {
        this.Listado_Documentos = paramTipoDoc.Data;
        console.log("Tipo Documento", this.Listado_Documentos);
        if (this.Listado_Documentos != null) {
          let datoDefault: any = this.Listado_Documentos.filter(data => data.PARAM_DEFAULT == true);
          this.fomulario_Registrarse.get("TipoDocumento").setValue(datoDefault[0].PARAM_ID);
        }
      }
    }

    const paramPaises: any = await this.parametrosService.ListarParametrosGeneral(3, 0);
    if (paramPaises != null) {
      if (paramPaises.IsSuccess) {
        this.Listado_Pais = paramPaises.Data;
        console.log("Paises", this.Listado_Pais);
        if (this.Listado_Pais != null) {
          let datoDefault: any = this.Listado_Pais.filter(data => data.PARAM_DEFAULT == true);
          this.fomulario_Registrarse.get("Pais").setValue(datoDefault[0].PARAM_ID);
        }
      }
    }

    const paramDepartamento: any = await this.parametrosService.ListarParametrosGeneral(41, 0);
    if (paramDepartamento != null) {
      if (paramDepartamento.IsSuccess) {
        this.Listado_Departamento = paramDepartamento.Data;
        console.log("Departamento", this.Listado_Departamento);
        if (this.Listado_Departamento != null) {
          let datoDefault: any = this.Listado_Departamento.filter(data => data.PARAM_DEFAULT == true);
          this.fomulario_Registrarse.get("Departamento").setValue(datoDefault[0].PARAM_ID);
        }
      }
    }

    const paramCiudad: any = await this.parametrosService.ListarParametrosGeneral(1, 0);
    if (paramCiudad != null) {
      if (paramCiudad.IsSuccess) {
        this.Listado_Ciudad = paramCiudad.Data;
        console.log("Ciudad", this.Listado_Ciudad);
        if (this.Listado_Ciudad != null) {
          let datoDefault: any = this.Listado_Ciudad.filter(data => data.PARAM_DEFAULT == true);
          this.fomulario_Registrarse.get("Ciudad").setValue(datoDefault[0].PARAM_ID);
        }
      }
    }

    const paramNaturaleza: any = await this.parametrosService.ListarParametrosGeneral(47, 0);
    if (paramNaturaleza != null) {
      if (paramNaturaleza.IsSuccess) {
        this.Listado_Naturaleza = paramNaturaleza.Data;
        console.log("Naturaleza", this.Listado_Naturaleza);
        if (this.Listado_Naturaleza != null) {
          let datoDefault: any = this.Listado_Naturaleza.filter(data => data.PARAM_DEFAULT == true);
          this.fomulario_Registrarse.get("Naturaleza").setValue(datoDefault[0].PARAM_ID);
        }
      }
    }

    const paramRegimen: any = await this.parametrosService.ListarParametrosGeneral(48, 0);
    if (paramRegimen != null) {
      if (paramRegimen.IsSuccess) {
        this.Listado_Regimen = paramRegimen.Data;
        console.log("Regimen", this.Listado_Regimen);
        if (this.Listado_Regimen != null) {
          let datoDefault: any = this.Listado_Regimen.filter(data => data.PARAM_DEFAULT == true);
          this.fomulario_Registrarse.get("Regimen").setValue(datoDefault[0].PARAM_ID);
        }
      }
    }

    const paramResponsabilidadFiscal: any = await this.parametrosService.ListarParametrosGeneral(50, 0);
    if (paramResponsabilidadFiscal != null) {
      if (paramResponsabilidadFiscal.IsSuccess) {
        this.Listado_ResponsabilidadFiscal = paramResponsabilidadFiscal.Data;
        console.log("ResponsabilidadFiscal", this.Listado_ResponsabilidadFiscal);
        if (this.Listado_ResponsabilidadFiscal != null) {
          let datoDefault: any = this.Listado_ResponsabilidadFiscal.filter(data => data.PARAM_DEFAULT == true);
          this.fomulario_Registrarse.get("ResponsabilidadFiscal").setValue(datoDefault[0].PARAM_ID);
        }
      }
    }

    await this.generales.CancelDialogAjax();
  }

  async NuevoRegistro() {
    this.InicializarFormulario();
  }

  async AbrirCrearTercero() {
    window.$('#modalCrearTercero').modal('show');
    this.generales.PasarFoco("NumeroDocumento", false, null);
  }

  async CerrarCrearTercero() {
    window.$('#modalCrearTercero').modal('hide');
  }

  async ValidaGuardarCliente() {
    try {
      await this.generales.OpenDialogAjax();

      let Tercero = new ClsTercerosModel();
      Tercero.TER_ID = this.fomulario_Registrarse.get('ID').value;
      Tercero.TER_TIPODOCUMENTO_ID = this.fomulario_Registrarse.get('TipoDocumento').value;
      Tercero.TER_IDENTIFICACION = this.fomulario_Registrarse.get('NumeroDocumento').value;
      Tercero.TER_NOMBRES = this.fomulario_Registrarse.get('Nombres').value;
      Tercero.TER_APELLIDOS = this.fomulario_Registrarse.get('Apellidos').value;

      Tercero.TER_GENERO = this.fomulario_Registrarse.get('Genero').value;
      let GeneroDescrip = this.Listado_Genero.filter(data => data.id == Tercero.TER_GENERO);
      if (GeneroDescrip != null) {
        Tercero.TER_GENERO_DESCRIP = GeneroDescrip[0].Descripcion;
      }

      Tercero.TER_NACIMIENTO = this.generales.formatoFecha(this.fomulario_Registrarse.get('FechaNacimiento').value);
      Tercero.TER_PAIS_ID = this.fomulario_Registrarse.get('Pais').value;
      Tercero.TER_DEPARTAMENTO_ID = this.fomulario_Registrarse.get('Departamento').value;
      Tercero.TER_CIUDAD_ID = this.fomulario_Registrarse.get('Ciudad').value;
      Tercero.TER_NATURALEZA_ID = this.fomulario_Registrarse.get('Naturaleza').value;
      Tercero.TER_REGIMEN_ID = this.fomulario_Registrarse.get('Regimen').value;
      Tercero.TER_RESPONSABILIDADFISCAL_ID = this.fomulario_Registrarse.get('ResponsabilidadFiscal').value;
      Tercero.TER_TELEFONO = this.fomulario_Registrarse.get('Telefono').value;
      Tercero.TER_CORREO = this.fomulario_Registrarse.get('Correo').value;

      Tercero.TER_EMPRESA_ID = this.EmpresaId;
      Tercero.TER_CLIENTE_PAGA = true;

      if (this.Crear_Acceso) {
        Tercero.TER_ACCESO = true;
        Tercero.TER_PASSWORD = this.fomulario_Registrarse.get('Password').value;
        Tercero.TER_PASSWORD_CONFIRMA = this.fomulario_Registrarse.get('Password_Confirma').value;
      } else {
        Tercero.TER_ACCESO = false;
        Tercero.TER_PASSWORD = "";
        Tercero.TER_PASSWORD_CONFIRMA = "";
      }

      console.log("Tercero", Tercero);

      let guardar: any;
      if (this.Crear_Acceso) {
        guardar = await this.pedidoCallCenterService.CallCenterTerceroGuardarOnline(Tercero);
      } else {
        guardar = await this.pedidoCallCenterService.CallCenterTerceroGuardar(Tercero);
      }
      if (guardar != null) {
        if (guardar.IsSuccess) {
          this.NuevoRegistro();
          await this.CerrarCrearTercero();
          if (this.Crear_Acceso) {
            await this.generales.MostrarMensajeOK(guardar.Message);
          }
          await this.eventoTercero.emit(guardar.Data);
        } else {
          await this.generales.MostrarMensajeERROR(guardar.Message);
        }
      } else {
        await this.generales.MostrarMensajeERROR("Error almacenando la información.");
      }
    } catch (e) {
      //await this.generales.CancelDialogAjax();
    }
    finally {
      await this.generales.CancelDialogAjax();
    }
  }

  async CambiaDocumento(data: any) {
    console.log("CambiaDocumento", data);
    console.log("CambiaDocumento", data.srcElement);
    console.log("CambiaDocumento", data.srcElement.value);
    console.log("CambiaDocumento", data.srcElement.id);
  }

  //async LimpiarControl(data: any) {
  //    console.log("Limpiar control tercero", data.srcElement.value);
  //    //data.srcElement.value = "";
  //    this.Descripcion = "";
  //    this.ExisteCliente = true;
  //}

  ////async EscribeTexto(data: any) {
  ////    console.log("Buscar información tercero", data.srcElement.value);

  ////    this.ExisteCliente = true;

  ////    if (data.srcElement != null) {
  ////        if (data.srcElement.value != "") {

  ////            if (data.srcElement.value.length >= 3) {
  ////                this.BuscandoCliente = true;

  ////                let result: any = await this.pedidoCallCenterService.CallCenterTerceroBuscar(this.EmpresaId, data.srcElement.value);
  ////                if (result != null) {
  ////                    if (result.IsSuccess) {
  ////                        console.log(result.Data);

  ////                        this.ListadoTerceros = result.Data;
  ////                    } else {
  ////                        console.log('Error Tercero Buscar: ' + result.Message);
  ////                    }
  ////                } else {
  ////                    this.ListadoTerceros = [];
  ////                }

  ////                if (this.ListadoTerceros.length <= 0) {
  ////                    this.ExisteCliente = false;
  ////                }

  ////                this.BuscandoCliente = false;
  ////            } else {
  ////                this.ListadoTerceros = [];
  ////                this.BuscandoCliente = false;
  ////            }
  ////        } else {
  ////            this.BuscandoCliente = false;
  ////            this.ListadoTerceros = [];
  ////        }
  ////    } else {
  ////        this.BuscandoCliente = false;
  ////    }
  ////}

  //async SeleccionaTercero(data: any) {
  //    console.log(data);
  //    this.ListadoTerceros = [];
  //    this.Descripcion = "";

  //    await this.eventoTercero.emit(data);
  //}

  async ValidarTerceroExiste() {
    if (!this.Crear_Acceso) {
      if (this.fomulario_Registrarse.get('NumeroDocumento').value.length > 3) {
        let result: any = await this.pedidoCallCenterService.CallCenterTerceroBuscar(this.EmpresaId, this.fomulario_Registrarse.get('NumeroDocumento').value);
        if (result != null) {
          if (result.IsSuccess) {
            console.log("existe", result.Data);
            if (result.Data.length > 0) {
              this.fomulario_Registrarse.get('TipoDocumento').setValue(result.Data[0].TER_TIPODOCUMENTO_ID);
              this.fomulario_Registrarse.get('NumeroDocumento').setValue(result.Data[0].TER_IDENTIFICACION);
              this.fomulario_Registrarse.get('Nombres').setValue(result.Data[0].TER_NOMBRES);
              this.fomulario_Registrarse.get('Apellidos').setValue(result.Data[0].TER_APELLIDOS);
              this.fomulario_Registrarse.get('Genero').setValue(result.Data[0].TER_GENERO);
              this.fomulario_Registrarse.get('FechaNacimiento').setValue(this.generales.formatoFecha(result.Data[0].TER_NACIMIENTO));
              this.fomulario_Registrarse.get('Pais').setValue(result.Data[0].TER_PAIS_ID);
              this.fomulario_Registrarse.get('Departamento').setValue(result.Data[0].TER_DEPARTAMENTO_ID);
              this.fomulario_Registrarse.get('Ciudad').setValue(result.Data[0].TER_CIUDAD_ID);
              this.fomulario_Registrarse.get('Naturaleza').setValue(result.Data[0].TER_NATURALEZA_ID);
              this.fomulario_Registrarse.get('Regimen').setValue(result.Data[0].TER_REGIMEN_ID);
              this.fomulario_Registrarse.get('ResponsabilidadFiscal').setValue(result.Data[0].TER_RESPONSABILIDADFISCAL_ID);
              this.fomulario_Registrarse.get('Telefono').setValue(result.Data[0].TER_TELEFONO);
              this.fomulario_Registrarse.get('Correo').setValue(result.Data[0].TER_CORREO);
            } else {
              this.fomulario_Registrarse.get('Nombres').setValue("");
              this.fomulario_Registrarse.get('Apellidos').setValue("");
              this.fomulario_Registrarse.get('Genero').setValue("M");
              this.fomulario_Registrarse.get('FechaNacimiento').setValue(this.generales.formatoFecha(new Date()));
              this.fomulario_Registrarse.get('Telefono').setValue("");
              this.fomulario_Registrarse.get('Correo').setValue("");
            }
          }
        }
      }
    }
  }
}
