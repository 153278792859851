export class ClsCancelaPedidoModel {
  public PedidoId: number;
  public UsuId: number;
  public NegoId: number;
  public EmpresaId: number;
  public PedidoKey: number;
  public IpEquipo: string;
  public Motivo: string;
 
}
