import { Component, OnInit, ViewChild, EventEmitter, Output, Input, Renderer2, Inject, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
import { ClsNegocioModel } from '../../models/Aplicacion/ClsNegocio.model';
import { GeneralesService } from '../../services/_Generales.service';
import { ZonaDomicilioService } from '../../services/ZonaDomicilio.service';
import { PedidoCallCenterService } from '../../services/PedidoCallCenter.service';
import { ClsZonaDomicilioModel } from '../../models/Aplicacion/ClsZonaDomicilio.model';

declare var window: any;

@Component({
  selector: 'app-infoNegocio',
  templateUrl: './infoNegocio.component.html',
  styleUrls: ['./infoNegocio.component.css']
})
export class InfoNegocioComponent implements OnInit {
  @Input() Negocio: ClsNegocioModel;
  @Input() Mostar_Tiempo_Entrega: boolean = true;
  @Input() Mostar_Distancia: boolean = true;
  @Input() Mostrar_Valor_Domicilio: boolean = true;
  @Input() Guardar_Valor_Domicilio: boolean = true;
  @Input() Mostar_Zona_Domicilio: boolean = false;

  Listado_Zonas_Negocio: ClsZonaDomicilioModel[];
  txtValorDomi: number;
  frmZona: FormGroup;

  constructor(
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private generales: GeneralesService,
    private pedidoCallCenterService: PedidoCallCenterService,
    private ZonaDomicilioService: ZonaDomicilioService,
  ) {

  }

  async ngOnInit() {
    this.frmZona = new FormGroup({
      'zonaId': new FormControl()
    });
    this.frmZona.get('zonaId').setValue("-2");

    this.txtValorDomi = this.Negocio.Tiempo_Entrega.VALOR_DOMICILIO;
    await this.ListarZonasNegocio();
  }

  CambiarValorDomicilio(Negocio) {
    this.generales.OpenDialogAjax();
    console.log(this.txtValorDomi);
    this.Negocio.Tiempo_Entrega.VALOR_DOMICILIO = parseFloat(this.txtValorDomi.toString());
    this.pedidoCallCenterService.TotalizarItemsCarrito();
    this.generales.CancelDialogAjax();
  }

  async ListarZonasNegocio() {
    try {
      this.generales.OpenDialogAjax();
      if (this.Mostar_Zona_Domicilio) {
        if (this.pedidoCallCenterService.Tipo_Pedido.TP_DESCRIPCION == "PEDIDO_DOMICILIO" || this.pedidoCallCenterService.Tipo_Pedido.TP_DESCRIPCION_TIPO == "PEDIDO_DOMICILIO") {
          this.Negocio.Tiempo_Entrega.ID = -2;//temporal este dato para validar que se seleccione una zona en el pedido online
          this.Negocio.Tiempo_Entrega.VALOR_DOMICILIO = 0;

          let resp: any = await this.ZonaDomicilioService.ZonaDomicilioListar(this.Negocio.NEGO_ID);
          if (resp != null) {
            if (resp.IsSuccess) {
              this.Listado_Zonas_Negocio = resp.Data;
            } else {
              this.generales.MostrarMensajeERROR(resp.Message);
            }
          }
        } else {
          this.Listado_Zonas_Negocio = null;
          this.Negocio.Tiempo_Entrega.ID = 0;//temporal este dato para validar que se seleccione una zona en el pedido online
          this.Negocio.Tiempo_Entrega.VALOR_DOMICILIO = 0;
          this.pedidoCallCenterService.Carrito_Compra.Carrito_Total.Valor_Envio = 0;
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.generales.CancelDialogAjax();
    }
  }

  async AsignarZonaDomicilio(event: any) {
    console.log(event);

    this.Negocio.Tiempo_Entrega.ID = -2;//temporal este dato para validar que se seleccione una zona en el pedido online
    this.Negocio.Tiempo_Entrega.VALOR_DOMICILIO = 0;
    this.pedidoCallCenterService.Carrito_Compra.Carrito_Total.Valor_Envio = 0;

    let zona = parseInt(this.frmZona.get('zonaId').value);

    for (var i = 0; i < this.Listado_Zonas_Negocio.length; i++) {
      if (zona == this.Listado_Zonas_Negocio[i].ZD_ID) {
        this.Negocio.Tiempo_Entrega.ID = this.Listado_Zonas_Negocio[i].ZD_ID;
        this.Negocio.Tiempo_Entrega.VALOR_DOMICILIO = this.Listado_Zonas_Negocio[i].ZD_VALOR;
        this.pedidoCallCenterService.Carrito_Compra.Carrito_Total.Valor_Envio = this.Listado_Zonas_Negocio[i].ZD_VALOR;
        this.pedidoCallCenterService.TotalizarItemsCarrito();
      }
    }
  }
}
