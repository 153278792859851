export class ClsAccesoNegocioModel {
  public NEGO_ID: number;

  public NEGO_NOMBRE: string;

  public NEGO_ULTCIERRE: string;

  public NEGO_EMPRESA: string;

  public NEGO_SISTEMA_LOCAL: string;

  public NEGO_CARACTERES_IMPRIME: string;
  public NEGO_FORMATO_FAC: string;
}
