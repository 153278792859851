export class ClsPedidoModel {
  public Pedido_ID: number = 0;

  public Tipo_Pago: string = "PENDIENTE" //PENDIENTE - EPAYCO - TARJETA_DEBITO - TARJETA_CREDITO - EFECTIVO - PAGO_EN_CAJA

  public Estado_Pago: string = "PAGO_EN_ESPERA"; //PAGO_EN_ESPERA - PAGO_PENDIENTE - PAGO_FINALIZADO_OK

  public Permite_Pagar: boolean = true;

  public Pedido_Key: string = "";

  public Pedido_Fecha: string = "1900-01-01";

  public Pedido_Hora: string = "00:00:00";

  public Pedido_Observacion: string = "";

  public Tipo_Solicitud: string = "";//APP-MOVIL / CALLCENTER / ONLINE
}
