import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EmoticonesService {
    @Output() _escuchaEvento_Emoticones: EventEmitter<any> = new EventEmitter();

    constructor() {

    }

    generarEvento_MostrarEmoticones(Valido:Boolean, Dato: any) {
        this._escuchaEvento_Emoticones.emit(Dato);
    }
}
