import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MasterModel } from '../models/master.model';
import { BaseService } from './_Base.service';
import { environment } from '../../environments/environment';
import { APIENDPOINT } from '../config/configuration';
import { ClsConsultaModel } from '../models/Web/ClsConsulta.model';
import { ClsPedidoEstadoModel } from '../models/Web/ClsPedidoEstado.model';
import { ClsPedidoDomiciliarioModel } from '../models/Web/ClsPedidoDomiciliario.model';
import { ClsCancelaPedidoModel } from '../models/Web/ClsCancelaPedido.model';

@Injectable({
    providedIn: 'root'
})
export class PedidoService extends BaseService<any, MasterModel> {

    private apiURL: string;

    constructor(protected _http: HttpClient) {
        super(_http, environment.apiGatewayURL)
        this.apiURL = environment.apiGatewayURL;
    }


    async ListarPedido(iDatos: ClsConsultaModel) {
        let token = await localStorage.getItem('token');
        if (token == null || token == undefined) {
            token = "";
        }

        return new Promise(resolve => {
            this.post(APIENDPOINT.ConsultarPedidosKanban, iDatos, true, token)
                .subscribe(resp => {
                    resolve(resp);
                }, error => {
                    console.log(error.error);
                    resolve(null);
                });
        });
    }

    async ListarPedidoHistorial(Empresa:number, NegocioID: number, UltimoID: number, Top: number) {
        let token = await localStorage.getItem('token');
        if (token == null || token == undefined) {
            token = "";
        }

        return new Promise(resolve => {
          this.get(APIENDPOINT.ConsultarPedidosKanbanHistorial + "?" + "Empresa=" + Empresa + "&" + "NegocioID=" + NegocioID + "&" + "UltimoID=" + UltimoID + "&" + "Top=" + Top, true, token)
                .subscribe(resp => {
                    resolve(resp);
                }, error => {
                    console.log(error);
                    resolve(null);
                });
        });
    }

    async CambiarEstado(iDatos: ClsPedidoEstadoModel) {
        let token = await localStorage.getItem('token');
        if (token == null || token == undefined) {
            token = "";
        }

        return new Promise(resolve => {
            this.post(APIENDPOINT.CambiarEstadoPedido, iDatos, true, token)
                .subscribe(resp => {
                    resolve(resp);
                }, error => {
                    console.log(error.error);
                        resolve(null);
                });
        });
    }

    async ListarDomiciliario(NegoId: number, EmpresaId: number) {
        let token = await localStorage.getItem('token');
        if (token == null || token == undefined) {
            token = "";
        }

        return new Promise(resolve => {
            this.get(APIENDPOINT.ListarDomiciliario + "?NegoId=" + NegoId + "&EmpresaId=" + EmpresaId, true, token)
                .subscribe(resp => {
                    if (resp.IsSuccess) {
                        resolve(resp.Data);
                    } else {
                        resolve(null);
                        console.log('Error al consultar domiciliario');
                    }
                }, error => {
                    console.log(error.error);
                    resolve(null);

                    return null;
                });
        });
    }

    async GuardarPedidoDomicilio(iDatos: ClsPedidoDomiciliarioModel) {
        let token = await localStorage.getItem('token');
        if (token == null || token == undefined) {
            token = "";
        }

        return new Promise(resolve => {
            this.post(APIENDPOINT.GuardarDomiciliario, iDatos, true, token)
                .subscribe(resp => {
                    resolve(resp);
                }, error => {
                    console.log(error.error);
                        resolve(null);
                });
        });
  }

  async ImprimirPedidoFactura(PedidoId: number) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }

    return new Promise(resolve => {
      this.post(APIENDPOINT.ImprimirPedidoFactura, PedidoId, true, token)
        .subscribe(resp => {
          resolve(resp);
        }, error => {
          console.log(error.error);
          resolve(null);
        });
    });
  }

  async CancelarPedidoId(cancelaPedido: ClsCancelaPedidoModel) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }

    return new Promise(resolve => {
      this.post(APIENDPOINT.CancelarPedidoId, cancelaPedido, true, token)
        .subscribe(resp => {
          resolve(resp);
        }, error => {
          console.log(error.error);
          resolve(null);
        });
    });
  }
}
