import { Component, OnInit, ViewChild, EventEmitter, Output, Input, Renderer2, Inject, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ClsNegocioModel } from '../../models/Aplicacion/ClsNegocio.model';
import { GeneralesService } from '../../services/_Generales.service';
import { ClsMenuVentaModel } from '../../models/Aplicacion/ClsMenuVenta.model';
import { PedidoCallCenterService } from '../../services/PedidoCallCenter.service';
import { ClsMenuDetalleModel } from '../../models/Aplicacion/ClsMenuDetalle.model';
declare var window: any;

@Component({
    selector: 'app-menuDetalle',
    templateUrl: './menuDetalle.component.html',
    styleUrls: ['./menuDetalle.component.css']
})
export class MenuDetalleComponent implements OnInit {
    @Input() Negocio: ClsNegocioModel;
    @Input() MenuVenta: ClsMenuVentaModel;

    ListadoMenuDetalle: ClsMenuDetalleModel[] = [];

    @Output() eventoMenuDetalle: any = new EventEmitter();

    CargandoDatos: boolean = true;
    UltimoID: number = 0;
    TopConsulta: number = 50;
    TieneDatos: boolean = true;
    
    constructor(
        private renderer2: Renderer2,
        @Inject(DOCUMENT) private document: Document,
        private pedidoCallCenterService: PedidoCallCenterService,
        private generales: GeneralesService
    ) {
        
    }

    async  ngOnInit() {
        await this.CargarMenuDetalle(this.MenuVenta.MENU_ID);   
    }

    async CargarMenuDetalle(MenuId: number) {
        try {
            //this.generales.OpenDialogAjax();

            this.CargandoDatos = true;

            const infoMenu: any = await this.pedidoCallCenterService.CallCenterMenuDetalleListar(this.Negocio.NEGO_EMPRESA_ID, this.Negocio.NEGO_ID, MenuId, this.UltimoID, this.TopConsulta);
            if (infoMenu != null) {
                if (infoMenu.IsSuccess) {
                    if (infoMenu.Data.length > 0) {
                        this.TieneDatos = true;
                    } else {
                        this.TieneDatos = false;
                    }

                    this.ListadoMenuDetalle = infoMenu.Data;

                    for (var i = 0; i < infoMenu.Data.length; i++) {
                        this.UltimoID = infoMenu.Data[i].DETALLE_ID;

                        console.log("info" + [i], infoMenu.Data[i]);
                        console.log("UltimoID", this.UltimoID);
                    }
                }

                //await this.oGeneral.GuardarStorage("menuVenta_" + "NegocioID_" + this.datosNEGOCIO.NEGO_ID + "_" + environment.API_KEY, this.MenuVenta);
            } else {
                this.generales.MostrarMensajeERROR("No es posible listar la información del menú en este momento.");
            }

            this.CargandoDatos = false;

        } catch (e) {
            this.CargandoDatos = false;
        } finally {
            //this.generales.CancelDialogAjax();
        }
    }

    async SeleccionaMenuDetalle(data: any) {
        console.log("SeleccionaMenuDetalle", data);
        this.eventoMenuDetalle.emit(data);
    }
}
