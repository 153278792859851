import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MasterModel } from '../models/master.model';
import { BaseService } from './_Base.service';
import { environment } from '../../environments/environment';
import { APIENDPOINT } from '../config/configuration';
import { ClsVerificaInicioModel } from '../models/Web/ClsVerificaInicio.model';

@Injectable({
  providedIn: 'root'
})
export class VerificaInicioService extends BaseService<ClsVerificaInicioModel, MasterModel> {

    private apiURL: string;

    constructor(protected _http: HttpClient) {
        super(_http, environment.apiGatewayURL)
        this.apiURL = environment.apiGatewayURL;
    }


  VerificarLogin(iDatos: ClsVerificaInicioModel) {
    return new Promise(resolve => {
      this.post(APIENDPOINT.verificarInicio, iDatos, false,'')
        .subscribe(resp => {
          resolve(resp);
        }, error => {
          resolve(error.error);
          console.log('Error', error.error);
        });
    });
  }

}
