import { Injectable, Output, EventEmitter } from '@angular/core';
import * as signalR from '@microsoft/signalr'
import { ClsAccesoNegocioModel } from '../../models/Web/ClsAccesoNegocio.model';
import { ClsUsuarioAccesoModel } from '../../models/Web/ClsAccesoUsuario.model';
import { HubService } from '../Hub.service';
import { ClsHubModel } from '../../models/Web/ClsHub.model';
import { RouteConfigLoadEnd } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class SignalRService {
    private hubConnection: signalR.HubConnection;
    EventoSignalR: any = new EventEmitter();
    Tiempo_Reconectar = 5000;
    Tiempo_Verificar = 5000;
    EmpresaId: number = 0
    NegoId: number = 0
    usuId: number = 0
    infoHub: ClsHubModel;

    constructor(private hub: HubService) {

    }

    async IniciarSignalR() {
        await this.datosNego();
        await this.ListarInformacionHUB();
    }

    sendMessage(message: any) {
        this.hubConnection.invoke('NewMessage', message);
    }

    private createConnection() {
        this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(this.infoHub.HUB_URL_DOMINIO + this.infoHub.HUB_URL_HUBS)
            .build();
    }

    private startConnection(): void {
        this.hubConnection
            .start()
            .then(() => {
                console.log('Hub connection started');
                this.ConectarHubWeb(this.EmpresaId.toString(), this.NegoId.toString(), this.usuId.toString(), this.infoHub.HUB_NOMBRE_USUARIO, this.infoHub.HUB_PASSWORD);
                this.VerificarEstadoConexion();
            })
            .catch(err => {
                if (this.hubConnection.state != "Connected") {
                    console.log('Error estableciendo conexion con hub, reintentando...');

                    setTimeout(() => {
                        this.startConnection();
                        console.log("Timeout iniciar conexion");
                    }, this.Tiempo_Reconectar);
                } else {
                    console.log('Hub Connection', this.hubConnection.state);
                }
            });

        this.hubConnection.onclose(data => {
            console.log("Controlando close", data);
        });
    }

    private VerificarEstadoConexion() {
        if (this.hubConnection.state != "Connected") {
            this.startConnection();
            return;
        }

        setTimeout(() => {
            this.VerificarEstadoConexion();
            console.log("Timeout verificar estado conexion");
        }, this.Tiempo_Verificar);
    }

    private ConectarHubWeb(Empresa_ID: string, Negocio_ID: string, Usuario_Id: string, TokenUsuName: string, TokenUsuPass: string) {
        this.hubConnection.invoke('ConectarWEB', Empresa_ID, Negocio_ID, Usuario_Id, TokenUsuName, TokenUsuPass);
    }

    private registerOnServerEvents(): void {
        this.hubConnection.on('MessageReceived', (data: any) => {
            let info: any = {};
            info = { tipo: 'MessageReceived', datos: data };
            console.log("MessageReceived", data);
            this.EventoSignalR.emit(info);
        });

        this.hubConnection.on('datosNavegadorKey', (data: any) => {
            let info: any = {};
            info = { tipo: 'datosNavegadorKey', datos: data };
            console.log("datosNavegadorKey", data);
            this.EventoSignalR.emit(info);
        });

        this.hubConnection.on('datosPedidoKey', (data: any) => {
            let info: any = {};
            info = { tipo: 'datosPedidoKey', datos: data };
            console.log("datosPedidoKey", data);
            this.EventoSignalR.emit(info);
        });

        this.hubConnection.on('datosNegocioKey', (data: any) => {
            let info: any = {};
            info = { tipo: 'datosNegocioKey', datos: data };
            console.log("datosNegocioKey", data);
            this.EventoSignalR.emit(info);
        });

        this.hubConnection.on('pedidoCallCenterGeneradoKey', (data: any) => {
            let info: any = {};
            info = { tipo: 'pedidoCallCenterGeneradoKey', datos: data };
            console.log("pedidoCallCenterGeneradoKey", data);
            this.EventoSignalR.emit(info);
        });

      this.hubConnection.on('pedidoCallCenterCanceladoKey', (data: any) => {
        let info: any = {};
        info = { tipo: 'pedidoCallCenterCanceladoKey', datos: data };
        console.log("pedidoCallCenterCanceladoKey", data);
        this.EventoSignalR.emit(info);
      });
    }


    async datosNego() {
        let datos: any = localStorage.getItem('negocio');
        if (datos != null && datos != '') {
            let objNego = new ClsAccesoNegocioModel();
            objNego = JSON.parse(datos);
            this.EmpresaId = objNego[0].NEGO_EMPRESA;
            this.NegoId = objNego[0].NEGO_ID;
        }

        let datos1: any = localStorage.getItem('sesion');
        if (datos1 != null && datos1 != '') {
            let objUsu = new ClsUsuarioAccesoModel();
            objUsu = JSON.parse(datos1);
            this.usuId = objUsu.USU_ID;
        }
    }

    async ListarInformacionHUB() {
        let result: any = await this.hub.ConsultarHub();
        if (result != null) {
            if (result.IsSuccess) {
                this.infoHub = result.Data;
                if (this.infoHub != null ) {
                    if (this.infoHub.HUB_URL_DOMINIO != '' && this.infoHub.HUB_URL_HUBS != '') {
                        await this.createConnection();
                        await this.registerOnServerEvents();
                        await this.startConnection();
                    } else {
                        console.log("URL de hub no esta definida.");
                    }
                } else {
                    console.log("No hay información de hub.");
                }
            } else {
                console.log("Error listando información de hub: " + result.Message);
            }
        }
    }
}
