import { Component, OnInit } from '@angular/core';
import { PedidoService } from '../../services/Pedido.service';
import { ClsConsultaModel } from '../../models/Web/ClsConsulta.model';

import { ClsPedidoModel } from '../../models/Web/Clspedido.model';

import { ClsPedidoEstadoModel } from '../../models/Web/ClsPedidoEstado.model';
import { ClsAccesoNegocioModel } from '../../models/Web/ClsAccesoNegocio.model';
import { ClsUsuarioAccesoModel } from '../../models/Web/ClsAccesoUsuario.model';
import { SignalRService } from '../../services/SignalR/signalR.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { ClsTercerosDomicilioModel } from '../../models/Web/ClsTercerosDomicilio.model';
import { ClsPedidoDomiciliarioModel } from '../../models/Web/ClsPedidoDomiciliario.model';
import { escapeLeadingUnderscores } from 'typescript';
import { GeneralesService } from '../../services/_Generales.service';
import { ClsKanbanPedidoModel } from '../../models/KanbanPedido/ClsKanbanPedido.model'
import { ClsKanbanPedido_DomicilioModel } from '../../models/KanbanPedido/ClsKanbanPedido_Domicilio.model';
import { ClsKanbanPedido_UsuarioModel } from '../../models/KanbanPedido/ClsKanbanPedido_Usuario.model';
import { ClsCancelaPedidoModel } from '../../models/Web/ClsCancelaPedido.model';
declare var window: any;
//declare var crearTicketImpresionWebBase64PDF;

@Component({
  selector: 'app-admin-pedidos',
  templateUrl: './admin-pedidos.component.html',
  styleUrls: ['./admin-pedidos.component.css']
})
export class AdminPedidosComponent implements OnInit {

  ip: string;
  empresa: number;
  negoId: number = 0;
  local: string = "";
  href: string = "";
  usuId: number = 0;
  ultId: number = 0;
  Top: number = 1000;

  listaEspera: ClsKanbanPedidoModel[] = [];//Cambiar el tipo de dato por el nuevo ClsKanbanPedido
  listaPreparacion: ClsKanbanPedidoModel[] = [];//Cambiar el tipo de dato por el nuevo ClsKanbanPedido
  listaReparto: ClsKanbanPedidoModel[] = [];//Cambiar el tipo de dato por el nuevo ClsKanbanPedido
  pedidoSeleccionado: ClsKanbanPedidoModel = null;
  listaCancelados: ClsKanbanPedidoModel[] = [];//Cambiar el tipo de dato por el nuevo ClsKanbanPedido
  listaFacturados: ClsKanbanPedidoModel[] = [];//Cambiar el tipo de dato por el nuevo ClsKanbanPedido

  formaDomicilio: FormGroup;
  listaDomiciliarios: ClsKanbanPedido_DomicilioModel[] = [];
  ////audio = new Audio("../../assets/audio/ube_eats_sound.mp3");
  audio;
  reproducir = true;
  mensaje: string = "";
  domicilioSeleccionado: number = -1;

  Mostrar_Telefono: boolean = false;
  Mostrar_BotonAceptar: boolean = true;
  Pedido_EstadoActual: string = "E";
  Pedido_Index: number = -1;

  Domiciliario_Id: number = -1;
  Domiciliario_Nombre: string = "";
  PedidoFactura: number = -1;

  MotivoCancela: string = "";
  Detalle_Id: string = "";

  tamañoFilaTexto: string = "47";
  formatoFac: string = "4";

  constructor(public pedidoService: PedidoService, private signalR: SignalRService, private router: Router, private generales: GeneralesService) {
    this.signalR.IniciarSignalR();

    this.formaDomicilio = new FormGroup({
      'CboDomiciliarios': new FormControl(this.Domiciliario_Id)
    });

    let datos: any = localStorage.getItem('negocio');
    if (datos != null && datos != '') {
      let objNego = new ClsAccesoNegocioModel();
      objNego = JSON.parse(datos);
      this.negoId = objNego[0].NEGO_ID;
      this.empresa = objNego[0].NEGO_EMPRESA;
      this.local = objNego[0].NEGO_SISTEMA_LOCAL;
      this.tamañoFilaTexto = objNego[0].NEGO_CARACTERES_IMPRIME;
      this.formatoFac = objNego[0].NEGO_FORMATO_FAC;
    }

    let datos1: any = localStorage.getItem('sesion');
    if (datos1 != null && datos1 != '') {
      let objUsu = new ClsUsuarioAccesoModel();
      objUsu = JSON.parse(datos1);
      this.usuId = objUsu.USU_ID;
    }

    this.ip = localStorage.getItem('ip');
    this.escucharEventoSignal();

    this.audio = new Audio("../../assets/audio/notificacion.wav");
    this.audio.load();
    this.audio.canPlayType("audio/*");
    this.reproducir = true;
  }


  async ngOnInit() {
    console.log(this.listaReparto);
    await this.cargarDomiciliarios();

    await this.IniciaListarPedidos();
  }

  async IniciaListarPedidos() {
    this.generales.OpenDialogAjax();
    await this.ListarPedidosEntrega();
    await this.ListarPedidosPreparacion();
    await this.ListarPedidosNuevos();
    await this.ListarPedidosCancelados();
    await this.ListarPedidosFacturados();
    this.generales.CancelDialogAjax();
  }

  //PENDIENTE
  //Cuando llame al destroy del componente inactivar el audio
  //Desconectar del hub

  async ngOnDestroy() {
    console.log("ngOnDestroy AdminPedidosComponent");

    //this.pedidoService = null;
    //this.signalR = null;
    this.reproducir = false;
    this.audio = null;
  }

  abrirModal() {
    window.$('#myModalDetalle').modal('show');
  }

  openDomicilio() {
    window.$('#myModalDomicilio').modal('show');
  }

  escucharEventoSignal() {
    this.signalR.EventoSignalR.subscribe(data => {
      switch (data.tipo) {
        case "datosPedidoKey":
          console.log(data.tipo, data.datos);
          this.ListarPedidosNuevos();
          break;

        case "pedidoCallCenterGeneradoKey":
          console.log(data.tipo, data.datos);
          this.ListarPedidosNuevos();
          break;

        case "pedidoCallCenterCanceladoKey":
          console.log(data.tipo, data.datos);
          this.generales.MostrarMensajeOK("Se ha cancelado el pedido " + data.datos + ".");
          this.pedidosCancelados(data.datos);
          break;
      }
    });
  }

  async pedidosCancelados(pedidoId) {
    let f = -1;
    f = this.listaEspera.findIndex(s => s.Informacion_Pedido.Pedido_ID == pedidoId);
    if (f != undefined && f != -1) {
      this.listaCancelados.push(this.listaEspera[f]);
      this.listaEspera.splice(f, 1);
    } else {
      f = this.listaPreparacion.findIndex(s => s.Informacion_Pedido.Pedido_ID == pedidoId)
      if (f != undefined && f != -1) {
        this.listaCancelados.push(this.listaPreparacion[f]);
        this.listaPreparacion.splice(f, 1);
      } else {
        f = this.listaReparto.findIndex(s => s.Informacion_Pedido.Pedido_ID == pedidoId)
        if (f != undefined && f != -1) {
          this.listaCancelados.push(this.listaReparto[f]);
          this.listaReparto.splice(f, 1);
        }
      }
    }
  }

  async ListarPedidosNuevos() {
    let consulta: ClsConsultaModel = new ClsConsultaModel();
    consulta.NegoId = this.negoId;
    consulta.UltmId = this.ultId;
    consulta.Top = this.Top;
    consulta.Tipo = 'E';
    consulta.Plataforma = this.local;

    let resp: any = await this.pedidoService.ListarPedido(consulta);
    if (resp != null) {
      if (resp.IsSuccess) {
        if (resp.Data != null) {
          for (var i = 0; i < resp.Data.length; i++) {
            let Pedido = resp.Data[i];
            if (Pedido.Informacion_Pedido.Estado_Pedido != "N") {
              this.listaEspera.push(Pedido);
              this.ultId = Pedido.Informacion_Pedido.Pedido_ID;
            }
          }
        }

        this.reproducir = true;
        this.playAudio(0);
      } else {

      }
    } else {

    }
  }

  async ListarPedidosPreparacion() {
    let consulta: ClsConsultaModel = new ClsConsultaModel();
    consulta.NegoId = this.negoId;
    consulta.UltmId = 0;
    consulta.Top = 100;
    consulta.Tipo = 'P';
    let resp: any = await this.pedidoService.ListarPedido(consulta);
    if (resp != null) {
      if (resp.IsSuccess) {
        if (resp.Data != null) {
          for (var i = 0; i < resp.Data.length; i++) {
            let Pedido = resp.Data[i];
            this.listaPreparacion.push(Pedido);
          }
        }
      } else {

      }
    } else {

    }
  }

  async ListarPedidosEntrega() {
    let consulta: ClsConsultaModel = new ClsConsultaModel();
    consulta.NegoId = this.negoId;
    consulta.UltmId = 0;
    consulta.Top = 100;
    consulta.Tipo = 'R';
    let resp: any = await this.pedidoService.ListarPedido(consulta)
    if (resp != null) {
      if (resp.IsSuccess) {
        if (resp.Data != null) {
          for (var i = 0; i < resp.Data.length; i++) {
            let Pedido = resp.Data[i];
            //if (resp.Data[0].Tipo_Pedido.Tipo == "PEDIDO_DOMICILIO") {
            //    this.formaDomicilio.addControl('PD_' + Pedido.Informacion_Pedido.Pedido_ID, new FormControl());
            //}
            this.listaReparto.push(Pedido);
          }
        }
      } else {

      }
    } else {

    }
  }

  async ListarPedidosCancelados() {
    let consulta: ClsConsultaModel = new ClsConsultaModel();
    consulta.NegoId = this.negoId;
    consulta.UltmId = 0;
    consulta.Top = 100;
    consulta.Tipo = 'N';
    let resp: any = await this.pedidoService.ListarPedido(consulta)
    if (resp != null) {
      if (resp.IsSuccess && resp.Data != null) {
        this.listaCancelados = resp.Data;
        console.log("cancelado", this.listaCancelados);
      }
    }
  }

  async ListarPedidosFacturados() {
    let consulta: ClsConsultaModel = new ClsConsultaModel();
    consulta.NegoId = this.negoId;
    consulta.UltmId = 0;
    consulta.Top = 100;
    consulta.Tipo = 'F';
    let resp: any = await this.pedidoService.ListarPedido(consulta)
    if (resp != null) {
      if (resp.IsSuccess && resp.Data != null) {
        this.listaFacturados = resp.Data;
      }
    }
  }

  ImprimirComanda(index, pedidoId) {
    console.log("pe", index);
    //this.crearTicketImpresionWebBase64PDF(this.listaEspera[index].Imprimir[0].ComandaBase64PDF[0]);
    this.crearTicketImpresionWebPDF(this.listaEspera[index].Imprimir[0].ComandaTextoPDF[0]);
  }

  ImprimirComanda1(index, pedidoId) {
    console.log("pp", index);
    // this.crearTicketImpresionWebBase64PDF(this.listaPreparacion[index].Imprimir[0].ComandaBase64PDF[0]);
    this.crearTicketImpresionWebPDF(this.listaEspera[index].Imprimir[0].ComandaTextoPDF[0]);
  }

  ImprimirPrefactura(index, pedidoId) {
    console.log("pr", index);
    //this.crearTicketImpresionWebBase64PDF(this.listaReparto[index].Imprimir[0].FacturaBase64PDF[0]);
    this.crearTicketImpresionWebPDF(this.listaReparto[index].Imprimir[0].FacturaTextoPDF[0]);
  }

  ImprimirPedido() {
    if (this.Pedido_EstadoActual != "R") {
      //this.crearTicketImpresionWebBase64PDF(this.pedidoSeleccionado.Imprimir[0].ComandaBase64PDF[0]);
      this.crearTicketImpresionWebPDF(this.pedidoSeleccionado.Imprimir[0].ComandaTextoPDF[0]);
    } else {
      //this.crearTicketImpresionWebBase64PDF(this.pedidoSeleccionado.Imprimir[0].FacturaBase64PDF[0]);
      this.crearTicketImpresionWebPDF(this.pedidoSeleccionado.Imprimir[0].FacturaTextoPDF[0]);
    }
  }

  crearTicketImpresionWebBase64PDF(Base64PDF: any) {
    var anchoVENTANA = "700";
    var altoVENTANA = "600";

    //var tamaño = "300mm";
    var tamaño = "75mm";
    var margenIzq = "0mm";
    var margenDer = "0mm";
    //if (Impresora == 1 || Impresora == 4 || Impresora == 3) {
    //    tamaño = "75mm";
    //}

    //if (Impresora == 5 || Impresora == 14 || Impresora == 15 || Impresora == 17) {//(58mm)
    //    anchoVENTANA = "300";
    //    altoVENTANA = "500";

    //    tamaño = "48mm";
    //    margenIzq = "0mm";
    //    margenDer = "0mm";
    //}

    var ticketFinal =
      '<canvas style="width: ' + tamaño + '; max-width:' + tamaño + '; margin-left: ' + margenIzq + ';" id="the-canvas"></canvas>' +
      '<script src="../assets/Scripts/build/pdf.js" type="text/javascript"></script>' +
      '<script type="text/javascript">' +
      'var pdfData = atob("' + Base64PDF + '");' +
      'var pdfjsLib;' +
      'if (typeof window !== "undefined" && window["pdfjs-dist/build/pdf"]) {' +
      'pdfjsLib = window["pdfjs-dist/build/pdf"];' +
      '} else {' +
      'pdfjsLib = require("../assets/Scripts//build/pdf.js");' +
      '}' +
      'pdfjsLib.workerSrc = "../assets/Scripts//build/pdf.worker.js";' +
      'var loadingTask = pdfjsLib.getDocument({data: pdfData });' +
      'loadingTask.promise.then(function (pdf) {' +
      'console.log("PDF loaded");' +
      'var pageNumber = 1;' +
      'pdf.getPage(pageNumber).then(function (page) {' +
      'console.log("Page loaded");' +
      'var scale = 3;' +
      'var viewport = page.getViewport(scale);' +
      'var canvas = document.getElementById("the-canvas");' +
      'var context = canvas.getContext("2d");' +
      'canvas.height = viewport.height;' +
      'canvas.width = viewport.width;' +
      'var renderContext = {' +
      'canvasContext: context,' +
      'viewport: viewport' +
      '};' +
      'var renderTask = page.render(renderContext);' +
      'renderTask.then(function () {' +
      'setTimeout(function(){window.print(); window.close();}, 100);' +
      '' +
      '});' +
      '});' +
      '}, function (reason) {' +
      'console.error(reason);' +
      '});' +
      '</script>';

    var myWindow = window.open('', '', 'width=' + anchoVENTANA + ',height=' + altoVENTANA + '');
    myWindow.document.write(ticketFinal);
    myWindow.document.close();
    myWindow.focus();
  }

  obtenerAlinearTextoTabla(alineaTexto) {
    var alinear = "text-align: left";

    if (alineaTexto == "CENTRO") {
      alinear = "text-align: center";
    }
    else {
      if (alineaTexto == "IZQUIERDA") {
        alinear = "text-align: left";
      }
      else {
        if (alineaTexto == "DERECHA") {
          alinear = "text-align: right";
        }
        else {
          if (alineaTexto == "JUSTIFICAR") {
            alinear = "text-align: justify";
          }
          else {
            if (alineaTexto == "JUSTIFICAR_TODO") {
              alinear = "text-align: justify";
            }
          }
        }
      }
    }

    return alinear;
  }
  calcularTamañoFuenteImp(objFUENTE) {

    var tamFuente = 7
    var FuenteImp = this.tamañoFilaTexto;
    if (objFUENTE != 0 && objFUENTE != null && objFUENTE != undefined) {
      FuenteImp = objFUENTE;
    }

    if (FuenteImp == "63") {
      tamFuente = 4.5;
    }
    if (FuenteImp == "61") {
      tamFuente = 5;
    }
    if (FuenteImp == "59") {
      tamFuente = 5.5;
    }
    if (FuenteImp == "55") {
      tamFuente = 6;
    }
    if (FuenteImp == "51") {
      tamFuente = 6.5;
    }
    if (FuenteImp == "47" || FuenteImp == "33") {
      tamFuente = 7;
    }
    if (FuenteImp == "44" || FuenteImp == "34") {
      tamFuente = 7.5;
    }
    if (FuenteImp == "42") {
      tamFuente = 8;
    }
    if (FuenteImp == "40") {
      tamFuente = 8.5;
    }
    if (FuenteImp == "35") {
      tamFuente = 9;
    }
    if (FuenteImp == "34") {
      tamFuente = 10;
    }
    if (FuenteImp == "33") {
      tamFuente = 11;
    }
    if (FuenteImp == "32") {
      tamFuente = 12;
    }
    if (FuenteImp == "31") {
      tamFuente = 13;
    }
    if (FuenteImp == "30") {
      tamFuente = 14;
    }
    if (FuenteImp == "25") {
      tamFuente = 15;
    }
    if (FuenteImp == "22") {
      tamFuente = 16;
    }
    return tamFuente;
  }
  crearTicketImpresionWebPDF(objDatos) {
    var divTicketWeb = '';
    var ticketFinal = "";
    var tieneLogoNego = "N";
    let cantColTabla: number = 16;

    //CREAR LAS COLUMNAS DE LA TABLA
    var fila = "";
    var columnas = "";
    for (var c = 0; c < cantColTabla; c++) {
      columnas = columnas + "<td></td>";
    }
    fila = fila + "<tr>" +
      columnas +
      "</tr>";

    columnas = "";

    var infoTk = objDatos.split('|');
    var ticketFin = '';
    let ctaColumnas: number = 0;
    for (var t = 0; t < infoTk.length; t++) {
      var dDat = infoTk[t].split('¥');
      if (dDat[0] != "") {
        var FuenteTamano = this.tamañoFilaTexto;
        var ColumnaBordes = "";
        var FuenteTipo = "font-weight: normal;";

        var datCol = dDat[0];
        let cantCol: number = parseInt(dDat[1]);
        var alineaCol = this.obtenerAlinearTextoTabla(dDat[2]);
        var tipoCol = dDat[3];

        if (dDat.length > 4 && dDat[4] != null) {
          FuenteTamano = dDat[4];
        }
        if (dDat.length > 5 && dDat[5] != null) {
          ColumnaBordes = dDat[5];
        }
        if (dDat.length > 6 && dDat[6] != null) {
          if (dDat[6] == "NEGRITA") {
            FuenteTipo = "font-weight: bold;";
          }
        }

        var BordeSUP = "";
        var BordeINF = "";
        var BordeIZQ = "";
        var BordeDER = "";
        var datBorde = ColumnaBordes.split(',');
        for (var i = 0; i < datBorde.length; i++) {
          if (datBorde[i] == "SUP") {
            BordeSUP = "border-top: 1px solid black;";
          }
          if (datBorde[i] == "INF") {
            BordeINF = "border-bottom: 1px solid black;";
          }
          if (datBorde[i] == "IZQ") {
            BordeIZQ = "border-left: 1px solid black;";
          }
          if (datBorde[i] == "DER") {
            BordeDER = "border-right: 1px solid black;";
          }
        }

        if (tipoCol == 'IMAGEN') {
          tieneLogoNego = "S";
          var img = "data:image/jpeg;base64, " + datCol;
          datCol = "<img alt='' src=" + "'" + img + "'" + " style='min-width: 30px; min-height: 30px; max-width: 100px; max-height: 100px' />";
        }

        //if (datCol.startsWith('------------------------------')) {
        if (datCol.substring(0, 30) == '------------------------------') {
          columnas = columnas + "<td colspan='" + cantCol + "' style='" + alineaCol + "; width: 100%, height: 100%; border-top: 1px solid black'></td>";
        } else {
          columnas = columnas + "<td colspan='" + cantCol + "' style='" + alineaCol + "; width: 100%, height: 100%; font-size: " + this.calcularTamañoFuenteImp(FuenteTamano) + "pt; " + FuenteTipo + " font-family: Trebuchet MS, Arial Narrow; " + BordeSUP + BordeINF + BordeIZQ + BordeDER + "'>" +
            datCol +
            "</td>";
        }

        ctaColumnas = ctaColumnas + cantCol;
        if (ctaColumnas == cantColTabla) {
          fila = fila + "<tr>" +
            columnas +
            "</tr>";

          columnas = "";
          ctaColumnas = 0;
        }
      }
    }

    //ticketFin = '<table style="width: 68mm; max-width:68mm; margin-left: 0mm; margin-right: 0mm; font-size: ' + calcularTamañoFuenteImp() + 'pt; font-weight: normal; font-family: Trebuchet MS, Arial Narrow">' +
    var anchoVENTANA = "300";
    var altoVENTANA = "500";

    var tamaño = "68mm";
    var margenIzq = "0mm";
    var margenDer = "0mm";


    ticketFin = "<table style='width: " + tamaño + "; max-width:" + tamaño + "; margin-left: " + margenIzq + "; margin-right: " + margenDer + ";'>" +
      fila +
      "</table>";

    divTicketWeb = "<pre>" +
      ticketFin +
      "</pre>";

    ticketFinal = "<div style='margin-left: 2mm;'>" +
      divTicketWeb +
      "</div>";

    var myWindow = window.open('', '', 'width=' + anchoVENTANA + ',height=' + altoVENTANA + '');
    myWindow.document.write(ticketFinal);

    if (tieneLogoNego == "S") {
      var pararImg = function () {
        //SI TIENE LOGO RETRASO UN POCO LA IMPRESION DEL FORMUALRIO PARA CARGAR LA IMAGEN EN EL MISMO
        myWindow.document.close();
        myWindow.focus();
        myWindow.print();
        myWindow.close();

      };
      setTimeout(pararImg, 1000);
    } else {
      myWindow.document.close();
      myWindow.focus();
      myWindow.print();
      myWindow.close();
    }
  }

  async cargarDomiciliarios() {
    let resp: any = await this.pedidoService.ListarDomiciliario(this.negoId, this.empresa);
    if (resp != null) {
      this.listaDomiciliarios = resp;
    }
  }

  async AsignarDomiciliario() {
    if (this.Domiciliario_Id == -1) {
      this.mensaje = "Por favor seleccione un domiciliario para entregar el pedido.";
      window.$('#myModalMen').modal('show');
      return;
    } else {
      for (var i = 0; i < this.listaDomiciliarios.length; i++) {
        if (this.Domiciliario_Id == this.listaDomiciliarios[i].TER_ID) {
          this.Domiciliario_Nombre = this.listaDomiciliarios[i].TER_NOMBRE;
        }
      }

      await this.MostrarAsignarDomiciliario();
    }
  }

  async MostrarAsignarDomiciliario() {
    window.$('#modalAsignarDomiciliario').modal('show');
  }

  async CancelarAsignarDomiciliario() {
    window.$('#modalAsignarDomiciliario').modal('hide');
  }

  async GuadarDomiciliarioPedido() {
    await this.generales.OpenDialogAjax();

    await this.CancelarAsignarDomiciliario();

    let Domiciliario: ClsPedidoDomiciliarioModel = new ClsPedidoDomiciliarioModel();

    Domiciliario.PD_TERCERO_ID = this.Domiciliario_Id;
    Domiciliario.PD_PEDIDO_ID = this.pedidoSeleccionado.Informacion_Pedido.Pedido_ID;
    Domiciliario.USU_ID = this.usuId;
    Domiciliario.IP_EQUIPO = this.ip;

    let resp: any = await this.pedidoService.GuardarPedidoDomicilio(Domiciliario);
    if (resp != null) {
      if (resp.IsSuccess) {
        await this.AsignarDomiciliarioPedido();

        this.generales.MostrarMensajeOK(resp.Message);
      } else {
        this.generales.MostrarMensajeERROR(resp.Message);
      }
    } else {
      this.generales.MostrarMensajeERROR("Error almacenando información del domiciliario.");
    }

    await this.generales.CancelDialogAjax();
    //if (this.formaDomicilio.get("CboDomiciliarios").value != null && this.formaDomicilio.get("CboDomiciliarios").value != "-1") {
    //    for (var i = 0; i < this.listaReparto.length; i++) {
    //        if (this.listaReparto[i].TIPO_PEDIDO.PARAM_DESCRIP == "PEDIDO_DOMICILIO") {
    //            if (this.formaDomicilio.get("PD_" + this.listaReparto[i].AP_ID).value) {
    //                Domiciliario.PD_PEDIDO_ID.push(this.listaReparto[i].AP_ID)
    //                this.listaReparto[i].TER_DOMICILIARIO = this.listaDomiciliarios[this.formaDomicilio.get("CboDomiciliarios").value];
    //            }
    //        }
    //    }
    //    console.log(this.listaReparto);
    //    if (Domiciliario.PD_PEDIDO_ID.length > 0) {
    //        Domiciliario.USU_ID = this.usuId;
    //        Domiciliario.IP_EQUIPO = this.ip;
    //        Domiciliario.PD_TERCERO_ID = this.listaDomiciliarios[this.formaDomicilio.get("CboDomiciliarios").value].TER_ID;
    //    }
    //}
  }

  async AsignarDomiciliarioPedido() {
    for (var i = 0; i < this.listaDomiciliarios.length; i++) {
      if (this.Domiciliario_Id == this.listaDomiciliarios[i].TER_ID) {
        this.pedidoSeleccionado.Domicilio = this.listaDomiciliarios[i];
      }
    }
  }

  async playAudio(N: number) {
    if (this.listaEspera.length > 0) {
      await this.audio.play();
      if (this.reproducir) {
        this.playAudio(0);
      }
    } else {
      this.reproducir = false;
    }
  }

  async pausarAudio() {
    console.log("pausarAudio");
    this.audio.pause();
    this.reproducir = false;
  }

  async reproducirAudio() {
    console.log("reproducirAudio");
    this.reproducir = true;
    this.playAudio(0);
  }

  async AsignarDomicilio() {
    console.log("Pendiente asignación de domiciliario para el pedido")
  }

  async AsignarPedido(index: number, pedido: any, estadoActual: string) {
    this.Pedido_Index = index;
    this.Pedido_EstadoActual = estadoActual;
    this.Mostrar_BotonAceptar = true;
    this.pedidoSeleccionado = null;

    setTimeout(async () => {
      this.pedidoSeleccionado = pedido;

      await this.ValidarMedioPago(this.pedidoSeleccionado.Medio_Pago.Tipo_Pago);

      window.$('#modalInformacionPedido').modal('show');
    }, 100);
  }

  async VerDetallePedido(index: number, pedido: any, estadoActual: string) {
    this.Pedido_Index = index;
    this.Pedido_EstadoActual = estadoActual;
    this.Mostrar_BotonAceptar = false;
    this.pedidoSeleccionado = null;

    setTimeout(async () => {
      this.pedidoSeleccionado = pedido;

      window.$('#modalInformacionPedido').modal('show');
    }, 100);
  }

  async ValidaMostrarTelefono() {
    this.Mostrar_Telefono = !this.Mostrar_Telefono;
  }

  async CambiarDomiciliario(data: any) {
    let DomicilioID = this.formaDomicilio.get("CboDomiciliarios").value;
    if (DomicilioID != "-1") {
      this.Domiciliario_Id = DomicilioID;
    } else {
      this.Domiciliario_Id = -1;
    }

    console.log("Control domicilio", this.Domiciliario_Id);
  }

  async CambiarEstado() {
    try {
      await this.generales.OpenDialogAjax();

      let estado: ClsPedidoEstadoModel = new ClsPedidoEstadoModel();
      estado.EstadoActual = this.Pedido_EstadoActual;
      estado.PedidoId = this.pedidoSeleccionado.Informacion_Pedido.Pedido_ID;
      estado.NegoId = this.negoId;
      estado.IPEquipo = this.ip;
      estado.UsuarioId = this.usuId;

      estado.MedioPagoOnline = this.pedidoSeleccionado.Medio_Pago.Pago_Online;
      estado.MedioPago = this.pedidoSeleccionado.Medio_Pago.Tipo_Pago;
      estado.PARAM_ID_PAGO = this.pedidoSeleccionado.Medio_Pago.MedioPago_ID;

      if (this.Pedido_EstadoActual == 'E') {
        let resp: any = await this.pedidoService.CambiarEstado(estado);
        if (resp != null) {
          if (resp.IsSuccess) {
            let Pedido: any = this.listaEspera[this.Pedido_Index];
            Pedido.Informacion_Pedido.Estado_Preparacion = "P";

            this.listaPreparacion.push(Pedido);
            this.listaEspera.splice(this.Pedido_Index, 1);

            window.$('#modalInformacionPedido').modal('hide');
          } else {
            this.generales.MostrarMensajeERROR(resp.Message);
          }
        } else {
          this.generales.MostrarMensajeERROR("Error almacenando información.");
        }
      }
      else if (this.Pedido_EstadoActual == 'P') {
        if (this.pedidoSeleccionado.Medio_Pago.Pago_Online) {
          if (this.pedidoSeleccionado.Usuario == null || this.pedidoSeleccionado.Usuario == undefined) {
            this.pedidoSeleccionado.Usuario = new ClsKanbanPedido_UsuarioModel();
            this.pedidoSeleccionado.Usuario.USU_ID = this.usuId;
          }

          estado.Pedido = this.pedidoSeleccionado;
        }

        let resp: any = await this.pedidoService.CambiarEstado(estado);
        if (resp != null) {
          if (resp.IsSuccess) {
            let Pedido: any = this.listaPreparacion[this.Pedido_Index];
            Pedido.Informacion_Pedido.Estado_Preparacion = "R";

            this.listaReparto.push(Pedido);
            this.listaPreparacion.splice(this.Pedido_Index, 1);

            window.$('#modalInformacionPedido').modal('hide');
          } else {
            this.generales.MostrarMensajeERROR(resp.Message);
          }
        } else {
          this.generales.MostrarMensajeERROR("Error almacenando información.");
        }
      }
      else if (this.Pedido_EstadoActual == 'R') {
        if (this.listaReparto[this.Pedido_Index].Tipo_Pedido.Tipo == "PEDIDO_DOMICILIO" && this.listaReparto[this.Pedido_Index].Domicilio == null) {
          this.generales.MostrarMensajeERROR("Por favor asigne el pedido a un domiciliario para poderlo finalizar.");
        } else {

          if (this.pedidoSeleccionado.Usuario == null || this.pedidoSeleccionado.Usuario == undefined) {
            this.pedidoSeleccionado.Usuario = new ClsKanbanPedido_UsuarioModel();
            this.pedidoSeleccionado.Usuario.USU_ID = this.usuId;
          }

          estado.Pedido = this.pedidoSeleccionado;

          let resp: any = await this.pedidoService.CambiarEstado(estado);
          if (resp != null) {
            if (resp.IsSuccess) {
              this.PedidoFactura = estado.PedidoId;
              let Pedido: any = this.listaReparto[this.Pedido_Index];
              Pedido.Informacion_Pedido.Estado_Preparacion = "F";
              this.listaFacturados.push(Pedido);

              this.listaReparto.splice(this.Pedido_Index, 1);

              window.$('#modalInformacionPedido').modal('hide');
              window.$('#divModalFactura').modal('show');
            } else {
              this.generales.MostrarMensajeERROR(resp.Message);
            }
          } else {
            this.generales.MostrarMensajeERROR("Error almacenando información.");
          }
        }
      }

      this.reproducirAudio();

    } catch (e) {
      console.log(e);
    } finally {
      await this.generales.CancelDialogAjax();
    }
  }

  async ReasignarDomiciliario() {
    this.pedidoSeleccionado.Domicilio = null;
  }

  async ValidarMedioPago(Tipo_Pago: string) {
    if (Tipo_Pago == "EFECTIVO") {
      this.pedidoSeleccionado.Medio_Pago.Efectivo.Cambio = this.pedidoSeleccionado.Medio_Pago.Efectivo.Recibido - this.pedidoSeleccionado.Pedido_Total.Valor_Total;
      if (this.pedidoSeleccionado.Medio_Pago.Efectivo.Cambio >= 0) {
        this.pedidoSeleccionado.Medio_Pago.Efectivo.Pago_Valido = true;
      } else {
        this.pedidoSeleccionado.Medio_Pago.Efectivo.Pago_Valido = false;
      }
    } else {
      if (this.pedidoSeleccionado.Medio_Pago.Tarjeta.Comprobante != "") {
        this.pedidoSeleccionado.Medio_Pago.Tarjeta.Pago_Valido = true;
      } else {
        this.pedidoSeleccionado.Medio_Pago.Tarjeta.Pago_Valido = false;
      }
    }
  }

  async ImprimirFactura() {
    console.log("Imprimir Pedido", this.PedidoFactura);
    let resp: any = await this.pedidoService.ImprimirPedidoFactura(this.PedidoFactura);
    if (resp != null) {
      console.log(resp.Data[0]);
      if (resp.IsSuccess) {
        console.log(resp.Data[0].FacturaTextoPDF[0]);
        this.crearTicketImpresionWebPDF(resp.Data[0].FacturaTextoPDF[0]);
        //this.crearTicketImpresionWebBase64PDF(resp.Data[0].FacturaBase64PDF[0]);        
      } else {
        this.generales.MostrarMensajeERROR(resp.Message);
      }
    } else {
      this.generales.MostrarMensajeERROR("Error al imprimir la factura.");
    }
  }

  ValidarCancelarPedido(Pedido: any) {
    window.$('#modalCancelarPedido').modal('show');
  }

  async GuadarCancelarPedido() {
    if (this.MotivoCancela == "") {

    } else {
      try {
        let cancela: ClsCancelaPedidoModel = new ClsCancelaPedidoModel();
        cancela.EmpresaId = this.empresa;
        cancela.IpEquipo = this.ip;
        cancela.UsuId = this.usuId;
        cancela.Motivo = this.MotivoCancela;
        cancela.NegoId = this.pedidoSeleccionado.Negocio.NEGO_ID;
        cancela.PedidoId = this.pedidoSeleccionado.Informacion_Pedido.Pedido_ID;


        this.generales.OpenDialogAjax();
        let resp: any = await this.pedidoService.CancelarPedidoId(cancela);
        if (resp != null) {
          if (resp.IsSuccess) {

          } else {
            this.generales.MostrarMensajeERROR(resp.Message);
          }
        } else {
          this.generales.MostrarMensajeERROR("Error cancelando el pedido.");
        }
      } catch (e) {

      } finally {
        await this.generales.CancelDialogAjax();
        this.MotivoCancela = "";
        window.$('#modalCancelarPedido').modal('hide');
      }
    }
  }

  MostrarCancelados() {
    this.Detalle_Id = "";
    window.$('#divModalPedidosCancelados').modal('show');

  }
  MostrarFacturados() {
    this.Detalle_Id = "";
    window.$('#divModalPedidosFacturados').modal('show');

  }

  async DetallePedidoCancelado(Pedido: ClsKanbanPedidoModel) {
    if (this.Detalle_Id == "Detalle_" + Pedido.Informacion_Pedido.Pedido_ID) {
      this.Detalle_Id = "";
    } else {
      this.Detalle_Id = "Detalle_" + Pedido.Informacion_Pedido.Pedido_ID;
    }
  }

  reimprimir(PedidoId: number) {
    this.PedidoFactura = PedidoId;
    this.ImprimirFactura();
  }

}


