import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { DatePipe, CommonModule } from '@angular/common';
import { DecimalPipe } from '@angular/common';

//Rutas
//import { RouterModule } from '@angular/router';
import { APP_ROUTING } from './app.routes';

import { AppComponent } from './app.component';

import { HomeComponent } from './components/home/home.component';

import { HeaderComponent } from './components/header/header.component';
import { BodyComponent } from './components/body/body.component';
import { FooterComponent } from './components/footer/footer.component';

//Paginacion
import { NgxPaginationModule } from 'ngx-pagination'; // <-- import the module

//Angular Material
import { MatTableModule } from '@angular/material/table'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
//GUARDS
//import { SeguridadGuard } from './components/guards/seguridad.guard';

//Servicios
import { HeroesService } from './services/heroes.service';
import { ProductoService } from './services/Producto.Service';
import { CategoriaService } from './services/categoria.service';
import { PersonaService } from './services/persona.service';
import { UsuarioService } from './services/usuario.service';
import { AuthService } from './services/_Auth.service';
import { AuthGuardService } from './services/_AuthGuard.service';
import { GeneralesService } from './services/_Generales.service';
import { FileUploadService } from './services/_FileUpload.service';
import { CalificarService } from './services/_Calificar.service';
import { EmoticonesService } from './services/_Emoticones.service';
import { SignalRService } from './services/SignalR/signalR.service';


//Componentes
import { LoginComponent } from './components/login/login.component';
import { NavbarComponent } from './components/shared/navbar/navbar.component';
//import { AboutComponent } from './components/about/about.component';
import { HeroesComponent } from './components/heroes/heroes.component';
import { HeroeComponent } from './components/heroe/heroe.component';
import { BuscadorComponent } from './components/buscador/buscador.component';
import { HeroeTarjetaComponent } from './components/heroe-tarjeta/heroe-tarjeta.component';
import { PipesComponent } from './components/pipes/pipes.component';
import { SearchComponent } from './components/search/search.component';
import { ArtistaComponent } from './components/artista/artista.component';
import { UsuarioComponent } from './components/usuario/usuario.component';
import { UsuarioNuevoComponent } from './components/usuario/usuario-nuevo.component';
import { UsuarioEditarComponent } from './components/usuario/usuario-editar.component';
import { UsuarioDetalleComponent } from './components/usuario/usuario-detalle.component';
import { MapaCrearDireccionComponent } from './components/MapaCrearDireccion/MapaCrearDireccion.component';



//IDIOMA EN FECHAS
import { LOCALE_ID } from '@angular/core';


//PIPES
import { CapitalizadoPipe } from './pipes/capitalizado.pipe';
import { DomseguroPipe } from './pipes/domseguro.pipe';
import { ContrasenaPipe } from './pipes/contrasena.pipe';
import { NoimagePipe } from './pipes/noimage.pipe';
import { TarjetasComponent } from './components/tarjetas/tarjetas.component';
import { LoadingComponent } from './components/shared/loading/loading.component';
import { NgStyleComponent } from './components/ng-style/ng-style.component';

//DIRECTIVAS
import { ResaltadoDirective } from './directives/resaltado.directive';
import { ProtegidaComponent } from './components/protegida/protegida.component';
import { TemplateComponent } from './components/template/template.component';
import { DataComponent } from './components/data/data.component';
//import { ChatComponent } from './components/chat/chat.component';
//import { ChatLoginComponent } from './components/chat/ChatLogin.component';
import { UploadComponent } from './components/upload/upload.component';
import { FileUploadComponent } from './components/upload/FileUpload.component';
import { CalificarComponent } from './components/calificar/Calificar.component';
import { EmoticonesComponent } from './components/emoticones/emoticones.component';
//import { ChatRegistrarUsuarioComponent } from './components/chat/ChatRegistrarUsuario.component';
//import { ChatRecuperaContrasenaComponent } from './components/chat/ChatRecuperaContrasena.component';
import { PagosComponent } from './components/pagos/pagos.component';
import { ConfigNegocioComponent } from './components/config-negocio/config-negocio.component';
import { PpalComponent } from './components/ppal/ppal.component';
import { VerificarInicioComponent } from './components/verificar-inicio/verificar-inicio.component';
import { ImagenPromocionComponent } from './components/imagen-promocion/imagen-promocion.component';
import { TipoPedidoComponent } from './components/tipo-pedido/tipo-pedido.component';
import { AdminDistanciasComponent } from './components/admin-distancias/admin-distancias.component';
import { AdminPedidosComponent } from './components/admin-pedidos/admin-pedidos.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MapaKeyComponent } from './components/mapa-key/mapa-key.component';
import { SMTPComponent } from './components/smtp/smtp.component';
import { ReferenciasComponent } from './components/referencias/referencias.component';
import { NegociosComponent } from './components/negocios/negocios.component';
import { AppMenuComponent } from './components/app-menu/app-menu.component';
import { MenuComponent } from './components/Menu/menu/menu.component';
import { MenuDetalleReferenciaComponent } from './components/Menu/menu-detalle-referencia/menu-detalle-referencia.component';
import { AdminPedidosCallcenterComponent } from './components/admin-pedidos-callcenter/admin-pedidos-callcenter.component';
import { AdminPedidosOnlineComponent } from './components/admin-pedidos-online/admin-pedidos-online.component';
import { ZonaDomicilioComponent } from './components/zona-domicilio/zona-domicilio.component';
import { TerceroBuscarComponent } from './components/terceroBuscar/terceroBuscar.component';
import { DireccionBuscarComponent } from './components/direccionBuscar/DireccionBuscar.component';
import { TerceroCrearComponent } from './components/terceroCrear/terceroCrear.component';
import { NegocioListarComponent } from './components/negocioListar/negocioListar.component';
import { MenuVentaComponent } from './components/menuVenta/menuVenta.component';
import { MenuVentaDetalleComponent } from './components/menuVentaDetalle/menuVentaDetalle.component';
import { MenuDetalleComponent } from './components/menuDetalle/menuDetalle.component';
import { InfoNegocioComponent } from './components/infoNegocio/infoNegocio.component';
import { RecuperarPasswordComponent } from './components/RecuperarPassword/RecuperarPassword.component';
import { RegistrarseComponent } from './components/Registrarse/Registrarse.component';
import { InfoTerceroComponent } from './components/infoTercero/infoTercero.component';
import { InfoMenuDetalleComponent } from './components/infoMenuDetalle/infoMenuDetalle.component';
import { InfoMenuDetallePersonalizarComponent } from './components/infoMenuDetallePersonalizar/infoMenuDetallePersonalizar.component';
import { MenuDetalleArticuloComponent } from './components/menuDetalleArticulo/menuDetalleArticulo.component';
import { InfoMenuDetallePersonalizarSUBComponent } from './components/infoMenuDetallePersonalizarSUB/infoMenuDetallePersonalizarSUB.component';
import { InfoMenuDetallePersonalizarToppingComponent } from './components/infoMenuDetallePersonalizarTopping/infoMenuDetallePersonalizarTopping.component';
import { InfoMenuDetallePersonalizarToppingSUBComponent } from './components/infoMenuDetallePersonalizarToppingSUB/infoMenuDetallePersonalizarToppingSUB.component';
import { DireccionBuscarManualComponent } from './components/direccion-buscar-manual/direccion-buscar-manual.component';


//const ENTRYCOMPONENTS = [
//    UploadComponent
//];


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    HeaderComponent,
    BodyComponent,
    FooterComponent,
    NavbarComponent,
    HeroesComponent,
    HeroeComponent,
    BuscadorComponent,
    HeroeTarjetaComponent,
    PipesComponent,
    CapitalizadoPipe,
    DomseguroPipe,
    ContrasenaPipe,
    SearchComponent,
    ArtistaComponent,
    NoimagePipe,
    TarjetasComponent,
    LoadingComponent,
    NgStyleComponent,
    ResaltadoDirective,
    UsuarioComponent,
    UsuarioNuevoComponent,
    UsuarioEditarComponent,
    UsuarioDetalleComponent,
    ProtegidaComponent,
    TemplateComponent,
    DataComponent,
    UploadComponent,
    FileUploadComponent,
    CalificarComponent,
    EmoticonesComponent,
    PagosComponent,
    ConfigNegocioComponent,
    PpalComponent,
    VerificarInicioComponent,
    ImagenPromocionComponent,
    TipoPedidoComponent,
    AdminDistanciasComponent,
    AdminPedidosComponent,
    AdminPedidosCallcenterComponent,
    AdminPedidosOnlineComponent,
    ZonaDomicilioComponent,
    MapaCrearDireccionComponent,
    MapaKeyComponent,
    SMTPComponent,
    ReferenciasComponent,
    NegociosComponent,
    AppMenuComponent,
    MenuComponent,
    MenuDetalleReferenciaComponent,
    TerceroBuscarComponent,
    DireccionBuscarComponent,
    DireccionBuscarManualComponent,
    TerceroCrearComponent,
    NegocioListarComponent,
    MenuVentaComponent,
    MenuVentaDetalleComponent,
    MenuDetalleComponent,
    MenuDetalleArticuloComponent,
    InfoNegocioComponent,
    InfoTerceroComponent,
    InfoMenuDetalleComponent,
    InfoMenuDetallePersonalizarComponent,
    InfoMenuDetallePersonalizarSUBComponent,
    InfoMenuDetallePersonalizarToppingComponent,
    InfoMenuDetallePersonalizarToppingSUBComponent,
    RecuperarPasswordComponent,
    RegistrarseComponent,
  ],
  entryComponents: [MenuComponent, MenuDetalleReferenciaComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    NgxPaginationModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    MatDialogModule,
    APP_ROUTING,
    //RouterModule.forRoot([
    //    { path: '', component: HomeComponent, pathMatch: 'full' },
    //    { path: 'counter', component: CounterComponent },
    //    { path: 'filtrado-producto-nombre', component: FiltradoProductoNombreComponent, canActivate: [SeguridadGuard]/*Asignar seguridad con login*/ },
    //    { path: 'filtrado-producto-categoria', component: FiltradoProductoCategoriaComponent, canActivate: [SeguridadGuard]/*Asignar seguridad con login*/ },
    //    { path: 'filtrado-persona-nombre', component: FiltradoPersonaNombreCompletoComponent, canActivate: [SeguridadGuard]/*Asignar seguridad con login*/},
    //    { path: 'filtrado-tipo-usuario', component: FiltradoUsuarioTipoUsuarioComponent, canActivate: [SeguridadGuard]/*Asignar seguridad con login*/},
    //    { path: 'mantenimiento-persona', component: MantenimientoPersonaComponent },
    //    { path: 'persona-form-mantenimiento/:id', component: PersonaFormMantenimientoComponent },
    //    { path: 'mantenimiento-usuario', component: MantenimientoUsuarioComponent},
    //    //{ path: 'mantenimiento-usuario/:id', component: UsuarioFormMantenimientoComponent },
    //    { path: 'usuario-form-mantenimiento/:id', component: UsuarioFormMantenimientoComponent, canActivate: [SeguridadGuard]/*Asignar seguridad con login*/},
    //    { path: 'login', component: LoginComponent },
    //    { path: 'pagina-error-login', component: PaginaErrorLoginComponent },
    //    { path: 'permiso-error-pagina', component: PermisoErrorPaginaComponent },

    //    //{ path: 'fetch-data', component: FetchDataComponent },
    //    //{ path: 'dia-semana', component: DiasSemana},
    //])
    AgmCoreModule.forRoot({ apiKey: 'AIzaSyDCbaNt7BDyC6SOwx-d31FBVcis5W-7Ln8' }),
    BrowserAnimationsModule
  ],
  providers: [
    AuthService,
    AuthGuardService,
    GeneralesService,
    FileUploadService,
    CalificarService,
    EmoticonesService,
    DecimalPipe,
    DatePipe,
    SignalRService
    //HeroesService,
    //ProductoService,
    //CategoriaService,
    //PersonaService,
    //UsuarioService
    //,
    //{ provide: LOCALE_ID, useValue: "es"}
    //{ provide: LOCALE_ID, useValue: "es" }
    //SeguridadGuard/*Seguridad Login*/
  ],
  bootstrap: [
    AppComponent
  ]
  //,
  //entryComponents: [ENTRYCOMPONENTS]
})
export class AppModule { }
