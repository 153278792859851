import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/_Auth.service';
import { ClsAccesoNegocioModel } from '../../../models/Web/ClsAccesoNegocio.model';
import { ClsOpcionesModel } from '../../../models/Web/ClsOpciones.model';
import { OpcionesService } from '../../../services/opciones.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  

  constructor(private _router: Router, public auth: AuthService, public opcService: OpcionesService) { }

  ngOnInit() {
    this.cargarDatosMenu();
  }

  async cargarDatosMenu() {
    if (this.auth.loggedIn) {
      let datos: any = await localStorage.getItem('negocio');
      if (datos != null && datos != '') {
        let objNego = new ClsAccesoNegocioModel();
        objNego = JSON.parse(datos);
        this.opcService.nombre = objNego[0].NEGO_NOMBRE;
        this.opcService.fecha = objNego[0].NEGO_ULTCIERRE + "";
      }
      this.opcService.ip = await localStorage.getItem('ip');

      await this.opcService.asignarOpciones(661);
      this.opcService.nombreusuario = JSON.parse(localStorage.getItem('sesion')).USU_NOMBRE;
    }
  }



}
