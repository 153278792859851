import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ConfigEmpresaService } from '../../services/ConfigEmpresa.service';
import { ClsSMTPEmpresaModel } from '../../models/Web/ClsSMTP.model';
import { ClsParametrosModel } from '../../models/Web/ClsParametros.model';
import { GeneralesService } from '../../services/_Generales.service';
declare var window: any;

@Component({
    selector: 'app-smtp',
    templateUrl: './smtp.component.html',
    styleUrls: ['./smtp.component.css']
})
export class SMTPComponent implements OnInit {
    myGroupSmtp: FormGroup;
    empresaId: number;
    mensajeRespuesta: string = "";

    listadoParametros: ClsParametrosModel[];
    objsmtp: ClsSMTPEmpresaModel;

    constructor(private smtpService: ConfigEmpresaService, private generales: GeneralesService) {
        let datosn: any = localStorage.getItem('negocio');
        if (datosn != null && datosn != '') {
            let objNego: any = JSON.parse(datosn);
            this.empresaId = objNego[0].NEGO_EMPRESA;
        }
        this.myGroupSmtp = new FormGroup({
            'TxtCorreo': new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
            'TxtClave': new FormControl('', Validators.required),
            'TxtTipoSMTP': new FormControl('', Validators.required)
        });
    }

    async ngOnInit() {
        this.IniciarSMTP();
    }

    async GuardarSmtp() {
        this.generales.OpenDialogAjax();

        let objsmtp: ClsSMTPEmpresaModel = new ClsSMTPEmpresaModel();
        objsmtp.SE_EMPRESA_ID = this.empresaId;
        objsmtp.SE_CORREO = this.myGroupSmtp.get("TxtCorreo").value;
        objsmtp.SE_CLAVE = this.myGroupSmtp.get("TxtClave").value;
        objsmtp.SE_SMTP_ID = this.myGroupSmtp.get("TxtTipoSMTP").value;
        objsmtp.SE_IP_EQUIPO = localStorage.getItem('ip');
        let resp: any = await this.smtpService.GuardarSMTPEmpresa(objsmtp);
        if (resp.IsSuccess) {
            this.mensajeRespuesta = resp.Message;

            await this.generales.MostrarMensajeOK(resp.Message);
            await this.consultarSmtp(this.myGroupSmtp.get("TxtTipoSMTP").value);
        } else {
            await this.generales.MostrarMensajeERROR(resp.Message);
        }

        this.generales.CancelDialogAjax();
    }

    async IniciarSMTP() {
        this.generales.OpenDialogAjax();

        let ParamDefault: number = 0;

        let parametros: any = await this.smtpService.ListarParametrosGenerales(61, 0);
        if (parametros != null) {
            this.listadoParametros = parametros;

            for (var i = 0; i < this.listadoParametros.length; i++) {
                if (i == 0 || this.listadoParametros[i].PARAM_DEFAULT == true) {
                    ParamDefault = this.listadoParametros[i].PARAM_ID;
                }
            }
        }
        this.myGroupSmtp.get("TxtTipoSMTP").setValue(ParamDefault);

        await this.consultarSmtp(ParamDefault);

        this.generales.CancelDialogAjax();
    }

    async consultarSmtp(ParamDefault: number) {
        let resp: any = await this.smtpService.ConsultarSMTPEmpresa(this.empresaId);
        if (resp != null) {
            this.objsmtp = resp;
            this.myGroupSmtp.get("TxtCorreo").setValue(this.objsmtp.SE_CORREO);
            this.myGroupSmtp.get("TxtClave").setValue(this.objsmtp.SE_CLAVE);

            if (this.objsmtp.SE_SMTP_ID == 0) {
                if (ParamDefault != 0) {
                    this.myGroupSmtp.get("TxtTipoSMTP").setValue(ParamDefault);
                }
            } else {
                this.myGroupSmtp.get("TxtTipoSMTP").setValue(this.objsmtp.SE_SMTP_ID);
            }
            
        }
    }

}
