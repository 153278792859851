import { Component, OnInit, ViewChild, EventEmitter, Output, Input, Renderer2, Inject, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ClsNegocioModel } from '../../models/Aplicacion/ClsNegocio.model';
import { GeneralesService } from '../../services/_Generales.service';
import { ClsMenuVentaModel } from '../../models/Aplicacion/ClsMenuVenta.model';
import { PedidoCallCenterService } from '../../services/PedidoCallCenter.service';
import { ClsMenuDetalleModel } from '../../models/Aplicacion/ClsMenuDetalle.model';
declare var window: any;

@Component({
    selector: 'app-menuVenta',
    templateUrl: './menuVenta.component.html',
    styleUrls: ['./menuVenta.component.css']
})
export class MenuVentaComponent implements OnInit {
    @Input() Negocio: ClsNegocioModel;
    @Output() eventoMenuVenta: any = new EventEmitter();

    ListadoMenuVenta: ClsMenuVentaModel[] = [];
    ListadoMenuDetalle: ClsMenuDetalleModel[] = [];

    MenuVenta: ClsMenuVentaModel;

    CargandoDatos: boolean = true;
    UltimoID: number = 0;
    TopConsulta: number = 50;
    TieneDatos: boolean = true;

    constructor(
        private renderer2: Renderer2,
        @Inject(DOCUMENT) private document: Document,
        private pedidoCallCenterService: PedidoCallCenterService,
        private generales: GeneralesService
    ) {
        
    }

    async  ngOnInit() {
        await this.CargarMenuVenta();
    }

    //#region Pedido Cargar Menú
    async CargarMenuVenta() {
        try {
            //await this.generales.OpenDialogAjax();

            this.CargandoDatos = true;

            const infoMenu: any = await this.pedidoCallCenterService.CallCenterMenuVentaListar(this.Negocio.NEGO_EMPRESA_ID, this.Negocio.NEGO_ID, this.UltimoID, this.TopConsulta);
            if (infoMenu != null) {
                if (infoMenu.IsSuccess) {
                    if (infoMenu.Data.length > 0) {
                        this.TieneDatos = true;
                    } else {
                        this.TieneDatos = false;
                    }

                    this.ListadoMenuVenta = infoMenu.Data;

                    for (var i = 0; i < infoMenu.Data.length; i++) {
                        this.UltimoID = infoMenu.Data[i].MENU_ID;

                        console.log("infoMenu_" + [i], infoMenu.Data[i]);
                        console.log("UltimoID", this.UltimoID);

                        //if (i == 0) {
                        //    await this.CargarMenuVentaDetalle(this.MenuVenta.MENU_ID);
                        //}

                        if (i == 0) {
                            this.SeleccionaMenuVenta(infoMenu.Data[i]);
                        }
                    }
                }
                
                //await this.oGeneral.GuardarStorage("" + "NegocioID_" + this.datosNEGOCIO.NEGO_ID + "_" + environment.API_KEY, this.MenuVenta);
            } else {
                this.generales.MostrarMensajeERROR("No es posible listar la información del menú en este momento.");
            }

            this.CargandoDatos = false;
        } catch (e) {
            this.CargandoDatos = false;
        }
        finally {
            //await this.generales.CancelDialogAjax();
        }
    }
    //#endregion

    async SeleccionaMenuVenta(data: any) {
        console.log("SeleccionaMenuVenta", data);

        if (this.MenuVenta != null) {
            if (this.MenuVenta.MENU_ID == data.MENU_ID) {
                return;
            }
        }

        this.ListadoMenuDetalle = null;
        this.MenuVenta = data;
        await this.eventoMenuVenta.emit(data);
    }
}
