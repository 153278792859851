import { Component } from '@angular/core';
import { GeneralesService } from './services/_Generales.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
    title = 'Globus Sistemas';

    constructor(public _generalesService: GeneralesService) {
        this._generalesService.mostrarMenu = true;
  }  
}
