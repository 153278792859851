import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MasterModel } from '../models/master.model';
import { BaseService } from './_Base.service';
import { environment } from '../../environments/environment';
import { APIENDPOINT } from '../config/configuration';
import { ClsMapaKeyModel } from '../models/Web/ClsMapaKey.model';
import { ClsSMTPEmpresaModel } from '../models/Web/ClsSMTP.model';

@Injectable({
    providedIn: 'root'
})
export class ConfigEmpresaService extends BaseService<any, MasterModel> {

    private apiURL: string;

    constructor(protected _http: HttpClient) {
        super(_http, environment.apiGatewayURL)
        this.apiURL = environment.apiGatewayURL;
    }


    async GuardarMapaKey(iDatos: ClsMapaKeyModel) {
        let token = await localStorage.getItem('token');
        if (token == null || token == undefined) {
            token = "";
        }
        return new Promise(resolve => {
            this.post(APIENDPOINT.GuardarMapaKey, iDatos, true, token)
                .subscribe(resp => {
                    resolve(resp);
                }, error => {
                    resolve(error.error);
                    console.log('Error', error.error);
                });
        });
    }

    async ConsultarMapaKey(EmpresaId: number) {
        let token = await localStorage.getItem('token');
        if (token == null || token == undefined) {
            token = "";
        }
        return new Promise(resolve => {
            this.get(APIENDPOINT.ConsultarMapaKey + "?EmpresaId=" + EmpresaId, true, token)
                .subscribe(resp => {
                    resolve(resp);
                }, error => {
                    console.log('Error al consultar key de mapas:', error);
                    resolve(null);
                });
        });
    }

    async GuardarSMTPEmpresa(iDatos: ClsSMTPEmpresaModel) {
        let token = await localStorage.getItem('token');
        if (token == null || token == undefined) {
            token = "";
        }
        return new Promise(resolve => {
            this.post(APIENDPOINT.GuardarSMTPEMpresa, iDatos, true, token)
                .subscribe(resp => {
                    resolve(resp);
                }, error => {
                    resolve(error.error);
                    console.log('Error', error.error);
                });
        });
    }

    async ConsultarSMTPEmpresa(EmpresaId: number) {
        let token = await localStorage.getItem('token');
        if (token == null || token == undefined) {
            token = "";
        }
        return new Promise(resolve => {
            this.get(APIENDPOINT.ConsultarSMTPEmpresa + "?EmpresaId=" + EmpresaId, true, token)
                .subscribe(resp => {
                    if (resp.IsSuccess) {
                        resolve(resp.Data);

                    } else {
                        resolve(null);
                        console.log('Error al consultar smtp');
                    }
                }, error => {
                    resolve(null);
                    console.log('Error al consultar smtp', error.error);
                    return null;
                });
        });
    }

    async ConsultarReferenciasEmpresa(EmpresaId: number) {
        let token = await localStorage.getItem('token');
        if (token == null || token == undefined) {
            token = "";
        }
        return new Promise(resolve => {
            this.get(APIENDPOINT.ConsultarReferenciasEmpresa + "?EmpresaId=" + EmpresaId, true, token)
                .subscribe(resp => {
                    if (resp.IsSuccess) {
                        resolve(resp.Data);

                    } else {
                        resolve(null);
                        console.log('Error al consultar smtp');
                    }
                }, error => {
                    resolve(null);
                    console.log('Error al consultar smtp', error.error);
                    return null;
                });
        });
    }

    async ConsultarReferenciasEmpresaDescripcion(EmpresaId: number, NegoId: number, texto: string, top: number) {
        let token = await localStorage.getItem('token');
        if (token == null || token == undefined) {
            token = "";
        }
        return new Promise(resolve => {
            this.get(APIENDPOINT.ConsultarReferenciasEmpresaDescripcion + "?EmpresaId=" + EmpresaId + "&NegoId=" + NegoId + "&Texto=" + texto + "&Top=" + top, true, token)
                .subscribe(resp => {
                    if (resp.IsSuccess) {
                        resolve(resp.Data);

                    } else {
                        resolve(null);
                        console.log('Error al consultar smtp');
                    }
                }, error => {
                    resolve(null);
                    console.log('Error al consultar smtp', error.error);
                    return null;
                });
        });
    }

    async ConsultarNegociosEmpresa(EmpresaId: number) {
        let token = await localStorage.getItem('token');
        if (token == null || token == undefined) {
            token = "";
        }
        return new Promise(resolve => {
            this.get(APIENDPOINT.ConsultarNegociosEmpresa + "?EmpresaId=" + EmpresaId, true, token)
                .subscribe(resp => {
                    resolve(resp);
                }, error => {
                    console.log('Error al consultar los negocios', error);
                    resolve(null);
                });
        });
    }


    async ListarParametrosGenerales(ClaseID: number, EmpresaID: number) {
        let token = await localStorage.getItem('token');
        if (token == null || token == undefined) {
            token = "";
        }
        return new Promise(resolve => {
            this.get(APIENDPOINT.ListarParametros + "?ClaseID=" + ClaseID + '&' + 'EmpresaID=' + EmpresaID, true, token)
                .subscribe(resp => {
                    if (resp.IsSuccess) {
                        resolve(resp.Data);

                    } else {
                        resolve(null);
                        console.log('Error al consultar parametros generales.');
                    }
                }, error => {
                    resolve(null);
                    console.log('Error al consultar smtp: ', error.error);
                    return null;
                });
        });
  }

  async ConsultarDireccionApi(Direccion: string, MapaKey: string) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }

    var mDir = [
      Direccion
    ];

    return new Promise(resolve => {
      //this.get(APIENDPOINT.ConsultarDireccionApi + "?" + "Direccion=" + mDir + "&" + "MapaKey=" + MapaKey, true, token)
      this.post(APIENDPOINT.ConsultarDireccionApi + "?" + "&" + "MapaKey=" + MapaKey, mDir, true, token)
        .subscribe(resp => {
          resolve(resp);
        }, error => {
          console.log('Error al consultar key de mapas:', error);
          resolve(null);
        });
    });
  }

  async ConsultarPlaceIdApi(PlaceId: string, Direccion: string, MapaKey: string) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }

    var mDir = [
      Direccion
    ];

    return new Promise(resolve => {
      //this.get(APIENDPOINT.ConsultarPlaceIdApi + "?" + "PlaceId=" + PlaceId + "&" + "Direccion=" + Direccion + "&" + "MapaKey=" + MapaKey, true, token)
      this.post(APIENDPOINT.ConsultarPlaceIdApi + "?" + "PlaceId=" + PlaceId + "&" + "MapaKey=" + MapaKey, mDir, true, token)
        .subscribe(resp => {
          resolve(resp);
        }, error => {
          console.log('Error al consultar key de mapas:', error);
          resolve(null);
        });
    });
  }
}
