import { Component, OnInit, ViewChild, EventEmitter, Output, Input, Renderer2, Inject, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ClsNegocioModel } from '../../models/Aplicacion/ClsNegocio.model';
import { GeneralesService } from '../../services/_Generales.service';
import { ClsMenuDetalleModel } from '../../models/Aplicacion/ClsMenuDetalle.model';
import { ClsMenuVentaModel } from '../../models/Aplicacion/ClsMenuVenta.model';
declare var window: any;

@Component({
    selector: 'app-infoMenuDetalle',
    templateUrl: './infoMenuDetalle.component.html',
    styleUrls: ['./infoMenuDetalle.component.css']
})
export class InfoMenuDetalleComponent implements OnInit {
    @Input() Negocio: ClsNegocioModel;
    @Input() MenuVenta: ClsMenuVentaModel;
    @Input() MenuDetalle: ClsMenuDetalleModel;
    
    constructor(
        private renderer2: Renderer2,
        @Inject(DOCUMENT) private document: Document,
        private generales: GeneralesService
    ) {
        
    }

    async  ngOnInit() {
        
    }
}
