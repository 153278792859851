import { ClsReferenciaModel } from './ClsReferencia.model';
import { ClsNegocioModel } from './ClsNegocio.model';
import { ClsTercerosModel } from './ClsTerceros.model';
import { ClsTercerosDireccionesModel } from './ClsTercerosDirecciones.model';
import { ClsCarritoCompraTotalModel } from './ClsCarritoCompraTotal.model';
import { ClsTipoPedidoModel } from './ClsTipoPedidoEmpresa.model';
import { ClsPedidoModel } from './ClsPedido.model';
import { ClsMedioPagoNegocioModel } from './ClsMedioPagoNegocio.model';
import { ClsPedidoUbicacionModel } from './ClsPedidoUbicacion.model';
import { ClsPedidoEventosModel } from './ClsPedidoEventos.model';
import { ClsNegocioTiempoEsperaModel } from './ClsNegocioTiempoEspera.model';

export class ClsCarritoCompraModel {
    public Negocio: ClsNegocioModel = new ClsNegocioModel();

    public Tercero_Pedido: ClsTercerosModel = new ClsTercerosModel();

    public Tercero_Paga: ClsTercerosModel = new ClsTercerosModel();

    public Direccion_Entrega: ClsTercerosDireccionesModel = new ClsTercerosDireccionesModel();

    public Referencias: ClsReferenciaModel[] = new Array<ClsReferenciaModel>();

    public Tipo_Pedido: ClsTipoPedidoModel = new ClsTipoPedidoModel();

    public Carrito_Total: ClsCarritoCompraTotalModel = new ClsCarritoCompraTotalModel();

    public Informacion_Pedido: ClsPedidoModel = new ClsPedidoModel();

    public MedioPago_Pedido: ClsMedioPagoNegocioModel = new ClsMedioPagoNegocioModel();

    public Pedido_Ubicacion: ClsPedidoUbicacionModel = new ClsPedidoUbicacionModel();

    public Tiempo_Espera: ClsNegocioTiempoEsperaModel[] = new Array<ClsNegocioTiempoEsperaModel>();

    public Informacion_Eventos: ClsPedidoEventosModel[] = new Array<ClsPedidoEventosModel>();
}
