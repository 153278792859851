import { ClsNegocioHorariosModel } from './ClsNegocioHorarios.model';
import { ClsMedioPagoNegocioModel } from './ClsMedioPagoNegocio.model';
import { ClsNegocioTiempoEntregaModel } from './ClsNegocioTiempoEntrega.model';

export class ClsNegocioModel {
    public NEGO_ID: number;

    public NEGO_EMPRESA_ID: number;

    public NEGO_NIT: string;

    public NEGO_NOMBRE: string;

    public NEGO_DECIMAL: number;

    public NEGO_OBSERVACION: string;
        
    public NEGO_LATITUD: number;
        
    public NEGO_LONGITUD: number;
        
    public NEGO_GEO_DIRECCION: string;
        
    public NEGO_DIRECCION: string;
        
    public NEGO_INDICACIONES: string;

    public NEGO_CIUDAD: string;

    public NEGO_TELEFONO: string;

    public NEGO_CALIFICACION: number;

    public NEGO_AVATAR: string;

    public NEGO_TIEMPO_ENTREGA: string;

    public NEGO_PEDIDO_MINIMO: number;

    public NEGO_VALOR_DOMICILIO: number;

    public NEGO_DISTANCIA_MAXIMA: number;

    public NEGO_DISTANCIA_TERCERO: number;

    public NEGO_ABIERTO: boolean;

    public Horarios: ClsNegocioHorariosModel[];

    public MedioPago: ClsMedioPagoNegocioModel[];

    public Tiempo_Entrega: ClsNegocioTiempoEntregaModel;
}
