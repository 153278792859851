import { Component, OnInit, ViewChild, EventEmitter, Output, Input, Renderer2, Inject, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClsNegocioModel } from '../../models/Aplicacion/ClsNegocio.model';
import { GeneralesService } from '../../services/_Generales.service';
import { ZonaDomicilioService } from '../../services/ZonaDomicilio.service';
import { PedidoCallCenterService } from '../../services/PedidoCallCenter.service';
import { TerceroService } from '../../services/Tercero.service';
import { NegocioService } from '../../services/Negocio.service';
import { ClsTercerosRecuperarPassword } from '../../models/Aplicacion/ClsTercerosRecuperarPassword.model';
import { ActivatedRoute } from '@angular/router';
declare var window: any;

@Component({
  selector: 'app-RecuperarPassword',
  templateUrl: './RecuperarPassword.component.html',
  styleUrls: ['./RecuperarPassword.component.css']
})
export class RecuperarPasswordComponent implements OnInit {
  @Output() eventoRecuperarPassword: any = new EventEmitter();
  fomulario_RecuperarPass: FormGroup;

  key: string = "";
  EmpresaId: number = 0;

  validation_messages = {
    //NumeroDocumento: [
    //  { type: 'required', message: 'El dato es requerido.' },
    //  { type: 'pattern', message: 'El dato no es valido.' }
    //],
    Correo: [
      { type: 'required', message: 'El dato es requerido.' },
      { type: 'pattern', message: 'El dato no es valido.' },
      { type: 'email', message: 'El formato no es valido.' }
    ]
  };

  constructor(
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private generales: GeneralesService,
    private activatedRoute: ActivatedRoute,
    private terceroService: TerceroService,
    public negocioService: NegocioService,
  ) {

  }

  async ngOnInit() {
    await this.InicializarFormulario();

    let datosn: any = localStorage.getItem('negocio');
    if (datosn != null && datosn != '') {
      let objNego: any = JSON.parse(datosn);
      this.EmpresaId = objNego[0].NEGO_EMPRESA;
    }

    if (this.EmpresaId == null || this.EmpresaId == 0) {
      this.activatedRoute.params
        .subscribe(param => {
          console.log("Parametros", param);;
          this.key = param.Key
        });
    }

    if (this.EmpresaId == null || this.EmpresaId == 0) {
      await this.ListarInformacionNegocio();
    }
  }

  async InicializarFormulario() {
    this.fomulario_RecuperarPass = new FormGroup({
      //NumeroDocumento: new FormControl(""),
      Correo: new FormControl("")
    });

    //this.fomulario_RecuperarPass.controls['NumeroDocumento'].setValidators([Validators.required, Validators.minLength(7)]);
    this.fomulario_RecuperarPass.controls['Correo'].setValidators([Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]);
  }

  async ListarInformacionNegocio() {
    await this.generales.OpenDialogAjax();
    try {
      var response: any = await this.negocioService.NegocioPedidoOnlineListar(this.key);
      if (response.IsSuccess) {
        if (response.Data != null) {
          this.EmpresaId = response.Data.NEGO_EMPRESA;
        }
      } else {
        console.log(response.Message);
      }
    } catch (e) {
      console.log(e);
    } finally {
      await this.generales.CancelDialogAjax();
    }
  }

  async ValidaRecuperarPassword() {
    await this.generales.OpenDialogAjax();
    try {
      let Tercero: ClsTercerosRecuperarPassword = new ClsTercerosRecuperarPassword();
      Tercero.EmpresaID = this.EmpresaId;
      Tercero.Correo = this.fomulario_RecuperarPass.get('Correo').value;

      let guardar: any = await this.terceroService.RecuperarClaveOnline(Tercero);
      if (guardar != null) {
        if (guardar.IsSuccess) {
          await this.generales.MostrarMensajeOK(guardar.Message);
          await this.eventoRecuperarPassword.emit(true);
        } else {
          await this.generales.MostrarMensajeERROR(guardar.Message);
          await this.eventoRecuperarPassword.emit(false);
        }
      } else {
        await this.generales.MostrarMensajeERROR("Error generando la información, intente más tarde.");
      }
    } catch (e) {
      console.log(e);
    } finally {
      await this.generales.CancelDialogAjax();
    }
  }
}
