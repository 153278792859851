import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-usuario-nuevo',
  templateUrl: './usuario-nuevo.component.html',
  styleUrls: ['./usuario-nuevo.component.css']
})
export class UsuarioNuevoComponent implements OnInit {

    constructor(private _activatedRoute: ActivatedRoute) {
        //Obtener el parametro que viene del padre
        this._activatedRoute.parent.params.subscribe(parametros => {
            console.log("Ruta Hija");
            console.log(parametros.id);
        })
    }

  ngOnInit() {
  }

}
