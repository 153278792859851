import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MasterModel } from '../models/master.model';
import { BaseService } from './_Base.service';
import { environment } from '../../environments/environment';
import { APIENDPOINT } from '../config/configuration';
import { ClsTipoPedidoEmpresaConfigModel } from '../models/Web/ClsTipoPedidoEmpresa.model';
import { ClsDistanciaNegocioModel } from '../models/Web/ClsDistanciaNegocio.model';


@Injectable({
    providedIn: 'root'
})
export class TipoPedidoEmpresaService extends BaseService<any, MasterModel> {

    private apiURL: string;

    constructor(protected _http: HttpClient) {
        super(_http, environment.apiGatewayURL)
        this.apiURL = environment.apiGatewayURL;
    }


    async GuardarTipoPedidosEmpresa(iDatos: ClsTipoPedidoEmpresaConfigModel[]) {
        let token = await localStorage.getItem('token');
        if (token == null || token == undefined) {
            token = "";
        }

        return new Promise(resolve => {
            this.post(APIENDPOINT.GuardarTipoPedidoEmpresa, iDatos, true, token)
                .subscribe(resp => {
                    resolve(resp);
                }, error => {
                    resolve(error.error);
                    //console.log('Error', error.error);
                });
        });
    }

    async  ConsultarTipoPedidosEmpresa(_token: string, empresaId: number) {
        let token = await localStorage.getItem('token');
        if (token == null || token == undefined) {
            token = "";
        }

        if (empresaId != 0) {
            return new Promise(resolve => {
                this.get(APIENDPOINT.ListarTipoPedidoEmpresa + "?EmpresaID=" + empresaId, true, token)
                    .subscribe(resp => {
                        if (resp.IsSuccess) {
                            resolve(resp.Data);
                        } else {
                            resolve(null);
                            console.log('Error al consultar tipo pedido');
                        }
                    }, error => {
                        resolve(null);
                        console.log('Error al consultar tipo pedido');
                        return null;
                    });
            });
        }
    }

    async GuardarDistanciaNegocio(iDatos: ClsDistanciaNegocioModel[]) {
        let token = await localStorage.getItem('token');
        if (token == null || token == undefined) {
            token = "";
        }

        return new Promise(resolve => {
            this.post(APIENDPOINT.GuardarDistanciaNegocio, iDatos, true, token)
                .subscribe(resp => {
                    resolve(resp);
                }, error => {
                    resolve(error.error);
                    //console.log('Error', error.error);
                });
        });
    }

    async ConsultarDistanciasNegocio(_token: string, NegocioId: number) {
        let token = await localStorage.getItem('token');
        if (token == null || token == undefined) {
            token = "";
        }

        if (NegocioId != 0) {
            return new Promise(resolve => {
                this.get(APIENDPOINT.ListarDistanciaNegocio + "?NegocioId=" + NegocioId, true, token)
                    .subscribe(resp => {
                        if (resp.IsSuccess) {
                            resolve(resp.Data);
                        } else {
                            resolve(null);
                            console.log('Error al consultar tipo pedido');
                        }
                    }, error => {
                        resolve(null);
                        console.log('Error al consultar tipo pedido');
                        return null;
                    });
            });
        }
    }


    async  ConsultarTiempoEsperaEmpresa(_token: string, EmpresaID: number) {
        let token = await localStorage.getItem('token');
        if (token == null || token == undefined) {
            token = "";
        }

        if (EmpresaID != 0) {
            return new Promise(resolve => {
                this.get(APIENDPOINT.ListarTiempoEsperaEmpresa + "?EmpresaID=" + EmpresaID, true, token)
                    .subscribe(resp => {
                        if (resp.IsSuccess) {
                            resolve(resp.Data);
                        } else {
                            resolve(null);
                            console.log('Error al consultar tiempo espera');
                        }
                    }, error => {
                        resolve(null);
                        console.log('Error al consultar tiempo espera');
                        return null;
                    });
            });
        }
    }

    async  ConsultarMedioPago(_token: string) {
        let token = await localStorage.getItem('token');
        if (token == null || token == undefined) {
            token = "";
        }

        return new Promise(resolve => {
            this.get(APIENDPOINT.ListarMedioPago, true, token)
                .subscribe(resp => {
                    if (resp.IsSuccess) {
                        resolve(resp.Data);
                    } else {
                        resolve(null);
                        console.log('Error al consultar medio pago');
                    }
                }, error => {
                    resolve(null);
                    console.log('Error al consultar medio pago');
                    return null;
                });
        });
    }
}
