import { ClsReferenciaModel } from './ClsReferencia.model';

export class ClsMenuToppingArticuloModel {
    public TOPART_ID: number;

    public TOPART_MENU_TOPPING_ID: number;

    public TOPART_ARTICULO_ID: number;

    public TOPART_NOMBRE: string;

    public TOPART_DESCRIPCION: string;

    public TOPART_LIMITE_MAXIMO: number;

    public TOPART_AVATAR: string;

    public TOPART_CANTIDAD_TOTAL: number;

    public TOPART_SELECCIONADO: boolean;

    //public Topping_Valor: ClsReferenciaValorModel;

    public Referencia: ClsReferenciaModel;
}