import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MasterModel } from '../models/master.model';
import { UsuarioModel } from '../models/usuario.model';
import { BaseService } from './_Base.service';
import { environment } from '../../environments/environment';
import { APIENDPOINT } from '../config/configuration';
import { map } from "rxjs/operators";
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class UsuarioService extends BaseService<UsuarioModel, MasterModel>{
    urlBase: string;

    //constructor(private http: HttpClient, @Inject('BASE_URL') baseURL: string) {
    //    this.urlBase = baseURL;
    //}

    constructor(protected http: HttpClient, private router: Router) {
        super(http, environment.apiGatewayURL)
        this.urlBase = environment.apiGatewayURL;
    }

    public getTipoUsuario() {
        return this.http.get(this.urlBase + "api/Usuario/listarTipoUsuario");
    }

    public getLogin(usuario, contra) {
        return this.http.get(this.urlBase + "api/Usuario/login/" + usuario + "/" + contra);
    }

    public getObtenerVariableSesion() {
        //PENDIENTE HACERLO CON LO DE SIXTO PARA RETORNAR LA CLASE FULL FUNCIONANDO Y PODER VALIDAR BIEN LAS VARIABLE SDE SESION VALIDAS
        return this.http.get(this.urlBase + "api/Usuario/ObtenerVariableSesion").subscribe(data => {
            var x = data;
            return x;
        });

        //return this.http.get(this.urlBase + "api/Usuario/ObtenerVariableSesion");
        //    return new Promise(resolve => {
        //        this.get(this.urlBase + "api/Usuario/ObtenerVariableSesion", true, "")
        //        .subscribe(resp => {
        //            if (resp.IsSuccess) {
        //                resolve(resp.Data);
        //            } else {
        //                resolve(null);
        //                console.log('Mostrar alerta de error');
        //            }
        //        }, error => {
        //            resolve(null);
        //            console.log('Mostrar alerta de error');
        //            return null;
        //        });
        //});
    }

    async VariableSesion() {
        //const valid = await this.getObtenerVariableSesion();
        //if (valid) {
        //    return true;
        //} else {
        this.router.navigate(["/permiso-error-pagina"]);
            return false;
        //}
    }

    //GetTipoUsuario(_token: string, ID: string) {
    //    return new Promise(resolve => {
    //        this.get(APIENDPOINT.getTipoUsuarios, true, _token)
    //            .subscribe(resp => {
    //                if (resp.IsSuccess) {
    //                    resolve(resp.Data);
    //                } else {
    //                    resolve(null);
    //                    console.log('Mostrar alerta de error');
    //                }
    //            }, error => {
    //                resolve(null);
    //                console.log('Mostrar alerta de error');
    //                return null;
    //            });
    //    });
    //}

    public getUsuario() {
        return this.http.get(this.urlBase + "api/Usuario/listarUsuarios");
    }

    public getUsuarioFiltroTipo(TipoID) {
        return this.http.get(this.urlBase + "api/Usuario/filtrarUsuariosTipo/" + TipoID);
    }
}
