export class ClsTipoPedidoModel {
    public TP_ID: number;

    public TP_PARAMETRO_ID: number;

    public TP_DESCRIPCION: string;

    public TP_DESCRIPCION_TIPO: string;

    public TP_COLOR: string;

    public TP_ICONO: string;

    public TP_APLICA_CLIENTE_NO_PAGA: boolean;

    public TP_REQUIERE_UBICACION: boolean;

    public TP_DEFAULT: boolean;
}
