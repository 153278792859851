import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MasterModel } from '../models/master.model';
import { BaseService } from './_Base.service';
import { environment } from '../../environments/environment';
import { APIENDPOINT } from '../config/configuration';
import { ClsImagenPromocionModel } from '../models/Web/ClsImagenPromocion.model';
import { DeleteModel } from '../models/delete.model';

@Injectable({
  providedIn: 'root'
})
export class ImagenPromoService extends BaseService<any, MasterModel> {

    private apiURL: string;

    constructor(protected _http: HttpClient) {
        super(_http, environment.apiGatewayURL)
        this.apiURL = environment.apiGatewayURL;
    }


  async GuardarImagenPromocion(iDatos: ClsImagenPromocionModel) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }

    return new Promise(resolve => {
      this.post(APIENDPOINT.GuardarImagenPromocion, iDatos, true, token)
        .subscribe(resp => {
          resolve(resp);
        }, error => {
          resolve(error.error);
          console.log('Error', error.error);
        });
    });
  }

  async EliminarImagenPromocion(iDatos: number) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }

      return new Promise(resolve => {
      this.post(APIENDPOINT.EliminarImagenPromocion, iDatos, true, token)
        .subscribe(resp => {
          resolve(resp);
        }, error => {
          resolve(error.error);
          console.log('Error', error.error);
        });
    });
  }


  async ConsultarImagenPromocion(_token: string, empresaId: number) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }
    if (empresaId != 0) {
      return new Promise(resolve => {
        this.get(APIENDPOINT.ListarImagenPromocion + "?EmpresaID=" + empresaId, true, token)
          .subscribe(resp => {
            if (resp.IsSuccess) {
              resolve(resp.Data);
            } else {
              resolve(null);
              console.log('Error al consultar configNegocio');
            }
          }, error => {
            resolve(null);
            console.log('Error al consultar configNegocio');
            return null;
          });
      });
    }
  }
}
