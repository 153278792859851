import { ClsMenuToppingModel } from './ClsMenuTopping.model';

export class ClsReferenciaToppingValorModel {
    public REFTOP_VALOR_TOTAL: number = 0;

    public REFTOP_VALOR_SUBTOTAL: number = 0;

    public REFTOP_VALOR_IMPUESTO: number = 0;

    public Menu_Toppings: ClsMenuToppingModel[];
}