import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ConfigEmpresaService } from '../../services/ConfigEmpresa.service';
import { ClsMapaKeyModel } from '../../models/Web/ClsMapaKey.model';
import { GeneralesService } from '../../services/_Generales.service';
import { escapeLeadingUnderscores } from 'typescript';
declare var window: any;

@Component({
  selector: 'app-mapa-key',
  templateUrl: './mapa-key.component.html',
  styleUrls: ['./mapa-key.component.css']
})
export class MapaKeyComponent implements OnInit {
  myGroup: FormGroup;
  empresaId: number;
  mensajeRespuesta: string = "";
  
  constructor(private mapaService: ConfigEmpresaService, private generales: GeneralesService) {

    let datosn: any = localStorage.getItem('negocio');
    if (datosn != null && datosn != '') {
      let objNego: any = JSON.parse(datosn);
      this.empresaId = objNego[0].NEGO_EMPRESA;
    }
    this.myGroup = new FormGroup({
      'TxtMapaKey': new FormControl('', Validators.required)
    });
    
    this.consultarMapaKey();
  }

  ngOnInit() {
    
  }

  async GuardarMapaKey() {
      this.generales.OpenDialogAjax();

    let objMapaKey: ClsMapaKeyModel = new ClsMapaKeyModel();
    objMapaKey.MK_EMPRESA_ID = this.empresaId;
    objMapaKey.MK_KEY_MAPS = this.myGroup.get("TxtMapaKey").value;
    objMapaKey.MK_IP_EQUIPO = localStorage.getItem('ip');
    let resp: any = await this.mapaService.GuardarMapaKey(objMapaKey);
    this.mensajeRespuesta = resp.Message;
    window.$('#myModal').modal('show');

      this.generales.CancelDialogAjax();
  }

  async consultarMapaKey() {
    let resp: any = await this.mapaService.ConsultarMapaKey(this.empresaId);
      if (resp != null) {
          if (resp.IsSuccess) {
              let objMapaKey = resp.Data;
              this.myGroup.get("TxtMapaKey").setValue(objMapaKey.MK_KEY_MAPS);
          }
    }
  }
}
