import { Pipe, PipeTransform, Type } from '@angular/core';

@Pipe({
    name: "contrasena"
})
export class ContrasenaPipe implements PipeTransform {
    transform(value: string, convertir: boolean = true): string {

        let nuevoPass = value;
        if (convertir) {
            nuevoPass = "";
            for (var i = 0; i < value.length; i++) {
                nuevoPass = nuevoPass + "*";
            }
        }
        
        return nuevoPass;
    }
}
