export class ClsTipoPedidoEmpresaConfigModel {
  public TP_ID: number;
  public TP_EMPRESA_ID: number;
  public TP_PARAMETRO_ID: string;
  public TP_ESTADO: string;
  public PARAM_DESCRIP: string;
    public TP_DESCRIPCION_TIPO: string;
    public PARAM_TIPO: string;
    public PARAM_PROPIEDADES: string;
}
