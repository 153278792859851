import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../services/_Auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ClsAccesoModel } from '../../models/Web/ClsAcceso.model';
import { ClsUsuarioAccesoModel } from '../../models/Web/ClsAccesoUsuario.model';
import { ClsAccesoNegocioModel } from '../../models/Web/ClsAccesoNegocio.model';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { GeneralesService } from '../../services/_Generales.service';
declare var window: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  loading: boolean;
  formaHome: FormGroup;
  
  acesso: ClsUsuarioAccesoModel;
  negocios: ClsAccesoNegocioModel[];
  displayedColumns: string[] = ['NEGO_ID', 'NEGO_NOMBRE'];
  dataSource:  MatTableDataSource<ClsAccesoNegocioModel>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;


  constructor(private _authService: AuthService, private _router: Router, private generales: GeneralesService) {
    this.loading = true;
    _authService.Salir();
  }

  async Ingresar() {
    try {

        //window.$('#myBootBox').modal('show');
        ////$("#myBootBox").on("show", function () {    // wire up the OK button to dismiss the modal when shown
        //    window.$("#myBootBox a.btn").on("click", function (e) {
        //        console.log("button pressed");   // just as an example...
        //        window.$("#myBootBox").modal('hide');     // dismiss the dialog
        //    });
        ////});

      if (this.formaHome.valid) {
          this.generales.OpenDialogAjax();

        let login = new ClsAccesoModel();
        login.IP = "0";
        login.EmpresaID = this.formaHome.controls["TxtEmpresaID"].value;
        login.Usuario = this.formaHome.controls["TxtUsuario"].value;
        login.Password = this.formaHome.controls["TxtPassword"].value;

        await this._authService.Ingresar(login);
        
        if (this._authService.ingresar) {
          this.negocios = await JSON.parse(localStorage.getItem('negocio'));
          this.dataSource = new MatTableDataSource<ClsAccesoNegocioModel>(this.negocios);
          this.paginator._intl.itemsPerPageLabel = "Registros por página";
          this.dataSource.paginator = this.paginator;       
        
            window.$('#modalSucursales').modal('show');
        }
        
      }
    } catch (e) {
      
      this._authService.respuesta = "Error intente mas tarde."
    }
    finally {
        this.generales.CancelDialogAjax();
    }
    
  }
  
  ngOnInit() {     
      this.loading = false;
      this.formaHome = new FormGroup({
        'TxtEmpresaID': new FormControl('', Validators.required),
        'TxtUsuario': new FormControl('',Validators.required),
        'TxtPassword': new FormControl('', Validators.required)
      });
    console.log(this.formaHome);
  }


  async seleccionar(negocio: number, todo:any) {
    console.log(todo);
    this._authService.loggedIn = true;
    this._authService.asignarNegocio(todo.NEGO_ULTCIERRE, todo.NEGO_NOMBRE);
    this._authService.loggedIn = true;
    await localStorage.setItem('negocio', '[' + JSON.stringify(todo) + ']');
      await window.$('#modalSucursales').modal('hide');
    this._router.navigate(['ppal']);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
