import { Injectable, EventEmitter, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { BaseService } from './_Base.service';
import { MasterModel } from '../models/master.model';
import { APIENDPOINT } from '../config/configuration';
import { ClsCarritoCompraModel } from 'src/app/models/Aplicacion/ClsCarritoCompra.model';
//import { ClsPedidoMedioPagoModel } from 'app/models/Aplicacion/ClsPedidoMedioPago.model';
//import { ResponseModel } from '../models/response.model';
//import { ClsReferenciaModel } from 'src/app/models/Aplicacion/ClsReferencia.model';
//import { ClsNegocioModel } from 'src/app/models/Aplicacion/ClsNegocio.model';
//import { debug } from 'util';
//import { ClsTercerosModel } from 'src/app/models/Aplicacion/ClsTerceros.model';


@Injectable({
  providedIn: 'root'
})
export class CarritoCompraService extends BaseService<any, MasterModel>{

  public Carrito_Compra: ClsCarritoCompraModel = new ClsCarritoCompraModel();

  private apiURL: string;

  constructor(
    protected _http: HttpClient,
  ) {
    super(_http, environment.apiGatewayURL);
    this.apiURL = environment.apiGatewayURL;
  }

  async ListarPedidoKey(_token: string, MensajeError: string, key: any) {
    let token = await localStorage.getItem('token');
    if (token == null || token == undefined) {
      token = "";
    }

    return new Promise(resolve => {
      this.get(APIENDPOINT.listarPedidoKey + "?" + "PedidoKEY=" + key, true, token)
        .subscribe(resp => {
          if (resp.IsSuccess) {
            resolve(resp.Data);
          } else {
            //this.uiService.ShowAlert(resp.Message, "Error");
            resolve(null);
          }
        }, error => {
          //this.uiService.ShowAlert(MensajeError, "Error");
          resolve(null);
        });
    });
  }


  VerificarPagoPedidoKey(_token: string, MensajeError: string, key: any) {
    return new Promise(resolve => {
      this.get(APIENDPOINT.verificarPagoPedidoKey + "?" + "PedidoKEY=" + key, true, _token)
        .subscribe(resp => {
          resolve(resp);
        }, error => {
          //this.uiService.ShowAlert(MensajeError, "Error");
          resolve(error.error);
        });
    });
  }

  //AsignarPedidoMedioPago(iDatos: ClsPedidoMedioPagoModel) {
  //    return new Promise(resolve => {
  //        this.post(APIENDPOINT.asignarPedidoMedioPago, iDatos)
  //            .subscribe(resp => {
  //                resolve(resp);
  //            }, error => {
  //                    resolve(error.error);
  //                console.log('Error', error);
  //            });
  //    });
  //}

  AsignarPagoWompi(_token: string, MensajeError: string, key: any) {
    return new Promise(resolve => {
      this.get(APIENDPOINT.asignarPagoWompi + "?" + "id=" + key, true, _token)
        .subscribe(resp => {
          resolve(resp);
        }, error => {
          //this.uiService.ShowAlert(MensajeError, "Error");
          resolve(error.error);
        });
    });
  }
}
