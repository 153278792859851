export class ClsImagenPromocionModel {
  public IMG_ID: number;
  public IMG_EMPRESA_ID: number;
  public IMG_URL_IMAGEN: string;
  public IMG_FECHA_INI: string;
  public IMG_FECHA_FIN: string;
  public IMG_HORA_INI: string;
  public IMG_HORA_FIN: string;
  public IMG_USUARIO_ID: number;
  public IMG_IP_EQUIPO: string;
  public IMG_HIPERVINCULO: string;
}
