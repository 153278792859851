import { Component, OnInit } from '@angular/core';
import { SpotifyService } from '../../services/spotify.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
    artistas: any[] = [];
    loading: boolean = false;

    constructor(private spotify: SpotifyService) {
    }

  ngOnInit() {
  }

    buscar(termino: string) {
        console.log(termino);
        if (termino != "") {
            this.loading = true;

            this.spotify.getArtistas(termino).subscribe((data: any) => {
                console.log(data);
                this.artistas = data;
                this.loading = false;
            });
        }
    }

}
