import { ClsMenuDetalleArticuloModel } from './ClsMenuDetalleArticulo.model';

export class ClsMenuDetalleModel {
    public DETALLE_ID: number;

    public DETALLE_NOMBRE: string;

    public DETALLE_DESCRIPCION: string;

    public DETALLE_VARIOS: boolean;

    public DETALLE_TOPPINGS: boolean;

    public DETALLE_AVATAR: string;

    public Articulo_Unitario: ClsMenuDetalleArticuloModel;
}