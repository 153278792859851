import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[appResaltado]',
})
export class ResaltadoDirective {
    
    constructor(private _elementRef: ElementRef) {
        console.log("Directiva llamada");
        this._elementRef.nativeElement.style.backgroundColor = "red";
    }

    @HostListener('mouseenter')
    mouseEntro() {
        this._elementRef.nativeElement.style.backgroundColor = "pink";
    }

    @HostListener('mouseleave')
    mouseSalio() {
        this._elementRef.nativeElement.style.backgroundColor = "orange";
    }

}
