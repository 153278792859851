import { ClsMedioPagoTipoPedidoModel } from "./ClsMedioPagoTipoPedido.model";

export class ClsMedioPagoNegocioModel {   
  public MPN_ID: number;
  public MPN_MEDIOPAGO_ID: number;
  public MPN_NEGOCIO_ID: number;
  public MPN_KEY: string;
  public MPN_AMBIENTE_PRUEBA: string;
  public MPN_DESCRIPCION_FACTURA: string;
  public MPN_ESTADO: string;
  public LISTADO_TIPO_PEDIDO: ClsMedioPagoTipoPedidoModel[];
}
