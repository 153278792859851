import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MasterModel } from '../models/master.model';
import { BaseService } from './_Base.service';
import { environment } from '../../environments/environment';
import { APIENDPOINT } from '../config/configuration';
import { ClsAccesoModel } from '../models/Web/ClsAcceso.model';

@Injectable({
  providedIn: 'root'
})
export class AccesoService extends BaseService<ClsAccesoModel, MasterModel> {

    private apiURL: string;

    constructor(protected _http: HttpClient) {
        super(_http, environment.apiGatewayURL)
        this.apiURL = environment.apiGatewayURL;
    }


  VerificarLogin(iDatos: ClsAccesoModel) {
    return new Promise(resolve => {
      this.post(APIENDPOINT.verificarLogin, iDatos, false, '')
        .subscribe(resp => {          
          resolve(resp);
        }, error => {            
                console.log('Error', error.error);
                resolve(null);
        });
    });
  }

}
