export class ClsTiempoEsperaNegocioModel {
 
  public TE_NEGO_ID: number;

  public TE_PARAM_ID: number;

  public TE_ESTADO: string;

  public TE_TIPO: string;

  public PARAM_DESCRIP: string;
}
