import { Component, OnInit } from '@angular/core';
import { PedidoService } from '../../services/Pedido.service';
import { ClsConsultaModel } from '../../models/Web/ClsConsulta.model';
import { ClsPedidoModel } from '../../models/Web/Clspedido.model';
import { ClsCancelaPedidoModel } from '../../models/Web/ClsCancelaPedido.model'
import { ClsPedidoEstadoModel } from '../../models/Web/ClsPedidoEstado.model';
import { ClsAccesoNegocioModel } from '../../models/Web/ClsAccesoNegocio.model';
import { ClsUsuarioAccesoModel } from '../../models/Web/ClsAccesoUsuario.model';
import { SignalRService } from '../../services/SignalR/signalR.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ClsTercerosDomicilioModel } from '../../models/Web/ClsTercerosDomicilio.model';
import { ClsPedidoDomiciliarioModel } from '../../models/Web/ClsPedidoDomiciliario.model';
import { TipoPedidoEmpresaService } from '../../services/TipoPedidoEmpresa.service';
import { ClsTipoPedidoEmpresaConfigModel } from '../../models/Web/ClsTipoPedidoEmpresa.model';
import { PedidoCallCenterService } from '../../services/PedidoCallCenter.service';
import { ClsTipoPedidoModel } from '../../models/Aplicacion/ClsTipoPedidoEmpresa.model';
import { GeneralesService } from '../../services/_Generales.service';
import { ClsTercerosModel } from '../../models/Aplicacion/ClsTerceros.model';
import { ClsTercerosDireccionesModel } from '../../models/Aplicacion/ClsTercerosDirecciones.model';
import { ClsNegocioModel } from '../../models/Aplicacion/ClsNegocio.model';
import { ClsMenuVentaModel } from '../../models/Aplicacion/ClsMenuVenta.model';
import { ClsMenuDetalleModel } from '../../models/Aplicacion/ClsMenuDetalle.model';
import { ClsMenuDetalleArticuloModel } from '../../models/Aplicacion/ClsMenuDetalleArticulo.model';
import { ClsKanbanPedidoModel } from '../../models/KanbanPedido/ClsKanbanPedido.model';
import { AuthService } from '../../services/_Auth.service';
import { NegocioService } from '../../services/Negocio.service';
import { ClsTerceroLoginModel } from '../../models/Aplicacion/ClsTerceroLogin.model';
import { TerceroService } from '../../services/Tercero.service';
declare var window: any;
declare var crearTicketImpresionWebBase64PDF;

@Component({
  selector: 'app-admin-pedidos-online',
  templateUrl: './admin-pedidos-online.component.html',
  styleUrls: ['./admin-pedidos-online.component.css']
})
export class AdminPedidosOnlineComponent implements OnInit {
  key: string = "";
  fomulario_Registrarse: FormGroup;
  fomulario_CreaCuenta: FormGroup;
  fomulario_Login: FormGroup;
  Negocio_Nombre: string = "......";
  Negocio_Logo: string = "https://appwebdata.globussistemas.com/uploads/foto.png";
  Negocio_Datos: any = null;
  Tercero_Datos: any = null;
  Tercero_LoggedIn: boolean = false;

  tmp_Tercero_Nombre: string = "";
  tmp_Negocio_Nombre: string = "";

  validation_messages = {
    Correo: [
      { type: 'required', message: 'El dato es requerido.' },
      { type: 'pattern', message: 'El dato no es valido.' },
      { type: 'email', message: 'El formato no es valido.' }
    ],
    Password: [
      { type: 'required', message: 'Los datos son requeridos.' },
      { type: 'minlength', message: 'Por lo menos 6 caracteres.' },
      { type: 'pattern', message: 'Debe tener letra mayuscula.' },
      { type: 'pattern', message: 'Debe tener letra minuscula.' },
      { type: 'pattern', message: 'Debe tener numeros.' },
      { type: 'maxlength', message: 'Sobrepasa los caracteres permitidos.' }
    ],
  };

  ip: string;
  empresa: number;
  negoId: number = 0;
  local: string = "";
  href: string = "";
  usuId: number = 0;
  ultId: number = 0;
  Top: number = 100;
  listaEspera: ClsPedidoModel[] = [];
  listaPreparacion: ClsPedidoModel[] = [];
  listaReparto: ClsPedidoModel[] = [];
  pedidoSeleccionado: ClsPedidoModel = new ClsPedidoModel();
  formaDomicilio: FormGroup;
  listaDomiciliarios: ClsTercerosDomicilioModel[] = [];
  audio = new Audio("../../assets/audio/notificacion.wav");
  reproducir = true;
  mensaje: string = "";
  domicilioSeleccionado: number = -1;

  HistorialPedidos: ClsKanbanPedidoModel[] = [];
  Detalle_Id: string = "";
  Historial_UltimoID = 0;
  Historial_Top = 10;
  PedidoEliminar: any;
  Motivo: string = "";
  //********************************************************************************//
  frmTipoPedido: FormGroup;
  frmDireccion: FormGroup;

  ListadoTipoPedido: ClsTipoPedidoModel[] = [];
  ListadoNegocio: ClsNegocioModel[] = [];

  Asignado_TipoPedido: ClsTipoPedidoModel;
  Asignado_Tercero: ClsTercerosModel;
  Asignado_Tercero_CargarMapa: string = "N";
  Asignado_Direccion: ClsTercerosDireccionesModel;
  Asignado_Direccion_Eliminar: ClsTercerosDireccionesModel;

  Asignado_Negocio: ClsNegocioModel;
  Asignado_MenuVenta: ClsMenuVentaModel;
  Asignado_MenuDetalle: ClsMenuDetalleModel;
  Asignado_MenuDetalleArticulo: ClsMenuDetalleArticuloModel;

  CreandoCliente: boolean = false;
  CaracterVacio: string = "---";
  ListaNegociosHistorial: ClsAccesoNegocioModel[] = [];
  NegoHistorial: number = 0;
  PedidoObservacion: string = "";
  //********************************************************************************//

  constructor(
    public auth: AuthService,
    public negocioService: NegocioService,
    public terceroService: TerceroService,
    public pedidoService: PedidoService,
    private signalR: SignalRService,
    private router: Router,
    private pedidoCallCenterService: PedidoCallCenterService,
    private generales: GeneralesService,
    private activatedRoute: ActivatedRoute,
  ) {

    this.auth.mostrar_Barra_Menu = false;

    this.activatedRoute.params
      .subscribe(param => {
        console.log("Parametros", param);;
        this.key = param.Key
      });
  }

  async ngOnInit() {
    await this.InicializarFormulario_Login();
    await this.ListarInformacionNegocio()
  }

  async InicializarFormulario_Login() {
    this.fomulario_Login = new FormGroup({
      Empresa_ID: new FormControl(0),
      Negocio_ID: new FormControl(0),
      Negocio_Key: new FormControl(""),
      Tercero_ID: new FormControl(0),
      Tercero_Key: new FormControl(""),
      Correo: new FormControl(""),
      Password: new FormControl(""),
      Recordarme: new FormControl(false)
    });

    /*this.fomulario_Login.controls['Correo'].setValidators([Validators.required, Validators.minLength(5)]);*/
    this.fomulario_Login.controls['Correo'].setValidators([Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]);
    this.fomulario_Login.controls['Password'].setValidators([Validators.required, Validators.minLength(5)]);
  }

  async ListarInformacionNegocio() {
    this.generales.OpenDialogAjax();

    try {
      var response: any = await this.negocioService.NegocioPedidoOnlineListar(this.key);
      if (response.IsSuccess) {
        if (response.Data != null) {
          this.Negocio_Datos = response.Data;
          this.Negocio_Nombre = response.Data.NEGO_NOMBRE;
          if (response.Data.CN_LOGO != "") {
            this.Negocio_Logo = response.Data.CN_LOGO;
          }
        }
      } else {
        this.generales.MostrarMensajeERROR(response.Message);
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.generales.CancelDialogAjax();
    }
  }

  async IniciarSesionCliente() {
    try {
      this.generales.OpenDialogAjax();

      var login: ClsTerceroLoginModel = new ClsTerceroLoginModel();
      login.Empresa_ID = this.Negocio_Datos.NEGO_EMPRESA;
      login.Negocio_ID = this.Negocio_Datos.NEGO_ID;
      login.Negocio_Key = this.key;
      login.Tercero_ID = 0;
      login.Tercero_Key = "";
      login.Correo = this.fomulario_Login.get('Correo').value;;
      login.Password = this.fomulario_Login.get('Password').value;

      var response: any = await this.terceroService.TerceroPedidoOnlineLogin(login);
      if (response.IsSuccess) {
        if (response.Data != null) {
          var data = response.Data;
          this.Tercero_Datos = data.Tercero;

          await localStorage.setItem('sesion', JSON.stringify(data));
          await localStorage.setItem('negocio', JSON.stringify(data.Acceso.LISTADO_NEGOCIO));
          await localStorage.setItem('opciones', JSON.stringify(data.Acceso.LISTADO_OPCIONES));
          await localStorage.setItem('ip', data.Acceso.IP);
          await localStorage.setItem('token', data.Acceso.TOKEN);
          if (data.Tercero != undefined && data.Tercero != null) {
            await localStorage.setItem('tercero', JSON.stringify(data.Tercero));
            await this.AsignarTercero(data.Tercero);
          }
          this.Tercero_LoggedIn = true;
          await this.ListarInformacionInicio();
        }
      } else {
        this.generales.MostrarMensajeERROR(response.Message);
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.generales.CancelDialogAjax();
    }
  }

  async CrearCuentaCliente() {
    try {
      await this.AbrirModal_CrearCuenta();
    } catch (e) {
      console.log(e);
    }
  }

  async AbrirModal_CrearCuenta() {
    window.$('#modalCrearCuenta').modal('show');
  }

  async CerrarModal_CrearCuenta() {
    window.$('#modalCrearCuenta').modal('hide');
  }

  async RecuperarPasswordCliente() {
    try {
      await this.AbrirModal_RecuperarPassword();
    } catch (e) {
      console.log(e);
    }
  }

  async AbrirModal_RecuperarPassword() {
    window.$('#modalRecuperarPassword').modal('show');
  }

  async CerrarModal_RecuperarPassword() {
    window.$('#modalRecuperarPassword').modal('hide');
  }

  async ValidaRecuperarPassword() {
    try {

    } catch (e) {

    } finally {
      this.CerrarModal_RecuperarPassword();
    }
  }

  async ListarInformacionInicio() {
    try {
      this.generales.OpenDialogAjax();

      this.formaDomicilio = new FormGroup({
        'CboDomiciliarios': new FormControl("-1")
      });

      this.frmTipoPedido = new FormGroup({
      });

      this.frmDireccion = new FormGroup({
      });

      let datos: any = localStorage.getItem('negocio');
      if (datos != null && datos != '') {
        let objNego = new ClsAccesoNegocioModel();
        objNego = JSON.parse(datos);
        this.negoId = objNego[0].NEGO_ID;
        this.empresa = objNego[0].NEGO_EMPRESA;
        this.local = objNego[0].NEGO_SISTEMA_LOCAL;
      }

      let datos1: any = localStorage.getItem('sesion');
      if (datos1 != null && datos1 != '') {
        let objUsu = JSON.parse(datos1);
        this.usuId = objUsu.Acceso.USU_ID;
        this.ListaNegociosHistorial = objUsu.Acceso.LISTADO_NEGOCIO;
      }

      this.ip = localStorage.getItem('ip');

      //this.audio = new Audio("../../assets/audio/bonus.wav");
      //this.audio.load();
      //this.audio.canPlayType("audio/*");
      //this.reproducir = true;

      await this.ListarTipoPedidosEmpresa();
    } catch (e) {
      console.log(e);
    } finally {
      this.generales.CancelDialogAjax();
    }
  }

  async ListarTipoPedidosEmpresa() {
    let result: any = await this.pedidoCallCenterService.CallCenterTipoPedidoListar(this.empresa);
    if (result != null) {
      if (result.IsSuccess) {
        this.ListadoTipoPedido = result.Data;

        for (var i = 0; i < this.ListadoTipoPedido.length; i++) {
          if (this.ListadoTipoPedido[i].TP_DEFAULT == true) {
            this.Asignado_TipoPedido = this.ListadoTipoPedido[i];
            await this.pedidoCallCenterService.AgregarCarrito_TipoPedido(this.Asignado_TipoPedido);
          }
        }
      } else {
        console.log('Error al Call Center Tipo Pedido Listar: ' + result.Message);
      }
    } else {

    }
  }

  async AsignarTipoPedido(tipoPedido: any) {
    this.generales.OpenDialogAjax();

    console.log("AsignarTipoPedido: ", tipoPedido);
    this.Asignado_TipoPedido = tipoPedido;
    await this.pedidoCallCenterService.AgregarCarrito_TipoPedido(this.Asignado_TipoPedido);

    this.generales.CancelDialogAjax();
    if (this.Asignado_TipoPedido.TP_DESCRIPCION != "PEDIDO_DOMICILIO") {
      await this.ListarNegocio();
    }
  }

  async AsignarTercero(data: any) {
    console.log("Tercero seleccionado", data);
    this.Asignado_Tercero = null;
    this.Asignado_Tercero_CargarMapa = "N";
    setTimeout(async () => {
      this.Asignado_Tercero = data;

      await this.pedidoCallCenterService.AgregarCarrito_TerceroPedido(this.Asignado_Tercero);
      await this.pedidoCallCenterService.AgregarCarrito_TerceroPaga(this.Asignado_Tercero);

      await this.LimpiarDireccionAsignado();
      await this.LimpiarNegocioListado();
    }, 100);
  }

  async AceptaBuscarDireccion(data: any) {
    try {
      console.log("AceptaBuscarDireccion", data);
      if (this.Asignado_Tercero != null) {
        if (data.IsSuccess) {
          let direccionTmp = new ClsTercerosDireccionesModel();
          direccionTmp.DT_ID = 0;
          direccionTmp.DT_TERCERO_ID = this.Asignado_Tercero.TER_ID;
          direccionTmp.DT_GEO_LATITUD = data.lat;
          direccionTmp.DT_GEO_LONGITUD = data.lng;
          direccionTmp.DT_GEO_DIRECCION = data.formatted_address;
          direccionTmp.DT_DIRECCION = data.formatted_address;
          direccionTmp.DT_INDICACIONES = data.indicaciones;
          direccionTmp.DT_FAVORITA = false;
          direccionTmp.DT_REGISTRADA = false;
          direccionTmp.DT_IP_EQUIPO = this.ip;
          direccionTmp.DT_SELECCIONADA = true;

          //GUARDAR DIRECCION
          this.generales.OpenDialogAjax();
          let result: any = await this.pedidoCallCenterService.CallCenterDireccionGuardar(direccionTmp);
          if (result != null) {
            if (result.IsSuccess) {
              this.Asignado_Tercero.Direcciones = result.Data;
              if (this.Asignado_Tercero.Direcciones == null) {
                this.Asignado_Tercero.Direcciones = [];
              } else {
                for (var i = 0; i < this.Asignado_Tercero.Direcciones.length; i++) {
                  if (this.Asignado_Tercero.Direcciones[i].DT_SELECCIONADA == true) {
                    direccionTmp = this.Asignado_Tercero.Direcciones[i];
                  }
                }
              }
              this.Asignado_Direccion = direccionTmp;
              await this.pedidoCallCenterService.AgregarCarrito_Direccion(this.Asignado_Direccion);
            } else {
              console.log('Error al Guardar Dirección: ' + result.Message);
              this.generales.MostrarMensajeERROR(result.Message);
            }
          } else {
            this.generales.MostrarMensajeERROR("No es posible almacenar la información de la dirección en este momento.");
          }
          this.generales.CancelDialogAjax();

          await this.ListarNegocio();
        } else {
          this.generales.MostrarMensajeERROR("Error al procesar la dirección del cliente, por favor intente nuevamente.");
        }
      } else {
        this.generales.MostrarMensajeERROR("No ha seleccionado un cliente para agregar la dirección, por favor verifique.");
      }

    } catch (e) {
      this.generales.CancelDialogAjax();
    } finally {

    }
  }

  async AceptaCrearTercero(data: any) {
    console.log("AceptaCrearTercero", data);
    this.Asignado_Tercero = null;

    setTimeout(async () => {
      this.Asignado_Tercero = data;

      await this.pedidoCallCenterService.AgregarCarrito_TerceroPedido(this.Asignado_Tercero);
      await this.pedidoCallCenterService.AgregarCarrito_TerceroPaga(this.Asignado_Tercero);

      await this.LimpiarDireccionAsignado();
      await this.LimpiarNegocioListado();
    }, 100);
  }

  async AsignarDireccion(data: any) {
    this.Asignado_Direccion = null;

    setTimeout(async () => {
      this.Asignado_Direccion = data;

      await this.pedidoCallCenterService.AgregarCarrito_Direccion(this.Asignado_Direccion);

      await this.ListarNegocio();
    }, 100);
  }

  async ListarNegocio() {
    try {
      this.generales.OpenDialogAjax();

      await this.LimpiarNegocioAsignado();

      if (((this.Asignado_TipoPedido.TP_DESCRIPCION == "PEDIDO_DOMICILIO" && this.Asignado_Direccion != null) || this.Asignado_TipoPedido.TP_DESCRIPCION != "PEDIDO_DOMICILIO") && this.empresa != null && this.empresa != 0 && this.empresa != undefined) {
        this.generales.OpenDialogAjax();
        let latitudD = 0;
        let longitudD = 0;
        if (this.Asignado_TipoPedido.TP_DESCRIPCION == "PEDIDO_DOMICILIO") {
          latitudD = this.Asignado_Direccion.DT_GEO_LATITUD;
          longitudD = this.Asignado_Direccion.DT_GEO_LONGITUD;
        }
        let result: any = await this.pedidoCallCenterService.CallCenterNegocioIdPorTipoPedidoListar(this.empresa, this.negoId, latitudD, longitudD, this.Asignado_TipoPedido.TP_ID);
        if (result != null) {
          if (result.IsSuccess) {
            console.log('Negocios: ', result.Data);
            //this.ListadoNegocio = result.Data;
            if (result.Data != null && result.Data.length > 0) {
              this.AsignarNegocio(result.Data[0]);
            } else {
              this.generales.MostrarMensajeERROR("No hay información de la sucursal para realizar el pedido a su dirección, intente más tarde.");
            }
          } else {
            console.log('Error al listar los negocios: ' + result.Message);
            this.generales.MostrarMensajeERROR(result.Message);
          }
        } else {
          this.generales.MostrarMensajeERROR("No es posible listar la información de los negocios en este momento.");
        }
        this.generales.CancelDialogAjax();
      } else {
        console.log('Error en información no definida para listar los negocio');
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.generales.CancelDialogAjax();
    }
  }

  async AsignarNegocio(data: any) {
    console.log("AsignarNegocio", data);
    await this.LimpiarNegocioAsignado();

    setTimeout(async () => {
      this.Asignado_Negocio = data;
      await this.pedidoCallCenterService.AgregarCarrito_Negocio(this.Asignado_Negocio);
      window.$('#modalMenuPedido').modal('show');
    }, 100);
  }

  async AsignaMenuVenta(data: any) {
    console.log("AsignaMenuVenta", data);
    this.Asignado_MenuVenta = null;

    setTimeout(() => {
      this.Asignado_MenuVenta = data;
    }, 100);
  }

  async AsignaMenuDetalle(data: any) {
    console.log("AsignaMenuDetalle", data);
    this.Asignado_MenuDetalle = null;

    setTimeout(async () => {
      this.Asignado_MenuDetalle = data;

      if (!data.DETALLE_VARIOS) {
        this.AsignaMenuDetalleArticulo(data.Articulo_Unitario);
      } else {
        await this.AbrirModal_MenuDetalle(data);
      }
    }, 100);
  }

  async AsignaMenuDetalleArticulo(data: any) {
    console.log("AsignaMenuDetalleArticulo", data);
    this.Asignado_MenuDetalleArticulo = null;

    setTimeout(async () => {
      this.Asignado_MenuDetalleArticulo = data;

      await this.AbrirModal_MenuDetallePersonalizar(data);
    }, 100);
  }

  async AbrirModal_MenuDetalle(data: any) {
    window.$('#modalMenuDetalleArticulo').modal('show');
  }

  async AbrirModal_MenuDetallePersonalizar(data: any) {
    window.$('#modalMenuDetallePersonalizar').modal('show');
  }

  async LimpiarNegocioListado() {
    this.ListadoNegocio = [];
  }
  async LimpiarNegocioAsignado() {
    this.Asignado_Negocio = null;
  }
  async LimpiarDireccionAsignado() {
    this.Asignado_Direccion = null;
  }
  async LimpiarTerceroAsignado() {
    this.Asignado_Tercero = null;
  }

  async ValidaCancelarPedido() {
    window.$('#modalCancelarPedido').modal('show');
  }

  async CancelarPedido() {
    window.$('#modalMenuPedido').modal('hide');
  }

  async AceptaPersonalizarArticulo(data: any) {
    console.log("AceptaPersonalizarArticulo", data);
  }

  async AbrirModal_CarritoCompra() {
    window.$('#modalCarritoCompra').modal('show');
  }

  async CerrarModal_CarritoCompra(data: any) {
    window.$('#modalCarritoCompra').modal('hide');
  }

  async VerificarTiempoEspera(event: any, Id: number) {
    for (var i = 0; i < this.pedidoCallCenterService.Carrito_Compra.Tiempo_Espera.length; i++) {
      if (this.pedidoCallCenterService.Carrito_Compra.Tiempo_Espera[i].ID == Id) {
        this.pedidoCallCenterService.Carrito_Compra.Tiempo_Espera[i].SELECCIONADO = true;
      } else {
        this.pedidoCallCenterService.Carrito_Compra.Tiempo_Espera[i].SELECCIONADO = false;
      }
    }
  }

  async ValidarTiempoEsperaSeleccionado() {
    try {
      if (this.pedidoCallCenterService.Carrito_Compra.Tiempo_Espera != null) {
        if (this.pedidoCallCenterService.Carrito_Compra.Tiempo_Espera.length > 0) {
          for (var i = 0; i < this.pedidoCallCenterService.Carrito_Compra.Tiempo_Espera.length; i++) {
            if (this.pedidoCallCenterService.Carrito_Compra.Tiempo_Espera[i].SELECCIONADO) {
              return true;
            }
          }
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async ConfirmarPedido() {
    //let Cargar = await this.uiService.iniciarLoadingNEW('');
    await this.generales.OpenDialogAjax();
    try {
      if (this.Asignado_Negocio.Tiempo_Entrega.ID == -2) {
        await this.generales.MostrarMensajeERROR("Por favor seleccione la zona del pedido.");
        return;
      }

      let validaTiempoEspera = await this.ValidarTiempoEsperaSeleccionado();
      if (validaTiempoEspera) {
        console.log("obs", this.PedidoObservacion);
        const result: any = await this.pedidoCallCenterService.CallCenterPedidoGuardar(this.negoId, this.usuId, this.PedidoObservacion, "ONLINE");
        if (result != null) {
          if (result.IsSuccess) {
            this.tmp_Tercero_Nombre = this.Asignado_Tercero.TER_NOMBRES + " " + this.Asignado_Tercero.TER_APELLIDOS;
            this.tmp_Negocio_Nombre = this.Asignado_Negocio.NEGO_NOMBRE;

            window.$('#modalConfirmacionPedido').modal('show');

            this.LimpiarPedido();
            //await this.generales.MostrarMensajeOK(result.Message);
          } else {
            await this.generales.MostrarMensajeERROR(result.Message);
          }
        } else {
          await this.generales.MostrarMensajeERROR("No es posible almacenar la información, intente nuevamente.");
        }
      }
    } catch (e) {
      console.log(e);
    }
    finally {
      await this.generales.CancelDialogAjax();
    }
  }

  async VerificarMedioPago(Control: any, MedioPago: any) {
    this.pedidoCallCenterService.Carrito_Compra.Negocio.MedioPago.forEach((medio => {
      if (medio.MedioPago_Negocio_ID == MedioPago.MedioPago_Negocio_ID) {
        medio.Seleccionado = true;
        this.pedidoCallCenterService.AgregarCarrito_MedioPago(MedioPago);
      } else {
        medio.Seleccionado = false;
      }
    }));
  }

  async LimpiarPedido() {
    window.$('#modalCarritoCompra').modal('hide');
    window.$('#modalMenuPedido').modal('hide');

    //this.Asignado_TipoPedido = null;
    //this.Asignado_Tercero = null;
    //this.Asignado_Direccion = null;

    /*this.Asignado_Negocio = null;*/
    this.Asignado_MenuVenta = null;
    this.Asignado_MenuDetalle = null;
    this.Asignado_MenuDetalleArticulo = null;

    this.pedidoCallCenterService.VaciarCarritoCompra();

    //this.ListadoNegocio = [];
  }

  async AbrirHistorialPedidos() {
    try {
      this.Detalle_Id = "";
      this.Historial_UltimoID = 0;
      this.HistorialPedidos = [];

      setTimeout(async () => {
        window.$('#modalHistorialPedidos').modal('show');

        await this.CargarHistorialPedidos();
      }, 100);
    } catch (e) {

    } finally {

    }
  }

  CambiarNegocioHistorial() {
    this.Historial_UltimoID = 0;
    this.HistorialPedidos = [];
    this.CargarHistorialPedidos();
  }


  async CargarHistorialPedidos() {
    try {

      this.generales.OpenDialogAjax();

      let resp: any = await this.pedidoService.ListarPedidoHistorial(this.empresa, this.NegoHistorial, this.Historial_UltimoID, this.Historial_Top);
      console.log("negocio", this.NegoHistorial);
      if (resp != null) {
        console.log("resp negocio", resp);
        if (resp.IsSuccess) {
          if (resp.Data != null) {
            for (var i = 0; i < resp.Data.length; i++) {
              let Pedido = resp.Data[i];
              this.Historial_UltimoID = Pedido.Informacion_Pedido.Pedido_ID;
              this.HistorialPedidos.push(Pedido);
            }
            console.log("historial", this.HistorialPedidos);
          }
        } else {
          this.generales.MostrarMensajeERROR(resp.Message);
        }
      } else {
        this.generales.MostrarMensajeERROR("Error listando historial de pedidos.");
      }
    } catch (e) {

    } finally {
      await this.generales.CancelDialogAjax();
    }
  }

  async DetalleHistorialPedido(Pedido: ClsKanbanPedidoModel) {
    if (this.Detalle_Id == "Detalle_" + Pedido.Informacion_Pedido.Pedido_ID) {
      this.Detalle_Id = "";
    } else {
      this.Detalle_Id = "Detalle_" + Pedido.Informacion_Pedido.Pedido_ID;
    }
  }

  ValidarCancelarPedido(Pedido: any) {
    this.PedidoEliminar = Pedido;
    window.$('#modalCancelarPedidoGuardado').modal('show');
  }

  async GuadarCancelarPedido(Pedido: ClsKanbanPedidoModel) {
    if (this.Motivo == "") {

    } else {
      try {
        let cancela: ClsCancelaPedidoModel = new ClsCancelaPedidoModel();
        cancela.EmpresaId = this.empresa;
        cancela.IpEquipo = this.ip;
        cancela.UsuId = this.usuId;
        cancela.Motivo = this.Motivo;
        cancela.NegoId = Pedido.Negocio.NEGO_ID;
        cancela.PedidoId = Pedido.Informacion_Pedido.Pedido_ID;

        this.generales.OpenDialogAjax();
        let resp: any = await this.pedidoService.CancelarPedidoId(cancela);
        if (resp != null) {
          if (resp.IsSuccess) {
            this.generales.MostrarMensajeOK(resp.Message);
            window.$('#modalHistorialPedidos').modal('hide');
          } else {
            this.generales.MostrarMensajeERROR(resp.Message);
          }
        } else {
          this.generales.MostrarMensajeERROR("Error cancelando el pedido.");
        }

      } catch (e) {

      } finally {
        await this.generales.CancelDialogAjax();
        this.Motivo = "";
        window.$('#modalCancelarPedidoGuardado').modal('hide');
      }
    }
  }

  ValidarEliminarDireccionTercero(direccion: any) {
    window.$('#modalEliminarDireccionTercero').modal('show');
    this.Asignado_Direccion_Eliminar = direccion;
    console.log("si llega", direccion);
  }

  async EliminarDireccionTercero() {
    try {
      this.generales.OpenDialogAjax();
      console.log("direccionid", this.Asignado_Direccion_Eliminar.DT_ID);
      console.log("direccionid", this.Asignado_Direccion_Eliminar.DT_ID);
      let resp: any = await this.pedidoCallCenterService.CallCenterDireccionEliminar(this.Asignado_Direccion_Eliminar.DT_ID);
      if (resp != null) {
        if (resp.IsSuccess) {
          this.generales.MostrarMensajeOK(resp.Message);
          window.$('#modalEliminarDireccionTercero').modal('hide');
          let dt = this.Asignado_Tercero.Direcciones.findIndex(s => s == this.Asignado_Direccion_Eliminar);
          this.Asignado_Tercero.Direcciones.splice(dt, 1);
        } else {
          this.generales.MostrarMensajeERROR(resp.Message);
        }
      } else {
        this.generales.MostrarMensajeERROR("Error eliminando dirección, intente mas tarde.");
      }
    } catch (e) {

    } finally {
      window.$('#modalEliminarDireccionTercero').modal('hide');
      await this.generales.CancelDialogAjax();
    }
  }

  CargarMapa() {
    this.Asignado_Tercero_CargarMapa = "S";
  }

  async ControlarEvento_RecuperarPassword(event: any) {
    try {
      console.log(event);
      this.CerrarModal_RecuperarPassword();
    } catch (e) {
      console.log(e);
    }
  }
}
