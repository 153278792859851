import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ClsReferenciaModel } from '../../models/Web/ClsReferencia.model';
import { ConfigEmpresaService } from '../../services/ConfigEmpresa.service';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { PedidoCallCenterService } from '../../services/PedidoCallCenter.service';
import { ClsTercerosModel } from '../../models/Aplicacion/ClsTerceros.model';
import { GeneralesService } from '../../services/_Generales.service';


@Component({
    selector: 'app-terceroBuscar',
    templateUrl: './terceroBuscar.component.html',
    styleUrls: ['./terceroBuscar.component.css']
})
export class TerceroBuscarComponent implements OnInit {
    //********************************************************************************//
    @Output() eventoTercero: any = new EventEmitter();

    EmpresaId: number;

    Descripcion: string = "";

    ListadoTerceros: ClsTercerosModel[] = [];

    ListadoTipoBusqueda = [
        {
            Tipo: "TELEFONO",
            Descripcion: "Teléfono",
            Seleccionado: true
        },
        {
            Tipo: "DOCUMENTO",
            Descripcion: "Documento",
            Seleccionado: false
        },
        {
            Tipo: "NOMBRE",
            Descripcion: "Nombre",
            Seleccionado: false
        }
    ];

    TipoBusqueda: any = null;


    BuscandoCliente: boolean = false;
    ExisteCliente: boolean = true;
    //********************************************************************************//

    constructor(private pedidoCallCenterService: PedidoCallCenterService, private generales: GeneralesService) {
        let datosn: any = localStorage.getItem('negocio');
        if (datosn != null && datosn != '') {
            let objNego: any = JSON.parse(datosn);
            this.EmpresaId = objNego[0].NEGO_EMPRESA;
        }
    }


    async  ngOnInit() {
        for (var i = 0; i < this.ListadoTipoBusqueda.length; i++) {
            if (this.ListadoTipoBusqueda[i].Seleccionado) {
                this.AsignarTipoBusqueda(this.ListadoTipoBusqueda[i]);
            }
        }
    }

    async LimpiarControl(data: any) {
        console.log("Limpiar control tercero", data.srcElement.value);
        //data.srcElement.value = "";
        this.Descripcion = "";
        this.ExisteCliente = true;
    }

    async EscribeTexto(data: any) {
        console.log("Buscar información tercero", data.srcElement.value);

        this.ExisteCliente = true;

        if (data.srcElement != null) {
            if (data.srcElement.value != "") {

                if (data.srcElement.value.length >= 3) {
                    this.BuscandoCliente = true;

                    let result: any = await this.pedidoCallCenterService.CallCenterTerceroBuscarPorTipo(this.EmpresaId, data.srcElement.value, this.TipoBusqueda.Tipo);
                    if (result != null) {
                        if (result.IsSuccess) {
                            console.log(result.Data);

                            this.ListadoTerceros = result.Data;
                        } else {
                            console.log('Error Tercero Buscar: ' + result.Message);
                        }
                    } else {
                        this.ListadoTerceros = [];
                    }

                    if (this.ListadoTerceros.length <= 0) {
                        this.ExisteCliente = false;
                    }

                    this.BuscandoCliente = false;
                } else {
                    this.ListadoTerceros = [];
                    this.BuscandoCliente = false;
                }
            } else {
                this.BuscandoCliente = false;
                this.ListadoTerceros = [];
            }
        } else {
            this.BuscandoCliente = false;
        }
    }

    async SeleccionaTercero(data: any) {
        console.log(data);
        this.ListadoTerceros = [];
        this.Descripcion = "";
        this.BuscandoCliente = false;

        await this.eventoTercero.emit(data);
    }

    async AsignarTipoBusqueda(data: any) {
        for (var i = 0; i < this.ListadoTipoBusqueda.length; i++) {
            if (this.ListadoTipoBusqueda[i].Tipo == data.Tipo) {
                this.ListadoTipoBusqueda[i].Seleccionado = true;
                this.TipoBusqueda = data;       
            } else {
                this.ListadoTipoBusqueda[i].Seleccionado = false;
            }
        }
    }
}
