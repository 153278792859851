import { Component, OnInit, ViewChild, EventEmitter, Output, Input, Renderer2, Inject, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ClsNegocioModel } from '../../models/Aplicacion/ClsNegocio.model';
import { GeneralesService } from '../../services/_Generales.service';
declare var window: any;

@Component({
    selector: 'app-negocioListar',
    templateUrl: './negocioListar.component.html',
    styleUrls: ['./negocioListar.component.css']
})
export class NegocioListarComponent implements OnInit {
    @Input() Negocio: ClsNegocioModel;

    @Output() eventoNegocio: any = new EventEmitter();

    
    constructor(
        private renderer2: Renderer2,
        @Inject(DOCUMENT) private document: Document,
        private generales: GeneralesService
    ) {
        
    }

    async  ngOnInit() {
        
    }

    async SeleccionaNegocio(data: any) {
        console.log("SeleccionaNegocio", data);
        this.eventoNegocio.emit(data);
    }
}
