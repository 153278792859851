import { Component, OnInit } from '@angular/core';
import { PedidoService } from '../../services/Pedido.service';
import { ClsConsultaModel } from '../../models/Web/ClsConsulta.model';
import { ClsPedidoModel } from '../../models/Web/Clspedido.model';
import { ClsCancelaPedidoModel } from '../../models/Web/ClsCancelaPedido.model'
import { ClsPedidoEstadoModel } from '../../models/Web/ClsPedidoEstado.model';
import { ClsAccesoNegocioModel } from '../../models/Web/ClsAccesoNegocio.model';
import { ClsUsuarioAccesoModel } from '../../models/Web/ClsAccesoUsuario.model';
import { SignalRService } from '../../services/SignalR/signalR.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { ClsTercerosDomicilioModel } from '../../models/Web/ClsTercerosDomicilio.model';
import { ClsPedidoDomiciliarioModel } from '../../models/Web/ClsPedidoDomiciliario.model';
import { TipoPedidoEmpresaService } from '../../services/TipoPedidoEmpresa.service';
import { ClsTipoPedidoEmpresaConfigModel } from '../../models/Web/ClsTipoPedidoEmpresa.model';
import { PedidoCallCenterService } from '../../services/PedidoCallCenter.service';
import { ClsTipoPedidoModel } from '../../models/Aplicacion/ClsTipoPedidoEmpresa.model';
import { GeneralesService } from '../../services/_Generales.service';
import { ClsTercerosModel } from '../../models/Aplicacion/ClsTerceros.model';
import { ClsTercerosDireccionesModel } from '../../models/Aplicacion/ClsTercerosDirecciones.model';
import { ClsNegocioModel } from '../../models/Aplicacion/ClsNegocio.model';
import { ClsMenuVentaModel } from '../../models/Aplicacion/ClsMenuVenta.model';
import { ClsMenuDetalleModel } from '../../models/Aplicacion/ClsMenuDetalle.model';
import { ClsMenuDetalleArticuloModel } from '../../models/Aplicacion/ClsMenuDetalleArticulo.model';
import { ClsKanbanPedidoModel } from '../../models/KanbanPedido/ClsKanbanPedido.model';
declare var window: any;
declare var crearTicketImpresionWebBase64PDF;

@Component({
  selector: 'app-admin-pedidos-callcenter',
  templateUrl: './admin-pedidos-callcenter.component.html',
  styleUrls: ['./admin-pedidos-callcenter.component.css']
})
export class AdminPedidosCallcenterComponent implements OnInit {
  ip: string;
  empresa: number;
  negoId: number = 0;
  local: string = "";
  href: string = "";
  usuId: number = 0;
  ultId: number = 0;
  Top: number = 100;
  listaEspera: ClsPedidoModel[] = [];
  listaPreparacion: ClsPedidoModel[] = [];
  listaReparto: ClsPedidoModel[] = [];
  pedidoSeleccionado: ClsPedidoModel = new ClsPedidoModel();
  formaDomicilio: FormGroup;
  listaDomiciliarios: ClsTercerosDomicilioModel[] = [];
  audio = new Audio("../../assets/audio/notificacion.wav");
  reproducir = true;
  mensaje: string = "";
  domicilioSeleccionado: number = -1;

  HistorialPedidos: ClsKanbanPedidoModel[] = [];
  Detalle_Id: string = "";
  Historial_UltimoID = 0;
  Historial_Top = 10;
  PedidoEliminar: any;
  Motivo: string = "";
  //********************************************************************************//
  frmTipoPedido: FormGroup;
  frmDireccion: FormGroup;

  ListadoTipoPedido: ClsTipoPedidoModel[] = [];
  ListadoNegocio: ClsNegocioModel[] = [];

  Asignado_TipoPedido: ClsTipoPedidoModel;
  Asignado_Tercero: ClsTercerosModel;
  Asignado_Tercero_CargarMapa: string = "N";
  Asignado_Direccion: ClsTercerosDireccionesModel;
  Asignado_Direccion_Eliminar: ClsTercerosDireccionesModel;

  Asignado_Negocio: ClsNegocioModel;
  Asignado_MenuVenta: ClsMenuVentaModel;
  Asignado_MenuDetalle: ClsMenuDetalleModel;
  Asignado_MenuDetalleArticulo: ClsMenuDetalleArticuloModel;

  CreandoCliente: boolean = false;
  CaracterVacio: string = "---";
  ListaNegociosHistorial: ClsAccesoNegocioModel[] = [];
  NegoHistorial: number = 0;
  PedidoObservacion: string = "";
  //********************************************************************************//

  constructor(public pedidoService: PedidoService, private signalR: SignalRService, private router: Router, private pedidoCallCenterService: PedidoCallCenterService, private generales: GeneralesService) {
    //this.signalR.IniciarSignalR();

    this.formaDomicilio = new FormGroup({
      'CboDomiciliarios': new FormControl("-1")
    });

    this.frmTipoPedido = new FormGroup({
    });

    this.frmDireccion = new FormGroup({
    });

    let datos: any = localStorage.getItem('negocio');
    if (datos != null && datos != '') {
      let objNego = new ClsAccesoNegocioModel();
      objNego = JSON.parse(datos);
      this.negoId = objNego[0].NEGO_ID;
      this.empresa = objNego[0].NEGO_EMPRESA;
      this.local = objNego[0].NEGO_SISTEMA_LOCAL;
    }

    let datos1: any = localStorage.getItem('sesion');
    if (datos1 != null && datos1 != '') {
      let objUsu = new ClsUsuarioAccesoModel();
      objUsu = JSON.parse(datos1);
      this.usuId = objUsu.USU_ID;
      var info = JSON.parse(datos1);
      this.ListaNegociosHistorial = info.LISTADO_NEGOCIO;
    }

    this.ip = localStorage.getItem('ip');
    //this.escucharEventoSignal();

    this.audio = new Audio("../../assets/audio/bonus.wav");
    this.audio.load();
    this.audio.canPlayType("audio/*");
    this.reproducir = true;
  }

  async ngOnInit() {
    await this.ListarTipoPedidosEmpresa();

  }

  async ListarTipoPedidosEmpresa() {
    this.generales.OpenDialogAjax();

    let result: any = await this.pedidoCallCenterService.CallCenterTipoPedidoListar(this.empresa);
    if (result != null) {
      if (result.IsSuccess) {
        this.ListadoTipoPedido = result.Data;

        for (var i = 0; i < this.ListadoTipoPedido.length; i++) {
          if (this.ListadoTipoPedido[i].TP_DEFAULT == true) {
            this.Asignado_TipoPedido = this.ListadoTipoPedido[i];
            await this.pedidoCallCenterService.AgregarCarrito_TipoPedido(this.Asignado_TipoPedido);
          }
        }
      } else {
        console.log('Error al Call Center Tipo Pedido Listar: ' + result.Message);
      }
    } else {

    }

    this.generales.CancelDialogAjax();
  }

  async AsignarTipoPedido(tipoPedido: any) {
    this.generales.OpenDialogAjax();

    console.log("AsignarTipoPedido: ", tipoPedido);
    this.Asignado_TipoPedido = tipoPedido;
    await this.pedidoCallCenterService.AgregarCarrito_TipoPedido(this.Asignado_TipoPedido);

    this.generales.CancelDialogAjax();
  }

  async AsignarTercero(data: any) {
    console.log("Tercero seleccionado", data);
    this.Asignado_Tercero = null;
    this.Asignado_Tercero_CargarMapa = "N";
    setTimeout(async () => {
      this.Asignado_Tercero = data;

      await this.pedidoCallCenterService.AgregarCarrito_TerceroPedido(this.Asignado_Tercero);
      await this.pedidoCallCenterService.AgregarCarrito_TerceroPaga(this.Asignado_Tercero);

      await this.LimpiarDireccionAsignado();
      await this.LimpiarNegocioListado();
    }, 100);
  }

  async AceptaBuscarDireccion(data: any) {
    try {
      console.log("AceptaBuscarDireccion", data);
      if (this.Asignado_Tercero != null) {
        if (data.IsSuccess) {
          let direccionTmp = new ClsTercerosDireccionesModel();
          direccionTmp.DT_ID = 0;
          direccionTmp.DT_TERCERO_ID = this.Asignado_Tercero.TER_ID;
          direccionTmp.DT_GEO_LATITUD = data.lat;
          direccionTmp.DT_GEO_LONGITUD = data.lng;
          direccionTmp.DT_GEO_DIRECCION = data.formatted_address;
          direccionTmp.DT_DIRECCION = data.formatted_address;
          direccionTmp.DT_INDICACIONES = data.indicaciones;
          direccionTmp.DT_FAVORITA = false;
          direccionTmp.DT_REGISTRADA = false;
          direccionTmp.DT_IP_EQUIPO = this.ip;
          direccionTmp.DT_SELECCIONADA = true;

          //GUARDAR DIRECCION
          this.generales.OpenDialogAjax();
          let result: any = await this.pedidoCallCenterService.CallCenterDireccionGuardar(direccionTmp);
          if (result != null) {
            if (result.IsSuccess) {
              this.Asignado_Tercero.Direcciones = result.Data;
              if (this.Asignado_Tercero.Direcciones == null) {
                this.Asignado_Tercero.Direcciones = [];
              } else {
                for (var i = 0; i < this.Asignado_Tercero.Direcciones.length; i++) {
                  if (this.Asignado_Tercero.Direcciones[i].DT_SELECCIONADA == true) {
                    direccionTmp = this.Asignado_Tercero.Direcciones[i];
                  }
                }
              }
              this.Asignado_Direccion = direccionTmp;
              await this.pedidoCallCenterService.AgregarCarrito_Direccion(this.Asignado_Direccion);
            } else {
              console.log('Error al Guardar Dirección: ' + result.Message);
              this.generales.MostrarMensajeERROR(result.Message);
            }
          } else {
            this.generales.MostrarMensajeERROR("No es posible almacenar la información de la dirección en este momento.");
          }
          this.generales.CancelDialogAjax();

          await this.ListarNegocio();
        } else {
          this.generales.MostrarMensajeERROR("Error al procesar la dirección del cliente, por favor intente nuevamente.");
        }
      } else {
        this.generales.MostrarMensajeERROR("No ha seleccionado un cliente para agregar la dirección, por favor verifique.");
      }

    } catch (e) {
      this.generales.CancelDialogAjax();
    } finally {

    }
  }

  async AceptaCrearTercero(data: any) {
    console.log("AceptaCrearTercero", data);
    this.Asignado_Tercero = null;

    setTimeout(async () => {
      this.Asignado_Tercero = data;

      await this.pedidoCallCenterService.AgregarCarrito_TerceroPedido(this.Asignado_Tercero);
      await this.pedidoCallCenterService.AgregarCarrito_TerceroPaga(this.Asignado_Tercero);

      await this.LimpiarDireccionAsignado();
      await this.LimpiarNegocioListado();
    }, 100);
  }

  async AsignarDireccion(data: any) {
    this.Asignado_Direccion = null;

    setTimeout(async () => {
      this.Asignado_Direccion = data;

      await this.pedidoCallCenterService.AgregarCarrito_Direccion(this.Asignado_Direccion);

      await this.ListarNegocio();
    }, 100);
  }

  async ListarNegocio() {
    await this.LimpiarNegocioAsignado();

    if (this.Asignado_Direccion != null && this.empresa != null && this.empresa != 0 && this.empresa != undefined) {
      this.generales.OpenDialogAjax();
      let result: any = await this.pedidoCallCenterService.CallCenterNegocioPorTipoPedidoListar(this.empresa, this.Asignado_Direccion.DT_GEO_LATITUD, this.Asignado_Direccion.DT_GEO_LONGITUD, this.Asignado_TipoPedido.TP_ID);
      if (result != null) {
        if (result.IsSuccess) {
          console.log('Negocios: ', result.Data);
          this.ListadoNegocio = result.Data;
        } else {
          console.log('Error al listar los negocios: ' + result.Message);
          this.generales.MostrarMensajeERROR(result.Message);
        }
      } else {
        this.generales.MostrarMensajeERROR("No es posible listar la información de los negocios en este momento.");
      }
      this.generales.CancelDialogAjax();
    } else {
      console.log('Error en información no definida para listar los negocio');
    }
  }

  async AsignarNegocio(data: any) {
    console.log("AsignarNegocio", data);
    await this.LimpiarNegocioAsignado();

    setTimeout(async () => {
      this.Asignado_Negocio = data;
      await this.pedidoCallCenterService.AgregarCarrito_Negocio(this.Asignado_Negocio);
      window.$('#modalMenuPedido').modal('show');
    }, 100);
  }

  async AsignaMenuVenta(data: any) {
    console.log("AsignaMenuVenta", data);
    this.Asignado_MenuVenta = null;

    setTimeout(() => {
      this.Asignado_MenuVenta = data;
    }, 100);
  }

  async AsignaMenuDetalle(data: any) {
    console.log("AsignaMenuDetalle", data);
    this.Asignado_MenuDetalle = null;

    setTimeout(async () => {
      this.Asignado_MenuDetalle = data;

      if (!data.DETALLE_VARIOS) {
        this.AsignaMenuDetalleArticulo(data.Articulo_Unitario);
      } else {
        await this.AbrirModal_MenuDetalle(data);
      }
    }, 100);
  }

  async AsignaMenuDetalleArticulo(data: any) {
    console.log("AsignaMenuDetalleArticulo", data);
    this.Asignado_MenuDetalleArticulo = null;

    setTimeout(async () => {
      this.Asignado_MenuDetalleArticulo = data;

      await this.AbrirModal_MenuDetallePersonalizar(data);
    }, 100);
  }

  async AbrirModal_MenuDetalle(data: any) {
    window.$('#modalMenuDetalleArticulo').modal('show');
  }

  async AbrirModal_MenuDetallePersonalizar(data: any) {
    window.$('#modalMenuDetallePersonalizar').modal('show');
  }

  async LimpiarNegocioListado() {
    this.ListadoNegocio = [];
  }
  async LimpiarNegocioAsignado() {
    this.Asignado_Negocio = null;
  }
  async LimpiarDireccionAsignado() {
    this.Asignado_Direccion = null;
  }
  async LimpiarTerceroAsignado() {
    this.Asignado_Tercero = null;
  }

  async ValidaCancelarPedido() {
    window.$('#modalCancelarPedido').modal('show');
  }

  async CancelarPedido() {
    window.$('#modalMenuPedido').modal('hide');
  }

  async AceptaPersonalizarArticulo(data: any) {
    console.log("AceptaPersonalizarArticulo", data);
  }

  async AbrirModal_CarritoCompra() {
    window.$('#modalCarritoCompra').modal('show');
  }

  async CerrarModal_CarritoCompra(data: any) {
    window.$('#modalCarritoCompra').modal('hide');
  }

  async VerificarTiempoEspera(event: any, Id: number) {
    for (var i = 0; i < this.pedidoCallCenterService.Carrito_Compra.Tiempo_Espera.length; i++) {
      if (this.pedidoCallCenterService.Carrito_Compra.Tiempo_Espera[i].ID == Id) {
        this.pedidoCallCenterService.Carrito_Compra.Tiempo_Espera[i].SELECCIONADO = true;
      } else {
        this.pedidoCallCenterService.Carrito_Compra.Tiempo_Espera[i].SELECCIONADO = false;
      }
    }
  }

  async ValidarTiempoEsperaSeleccionado() {
    try {
      if (this.pedidoCallCenterService.Carrito_Compra.Tiempo_Espera != null) {
        if (this.pedidoCallCenterService.Carrito_Compra.Tiempo_Espera.length > 0) {
          for (var i = 0; i < this.pedidoCallCenterService.Carrito_Compra.Tiempo_Espera.length; i++) {
            if (this.pedidoCallCenterService.Carrito_Compra.Tiempo_Espera[i].SELECCIONADO) {
              return true;
            }
          }

          //this.uiService.ShowAlert("Seleccione el tiempo de espera.", environment.tituloAPP);
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } catch (e) {
      //this.uiService.ShowAlert("Verifique el tiempo de espera.", environment.tituloAPP);
      return false;
    }
  }

  async ConfirmarPedido() {
    //let Cargar = await this.uiService.iniciarLoadingNEW('');
    await this.generales.OpenDialogAjax();
    try {
      let validaTiempoEspera = await this.ValidarTiempoEsperaSeleccionado();
      if (validaTiempoEspera) {
        console.log("obs", this.PedidoObservacion);
        const result: any = await this.pedidoCallCenterService.CallCenterPedidoGuardar(this.negoId, this.usuId, this.PedidoObservacion, "CALLCENTER");
        debugger;
        if (result != null) {
          if (result.IsSuccess) {
            //        await this.carritoCompra.VaciarCarritoCompra(this.infoCarritoPAGO.Negocio);

            //        await this.Salir(verificarInfo.Data);
            //        await this.navCtrl.navigateRoot("/main/tabs/tabPedidoDetalle/" + verificarInfo.Data, { animated: true, animationDirection: "forward" });
            //    } else {
            //        this.uiService.ShowAlert(verificarInfo.Message, environment.tituloAPP);
            this.LimpiarPedido();
            await this.generales.MostrarMensajeOK(result.Message);
          } else {
            await this.generales.MostrarMensajeERROR(result.Message);
          }
        } else {
          //this.uiService.ShowAlert("No es posible continuar, intente nuevamente.", environment.tituloAPP);
          await this.generales.MostrarMensajeERROR("No es posible almacenar la información, intente nuevamente.");
        }
      }
    } catch (e) {
      console.log(e.message);
    }
    finally {
      await this.generales.CancelDialogAjax();
    }
  }

  async VerificarMedioPago(Control: any, MedioPago: any) {
    this.pedidoCallCenterService.Carrito_Compra.Negocio.MedioPago.forEach((medio => {
      if (medio.MedioPago_Negocio_ID == MedioPago.MedioPago_Negocio_ID) {
        medio.Seleccionado = true;
        this.pedidoCallCenterService.AgregarCarrito_MedioPago(MedioPago);
      } else {
        medio.Seleccionado = false;
      }
    }));
  }

  async LimpiarPedido() {
    window.$('#modalCarritoCompra').modal('hide');
    window.$('#modalMenuPedido').modal('hide');

    //this.Asignado_TipoPedido = null;
    this.Asignado_Tercero = null;
    this.Asignado_Direccion = null;

    this.Asignado_Negocio = null;
    this.Asignado_MenuVenta = null;
    this.Asignado_MenuDetalle = null;
    this.Asignado_MenuDetalleArticulo = null;

    this.pedidoCallCenterService.VaciarCarritoCompra();

    this.ListadoNegocio = [];
  }

  async AbrirHistorialPedidos() {
    try {
      this.Detalle_Id = "";
      this.Historial_UltimoID = 0;
      this.HistorialPedidos = [];

      setTimeout(async () => {
        window.$('#modalHistorialPedidos').modal('show');

        await this.CargarHistorialPedidos();
      }, 100);
    } catch (e) {

    } finally {

    }
  }

  CambiarNegocioHistorial() {
    this.Historial_UltimoID = 0;
    this.HistorialPedidos = [];
    this.CargarHistorialPedidos();
  }


  async CargarHistorialPedidos() {
    try {

      this.generales.OpenDialogAjax();

      let resp: any = await this.pedidoService.ListarPedidoHistorial(this.empresa, this.NegoHistorial, this.Historial_UltimoID, this.Historial_Top);
      console.log("negocio", this.NegoHistorial);
      if (resp != null) {
        console.log("resp negocio", resp);
        if (resp.IsSuccess) {
          if (resp.Data != null) {
            for (var i = 0; i < resp.Data.length; i++) {
              let Pedido = resp.Data[i];
              //if (Pedido.Informacion_Pedido.Pedido_ID > this.Historial_UltimoID) {
              this.Historial_UltimoID = Pedido.Informacion_Pedido.Pedido_ID;
              //}
              this.HistorialPedidos.push(Pedido);
            }
            console.log("historial", this.HistorialPedidos);
          }
        } else {
          this.generales.MostrarMensajeERROR(resp.Message);
        }
      } else {
        this.generales.MostrarMensajeERROR("Error listando historial de pedidos.");
      }
    } catch (e) {

    } finally {
      await this.generales.CancelDialogAjax();
    }
  }

  async DetalleHistorialPedido(Pedido: ClsKanbanPedidoModel) {
    if (this.Detalle_Id == "Detalle_" + Pedido.Informacion_Pedido.Pedido_ID) {
      this.Detalle_Id = "";
    } else {
      this.Detalle_Id = "Detalle_" + Pedido.Informacion_Pedido.Pedido_ID;
    }
  }

  ValidarCancelarPedido(Pedido: any) {
    this.PedidoEliminar = Pedido;
    window.$('#modalCancelarPedidoGuardado').modal('show');
  }

  async GuadarCancelarPedido(Pedido: ClsKanbanPedidoModel) {
    if (this.Motivo == "") {

    } else {
      try {
        let cancela: ClsCancelaPedidoModel = new ClsCancelaPedidoModel();
        cancela.EmpresaId = this.empresa;
        cancela.IpEquipo = this.ip;
        cancela.UsuId = this.usuId;
        cancela.Motivo = this.Motivo;
        cancela.NegoId = Pedido.Negocio.NEGO_ID;
        cancela.PedidoId = Pedido.Informacion_Pedido.Pedido_ID;

        this.generales.OpenDialogAjax();
        let resp: any = await this.pedidoService.CancelarPedidoId(cancela);
        if (resp != null) {
          if (resp.IsSuccess) {
            this.generales.MostrarMensajeOK(resp.Message);
            window.$('#modalHistorialPedidos').modal('hide');
          } else {
            this.generales.MostrarMensajeERROR(resp.Message);
          }
        } else {
          this.generales.MostrarMensajeERROR("Error cancelando el pedido.");
        }

      } catch (e) {

      } finally {
        await this.generales.CancelDialogAjax();
        this.Motivo = "";
        window.$('#modalCancelarPedidoGuardado').modal('hide');
      }
    }
  }

  ValidarEliminarDireccionTercero(direccion: any) {
    window.$('#modalEliminarDireccionTercero').modal('show');
    this.Asignado_Direccion_Eliminar = direccion;
    console.log("si llega", direccion);
  }

  async EliminarDireccionTercero() {
    try {
      this.generales.OpenDialogAjax();
      console.log("direccionid", this.Asignado_Direccion_Eliminar.DT_ID);
      let resp: any = await this.pedidoCallCenterService.CallCenterDireccionEliminar(this.Asignado_Direccion_Eliminar.DT_ID);
      if (resp != null) {
        if (resp.IsSuccess) {
          this.generales.MostrarMensajeOK(resp.Message);
          window.$('#modalEliminarDireccionTercero').modal('hide');
          let dt = this.Asignado_Tercero.Direcciones.findIndex(s => s == this.Asignado_Direccion_Eliminar);
          this.Asignado_Tercero.Direcciones.splice(dt, 1);
        } else {
          this.generales.MostrarMensajeERROR(resp.Message);
        }
      } else {
        this.generales.MostrarMensajeERROR("Error eliminando dirección, intente mas tarde.");
      }
    } catch (e) {

    } finally {
      window.$('#modalEliminarDireccionTercero').modal('hide');
      await this.generales.CancelDialogAjax();
    }
  }

  CargarMapa() {
    this.Asignado_Tercero_CargarMapa = "S";
  }


}
