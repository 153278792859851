import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ClsAccesoNegocioModel } from '../../models/Web/ClsAccesoNegocio.model';
import { ConfigEmpresaService } from '../../services/ConfigEmpresa.service';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-negocios',
  templateUrl: './negocios.component.html',
  styleUrls: ['./negocios.component.css']
})
export class NegociosComponent implements OnInit {
  @Input() inputNegocios: string[] = []
  negocios: ClsAccesoNegocioModel[] = []
  @Output() eventoNegocios: any = new EventEmitter();
  empresaId: number;
  formaNegocios: FormGroup;

  constructor(private empresaService: ConfigEmpresaService) {
    let datosn: any = localStorage.getItem('negocio');
    if (datosn != null && datosn != '') {
      let objNego: any = JSON.parse(datosn);
      this.empresaId = objNego[0].NEGO_EMPRESA;
    }
  }

  async ngOnInit() {
    this.formaNegocios = new FormGroup({});

    await this.ListarNegociosEmpresa();
  }

  async ListarNegociosEmpresa() {
    let resp: any = await this.empresaService.ConsultarNegociosEmpresa(this.empresaId);
    console.log(resp);
    if (resp != null) {
      for (var i = 0; i < resp.length; i++) {
        this.formaNegocios.addControl(resp[i].NEGO_ID + "", new FormControl());
      }
      this.negocios = resp;
      for (var i = 0; i < this.inputNegocios.length; i++) {
        if (this.formaNegocios.controls[this.inputNegocios[i]] != null) {
          this.formaNegocios.controls[this.inputNegocios[i]].setValue(true);
        }
      }
    }
  }

  async seleccionar() {
    let listaId :string[]=[]
    for (const field in this.formaNegocios.controls) { // 'field' is a string
      const control = this.formaNegocios.get(field); // 'control' is a FormControl      
      if (control.value == true) {
        listaId.push(field);
      }      
    }
    console.log(listaId);
    this.eventoNegocios.emit(listaId);
  }
}
