import { Component, OnInit, ViewChild, EventEmitter, Output, Input, Renderer2, Inject, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ClsNegocioModel } from '../../models/Aplicacion/ClsNegocio.model';
import { GeneralesService } from '../../services/_Generales.service';
import { ClsMenuVentaModel } from '../../models/Aplicacion/ClsMenuVenta.model';
import { ClsMenuToppingModel } from '../../models/Aplicacion/ClsMenuTopping.model';
import { ClsMenuDetalleModel } from '../../models/Aplicacion/ClsMenuDetalle.model';
import { ClsMenuDetalleArticuloModel } from '../../models/Aplicacion/ClsMenuDetalleArticulo.model';
import { PedidoCallCenterService } from '../../services/PedidoCallCenter.service';
import { ClsReferenciaToppingValorModel } from '../../models/Aplicacion/ClsReferenciaToppingValor.model';
import { ClsMenuToppingArticuloModel } from '../../models/Aplicacion/ClsMenuToppingArticulo.model';
import { ClsReferenciaModel } from '../../models/Aplicacion/ClsReferencia.model';

declare var window: any;

@Component({
    selector: 'app-infoMenuDetallePersonalizarSUB',
    templateUrl: './infoMenuDetallePersonalizarSUB.component.html',
    styleUrls: ['./infoMenuDetallePersonalizarSUB.component.css']
})
export class InfoMenuDetallePersonalizarSUBComponent implements OnInit {
    @Input() Negocio: ClsNegocioModel;
    @Input() MenuVenta: ClsMenuVentaModel;
    @Input() SubCombo: any;

    @Output() eventoPersonalizarSUB: any = new EventEmitter();

    PersonalizarValido: boolean = true;

    constructor(
        private renderer2: Renderer2,
        @Inject(DOCUMENT) private document: Document,
        private pedidoCallCenterService: PedidoCallCenterService,
        private generales: GeneralesService
    ) {

    }

    async  ngOnInit() {
        await this.VerificaPersonalizarTODO();
    }

    async VerificaPersonalizarToggle(Control: any, Combo: any, Detalle: any) {
        for (var i = 0; i < Combo.Combo_Detalle.length; i++) {
            if (Combo.Combo_Detalle[i].COMBO_ID == Detalle.COMBO_ID) {
                Combo.Combo_Detalle[i].COMBO_SELECCIONADO = true;
            } else {
                Combo.Combo_Detalle[i].COMBO_SELECCIONADO = false;
            }
        }

        console.log("Detalle.COMBO_SELECCIONADO", Detalle.COMBO_SELECCIONADO);

        this.VerificarMenuValidoToggle(Combo, Detalle);
    }

    async VerificarMenuValidoChecked(Combo: any) {
        Combo.COMBO_MENU_VALIDO = true;

        let CtaSeleccionados = 0;
        Combo.Combo_Detalle.forEach((detalle => {
            if (detalle.COMBO_SELECCIONADO == true && detalle.COMBO_MODIFICABLE == true) {
                CtaSeleccionados++;
            }
        }));

        if (CtaSeleccionados < Combo.COMBO_CANTIDAD_MINIMA) {
            Combo.COMBO_MENU_VALIDO = false;
            this.PersonalizarValido = false;
        }

        if (CtaSeleccionados > Combo.COMBO_CANTIDAD_MAXIMA) {
            Combo.COMBO_MENU_VALIDO = false;
            this.PersonalizarValido = false;
        }

        await this.TotalizarValorCOMBO();
        await this.VerificarTodoPersonalizarValido();

        console.log("Combo", Combo);
    }

    async VerificaPersonalizarTODO() {
        this.SubCombo.Info_SubCombo_Valor.Info_SubCombo.forEach((combo => {
            if (combo.COMBO_CANTIDAD_MAXIMA > 1 && combo.COMBO_MUESTRA_MENU == true) {
                this.VerificarMenuValidoChecked(combo);
            }

            if (combo.COMBO_CANTIDAD_MAXIMA <= 1 && combo.COMBO_MUESTRA_MENU == true) {
                combo.Combo_Detalle.forEach((detalle => {
                    this.VerificarMenuValidoToggle(combo, detalle);
                }));
            }
        }));
    }

    async VerificarTodoPersonalizarValido() {
        this.PersonalizarValido = true;

        this.SubCombo.Info_SubCombo_Valor.Info_SubCombo.forEach((combo => {
            if (combo.COMBO_MENU_VALIDO == false) {
                this.PersonalizarValido = false;
            }
        }));
    }

    async VerificarMenuValidoToggle(Combo: any, Detalle: any) {
        Combo.COMBO_MENU_VALIDO = true;

        let CtaSeleccionados = 0;

        if (Detalle.COMBO_SELECCIONADO == true) {
            Combo.Combo_Detalle.forEach((detalle => {
                if (detalle.COMBO_ID != Detalle.COMBO_ID && detalle.COMBO_MODIFICABLE == true) {
                    detalle.COMBO_SELECCIONADO = false;
                }
            }));
        }

        Combo.Combo_Detalle.forEach((detalle => {
            if (detalle.COMBO_SELECCIONADO == true && detalle.COMBO_MODIFICABLE == true) {
                CtaSeleccionados++;
            }
        }));

        if (CtaSeleccionados < Combo.COMBO_CANTIDAD_MINIMA) {
            Combo.COMBO_MENU_VALIDO = false;
            this.PersonalizarValido = false;
        }

        if (CtaSeleccionados > Combo.COMBO_CANTIDAD_MAXIMA) {
            Combo.COMBO_MENU_VALIDO = false;
            this.PersonalizarValido = false;
        }

        await this.TotalizarValorCOMBO();
        await this.VerificarTodoPersonalizarValido();

        console.log("Detalle", Detalle);
        console.log("Combo", Combo);
    }

    async TotalizarValorCOMBO() {
        let Combo_Valor_Total = 0;
        let Combo_Valor_SubTotal = 0;
        let Combo_Valor_Impuesto = 0;

        this.SubCombo.Info_SubCombo_Valor.Info_SubCombo.forEach((combo => {
            combo.Combo_Detalle.forEach((detalle => {
                if (detalle.COMBO_SELECCIONADO == true) {
                    Combo_Valor_Total = Combo_Valor_Total + detalle.COMBO_VALOR_TOTAL;
                    Combo_Valor_SubTotal = Combo_Valor_SubTotal + detalle.COMBO_VALOR_SUBTOTAL;
                    Combo_Valor_Impuesto = Combo_Valor_Impuesto + detalle.COMBO_VALOR_IMPUESTO;
                }
            }));
        }));

        this.SubCombo.Info_SubCombo_Valor.SUBCOMBO_VALOR_TOTAL = Combo_Valor_Total;
        this.SubCombo.Info_SubCombo_Valor.SUBCOMBO_VALOR_SUBTOTAL = Combo_Valor_SubTotal;
        this.SubCombo.Info_SubCombo_Valor.SUBCOMBO_VALOR_IMPUESTO = Combo_Valor_Impuesto;

        //this.Referencia.Referencia_Valor.REF_UNIDAD_VENTA_TOTAL = this.Referencia.Referencia_Valor.REF_UNIDAD_ORIGINAL_TOTAL + this.Referencia.Referencia_Combo_Valor.REFCOMBO_VALOR_TOTAL + this.Referencia.Referencia_Topping_Valor.REFTOP_VALOR_TOTAL;
        //this.Referencia.Referencia_Valor.REF_UNIDAD_VENTA_SUBTOTAL = this.Referencia.Referencia_Valor.REF_UNIDAD_ORIGINAL_SUBTOTAL + this.Referencia.Referencia_Combo_Valor.REFCOMBO_VALOR_SUBTOTAL + this.Referencia.Referencia_Topping_Valor.REFTOP_VALOR_SUBTOTAL;
        //this.Referencia.Referencia_Valor.REF_UNIDAD_VENTA_IMPUESTO = this.Referencia.Referencia_Valor.REF_UNIDAD_ORIGINAL_IMPUESTO + this.Referencia.Referencia_Combo_Valor.REFCOMBO_VALOR_IMPUESTO + this.Referencia.Referencia_Topping_Valor.REFTOP_VALOR_IMPUESTO;

        //this.datosREFERENCIA.Referencia_Valor.REF_VALOR_TOTAL = this.datosREFERENCIA.Referencia_Valor.REF_UNIDAD_VENTA_TOTAL * this.datosREFERENCIA.REF_CANTIDAD_TOTAL;
        //this.datosREFERENCIA.Referencia_Valor.REF_VALOR_SUBTOTAL = this.datosREFERENCIA.Referencia_Valor.REF_UNIDAD_VENTA_SUBTOTAL * this.datosREFERENCIA.REF_CANTIDAD_TOTAL;
        //this.datosREFERENCIA.Referencia_Valor.REF_VALOR_IMPUESTO = this.datosREFERENCIA.Referencia_Valor.REF_UNIDAD_VENTA_IMPUESTO * this.datosREFERENCIA.REF_CANTIDAD_TOTAL;
    }

    async AceptaPersonalizarSubCOMBO(event: any) {
        //this.Salir(event);
    }

    async AceptaPersonalizarSubCombo(data: any) {
        await this.generales.OpenDialogAjax();

        await this.eventoPersonalizarSUB.emit(data);

        await window.$('#modalMenuDetallePersonalizarSUB').modal('hide');

        await this.generales.CancelDialogAjax();
    }
}
